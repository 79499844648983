import React, { useEffect, useState } from "react";
import BottomNav from "./BottomNav";
import StatusBar from "./StatusBar";
import styles from "./Container.module.scss";
import { ToastProvider } from "react-toast-notifications";

import {
  Grid,
  Loader,
  Modal,
  Button,
  Icon,
  Checkbox,
  Placeholder,
} from "semantic-ui-react";
import { preloadStates } from "../store/actions/uiActions";
import { updateDayStreak } from "../store/actions/diaryActions";
import { getLatestXp } from "../store/actions/xpActions";
import { updateTdee, updateTerms } from "../store/actions/authActions";
import { Routes, Route, Navigate } from "react-router-dom";
import { resetOverlay, loadOverlay } from "../store/actions/uiActions";
import Home from "../screens/Home/HomeScreen";
import Planner from "../screens/Planner";
import Recipes from "../screens/Recipes";
import Profile from "../screens/Profile/ProfileHome";
import SignIn from "../screens/Registration/SignIn";
import Progress from "../screens/Profile/Progress";
import Shopping from "../screens/shopping/Shopping";
import Stream from "../screens/stream/";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import TDEEQuiz from "../screens/Profile/TDEE";
import Intro from "../screens/Profile/Intro";
import axios from "axios";
import token from "../api/axios-token";
import { useQuery, useQueryClient } from "react-query";

const getTerms = () =>
  axios
    .get("https://www.iubenda.com/api/terms-and-conditions/82211088/no-markup")
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });

const TermsModal = () => {
  const [mailing, setMailing] = useState(0);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const data = useQuery("terms-and-conditions", getTerms, {
    refetchOnWindowFocus: false,
  });

  const acceptTerms = () => {
    const acceptTerms = {
      mailing: mailing,
    };

    token
      .post("/profile/update_terms", {
        mailing: mailing,
      })
      .then(function () {
        dispatch(updateTerms(acceptTerms));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Modal className={styles.TermsModal} open={true}>
      <Modal.Header>Terms and Conditions</Modal.Header>
      {show ? (
        <Modal.Content scrolling>
          <Modal.Description>
            {data.isLoading || !data.data ? (
              <Placeholder fluid>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line /> <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line /> <Placeholder.Line />
                  <Placeholder.Line /> <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
              </Placeholder>
            ) : (
              <div
                style={{ width: "100%", overflow: "hidden" }}
                dangerouslySetInnerHTML={{ __html: data.data.content }}
              />
            )}
          </Modal.Description>
        </Modal.Content>
      ) : (
        <Modal.Content>
          <div>
            Our terms and conditions have been updated, please read and agree
            before continuing to PlanEatMeal.
          </div>
          <Button
            onClick={() => setShow(true)}
            color="teal"
            style={{ marginTop: "1em" }}
          >
            Terms and Conditions
          </Button>
        </Modal.Content>
      )}
      <Modal.Actions>
        <Grid columns="equal" stackable>
          <Grid.Column>
            <div>
              <Checkbox
                checked={mailing}
                onChange={(e, d) => setMailing(d.checked ? 1 : 0)}
                toggle
                label="Sign up to mailing list for recipes, fitness tips, and more. "
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <Button onClick={() => acceptTerms()} primary>
              Accept Terms and Conditions
            </Button>
          </Grid.Column>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
};

const ContainerBox = (props) => {
  const [xp, setXp] = useState([]);
  const [bubbles, setBubbles] = useState([]);
  const [terms, showTerms] = useState(false);
  const updateUserTdee = (payload) => {
    props.updateTdee(payload);
    props.resetOverlay();
  };
  const queryClient = new useQueryClient();

  useEffect(() => {
    if (props.auth.loggedIn) {
      const onboarding = props.auth.user.onboarding;
      const introCheck = _.find(onboarding, {
        boarding_variable: "INTRODUCTION",
        status: null,
      });

      if (introCheck) {
        props.loadOverlay("INTRODUCTION");
      }

      if (!props.recipesLoaded || !props.plannerLoaded) {
        props.preloadStates();
      }
      props.updateDayStreak();
      props.getLatestXp();
    }
  }, []);

  useEffect(() => {
    if (props.auth.user && props.auth.user.terms) {
      if (parseInt(props.auth.user.terms) != 1) {
        showTerms(true);
      } else {
        showTerms(false);
      }
    }
  }, [props.auth.user]);

  useEffect(() => {}, []);

  useEffect(() => {
    const bubbles = _.filter(props.xp, function (r) {
      return r.amount > 0 || r.type == "water_goal";
    });
    setBubbles(bubbles);
  }, [props.xp]);

  return (
    <>
      {props.overlay ? (
        <>
          {props.overlay == "TDEE" && (
            <>
              {terms && <TermsModal />}
              <TDEEQuiz update={(payload) => updateUserTdee(payload)} />
            </>
          )}
          {props.overlay == "INTRODUCTION" && <Intro />}
        </>
      ) : (
        <>
          {!props.recipesLoaded || !props.plannerLoaded ? (
            <Grid
              textAlign="center"
              style={{ height: "100vh", width: "100%" }}
              verticalAlign="middle"
              centered
            >
              <Grid.Column>
                <Loader active />
              </Grid.Column>
            </Grid>
          ) : (
            <>
              {terms ? (
                <TermsModal />
              ) : (
                <ToastProvider>
                  <StatusBar
                    auth={props.auth}
                    streak={props.streak}
                    total={props.total}
                    preTotal={props.preTotal}
                    bubbles={bubbles}
                  />

                  <div data-tour="container" className={styles.container}>
                    <div data-tour="content" className={styles.content}>
                      <Routes>
                        <Route path="/home/*" element={<Home />} />
                        <Route path="/sign-in" element={<SignIn />} />
                        <Route path="/progress" element={<Progress />} />
                        <Route path="/profile/*" element={<Profile />} />
                        <Route path="/planner/*" element={<Planner />} />
                        <Route path="/recipes/*" element={<Recipes />} />
                        <Route path="/shopping/*" element={<Shopping />} />
                        <Route path="/*" element={<Navigate to="/home" />} />
                      </Routes>
                    </div>
                  </div>
                  <Grid className={`${styles.bottomNav} no-print`}>
                    <Grid.Row only="tablet mobile">
                      <BottomNav />
                    </Grid.Row>
                  </Grid>
                </ToastProvider>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    preloadStates: () => dispatch(preloadStates()),
    updateDayStreak: () => dispatch(updateDayStreak()),
    getLatestXp: () => dispatch(getLatestXp()),
    updateTdee: (payload) => dispatch(updateTdee(payload)),
    resetOverlay: () => dispatch(resetOverlay()),
    loadOverlay: (payload) => dispatch(loadOverlay(payload)),
  };
};
const mapStateToProps = (state) => {
  return {
    recipesLoaded: state.recipes.recipesLoaded,
    recipeDetailsLoaded: state.recipes.recipeDetailsLoaded,
    plannerLoaded: state.planner.plannerLoaded,
    streak: state.diary.streak,
    xp: state.xp.xp,
    total: state.xp.total,
    preTotal: state.xp.existingTotal,
    auth: state.auth,
    overlay: state.ui.overlay,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContainerBox);
