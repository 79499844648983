import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import recipeclass from "./Recipes.module.scss";
import classes from "../Screens.module.scss";
import RecipeCard from "../../components/recipes/recipeCard";
import { connect } from "react-redux";
import axios from "../../api/axios-token";
import { getRecipes } from "../../store/actions/recipeActions";
import useWindowSize from "../../utility/useWindowSize";
import _ from "lodash";
import Tags from "../Recipes/components/TagSearch";
import amplitude from "amplitude-js";
import {
  Container,
  Grid,
  Header,
  Progress,
  Button,
  Message,
  Ref,
  Dimmer,
  Loader,
  Icon,
  Step,
  List,
  Label,
} from "semantic-ui-react";

const RecipePDF = (props) => {
  const inputFileRef = useRef(null);
  const [scanStarted, setScanStarted] = useState(false);
  const [scanComplete, setScanComplete] = useState(false);
  const [scanProgress, setScanProgress] = useState(0);
  const [startUpload, setStartUpload] = useState(false);
  const [recipes, setRecipes] = useState([]);
  const [errors, setErrors] = useState(null);
  const [serverResponse, setServerResponse] = useState(null);
  const [importingRecipes, setImportingRecipes] = useState(false);
  const [scanCaption, setScanCaption] = useState(null);

  const [tags, setTags] = useState([]);
  let history = useNavigate();

  const windowSize = useWindowSize();
  const scanRef = useRef(null);
  const uploadRef = useRef(null);
  const foundRef = useRef(null);

  function toggleDisable(id, toggle) {
    const arrayCopy = recipes;

    _.set(_.find(arrayCopy, { id: id }), "disabled", toggle);
    setRecipes([...arrayCopy]);
  }

  useEffect(() => {
    if (uploadRef.current) {
      uploadRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    if (scanRef.current) {
      scanRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    if (foundRef.current && recipes.length > 0) {
      const scrollTop = foundRef.current.offsetTop;
      console.log(scrollTop);
      window.scroll({ top: scrollTop, behavior: "smooth" });
    }
  }, [scanStarted, scanComplete, recipes]);

  function checkScanProgress() {
    const checkInterval = setInterval(() => {
      axios
        .get("/recipes/scan_progress")
        .then(function (response) {
          if (response.data.progress.message) {
            setScanCaption(response.data.progress.message);
          }
          if (response.data.progress.progress > 0) {
            const progress =
              (response.data.progress.progress / response.data.progress.total) *
              100;
            setScanProgress(progress);
            if (progress == 100) {
              clearInterval(checkInterval);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }, 200);
    return false;
  }
  function uploadFile(e) {
    setStartUpload(true);
    const config = {
      timeout: 1000 * 60 * 2,
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
        if (percentCompleted == 100 && !scanStarted) {
          checkScanProgress();
          setScanStarted(true);
        }
      },
    };
    let data = new FormData();
    data.append("file", e.target.files[0]);
    e.target.value = null;
    axios
      .post("/recipes/upload_pdf", data, config)
      .then((res) => {
        if (!res.data.success) {
          amplitude.getInstance().logEvent("Upload file failed");

          setErrors(res.data.message);
          setScanStarted(false);
          setScanProgress(0);
          setStartUpload(false);
          return false;
        } else {
          amplitude.getInstance().logEvent("Uploaded file");

          setScanComplete(true);
          const results = res.data["results"];
          setServerResponse(res.data["response"]);
          const array = [];
          results.map((recipe, index) => {
            //console.log(recipe);

            array.push({ ...recipe, disabled: false });
          });
          setRecipes(array);
        }
      })
      .catch((err) => console.log(err));
  }

  const importRecipes = () => {
    const recipeData = [];
    setImportingRecipes(true);
    recipes.map((recipe, index) => {
      if (!recipe.disabled) {
        recipeData.push(recipe.id);
      }
    });

    axios
      .post("/recipes/import_pdf", {
        recipeData: recipeData,
        serverResponse: serverResponse,
        tags: tags,
      })
      .then(function (response) {
        props.getRecipes();
      })
      .then(function () {
        amplitude.getInstance().logEvent("Imported PDF Recipes");

        setImportingRecipes(false);
        history({
          pathname: "/recipes",
        });
      })
      .catch(function (error) {
        console.log(error);
        alert("Something went wrong importing recipes.");
      });
  };

  const [progress, setProgress] = useState(0);
  return (
    <>
      <div>
        <Container className={classes.pageContainer}>
          {importingRecipes && (
            <Dimmer active>
              <Loader indeterminate>Importing Recipes</Loader>
            </Dimmer>
          )}
          <>
            <Step.Group widths={3}>
              <Step
                onClick={(e) => {
                  setErrors(false);
                  setScanStarted(false);
                  setScanProgress(0);
                  setStartUpload(false);

                  inputFileRef.current.click();
                }}
                active={startUpload && !scanStarted}
              >
                <Icon name="file pdf outline" />
                <Step.Content>
                  <Step.Title>Upload PDF</Step.Title>
                  <Step.Description>
                    Upload your PDF - this must be an unmodified version of your
                    origial PDF
                  </Step.Description>
                </Step.Content>
              </Step>

              <Step active={scanStarted && !scanComplete}>
                <Icon name="search" />
                <Step.Content>
                  <Step.Title>Scan Recipes</Step.Title>
                  <Step.Description>
                    Allow the system to scan for recipes that can be imported.{" "}
                  </Step.Description>
                </Step.Content>
              </Step>

              <Step active={scanComplete && recipes.length > 0}>
                <Icon name="book" />
                <Step.Content>
                  <Step.Title>Select Recipes</Step.Title>
                  <Step.Description>
                    You can uncheck your recipes however it's usual to include
                    all recipes.
                  </Step.Description>
                </Step.Content>
              </Step>
            </Step.Group>

            <Grid padded="vertically">
              <Grid.Row>
                <Grid.Column>
                  <Header>Upload recipe from PDF</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column>
                  Currently the following PDF Plans are supported:
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={useWindowSize.width > 991 ? 3 : 2}>
                <Grid.Column>
                  <Label style={{ margin: "1em" }}>
                    <Icon name="file pdf outline" />
                    Bodycoach Cycle 1
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <Label style={{ margin: "1em" }}>
                    <Icon name="file pdf outline" />
                    Bodycoach Cycle 2
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <Label style={{ margin: "1em" }}>
                    <Icon name="file pdf outline" />
                    Bodycoach Cycle 3
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <Label style={{ margin: "1em" }}>
                    <Icon name="file pdf outline" />
                    Bodycoach Grad Plan 1 (Cycle 4)
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <Label style={{ margin: "1em" }}>
                    <Icon name="file pdf outline" />
                    Bodycoach Grad Plan 2 (Cycle 5)
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <Label style={{ margin: "1em" }}>
                    <Icon name="file pdf outline" />
                    Bodycoach Grad Plan 3 (Cycle 6)
                  </Label>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <em>
                    All Bodycoach vegetarian plans are supported. Support is
                    predominantly for UK plans however other plans may also
                    work.{" "}
                  </em>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <input
                    className={recipeclass.File}
                    type="file"
                    ref={inputFileRef}
                    onChange={(e) => uploadFile(e)}
                  />
                  <Button
                    color="green"
                    onClick={(e) => {
                      setErrors(false);
                      setScanStarted(false);
                      setScanProgress(0);
                      setStartUpload(false);

                      inputFileRef.current.click();
                    }}
                  >
                    Upload
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {errors && (
              <Message negative>
                <Message.Header>Something went wrong</Message.Header>
                <div dangerouslySetInnerHTML={{ __html: errors }}></div>
              </Message>
            )}
          </>

          {startUpload && !scanStarted && (
            <div ref={uploadRef}>
              <Header name="uploading">Uploading File</Header>
              <Progress percent={progress} color="green" />
            </div>
          )}
          {scanStarted && !scanComplete && (
            <div ref={scanRef}>
              <Header name="scanning">Scanning File</Header>
              {scanCaption ? <Header>{scanCaption}</Header> : null}
              <Progress percent={scanProgress} color="teal" />
            </div>
          )}
          {scanComplete && (
            <div ref={foundRef}>
              <Header name="recipes-found">{`Found ${recipes.length} recipes:`}</Header>
              <Header.Subheader>
                Press Import to import all recipes, you can press a recipe to
                exclude it from the import.{" "}
              </Header.Subheader>
              {!recipes ? null : (
                <Grid stretched>
                  <Grid.Row>
                    <Grid.Column>
                      <Button
                        onClick={() => importRecipes()}
                        className="rounded"
                        fluid
                        color="green"
                      >
                        Import Recipes
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Label basic color="green" pointing="below">
                        Your recipes will be automatically categorised into
                        Cycle and MealType, but you can add something else here
                        to help you distinguish them if needed. This will be
                        applied to each recipe.
                      </Label>
                      <Tags
                        tagValue={tags}
                        updateTags={(tags) => setTags([...tags])}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  {recipes.map((recipe, index) => {
                    return (
                      <Grid.Column
                        width={8}
                        onClick={(e) => {
                          toggleDisable(recipe.id, !recipe.disabled);
                        }}
                        key={recipe.id}
                      >
                        <RecipeCard
                          mini
                          id={recipe.id}
                          recipeID={recipe.id}
                          hash={recipe.hash}
                          recipeName={recipe.title}
                          recipeImage={recipe.image}
                          disabled={recipe.disabled}
                        />
                      </Grid.Column>
                    );
                  })}
                </Grid>
              )}
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRecipes: () => dispatch(getRecipes()),
  };
};

export default connect(null, mapDispatchToProps)(RecipePDF);
