import React, { useState, useEffect } from "react";
import {
  getRelativeDayInWeek,
  getISODay,
  addDays,
  getShortDay,
  dateDifference,
  getDateISO,
} from "../Planner/dateHelper";
import { isMobile } from "react-device-detect";
import classes from "./Home.module.scss";
import { connect } from "react-redux";
import _ from "lodash";
import {
  getRecipes,
  resetRecipeFilters,
} from "../../store/actions/recipeActions";
import { Dimmer, Loader, Icon, Header, Image, Grid } from "semantic-ui-react";

import { updateXp } from "../../store/actions/xpActions";
import {
  clearSelected,
  plannerWeeks,
} from "../../store/actions/plannerActions";
import {
  updateDiary,
  updateDiarySegment,
  replaceRecipe,
} from "../../store/actions/diaryActions";

import { saveVisibleDay } from "../../store/actions/uiActions";
import HomeDay from "./HomeDay";
import { useLocation, useNavigate } from "react-router-dom";
import usePrevious from "../../utility/usePrevious";
import SwipeableViews from "react-swipeable-views";
import { virtualize, bindKeyboard } from "react-swipeable-views-utils";

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

const HomeScreen = (props) => {
  const [userStartDay, setStartDate] = useState(props.userStartDay);

  const [activeDay, setActiveDay] = useState(new Date());

  // const [days, setDays] = useState([]);

  const [stateLoaded, setStateLoaded] = useState(false);
  const [dayIndex, setDayIndex] = useState(0);

  const [visibleDay, setVisibleDay] = useState(new Date());
  //const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const [weekRelativeStart, setWeekRelativeStart] = useState(
    getRelativeDayInWeek(new Date(visibleDay), userStartDay)
  );
  const location = useLocation();
  const history = useNavigate();

  useEffect(() => {
    if (props.userStartDay) {
      setStartDate(props.userStartDay);
    }
  }, [props.userStartDay]);

  useEffect(() => {
    setWeekRelativeStart(
      getRelativeDayInWeek(new Date(visibleDay), userStartDay)
    );
  }, [userStartDay, visibleDay]);

  if (
    getISODay(usePrevious(weekRelativeStart)) != getISODay(weekRelativeStart)
  ) {
    props.plannerWeeks(weekRelativeStart);
  }

  useEffect(() => {
    if (location.state && location.state.reset) {
      props.saveVisibleDay(0);
      setDayIndex(0);
      setVisibleDay(addDays(new Date(activeDay), 0));
      history("/home", { state: null, replace: true });
    } else {
      if (props.visibleDay != 0) {
        handleChangeIndex(props.visibleDay);
      }
    }
  }, [location]);

  useEffect(() => {
    if (props.recipeState.recipeDetailsLoaded) {
      setStateLoaded(true);
    }
  }, [props.recipeState]);

  useEffect(() => {
    props.saveVisibleDay(dayIndex);
  }, [dayIndex]);

  useEffect(() => {
    // console.log(props.index);
  }, [props.index]);

  function changeDay(dateDiff) {
    handleChangeIndex(dayIndex + dateDiff);
  }

  // useEffect(() => {
  //   const wS = activeWeek * 7;
  //   const wE = wS + 7;
  //   setWeekStart(wS);
  //   setweekEnd(wE);
  // }, [activeWeek]);

  //  useEffect(() => {
  // const offset = visibleDay.getDay() - userStartDay;
  // console.log(offset);
  // if (visibleDay) {
  //   if (offset < 0) {
  //     const currWeek = activeWeek - 1;
  //     setActiveWeek(currWeek);
  //   }
  //   if (visibleDay.getDay() > 6 && activeWeek < 0) {
  //     const currWeek = activeWeek + 1;
  //     setActiveWeek(currWeek);
  //   }
  // }
  // }, [visibleDay]);

  function slideRenderer(params) {
    const { index, key } = params;

    return (
      <HomeDay
        key={key}
        recipes={props.recipes}
        cards={props.cards}
        planner={props.planner}
        recipeState={props.recipeState}
        activeDay={addDays(new Date(activeDay), index)}
        visibleDay={visibleDay}
      />
    );
  }

  const handleChangeIndex = (index) => {
    setDayIndex(index);
    setVisibleDay(addDays(new Date(activeDay), index));
  };

  const weekdays = [];

  let activeHover = 0;
  var i = 0;
  for (i; i < 7; i++) {
    const date = addDays(new Date(weekRelativeStart), i);
    let active = null;
    let disabled = false;

    const currDay = new Date();
    if (getISODay(date) === getISODay(visibleDay)) {
      active = "active";
      activeHover = i;
    } else {
      if (dateDifference(date, currDay) > 3) {
        disabled = true;
      }
    }
    weekdays.push({
      date: date,
      iso: getDateISO(date),
      active: active,
      disabled: disabled,
    });
  }

  const days = weekdays;

  return (
    <div className={classes.Container}>
      {!activeDay || !stateLoaded ? (
        <Dimmer active inverted>
          <Loader size="mini">Loading</Loader>
        </Dimmer>
      ) : (
        <>
          <div className={classes.DayWrapper}>
            <div
              style={{ background: `url(${process.env.PUBLIC_URL}/water.png)` }}
              className={classes.waterTrackCurves}
            ></div>
            <div className={classes.DayContainer}>
              <div
                className={classes.ActiveDay}
                style={{ left: `calc(4.5% + (${activeHover}* 13%))` }}
              ></div>
              <div className={classes.DayArrow}>
                <Icon onClick={() => changeDay(-1)} name="caret left" />
              </div>
              {_.map(days, function (d, i) {
                return (
                  <div
                    key={`day-${i}`}
                    className={`${classes.Days} ${d.active}`}
                    onClick={() => changeDay(i - activeHover)}
                  >
                    <div className={classes.Name}>{getShortDay(d.date)}</div>
                    <div className={classes.Number}>{d.date.getDate()}</div>
                  </div>
                );
              })}
              <div className={classes.DayArrow} onClick={() => changeDay(1)}>
                <Icon name="caret right" />
              </div>
            </div>

            <VirtualizeSwipeableViews
              className="swipeContainer"
              index={dayIndex}
              onChangeIndex={handleChangeIndex}
              slideRenderer={slideRenderer}
            />
          </div>
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRecipes: () => dispatch(getRecipes()),
    resetRecipeFilters: (enable) => dispatch(resetRecipeFilters(enable)),
    clearSelected: () => dispatch(clearSelected()),
    updateXp: (payload) => dispatch(updateXp(payload)),
    updateDiary: (payload) => dispatch(updateDiary(payload)),
    updateDiarySegment: (id, payload) =>
      dispatch(updateDiarySegment(id, payload)),
    replaceRecipe: (uuid, replaceID, type, day) =>
      dispatch(replaceRecipe(uuid, replaceID, type, day)),
    saveVisibleDay: (date) => dispatch(saveVisibleDay(date)),
    plannerWeeks: (start) => dispatch(plannerWeeks(start)),
  };
};
const mapStateToProps = (state) => {
  return {
    recipes: state.recipes.recipes,
    cards: state.planner.customCards,
    planner: state.planner.planner,
    diary: state.diary.diary,
    recipeState: state.recipes,
    selectedRecipes: state.planner.selectedRecipes,
    selectedWorkouts: state.planner.selectedWorkouts,
    selectedCards: state.planner.selectedCards,
    userStartDay: state.auth.user.options.startDay,
    visibleDay: state.ui.visibleDay,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
