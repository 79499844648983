import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import axios from "../../api/axios-token";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import uuid from "react-uuid";
import { parseShopping } from "../../utility/helper";
import {
  Container,
  Header,
  Dropdown,
  Dimmer,
  Loader,
  Button,
  Message,
  Grid,
  Modal,
  Segment,
} from "semantic-ui-react";
import ShoppingListGroup from "./ShoppingListGroup";
import AnimateHeight from "react-animate-height";
import ShoppingAdjustModal from "./ShoppingAdjustModal";
import StockAdjustModal from "./StockAdjustModal";
import AdditionalGroup from "./AdditionalGroup";
import {
  initShopping,
  updateShoppingPurchased,
  updateShoppingFilter,
  removeShoppingIds,
  updateIngredientAdjustment,
  insertShoppingItem,
  updateItem,
  removeItem,
  archiveShopping,
  clearShopping,
} from "../../store/actions/shoppingActions";

import { getISODay } from "../Planner/dateHelper";

import { updateXp } from "../../store/actions/xpActions";
import { updateDiary } from "../../store/actions/diaryActions";
import PurchasedGroup from "./PurchasedGroup";
import ShoppingComplete from "./ShoppingComplete";
import AddItems from "./AddItems";
import _ from "lodash";
import classes from "./Shopping.module.scss";
import useMousePosition from "../../utility/useMousePosition";
import useWindowSize from "../../utility/useWindowSize";
import amplitude from "amplitude-js";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";

const Shopping = (props) => {
  const [data, setData] = useState({});
  const [purchased, setPurchased] = useState([]);
  const [sortby, setSortBy] = useState("aisle");
  const [adjustModal, setAdjustModal] = useState(false);
  const [adjustProperties, setAdjustProperties] = useState({
    ing_id: null,
    converted_unit: null,
    amount: null,
    name: null,
    cupboard_name: null,
    cupboard_id: null,
  });
  const [stockModal, setStockModal] = useState(false);
  const [stockProperties, setStockProperties] = useState({
    ing_id: null,
    converted_unit: null,
    amount: null,
    name: null,
  });
  const [shoppingComplete, setShoppingComplete] = useState(false);
  const [completeButton, setCompleteButton] = useState(false);
  const [clearShoppingPrompt, showClearShoppingPrompt] = useState(false);
  const [collapsed, setCollapsed] = useState([]);

  const [parsedShopping, setParsedShopping] = useState(null);
  const mousePosition = useMousePosition();
  const windowSize = useWindowSize();

  function addItem(v) {
    const data = {
      id: uuid(),
      name: v,
    };
    amplitude.getInstance().logEvent("Add Custom Shopping Item");
    props.insertShoppingItem(data);
  }

  function shoppingCompleted() {
    props.archiveShopping();
    amplitude.getInstance().logEvent("Shopping Completed");

    const dayCompleteObject = {
      date: parseInt(getISODay(new Date())),
      type: "shopping_bonus",
      replacement_id: null,
      calories: null,
      protein: null,
      carbs: null,
      fat: null,
      quantity: null,
      planner_uuid: null,
      completed: 1,
    };
    props.updateXp({
      type: "shopping",
      amount: 25,
      x: mousePosition.x,
      y: mousePosition.y,
    });
    props.updateDiary(dayCompleteObject);
  }

  function additionalQuantity(
    ing_id,
    unit,
    quantity,
    name,
    cupboard,
    cupboard_id,
    mode
  ) {
    let amount = parseFloat(quantity);

    if (isNaN(amount)) {
      amount = 0;
    }
    if (mode == "minus") {
      amount = amount * -1;
    }
    setAdjustModal(false);
    const payload = {
      uuid: uuid(),
      ingredient_id: ing_id,
      unit: unit,
      quantity: amount,
      name: name,
      cupboard: cupboard,
      cupboard_id: cupboard_id,
    };
    amplitude.getInstance().logEvent("Adjusted Ingredient");

    props.updateIngredientAdjustment(payload);
  }

  function toggleCollapsed(key) {
    const collapsedCopy = [...collapsed];

    const remove = _.remove(collapsedCopy, function (o) {
      return o === key;
    });

    if (remove.length < 1) {
      setCollapsed([...collapsedCopy, key]);
    } else {
      setCollapsed([...collapsedCopy]);
    }
  }

  function showAdjustModal(
    ing_id,
    converted_unit,
    amount,
    name,
    cupboard,
    cupboard_id
  ) {
    const ingredient = _.find(props.additional, {
      ingredient_id: ing_id,
      unit: converted_unit,
    });
    let initialQuantity = 0;
    if (ingredient) {
      initialQuantity = ingredient.quantity;
    }
    amount = amount - initialQuantity;
    setAdjustProperties({
      ing_id,
      converted_unit,
      amount: amount,
      name,
      cupboard,
      cupboard_id,
      initialQuantity,
    });
    setAdjustModal(true);
  }
  function showStockModal(ing_id, converted_unit, amount, name) {
    setStockProperties({
      ing_id,
      converted_unit,
      amount,
      name,
    });
    setStockModal(true);
  }

  function togglePurchase(array) {
    const newPurchased = [...purchased];
    let removeArray = [];
    let addArray = [];

    _.map(array, function (el) {
      const removed = _.remove(newPurchased, function (a) {
        return a === el;
      });
      let type = "recipe";
      let id = el;
      if (el.includes("add-")) {
        type = "addition";
        id = id.replace("add-", "");
      }
      if (el.includes("extra-")) {
        type = "extra";
        id = id.replace("extra-", "");
      }

      if (removed.length < 1) {
        addArray.push({ shopping_id: id, purchased: 1, type: type });
      } else {
        removeArray.push({ shopping_id: id, purchased: 1, type: type });
      }
    });

    if (removeArray.length != array.length) {
      addArray = [...addArray, ...removeArray];
    } else {
      removeArray.map(function (r, i) {
        removeArray[i].purchased = 0;
      });
      addArray = [...addArray, ...removeArray];
    }

    props.updateShoppingPurchased(addArray);
  }

  useEffect(() => {
    if (!props.init) {
      props.initShopping();
    }
  }, []);
  useEffect(() => {
    if (
      shoppingCount > 0 &&
      purchased.length ==
        props.shopping.length + props.additional.length + props.extra.length
    ) {
      setShoppingComplete(true);
    }
  }, [purchased]);

  useEffect(() => {
    const purchasedShopping = _.reduce(
      props.purchased,
      function (a, c) {
        if (c.purchased) {
          switch (c.type) {
            case "addition":
              a.push("add-" + c.shopping_id);
              break;
            case "extra":
              a.push("extra-" + c.shopping_id);
              break;
            case "recipe":
              a.push(c.shopping_id);
              break;
          }
        }

        return a;
      },
      []
    );

    setPurchased(purchasedShopping);
  }, [props.purchased]);

  useEffect(() => {
    const parsedShopping = parseShopping(
      props.shopping,
      data,
      props.filter,
      purchased,
      props.additional,
      props.extra
    );

    setParsedShopping(parsedShopping);
  }, [purchased, data, props.filter, props.additional]);

  useLayoutEffect(() => {
    if (props.init) {
      let sortname = "cupboard_id";
      if (props.filter == "recipe") {
        sortname = "recipe_id";
      }
      if (props.filter == "day") {
        sortname = "iso_date";
      }
      const shopping = _.filter(props.shopping, function (s) {
        return parseInt(s.portions) > 0;
      });
      const groups = _.groupBy(shopping, sortname);
      setData(groups);
    }
  }, [props.shopping, props.filter]);

  const options = [
    {
      key: "aisle",
      text: "Aisle",
      value: "aisle",
      content: "Aisle",
    },
    {
      key: "recipe",
      text: "Recipe",
      value: "recipe",
      content: "Recipe",
    },
    {
      key: "daya",
      text: "Day",
      value: "day",
      content: "Day",
    },
  ];
  const shoppingCount = _.reduce(
    data,
    function (total, curr) {
      return (total += curr.length);
    },
    0
  );

  const getClipboard = () => {
    let shoppingclipboard = [];
    _.map(parsedShopping.columns, function (column, index) {
      _.map(column.shopping, function (shopping, index) {
        let spacer =
          shopping.quantity == "whole" || "slices" || "handful" || "sm handful"
            ? " "
            : "";
        if (!shopping.checked) {
          shoppingclipboard.push(
            shopping.name + "\t" + shopping.quantity + spacer + shopping.unit
          );
        }
      });
    });
    _.map(parsedShopping.extras_column, function (e) {
      if (parsedShopping.purchased.indexOf("extra-" + e.id) < 0) {
        shoppingclipboard.push(e.name);
      }
    });
    shoppingclipboard = shoppingclipboard.join("\n", shoppingclipboard);
    return shoppingclipboard;
  };

  return (
    <Container className="A4" style={{ paddingTop: "1em" }}>
      {!props.init ? (
        <Dimmer active inverted>
          <Loader size="small">Loading Shopping</Loader>
        </Dimmer>
      ) : (
        <>
          <ShoppingComplete
            show={shoppingComplete}
            complete={() => {
              shoppingCompleted();
              setShoppingComplete(false);
            }}
            close={() => {
              setShoppingComplete(false);
              setCompleteButton(true);
            }}
          />
          <ShoppingAdjustModal
            show={adjustModal}
            cancel={() => {
              setAdjustModal(false);
            }}
            data={adjustProperties}
            update={(
              ing_id,
              unit,
              quantity,
              name,
              cupboard,
              cupboard_id,
              mode
            ) => {
              additionalQuantity(
                ing_id,
                unit,
                quantity,
                name,
                cupboard,
                cupboard_id,
                mode
              );
            }}
          />
          <StockAdjustModal
            show={stockModal}
            cancel={() => {
              setStockModal(false);
            }}
            data={stockProperties}
          />

          <Grid stackable>
            <Grid.Row>
              <Grid.Column computer={12} tablet="16" mobile="16">
                <Segment className={classes.shoppingSegment}>
                  {windowSize.width < 992 && (
                    <Header as="h2">Shopping List</Header>
                  )}
                  <Grid.Row>
                    <div className={classes.shoppingBanner}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {windowSize.width > 991 && (
                          <Header
                            style={{
                              margin: 0,
                              marginRight: "2em",
                              display: "inline-block",
                            }}
                            as="h2"
                          >
                            Shopping List
                          </Header>
                        )}
                        <div
                          style={{ display: "inline-block" }}
                          className={classes.shoppingCount}
                        >
                          {shoppingCount} items
                        </div>
                        {shoppingCount > 0 && (
                          <div className="no-print">
                            <CopyToClipboard
                              onCopy={() => {
                                alert("Shopping copied to clipboard!");
                              }}
                              text={getClipboard()}
                            >
                              <Button
                                style={{ marginLeft: "1em" }}
                                color="teal"
                                size="tiny"
                                circular
                                icon="copy"
                              />
                            </CopyToClipboard>
                            <Button
                              style={{ marginLeft: "1em" }}
                              color="teal"
                              size="tiny"
                              circular
                              icon="print"
                              onClick={() => window.print()}
                            />
                          </div>
                        )}
                      </div>
                      <div className={`no-print ${classes.sortDropdown}`}>
                        <Header as="h4">
                          <Header.Content>
                            <Dropdown
                              direction="left"
                              onChange={(e, d) => {
                                props.updateShoppingFilter(d.value);
                              }}
                              inline
                              options={options}
                              value={props.filter}
                              text={`Sort by ${props.filter}`}
                            />
                          </Header.Content>
                        </Header>
                      </div>
                    </div>
                  </Grid.Row>

                  {shoppingCount < 1 && (
                    <Message info>
                      <Message.Header> Shopping list empty</Message.Header>
                      Your shopping list is empty. Head to the{" "}
                      <Link to={`/planner`}>planner page</Link> to send items to
                      your shopping.
                    </Message>
                  )}

                  <Grid.Column>
                    <ShoppingListGroup
                      parsedShopping={parsedShopping}
                      className={classes.listGroup}
                      toggleCollapsed={(id) => toggleCollapsed(id)}
                      collapsed={collapsed}
                      togglePurchase={(arr) => togglePurchase(arr)}
                      purchased={purchased}
                      data={data}
                      shopping={props.shopping}
                      sort={props.filter}
                      removeShoppingIds={(arr) => props.removeShoppingIds(arr)}
                      edit={(i, u, q, n, cupb, cupb_id) => {
                        showAdjustModal(i, u, q, n, cupb, cupb_id);
                      }}
                      stock={(i, u, q, n) => {
                        showStockModal(i, u, q, n);
                      }}
                      additional={props.additional}
                    />
                  </Grid.Column>
                  <Grid.Row>
                    <AddItems
                      sort={props.filter}
                      extra={props.extra}
                      toggleCollapsed={(id) => toggleCollapsed(id)}
                      collapsed={collapsed}
                      purchased={purchased}
                      togglePurchase={(p) => togglePurchase(p)}
                      addItem={(v) => {
                        addItem(v);
                      }}
                      remove={(id) => {
                        props.removeItem(id);
                      }}
                      updateItem={(id, val) => {
                        props.updateItem(id, val);
                      }}
                    />

                    {props.filter != "aisle" ? (
                      <AdditionalGroup
                        toggleCollapsed={(id) => toggleCollapsed(id)}
                        collapsed={collapsed}
                        purchased={purchased}
                        additional={props.additional}
                        togglePurchase={(arr) => togglePurchase(arr)}
                      />
                    ) : null}
                  </Grid.Row>
                  {windowSize.width < 992 && (
                    <Grid.Row only="mobile tablet">
                      <Grid.Column>
                        <PurchasedGroup
                          toggleCollapsed={(id) => toggleCollapsed(id)}
                          collapsed={collapsed}
                          additional={props.additional}
                          purchased={purchased}
                          extra={props.extra}
                          shopping={props.shopping}
                          data={data}
                          sort={props.filter}
                          togglePurchase={(arr) => togglePurchase(arr)}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  {purchased.length > 0 && (
                    <Grid.Row className="no-print">
                      <Grid.Column>
                        <Button
                          style={{ margin: "1em 0" }}
                          fluid
                          content="Shopping Complete"
                          labelPosition="right"
                          icon="checkmark"
                          color="green"
                          onClick={() => shoppingCompleted()}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  {shoppingCount > 0 && (
                    <Grid.Row className="no-print">
                      <Grid.Column>
                        <Modal
                          trigger={
                            <Button
                              style={{ margin: "1em 0" }}
                              fluid
                              content="Clear Shopping"
                              labelPosition="right"
                              icon="trash"
                              color="red"
                            />
                          }
                          header="Clear your shopping?"
                          content="This will remove all items for your shopping list. You'll receive no points for completing this shop."
                          actions={[
                            "Cancel",
                            {
                              onClick: () => {
                                amplitude
                                  .getInstance()
                                  .logEvent("Clear Shopping");
                                props.clearShopping();
                              },
                              key: "delete",
                              content: "Remove",
                              negative: true,
                            },
                          ]}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </Segment>
              </Grid.Column>

              <Grid.Column width={4} only="computer" className="no-print">
                <Segment className={classes.shoppingSegment}>
                  <PurchasedGroup
                    toggleCollapsed={(id) => toggleCollapsed(id)}
                    collapsed={collapsed}
                    additional={props.additional}
                    purchased={purchased}
                    extra={props.extra}
                    shopping={props.shopping}
                    data={data}
                    sort={props.filter}
                    togglePurchase={(arr) => togglePurchase(arr)}
                  />

                  {purchased.length > 0 && (
                    <Grid.Row>
                      <Grid.Column>
                        <Button
                          style={{ margin: "1em 0" }}
                          fluid
                          content="Shopping Complete"
                          labelPosition="right"
                          icon="checkmark"
                          color="green"
                          onClick={() => shoppingCompleted()}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initShopping: () => dispatch(initShopping()),
    updateShoppingPurchased: (shoppingArray) =>
      dispatch(updateShoppingPurchased(shoppingArray)),
    updateShoppingFilter: (filter) => dispatch(updateShoppingFilter(filter)),
    removeShoppingIds: (array) => dispatch(removeShoppingIds(array)),
    updateIngredientAdjustment: (payload) =>
      dispatch(updateIngredientAdjustment(payload)),
    insertShoppingItem: (payload) => dispatch(insertShoppingItem(payload)),
    updateItem: (id, value) => dispatch(updateItem(id, value)),
    removeItem: (id) => dispatch(removeItem(id)),
    archiveShopping: () => dispatch(archiveShopping()),
    clearShopping: () => dispatch(clearShopping()),
    updateXp: (payload) => dispatch(updateXp(payload)),
    updateDiary: (payload) => dispatch(updateDiary(payload)),
  };
};
const mapStateToProps = (state) => {
  return {
    shopping: state.shopping.shopping,
    init: state.shopping.initShopping,
    purchased: state.shopping.purchased,
    additional: state.shopping.additional,
    filter: state.shopping.filter,
    extra: state.shopping.extra,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Shopping);
