import React, { useState, useEffect } from "react";
import classes from "../Screens.module.scss";

import axios from "../../api/axios-token";
import recipeclasses from "./Recipes.module.scss";
import _ from "lodash";
import { connect } from "react-redux";
import DiscoverRecipes from "./DiscoverRecipes";
import MyRecipes from "./MyRecipes";
import {
  useNavigate,
  useLocation,
  Navigate,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";
import RecipeDetails from "./RecipeDetails";
import RecipeEdit from "./RecipeEdit";
import {
  getUserRecipeDetails,
  updateRecipes,
  getRecipes,
  importRecipe,
} from "../../store/actions/recipeActions";
import LogRocket from "logrocket";
import { updateOnboarding } from "../../store/actions/uiActions";
import RecipeManager from "./RecipeManager";
import {
  Container,
  Grid,
  Header,
  Dimmer,
  Loader,
  Transition,
  Icon,
  Dropdown,
  Modal,
  Button,
  Message,
  Form,
} from "semantic-ui-react";
import Tour from "reactour";
import amplitude from "amplitude-js";
const RecipesHome = (props) => {
  let history = useNavigate();
  let location = useLocation();

  const [urlModal, setUrlModal] = useState(false);
  const [url, setUrl] = useState("");
  const [urlLoading, setUrlLoading] = useState(false);
  const [urlError, setUrlError] = useState(null);
  const [recipeTab, setRecipeTab] = useState("my");
  const [tour, setTour] = useState(false);
  const [webError, setWebError] = useState(false);
  const [managing, setManaging] = useState(false);

  const recipeWeb = () => {
    // let url = prompt(
    //   "Please enter your url",
    //   "https://www.bbc.co.uk/food/recipes/cottage_pie_with_31714"
    // );

    setUrlModal(true);
  };

  function validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  const importWeb = () => {
    setUrlLoading(true);
    setUrlModal(false);
    props.importRecipe(url, history);
    amplitude.getInstance().logEvent("Started Web Import");
  };

  useEffect(() => {
    if (webError) {
      if (process.env.NODE_ENV === "production") {
        amplitude.getInstance().logEvent("Web Import Error");
        LogRocket.captureMessage("Recipe Web Import Failed", {
          tags: {
            // additional data to be grouped as "tags"
            subscription: "ImportRecipe",
          },
          extra: {
            // additional arbitrary data associated with the event
            webPage: url,
          },
        });
      }
    }
  }, [webError]);

  const recipePDF = () => {
    history("/recipes/pdf");
  };
  const recipeCreate = () => {
    history("/recipes/create");
  };

  const closeTour = () => {
    setTour(false);
    props.updateOnboarding({ variable: "RECIPES_ONBOARDING", status: 0 });
  };
  const endTour = () => {
    setTour(false);
    props.updateOnboarding({ variable: "RECIPES_ONBOARDING", status: 1 });
  };

  useEffect(() => {
    if (props.onboarding) {
      const homeBoarding = _.find(props.onboarding, {
        boarding_variable: "RECIPES_ONBOARDING",
      });

      if (homeBoarding.status === null) {
        setTour(true);
      }
    }
  }, []);

  useEffect(() => {
    if (location.state && location.state.webError) {
      setUrlLoading(false);
      setWebError(true);
      history("/recipes", { state: null, replace: true });
    }
    if (location.state && location.state.manageRecipes) {
      setManaging(true);
      amplitude.getInstance().logEvent("Recipe Manager Launched");

      history("/recipes/my", { state: null, replace: true });
    }
  }, [location.state]);

  const options = [
    {
      key: "web",
      content: (
        <Button fluid color="yellow">
          <Icon name="attach" />
          Import from Web
        </Button>
      ),
      value: "web",
      onClick: () => recipeWeb(),
    },
    {
      key: "pdf",
      content: (
        <Button fluid color="teal">
          <Icon name="file pdf outline" />
          Import from PDF
        </Button>
      ),
      onClick: () => recipePDF(),
    },
    {
      key: "create",
      onClick: () => recipeCreate(),
      content: (
        <Button fluid color="green">
          <Icon name="file alternate outline" />
          Create a new recipe
        </Button>
      ),
      value: "hide",
    },
  ];

  const recipeCount = props.recipes.recipes.length;

  const AddButton = () => (
    <>
      <div data-tour="recipeAddButton" className={recipeclasses.addButton}>
        <Dropdown
          icon={<Icon size="big" name="add" />}
          floating
          upward
          direction="left"
          className="button icon"
          options={options}
          trigger={<></>}
        />
      </div>
    </>
  );

  const steps = [
    {
      selector: '[data-tour = "container"]',
      content: (
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column style={{ textAlign: "center" }}>
              <Icon name="lemon" color="yellow" size="huge" />

              <h2>Welcome to the recipe page</h2>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {recipeCount > 0 ? (
                <p>
                  Looks like you've already got some recipes here. Let's show
                  you around the new recipe pages.
                </p>
              ) : (
                <p>
                  Any recipes you import will appear here. Let's give you a
                  quick tour before you get started.{" "}
                </p>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "search"]',
      content: (
        <div>
          <h2>The search bar</h2>
          <>
            <p>You can search recipes by name, ingredient, or tag name</p>
          </>
        </div>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "filters"]',
      content: (
        <div>
          <h2>Categories and Tags</h2>
          <>
            <p>
              Categories are global filters that can be applied to your recipes,
              are automatically applied but can also be edited. Tags are the
              same as categories, but personal to your account, you can create
              tags for absolutely anything.
            </p>
          </>
        </div>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
      position: [50, 300],
    },
    {
      selector: '[data-tour = "recipeAddButton"]',
      content: (
        <div>
          <h2>Adding Recipes</h2>
          <>
            <p>
              You can add recipes from the web, manually add them, scan recipes
              books and more.
            </p>
          </>
        </div>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "discoverRecipes"]',
      content: (
        <div>
          <h2>Discover Recipes</h2>
          <>
            <p>
              Search through recipes that other people in the community have
              shared and automatically tailor them to your recipes. We'll see
              you in the planner screen.
            </p>
          </>
        </div>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
      stepInteraction: true,
      position: [50, 200],
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
        props.updateOnboarding({ variable: "RECIPES_ONBOARDING", status: 1 });
        history("/recipes/discover");
      },
    },
  ];

  const startManaging = () => {
    setManaging(true);
  };
  const stopManaging = () => {
    stopManaging(false);
  };

  return (
    <>
      <ul className={recipeclasses.recipeHomeMenu}>
        <NavLink to="/recipes/discover" activeClassName="active">
          <li data-tour="discoverRecipes">Discover</li>
        </NavLink>

        <NavLink to="/recipes/my" activeClassName="active" as="li">
          <li data-tour="myRecipes">My Recipes</li>
        </NavLink>
      </ul>

      <Container className={classes.pageContainer}>
        {urlLoading && (
          <Dimmer active inverted>
            <Loader inverted content="Importing Website" />
          </Dimmer>
        )}
        <Modal
          key="urlModal"
          closeIcon
          open={urlModal}
          onClose={() => setUrlModal(false)}
          onOpen={() => setUrlModal(true)}
        >
          <Header icon="search" content="Import recipe from website" />
          <Modal.Content>
            <p>Enter a url and we'll scan the ingredients</p>
            <Form>
              <Form.Input
                key="modalInput"
                value={url}
                fluid
                type="url"
                placeholder="Enter a web address"
                onChange={(e, v) => {
                  setUrlError(false);
                  setUrl(v.value);
                }}
              />
            </Form>
            {urlError && (
              <Message negative>
                <Message.Header>Invalid URL</Message.Header>
                <p>Please enter or paste a valid web address.</p>
              </Message>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="red"
              onClick={() => {
                setUrlModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color="green"
              onClick={() => {
                if (validURL(url)) {
                  importWeb();
                } else {
                  setUrlError(true);
                }
              }}
            >
              Import
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={webError}
          header="Error importing web recipe"
          content="Unfortunately we cannot import this recipe, the website has been logged in order to improve our web detection for the future. "
          actions={[
            {
              key: "done",
              content: "Ok",
              positive: true,
              onClick: () => {
                setWebError(false);
                setUrl("");
              },
            },
          ]}
        />

        <Routes>
          <Route path={`discover/*`} element={<DiscoverRecipes />} />
          <Route
            path={`my/*`}
            element={
              <MyRecipes
                toggle={false}
                mode={managing ? "manager" : "browser"}
                startManaging={() => startManaging()}
              />
            }
          />

          <Route path={`/`} element={<Navigate to="my" />} />
        </Routes>

        <Routes>
          <Route
            path={"my"}
            element={
              <Tour
                rounded={15}
                showNavigationNumber={false}
                showNumber={false}
                steps={steps}
                isOpen={tour}
                disableInteraction
                onRequestClose={() => closeTour()}
                closeWithMask={false}
                accentColor="#47c73c"
                lastStepNextButton={
                  <Button
                    OnClick={() => endTour()}
                    className="rounded"
                    color="green"
                  >
                    Discover Recipes
                  </Button>
                }
              />
            }
          />
        </Routes>
        <Routes>
          <Route path="/my" element={<AddButton />} />
          <Route path="/discover" element={<AddButton />} />
        </Routes>
        {managing && <RecipeManager close={() => setManaging(false)} />}
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateRecipes: (payload) => dispatch(updateRecipes(payload)),
    getUserRecipeDetails: () => dispatch(getUserRecipeDetails()),
    getRecipes: () => dispatch(getRecipes()),
    importRecipe: (url, history) => dispatch(importRecipe(url, history)),
    updateOnboarding: (payload) => dispatch(updateOnboarding(payload)),
  };
};
const mapStateToProps = (state) => {
  return {
    recipes: state.recipes,
    onboarding: state.auth.user.onboarding,
    recipeScreen: state.ui.recipeScreen,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipesHome);
