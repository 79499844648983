import React, { useState, useEffect } from "react";
import { Feed, Icon } from "semantic-ui-react";
import CustomIcon from "../../../components/CustomIcon";
import axios from "../../../api/axios-token";
import isUrl from "is-url";
import _ from "lodash";
const RecipeImage = ({ data, recipes }) => {
  const [imageData, setImageData] = useState([]);

  const getRecipeImage = (id) =>
    axios
      .get("/recipes/image/" + id)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return null;
      });

  useEffect(() => {
    if (recipes) {
      const recipeData = [];

      const getRecipes = _.map(data, async (d) => {
        const imageData = await getRecipeImage(d.id);
        let image, id;

        if (isUrl(imageData.image)) {
          image = imageData.image;
        } else {
          if (imageData.image) {
            image = `${process.env.REACT_APP_API}/${imageData.image}`;
          }
        }
        id = imageData.hash;

        if (id && image) {
          recipeData.push({ id, image, type: "db" });
        }

        if (imageData.recipe && imageData.recipe.type == "meal") {
          recipeData.push({ id, image: null, type: "meal" });
        }

        return true;
      });

      Promise.all(getRecipes).then(() => {
        setImageData(recipeData);
      });
    } else {
      const recipeData = [];
      _.map(data, function (d) {
        let image, id;
        if (isUrl(d.image)) {
          image = d.image;
        } else {
          image = `${process.env.REACT_APP_API}/${imageData.image}`;
        }
        id = d.id;
        recipeData.push({ id, image, type: "raw" });
      });
      setImageData(recipeData);
    }
  }, []);

  let mealIcon = "meal";

  return imageData.length ? (
    <Feed.Extra images>
      {_.map(imageData, function (d, i) {
        if (d.image) {
          return (
            <a key={`${d.id}-${i}-recipe`} href={`/recipes/view/${d.id}`}>
              <img src={d.image} />
            </a>
          );
        } else {
          return (
            <a key={`${d.id}-${i}-recipe`} href={`/recipes/view/${d.id}`}>
              <div
                style={{
                  display: "inline-flex",
                  padding: "0.2em 1.666em",
                  alignItems: "center",
                  border: "1px solid #89b058",
                  marginTop: "0.3em",
                  borderRadius: "10px",
                }}
                className="cardholder"
              >
                <CustomIcon icon="meal" />
              </div>
            </a>
          );
        }
      })}
    </Feed.Extra>
  ) : null;
};

export default RecipeImage;
