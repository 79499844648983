import React, { useEffect, useState } from "react";

import {
  Routes,
  Route,
  Navigate,
  useLocation,
  BrowserRouter,
} from "react-router-dom";

import { connect } from "react-redux";
import SignIn from "./screens/Registration/SignIn";
import SignOut from "./screens/Registration/SignOut";
import SignUp from "./screens/Registration/SignUp";
import Home from "./screens/Home/HomeScreen";
import Planner from "./screens/Planner";
import Recipes from "./screens/Recipes";
import Profile from "./screens/Profile/Profile";
import Shopping from "./screens/shopping/Shopping";
import Stream from "./screens/stream/";
import Admin from "./screens/admin/AdminHome";
import Landing from "./screens/Landing/LandingHome";
import LandingHome from "./screens/Landing/Landing";
import Guide from "./screens/Pages/Guide";
import Legal from "./screens/Pages/Legal";
import ResetPassword from "./screens/Registration/ResetPassword";
import ErrorBoundary from "./ErrorBoundary";

import Container from "./UI/ContainerBox";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { authCheckLogin } from "./store/actions/authActions";
import { Grid, Loader } from "semantic-ui-react";
import LogRocket from "logrocket";
import amplitude from "amplitude-js";
import ReactGA from "react-ga";

// if (process.env.NODE_ENV === "development") {
//   const whyDidYouRender = require("@welldone-software/why-did-you-render");
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//     trackHooks: true,
//   });
// }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = (props) => {
  useEffect(() => {
    props.checkLogin();
  }, []);
  const [initialized, setInitialized] = useState(false);

  const location = useLocation();

  useEffect(() => {
    //  if (!window.location.href.includes("localhost")) {
    const trackingId = "UA-190214234-1";
    ReactGA.initialize(trackingId);
    setInitialized(true);
    // }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      if (props.isAuthenticated && props.user && props.user.id) {
        ReactGA.set({
          userId: props.user.app_id,
        });
      }
    }
  }, [initialized, location]);

  if (process.env.NODE_ENV === "production") {
    amplitude.getInstance().init("402040236a8fc03776e73f1ef317260e");

    LogRocket.init("jhuxhx/plan-eat-meal", {
      release: "0.10.0",
      network: {
        requestSanitizer: (request) => {
          if (request.url.toLowerCase().indexOf("auth") !== -1) {
            request.body = null;
          }
          return request;
        },
      },
    });
    if (props.isAuthenticated && props.user && props.user.id) {
      LogRocket.identify(props.user.id, {
        name: `${props.user.first_name} ${props.user.last_name}`,
        userID: props.user.app_id,
        email: `${props.user.email}`,
      });
      amplitude.getInstance().setUserId(props.user.app_id);
    }
  }

  let routes = (
    <Routes>
      <Route path="/sign-out" element={<SignOut />} />
      <Route
        path="/sign-in"
        element={
          <Landing>
            <SignIn />
          </Landing>
        }
      />
      <Route
        path="/reset-password/:code"
        element={
          <Landing>
            <ResetPassword />
          </Landing>
        }
      />
      <Route
        path="/sign-up"
        element={
          <Landing>
            <SignUp />
          </Landing>
        }
      />
      <Route
        path="guide"
        element={
          <Landing>
            <Guide />
          </Landing>
        }
      />

      <Route
        path="guide/:category/:slug"
        element={
          <Landing>
            <Guide />
          </Landing>
        }
      />

      <Route
        path="policies"
        element={
          <Landing>
            <Legal />
          </Landing>
        }
      />
      <Route
        path="policies/:type/:subtype"
        element={
          <Landing>
            <Legal />
          </Landing>
        }
      />
      <Route
        path="policies/:type"
        element={
          <Landing>
            <Legal />
          </Landing>
        }
      />
      <Route
        path="/*"
        element={
          <Landing>
            <LandingHome />
          </Landing>
        }
      />
    </Routes>
  );

  if (props.loading) {
    routes = (
      <Grid
        centered
        textAlign="center"
        style={{ height: "100vh", width: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column>
          <Loader active />
        </Grid.Column>
      </Grid>
    );
  }
  if (props.isAuthenticated) {
    routes = (
      <>
        <Routes>
          <Route
            path="landing"
            element={
              <Landing>
                <LandingHome />
              </Landing>
            }
          />
          <Route
            path="guide"
            element={
              <Landing>
                <Guide />
              </Landing>
            }
          />

          <Route
            path="guide/:category/:slug"
            element={
              <Landing>
                <Guide />
              </Landing>
            }
          />

          <Route
            path="policies"
            element={
              <Landing>
                <Legal />
              </Landing>
            }
          />
          <Route
            path="policies/:type/:subtype"
            element={
              <Landing>
                <Legal />
              </Landing>
            }
          />
          <Route
            path="policies/:type"
            element={
              <Landing>
                <Legal />
              </Landing>
            }
          />

          <Route path="admin/*" element={<Admin />} />
          <Route path="sign-out" element={<SignOut />} />
          <Route
            path="/*"
            element={
              <ErrorBoundary>
                <Container />
              </ErrorBoundary>
            }
          />
        </Routes>
      </>
    );
  } else {
  }

  return (
    <QueryClientProvider client={queryClient}>
      {routes}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.loggedIn,
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkLogin: () => dispatch(authCheckLogin()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
