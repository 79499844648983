import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import classes from "./Planner.module.scss";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

import { Label, Modal } from "semantic-ui-react";

function truncate(str, n, useWordBoundary) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1); // the original check
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + "..."
  );
}

const Leftover = (props) => {
  let image = null;
  if (props.data.type == "recipe") {
    if (props.data.image) {
      if (props.data.source_method == "web") {
        image = props.data.image;
      } else {
        image = `${process.env.REACT_APP_API}/recipe_images/${props.data.image}`;
      }
    }
  }

  return (
    <div
      {...props.draggableProps}
      ref={props.innerRef}
      className={classes.recipeCard}
    >
      <div
        {...props.dragHandleProps}
        className={classes.recipeImage}
        style={{ position: "relative", background: `url( ${image})` }}
      >
        <Label className={classes.leftoverNumber} circular color="red">
          {props.data.leftovers}
        </Label>
      </div>
      <span>{truncate(props.data.name, 35, true)}</span>
    </div>
  );
};

const Leftovers = (props) => {
  if (props.leftovers.length) {
    const leftOvers = props.leftovers.map((rec, i) => {
      const recipe = _.find(props.recipes.recipes, function (r) {
        return r.id == rec.recipeID;
      });

      if (recipe) {
        const segment = {
          recipe_id: recipe.id,
          name: recipe.name,
          image: recipe.image,
          type: "recipe",
          source_method: recipe.source_method,
          leftovers: rec.leftovers,
          servings: rec.servings,
          portions: rec.portions,
        };
        return segment;
      }
    });

    return (
      <div className={classes.clipBoard}>
        <div className={classes.clipBoardTitle}>
          Leftovers
          <div onClick={() => props.modal()} className={classes.questionIcon}>
            <FontAwesomeIcon icon={faQuestionCircle} />
          </div>
        </div>

        <Droppable
          isDropDisabled={true}
          direction="horizontal"
          droppableId={`leftovers`}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={classes.Items}
            >
              {leftOvers.map((d, i) => {
                return (
                  <Draggable
                    type="LEFTOVER"
                    key={`leftover-${i}`}
                    draggableId={`leftover-${i}`}
                    index={i}
                    data={d}
                  >
                    {(provided, snapshot) => {
                      return (
                        <Leftover
                          draggableProps={provided.draggableProps}
                          dragHandleProps={provided.dragHandleProps}
                          innerRef={provided.innerRef}
                          key={`leftover-${i}`}
                          id={`leftover-${i}`}
                          data={d}
                        />
                      );
                    }}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => {
  return {
    recipes: state.recipes,
  };
};

export default connect(mapStateToProps)(Leftovers);
