import React, { useRef, useState, useEffect } from "react";
import classes from "./Landing.module.scss";
import {
  Grid,
  Container,
  Menu,
  Header,
  Image,
  Button,
  Segment,
  Card,
  Ref,
  Dropdown,
  Icon,
} from "semantic-ui-react";
import getRecipes from "./GetRecipes";
import useWindowSize from "../../utility/useWindowSize";
import DeviceWrapper from "../Profile/DeviceWrapper";
import Recipes from "../Recipes";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import arrayMove from "array-move";
import Macros from "../Recipes/components/Macros";
import MacroBox from "../Recipes/components/MacroBox";
import { NavLink, useNavigate } from "react-router-dom";
import amplitude from "amplitude-js";

const Landing = () => {
  const [entries, setEntries] = useState([null, null, null]);
  const [recipes, setRecipes] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const window = useWindowSize();
  const phoneContainer = useRef();
  const phoneWidth = useRef();
  const phoneHeight = useRef();
  const history = useNavigate();
  let phoneOffset = 0;
  let phoneOffsetRight = 0;
  let phoneMaxHeight = "auto";
  if (window.width > 767) {
    if (
      phoneHeight.current &&
      phoneHeight.current.offsetHeight &&
      phoneContainer.current
    ) {
      phoneOffset =
        (phoneHeight.current.offsetHeight -
          phoneContainer.current.offsetHeight) /
        -2;
      phoneOffsetRight =
        (phoneWidth.current.offsetWidth - phoneHeight.current.offsetWidth) / 2;
    }
  }
  if (window.width < 992) {
    phoneMaxHeight = phoneHeight.current.scrollHeight / 2;
    phoneMaxHeight += "px";
  }

  const masterRecipes = getRecipes();

  useEffect(() => {
    setRecipes(getRecipes());
  }, []);

  useEffect(() => {
    const ingredientsCopy = _.reduce(
      entries,
      function (acc, val) {
        if (val) {
          return [...acc, ...val.ingredients];
        } else {
          return acc;
        }
      },
      []
    );

    setIngredients([...ingredientsCopy]);
  }, [entries]);

  function getStyle(style, snapshot) {
    if (!snapshot.isDragging) return {};
    if (!snapshot.isDropAnimating) {
      return style;
    }

    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`,
    };
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    amplitude.getInstance().logEvent("Drag and Drop on Landing");

    if (result.destination.droppableId == "recipes") {
      let id;
      if (result.draggableId.includes("placed-")) {
        id = result.draggableId.replace("placed-", "");
        const entry = _.find(masterRecipes, {
          real_id: id,
        });

        const j = parseInt(result.source.droppableId.replace("droppable-", ""));
        const entriesCopy = [...entries];
        entriesCopy[j] = null;

        setEntries(entriesCopy);

        const recipesCopy = [...recipes, entry];
        arrayMove.mutate(recipesCopy, -1, result.destination.index);
        setRecipes([...recipesCopy]);
      }
      if (result.draggableId.includes("recipe-")) {
        const recipesCopy = [...recipes];
        arrayMove.mutate(
          recipesCopy,
          result.source.index,
          result.destination.index
        );

        setRecipes([...recipesCopy]);
      }
    }

    if (result.destination.droppableId.includes("droppable-")) {
      if (result.source.droppableId.includes("droppable")) {
        const id = result.draggableId.replace("placed-", "");
        const entry = _.find(masterRecipes, {
          real_id: id,
        });
        const j = parseInt(result.source.droppableId.replace("droppable-", ""));
        const n = parseInt(
          result.destination.droppableId.replace("droppable-", "")
        );
        const entriesCopy = [...entries];
        entriesCopy[j] = null;
        entriesCopy[n] = { ...entry };

        setEntries(entriesCopy);
        //  const recipesCopy = [...recipes, entry];
        //  setRecipes([...recipesCopy]);

        return;
      }

      const id = result.draggableId.replace("recipe-", "");
      const entry = _.find(masterRecipes, {
        real_id: id,
      });

      const entriesCopy = [...entries];
      const n = parseInt(
        result.destination.droppableId.replace("droppable-", "")
      );

      entriesCopy[n] = { ...entry };
      setEntries(entriesCopy);

      if (result.source.droppableId == "recipes") {
        const recipesCopy = [...recipes];
        _.remove(recipesCopy, { real_id: id });
        setRecipes(recipesCopy);
      }
    }
  };

  return (
    <>
      <Grid padded stackable columns={2}>
        <Grid.Row
          className={`${classes.first} ${classes.heavyPadding}`}
          style={{
            minHeight: `${window.height - 105}px`,
          }}
        >
          <Grid.Column className={classes.textContent}>
            <div className={classes.contentContainer}>
              <Header className={classes.header} size="large">
                Your <span className={classes.greenHighlight}>lifestyle</span>{" "}
                change, simplified
              </Header>
              <Header.Subheader>
                The smart{" "}
                <span className={classes.orangeHighlight}>meal planner</span>{" "}
                that helps you save, tailor, and shop recipes, and reach your
                health and fitness goals.
              </Header.Subheader>
              <div className={classes.Buttons}>
                <div>
                  <Button
                    color="green"
                    size="huge"
                    onClick={() => {
                      amplitude
                        .getInstance()
                        .logEvent("Clicked Main Landing Signup");
                      history("/sign-up");
                    }}
                  >
                    Start Now
                  </Button>
                  <p className={classes.greenHighlight}>
                    Free. Set up in minutes
                  </p>
                </div>

                <div>
                  <Button
                    color="teal"
                    size="huge"
                    onClick={() => {
                      history("/sign-in");
                    }}
                  >
                    Sign In
                  </Button>
                  <p className={classes.tealHighlight}>Already registered?</p>
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <Image
              src={`${process.env.PUBLIC_URL}/img/landing/top-view.png`}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column>
            <div className={`${classes.heavyPadding} ${classes.adjustScreen}`}>
              <Ref innerRef={phoneContainer}>
                <Segment padded="very" inverted color="blue">
                  <Grid stackable columns={2}>
                    <Grid.Row>
                      <Grid.Column verticalAlign="middle">
                        <Header
                          className={classes.alternativeHeader}
                          style={{ color: "white" }}
                          as="h1"
                        >
                          Add and tailor any recipe to your needs
                        </Header>
                        <p>
                          Add recipes from your own collection, the web, or even
                          recipe books with the text recognition feature. Scan
                          your ingredients and adjust them to your personal
                          nutrition needs, tailored to your goals.
                        </p>
                        <Header
                          style={{ color: "white" }}
                          as="h4"
                          onClick={() => {
                            amplitude
                              .getInstance()
                              .logEvent("Clicked Adjust Landing Signup");

                            history("/sign-up");
                          }}
                        >
                          <Header.Content>
                            Get started <Icon name="arrow right" />
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                      <Ref innerRef={phoneWidth}>
                        <Grid.Column>
                          <div
                            style={{
                              top: `${phoneOffset}px`,
                              right: `${phoneOffsetRight}px`,
                              maxHeight: `${phoneMaxHeight}`,
                            }}
                            ref={phoneHeight}
                            className={classes.phoneColumn}
                          >
                            <DeviceWrapper
                              topPadding="0.5em"
                              device="iPhone6"
                              orientation="portrait"
                              color="white"
                              poster={`${process.env.PUBLIC_URL}/img/onboarding/adjustVideoPoster.jpg`}
                              source={`${process.env.PUBLIC_URL}/img/onboarding/adjustVideo.webm`}
                              type="video/webm"
                            />
                          </div>
                        </Grid.Column>
                      </Ref>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Ref>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
        <Grid stackable className={classes.Planner}>
          <Grid.Row>
            <Grid.Column>
              <Header className={classes.alternativeHeader} as="h1">
                Plan your first day in seconds
              </Header>
              <Droppable droppableId="recipes" direction="horizontal">
                {(provided, snapshot) => (
                  <ul
                    className="ui grid doubling six columns"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {_.map(recipes, function (r, i) {
                      return (
                        <Draggable
                          key={`recipe-${r.real_id}`}
                          draggableId={`recipe-${r.real_id}`}
                          index={i}
                        >
                          {(provided, snapshot) => (
                            <li
                              className="column"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div>
                                <Card
                                  image={`${process.env.PUBLIC_URL}/img/landing/${r.image}`}
                                />
                                <h4>{r.name}</h4>
                              </div>
                            </li>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header className={classes.alternativeHeader} as="h2">
                Monday
                <Header.Subheader>(Drag and drop recipes)</Header.Subheader>
                <Card.Group itemsPerRow={3} className={classes.Day}>
                  <Droppable droppableId="droppable-0">
                    {(provided, snapshot) => (
                      <div
                        className={`card ${entries[0] && "active"}`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <Card.Content>
                          {entries[0] && (
                            <Draggable
                              draggableId={`placed-${entries[0].real_id}`}
                              index={0}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getStyle(
                                    provided.draggableProps.style,
                                    snapshot
                                  )}
                                >
                                  <Card
                                    image={`${process.env.PUBLIC_URL}/img/landing/${entries[0].image}`}
                                  />
                                  <h4>{entries[0].name}</h4>
                                </div>
                              )}
                            </Draggable>
                          )}
                          <div style={{ visibility: "hidden", height: 0 }}>
                            {provided.placeholder}
                          </div>
                        </Card.Content>
                      </div>
                    )}
                  </Droppable>

                  <Droppable droppableId="droppable-1">
                    {(provided, snapshot) => (
                      <div
                        className={`card ${entries[1] && "active"}`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <Card.Content>
                          {entries[1] && (
                            <Draggable
                              draggableId={`placed-${entries[1].real_id}`}
                              index={1}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getStyle(
                                    provided.draggableProps.style,
                                    snapshot
                                  )}
                                >
                                  <Card
                                    image={`${process.env.PUBLIC_URL}/img/landing/${entries[1].image}`}
                                  />
                                  <h4>{entries[1].name}</h4>
                                </div>
                              )}
                            </Draggable>
                          )}
                          <div style={{ visibility: "hidden", height: 0 }}>
                            {provided.placeholder}
                          </div>
                        </Card.Content>
                      </div>
                    )}
                  </Droppable>
                  <Droppable droppableId="droppable-2">
                    {(provided, snapshot) => (
                      <div
                        className={`card ${entries[2] && "active"}`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <Card.Content>
                          {entries[2] && (
                            <Draggable
                              draggableId={`placed-${entries[2].real_id}`}
                              index={2}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getStyle(
                                    provided.draggableProps.style,
                                    snapshot
                                  )}
                                >
                                  <Card
                                    image={`${process.env.PUBLIC_URL}/img/landing/${entries[2].image}`}
                                  />
                                  <h4>{entries[2].name}</h4>
                                </div>
                              )}
                            </Draggable>
                          )}
                          <div style={{ visibility: "hidden", height: 0 }}>
                            {provided.placeholder}
                          </div>
                        </Card.Content>
                      </div>
                    )}
                  </Droppable>
                </Card.Group>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <h2 className={classes.alternativeHeader}>
                Your Monday Insights
              </h2>
              <Macros local alwaysShow ingredients={ingredients}>
                <MacroBox mini hideadjustment showAlways basic />
              </Macros>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </DragDropContext>
      <Grid className={classes.finalScreen} stackable columns="equal">
        <Grid.Row>
          <Grid.Column verticalAlign="middle">
            <div className={`${classes.phoneCrop} ${classes.yellow}`}>
              <DeviceWrapper
                topPadding="0.5em"
                device="galaxyS5"
                orientation="portrait"
                color="black"
                poster={`${process.env.PUBLIC_URL}/img/onboarding/shoppingListPoster.jpg`}
                source={`${process.env.PUBLIC_URL}/img/onboarding/shoppingList.webm`}
                type="video/webm"
              />
            </div>
          </Grid.Column>
          <Grid.Column verticalAlign="middle">
            <div>
              <Header className={classes.alternativeHeader} as="h1">
                Instantly generate a shopping list
              </Header>
              <Header.Subheader>
                In a click of a button your recipes are instantly transformed
                into a shopping list. Search by day, recipe, and add other items
                to form a complete shopping list that you can take on the go.
              </Header.Subheader>
              <Header
                as="h4"
                onClick={() => {
                  amplitude
                    .getInstance()
                    .logEvent("Clicked Shopping List Landing Signup");

                  history("/sign-up");
                }}
              >
                <Header.Content>
                  Get started <Icon name="arrow right" />
                </Header.Content>
              </Header>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row reversed="mobile" style={{ background: "white" }}>
          <Grid.Column verticalAlign="middle">
            <div>
              <Header className={classes.alternativeHeader} as="h1">
                Track your progress
              </Header>
              <Header.Subheader>
                Consistency is key to a healthy lifestyle. Track your progress
                daily, build up your daily streaks and energy points to monitor
                your progress.
              </Header.Subheader>
              <Header
                as="h4"
                onClick={() => {
                  amplitude
                    .getInstance()
                    .logEvent("Clicked Progress Landing Signup");

                  history("/sign-up");
                }}
              >
                <Header.Content>
                  Get started <Icon name="arrow right" />
                </Header.Content>
              </Header>
            </div>
          </Grid.Column>
          <Grid.Column verticalAlign="middle">
            <div className={`${classes.phoneCrop} ${classes.blue}`}>
              <DeviceWrapper
                topPadding="0.5em"
                device="galaxyS5"
                orientation="portrait"
                color="black"
                poster={`${process.env.PUBLIC_URL}/img/onboarding/trackingVideoPoster.jpg`}
                source={`${process.env.PUBLIC_URL}/img/onboarding/trackingVideo.webm`}
                type="video/webm"
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default Landing;
