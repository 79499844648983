import * as actionTypes from "../actions/xpActions";
import _ from "lodash";
const initialState = {
  xp: [],
  existingTotal: 0,
  total: 0,
};

const reducer = (state = initialState, action) => {
  let xpCopy, total;
  switch (action.type) {
    case actionTypes.UPDATE_XP_PRE:
      return { ...state, existingTotal: action.payload, total: action.payload };
    case actionTypes.UPDATE_XP:
      xpCopy = [...state.xp, action.payload];
      total = _.reduce(
        xpCopy,
        function (a, c) {
          return a + parseInt(c.amount);
        },
        0
      );

      return {
        ...state,
        xp: xpCopy,
        total: total + parseInt(state.existingTotal),
      };
    default:
      return state;
  }
};

export default reducer;
