import React, { useEffect, useState } from "react";
import { Card, Grid, Header, Ref, Icon } from "semantic-ui-react";
import recipes from "./Recipes.module.scss";
import _ from "lodash";
import { connect } from "react-redux";
import Tag from "./tag";
import CustomIcon from "../../components/CustomIcon";
import useProgressiveImage from "./useProgressiveImage";
import "intersection-observer";

import { useInView } from "react-hook-inview";

const RecipeCard = (props) => {
  const [expand, setExpand] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(null);
  const [ref, isVisible] = useInView({
    threshold: 1,
  });
  useEffect(() => {
    if (props.children) {
      document.body.style.overflow = "hidden";
      //   document.body.style.paddingRight = "15px";
    }
    return () => {
      document.body.style.overflow = "unset";
      // document.body.style.paddingRight = "0px";
    };
  }, [props.children]);

  const tags = _.values(props.recipes.tags);

  const Categories = (props) => {
    if (!props.categories) {
      return null;
    }
    return props.categories
      .filter(function (el) {
        return el.enabled === true;
      })
      .map(function (c, index) {
        return <Tag key={`category-${index}`} label={c.category_name} />;
      });
  };

  const Tags = (props) => {
    if (!props.recipeTags) {
      return null;
    }
    return props.recipeTags.map(function (e) {
      let recipeTags = tags.filter(function (el) {
        return el.id === e;
      });

      return recipeTags.map(function (t, index) {
        return <Tag key={index} label={t.tag_name} color={t.tag_color} />;
      });
    });
  };

  let miniStyle = null;
  if (props.mini) {
    miniStyle = "mini";
  }
  let disabled = null;
  if (props.disabled) {
    disabled = "disabled";
  }
  let selected = null;
  if (props.selected) {
    selected = "selected";
  }
  let type = recipes.recipe;
  if (props.type == "meal") {
    type = recipes.meal;
  }
  let hasChildren,
    hasChildrenStackable = null;
  if (props.children) {
    hasChildren = recipes.popout;
    hasChildrenStackable = true;
  }
  let expanded = null;
  if (props.children) {
    expanded = true;
    // expanded = {
    //   position: "fixed",
    //   top: "80px",
    //   width: "95vw",
    //   height: "80vh",
    //   zIndex: "99",
    // };
  }

  useEffect(() => {
    if (props.children) {
      setExpand(true);
    } else {
      setExpand(false);
    }
  }, [props]);
  let background = true;
  let loaded, backgroundPlaceholder;
  backgroundPlaceholder = `${process.env.REACT_APP_API}/app/image/${props.hash}/thumb`;
  let backgroundImage = useProgressiveImage(
    props.masterID || props.recipeID,
    props.hash,
    props.recipeImage
  );

  return (
    <Ref innerRef={ref}>
      <Card fluid className={`${recipes.Card} ${disabled} ${selected} ${type}`}>
        {selected && (
          <div className={recipes.SelectedImage}>
            <Icon circular inverted color="green" name="check" />
          </div>
        )}
        <Card.Content className={`${recipes.recipeContainer} ${miniStyle} `}>
          <Grid stackable={hasChildrenStackable}>
            {!background && props.type == "meal" ? (
              <Grid.Column width={8}>
                <div className={recipes.mealCardPlaceholder}>
                  <CustomIcon icon="meal" />
                </div>
              </Grid.Column>
            ) : (
              <Grid.Column width={8} className={recipes.imageColumn}>
                {background ? (
                  <div
                    style={{
                      background: `url(${process.env.PUBLIC_URL}/img/placeholder.png)`,
                    }}
                    className={`column ${recipes.recipeCardImage}`}
                  >
                    {backgroundImage && (
                      <div
                        className={recipes.recipeCardRealImage}
                        style={{
                          transition: "all 0.2s ease-in",
                          opacity: 1,
                          background: `url("${backgroundImage}")`,
                        }}
                      ></div>
                    )}
                  </div>
                ) : (
                  <div className={recipes.imageIconPlaceholder}>
                    <Icon name="image outline" />
                  </div>
                )}
              </Grid.Column>
            )}
            <Grid.Column className={recipes.recipeCard} width={8}>
              <div className={recipes.recipeCardBody}>
                <h3>{props.recipeName}</h3>
              </div>
              <div className="tagContainer">
                <Categories categories={props.recipeCategories} />
                <Tags recipeTags={props.recipeTags} />
              </div>
            </Grid.Column>
          </Grid>
        </Card.Content>
      </Card>
    </Ref>
  );
};

const mapStateToProps = (state) => {
  return {
    recipes: state.recipes,
  };
};

export default connect(mapStateToProps)(RecipeCard);
