import React from "react";
import Profile from "./Profile";
import EditProfile from "./EditProfile";

import { Route, Routes } from "react-router-dom";

const ProfileHome = () => {
  return (
    <Routes>
      <Route path="/settings" element={<EditProfile />} />
      <Route path="/*" element={<Profile />} />
    </Routes>
  );
};

export default ProfileHome;
