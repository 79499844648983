import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { Flipper, Flipped } from "react-flip-toolkit";

import {
  Grid,
  Card,
  Placeholder,
  Message,
  Loader,
  Input,
  Icon,
  Divider,
} from "semantic-ui-react";
import classes from "./Recipe.module.scss";
import RecipeCard from "../../../components/recipes/recipeCard";
import { connect } from "react-redux";
import LongPress from "react-long";
import {
  updateSelectedRecipes,
  updateSelectedWorkouts,
  clearSelected,
} from "../../../store/actions/plannerActions";
import _ from "lodash";

const RecipesList = (props) => {
  const toggleRecipe = (id) => {
    if (props.toggle) {
      props.clearSelected();
      props.updateSelectedRecipes([id]);
      return;
    }
    const currentRecipes = [...props.selectedRecipes];

    if (
      _.find(currentRecipes, function (o) {
        return o == id;
      })
    ) {
      _.remove(currentRecipes, function (el) {
        return el == id;
      });
    } else {
      currentRecipes.push(id);
    }

    props.updateSelectedRecipes(currentRecipes);
  };
  const recipes = props.recipes;
  const displayLength = (recipes.display && recipes.display.length) || 0;

  return (
    <Grid doubling stretched columns={2} className={classes.recipeWrapper}>
      {displayLength > 0 ||
      (recipes.recipesLoaded && recipes.display !== null) ? (
        Object.keys(recipes.display).map((recipe, i) => {
          return (
            <LongPress
              time={3000}
              onLongPress={() => {
                props.managerMode();
              }}
              key={recipes.display[recipe].hash}
            >
              <Grid.Column
                onClick={() => {
                  if (props.mode == "planner" || props.mode == "manager") {
                    toggleRecipe(recipes.display[recipe].id);
                  } else {
                    props.recipeNavigate(recipes.display[recipe].hash);
                  }
                }}
              >
                <RecipeCard
                  mode={props.mode}
                  selected={
                    (props.mode == "planner" || props.mode == "manager") &&
                    props.selectedRecipes.indexOf(recipes.display[recipe].id) >
                      -1
                      ? true
                      : false
                  }
                  masterID={recipes.display[recipe].master_recipe}
                  recipeID={recipes.display[recipe].id}
                  shake={props.mode == "manager" ? true : false}
                  id={recipes.display[recipe].hash}
                  hash={recipes.display[recipe].hash}
                  recipeName={recipes.display[recipe].name}
                  recipeImage={recipes.display[recipe].image}
                  recipeSource={recipes.display[recipe].source}
                  recipeSourceMethod={recipes.display[recipe].source_method}
                  recipeTags={recipes.display[recipe].tagIDs}
                  recipeCategories={recipes.display[recipe].categories}
                  type={recipes.display[recipe].type}
                />
              </Grid.Column>
            </LongPress>
          );
        })
      ) : recipes.display !== null ? (
        <>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Card.Content>
            </Card>
          </Grid.Column>
        </>
      ) : (
        <Grid.Row>
          <Grid.Column width={16}>
            <Message>
              <Message.Header>Add some recipes</Message.Header>
              Your recipe library is currently empty. Add some recipes by
              clicking the{" "}
              <Icon
                style={{ color: "green" }}
                name="plus"
                circular
                color="yellow"
                inverted
              />
              button
            </Message>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateSelectedRecipes: (array) => dispatch(updateSelectedRecipes(array)),
    updateSelectedWorkouts: (array) => dispatch(updateSelectedWorkouts(array)),
    clearSelected: () => dispatch(clearSelected()),
  };
};

const mapStateToProps = (state) => {
  return {
    selectedRecipes: state.planner.selectedRecipes,
    recipesLoaded: state.recipes.recipesLoaded,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipesList);
