import React, { useState, useEffect } from "react";
import useIfMounted from "../../utility/isMounted";
const useProgressiveImage = (id, hash, image) => {
  const [sourceLoaded, setSourceLoaded] = useState(null);
  const ifMounted = useIfMounted();

  useEffect(() => {
    const img = new Image();
    let url = `${process.env.REACT_APP_API}/image_cache/${id}.jpg`;
    let backup = `${process.env.REACT_APP_API}/app/image/${hash}.jpg`;
    if (image) {
      img.src = url;
      img.onload = () => ifMounted(() => setSourceLoaded(url));
      img.onerror = () => ifMounted(() => setSourceLoaded(backup));
    }
  }, [id, hash]);

  return sourceLoaded;
};

export default useProgressiveImage;
