import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../store/actions/authActions";
import amplitude from "amplitude-js";
const PlanandShop = () => {
  const dispatch = useDispatch();
  const planandShop = () => {
    var domain = "https://login.planandshop.uk";
    var planPopup = window.open(
      domain + "/auth/login?planeatmeal",
      "popUpWindow",
      "height=500,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
    );
    window.addEventListener(
      "message",
      function (event) {
        if (event.origin !== domain) return;
        //  alert(event.data);
        if (event.data.token) {
          dispatch(loginSuccess(event.data));
          amplitude.getInstance().logEvent("Logged in with Plan and Shop");

          localStorage.setItem("REACT_TOKEN_AUTH", event.data.token);
          localStorage.setItem("REACT_TOKEN_REFRESH", event.data.refresh);
          localStorage.setItem("STREAM_TOKEN", event.data.stream);
        }
        planPopup.close();
      },
      false
    );
  };
  return (
    <Button color="blue" onClick={() => planandShop()} fluid type="button">
      <Icon name="shopping basket" /> Login with Plan and Shop
    </Button>
  );
};

export default PlanandShop;
