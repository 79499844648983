import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useLayoutEffect,
} from "react";
import { connect, useDispatch } from "react-redux";
import {
  Container,
  Input,
  Button,
  Icon,
  Form,
  Loader,
  Dimmer,
  Modal,
} from "semantic-ui-react";
import axios from "../../api/axios-token";
import Ingredients from "../Recipes/components/IngredientsBasic";
import IngredientSearch from "../Recipes/components/IngredientSearch";
import Unit from "../Recipes/components/UnitSelector";
import ImageUploader from "../../components/upload-image/index.js";
import uuid from "react-uuid";
import _ from "lodash";
import recipes from "../Recipes/Recipes.module.scss";
import TagSearch from "../Recipes/components/TagSearch";
import Macros from "../Recipes/components/Macros";
import MacroBox from "../Recipes/components/MacroBox";
import AnimateHeight from "react-animate-height";
import { updateMeal, insertMeal } from "../../store/actions/recipeActions";

const MealCreate = (props) => {
  const [ingredients, setIngredients] = useState([]);
  const [title, setTitle] = useState("");
  const [currIngredient, setCurrIngredient] = useState([]);
  const [unit, setUnit] = useState("g");
  const [quantity, setQuantity] = useState("");
  const [serves, setServes] = useState(1);
  const [image, setImage] = useState([]);
  const [tags, setTags] = useState([]);
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();

  function addIngredient() {
    if (!quantity) {
      alert("You must enter an ingredient quantity");
      return;
    }
    if (currIngredient.length < 1) {
      alert("Please search for an ingredient.");
      return;
    }
    const newID = uuid();

    const data = {
      user_quantity: quantity,
      unit: unit,
      name: currIngredient[1],
      instructions: "",
      ingredientID: currIngredient[0],
      image: null,
      riid: newID,
      uuid: newID,
    };

    setIngredients([...ingredients, data]);
  }
  function removeIngredient(riid) {
    const copy = [...ingredients];

    _.remove(copy, { riid: riid });
    setIngredients(copy);
  }

  function updateSearchIngredient(ingId, ingName) {
    setCurrIngredient([ingId, ingName]);
  }
  const onDrop = (picture) => {
    setImage(picture);
  };

  const receiveUpdate = (data) => {
    console.log(data);
  };

  const saveMeal = () => {
    setSaving(true);

    let errors = 0;
    let formData = new FormData();

    if (image.length > 0) {
      if (image[0][0] instanceof File || image[0][0] instanceof Blob) {
        formData.append("file", image[0][0], image[0][0].name);
      }
    }

    if (!title) {
      errors++;
    }
    if (!serves) {
      errors++;
    }
    if (ingredients.length < 1) {
      errors++;
    }

    if (errors > 0) {
      alert("Please add some ingredients.");
      return false;
    }

    formData.append("name", title);
    formData.append("serves", serves);
    formData.append("type", "meal");

    formData.append("tags", tags);

    formData.append("ingredients", JSON.stringify(ingredients));

    formData.append("source_method", "meal");

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    axios
      .post("/recipes/save", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        if (response.data.action == "UPDATE") {
          dispatch(updateMeal(response.data));
        } else {
          dispatch(insertMeal(response.data));
        }
      })
      .then(function () {
        props.createMealCallback();
        setSaving(false);
      });
  };

  const converted_units = {
    cup: "ml/g",
    ml: "ml",
    kg: "g",
    pt: "ml",
    tsp: "ml/g",
    tbsp: "ml/g",
    pinch: "ml/g",
    dash: "ml/g",
    squeeze: "ml",
    whole: "whole",
    handful: "handfuls",
    bunch: "bunch",
    "small bunch": "small bunch",
    slices: "slices",
    g: "g",
    l: "l",
    oz: "g",
    lb: "g",
  };
  const metric_translation = {
    cup: 240,
    ml: 1,
    kg: 1000,
    pt: 480,
    tsp: 5,
    tbsp: 15,
    pinch: 0.5,
    dash: 0.5,
    squeeze: 15,
    whole: 1,
    handful: 1,
    bunch: 1,
    "small bunch": 1,
    slices: 1,
    g: 1,
    l: 1000,
    oz: 28,
    lb: 450,
  };

  const updateIngredient = (id, data) => {
    let ingCopy, updatedIngredient;
    updatedIngredient = {
      ..._.find(ingredients, {
        riid: id,
      }),
      ...data,
    };

    delete updatedIngredient.macros;
    updatedIngredient.loadingStatus = 0;
    updatedIngredient.converted_unit = converted_units[updatedIngredient.unit];
    updatedIngredient.metric_translation =
      metric_translation[updatedIngredient.unit];

    ingCopy = ingredients.map((ingredient) =>
      ingredient.riid === id ? { ...updatedIngredient } : ingredient
    );

    setIngredients([...ingCopy]);
    return true;
  };

  return (
    <Container>
      {saving && (
        <Dimmer active inverted>
          <Loader>Saving Meal</Loader>
        </Dimmer>
      )}

      <Form
        style={{ marginBottom: "1em" }}
        onSubmit={(e) => {
          saveMeal();
        }}
      >
        <Form.Field>
          <label>Meal Name</label>
          <Input
            required
            placeholder="Meal Name"
            value={title}
            onChange={(e, d) => setTitle(d.value)}
          />
        </Form.Field>
        <ImageUploader
          className={recipes.ImageUpload}
          onChange={onDrop}
          withPreview
          inline
          withIcon={false}
          widthCaption={false}
          buttonText="Upload Image"
          singleImage
          withLabel
          imgExtension={[".jpg", ".png"]}
          maxFileSize={5242880}
        />
        <Form.Field>
          <Macros ingredients={ingredients}>
            <Ingredients
              editing
              ingredients={ingredients}
              removeIng={(obj) => removeIngredient(obj)}
              updateIngredient={(id, data) => updateIngredient(id, data)}
            />
          </Macros>
        </Form.Field>

        <Form.Group widths="equal">
          <Form.Field style={{ margin: 0 }}>
            <IngredientSearch
              callback={(ing, name) => updateSearchIngredient(ing, name)}
              checkChange={ingredients}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group unstackable widths={3}>
          <Form.Field>
            <label>Quantity</label>
            <Input
              type="number"
              placeholder="Quantity"
              onChange={(e, d) => setQuantity(d.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Unit</label>
            <Unit value={unit} change={(u) => setUnit(u)} />
          </Form.Field>
          <Form.Field style={{ display: "flex", alignItems: "flex-end" }}>
            <Button
              type="button"
              onClick={() => {
                addIngredient();
              }}
              style={{ margin: "1px", flex: 1 }}
              color="teal"
            >
              Add
            </Button>
          </Form.Field>
        </Form.Group>

        <Form.Field widths={16}>
          <label>Tags</label>
          <TagSearch tagValue={tags} updateTags={(tags) => setTags(tags)} />
        </Form.Field>
        <Form.Group unstackable widths={2}>
          <Form.Field>
            <label>Serves</label>
            <Input
              required
              type="number"
              icon="food"
              iconPosition="left"
              placeholder="Servings"
              value={serves}
              onChange={(e, d) => setServes(d.value)}
            />
          </Form.Field>
          <Form.Field style={{ display: "flex", alignItems: "flex-end" }}>
            <Button
              style={{ margin: "1px" }}
              size="medium"
              type="submit"
              positive
            >
              Create Meal
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
      <Macros ingredients={ingredients}>
        <MacroBox hideadjustment mini showAlways basic />
      </Macros>
    </Container>
  );
};

// const mapDispatchToProps = (dispatch, ownProps) => {
//   return {
//     updateRecipe: (payload) => dispatch(updateRecipe(payload, ownProps)),
//     insertRecipe: (payload) => dispatch(insertRecipe(payload, ownProps)),
//   };
// };
// const mapStateToProps = (state) => {
//   return {
//     recipes: state.recipes,
//   };
// };

export default connect()(MealCreate);
