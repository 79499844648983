import React from "react";
import { Select } from "semantic-ui-react";

const UnitSelector = (props) => {
  const options = [
    { key: "a", text: "g", value: "g" },
    { key: "b", text: "ml", value: "ml" },
    { key: "c", text: "whole", value: "whole" },
    { key: "d", text: "kg", value: "kg" },
    { key: "e", text: "l", value: "l" },
    { key: "f", text: "lb", value: "lb" },
    { key: "g", text: "oz", value: "oz" },
    { key: "cup", text: "cup", value: "cup" },
    { key: "h", text: "pt", value: "pint" },
    { key: "i", text: "tsp", value: "tsp" },
    { key: "j", text: "tbsp", value: "tbsp" },
    { key: "k", text: "pinch", value: "pinch" },
    { key: "l", text: "dash", value: "dash" },
    { key: "m", text: "squeeze", value: "squeeze" },
    { key: "n", text: "handful", value: "handful" },
    { key: "o", text: "bunch", value: "bunch" },
    { key: "p", text: "small bunch", value: "sm bunch" },
    { key: "q", text: "slices", value: "slices" },
  ];

  return (
    <Select
      fluid
      search
      value={props.value}
      options={options}
      placeholder="Unit"
      onChange={(e, v) => {
        props.change(v.value);
      }}
    />
  );
};

export default UnitSelector;
