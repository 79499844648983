import axios from "../../api/axios-token";
import amplitude from "amplitude-js";
import { getISODay } from "../../screens/Planner/dateHelper";
export const INSERT_DIARY = "INSERT_DIARY";
export const UPDATE_DIARY_SEGMENT = "UPDATE_DIARY_SEGMENT";
export const REPLACE_PLAN_RECIPE = "REPLACE_PLAN_RECIPE";
export const HYDRATE_DIARY = "HYDRATE_DIARY";
export const UPDATE_STREAK = "UPDATE_STREAK";

export const updateDiary = (payload) => {
  axios
    .post("/diary/update_diary", {
      data: payload,
    })
    .catch(function (error) {
      console.log("Update Error");
    });
  return {
    type: INSERT_DIARY,
    payload: payload,
  };
};

export const rehydrateDiary = () => {
  return (dispatch) => {
    axios
      .get("/diary/get_diary")
      .then(function (response) {
        dispatch(updateDiaryState(response.data.diary));
      })
      .catch(function (error) {
        //NEEDTODO
        console.log(error);
      });
  };
};

export const updateDayStreak = () => {
  const newDiary = {
    date: getISODay(new Date()),
    type: "day_streak",
    amount: 1,
  };
  return (dispatch) => {
    axios
      .post("/diary/day_streak", {
        data: newDiary,
      })
      .then(function (res) {
        if (res.data.success) {
          amplitude.getInstance().logEvent("User Active Day");
        }
        if (res.data.amount) {
          dispatch(updateStreak(res.data));
        }
      })
      .catch(function (error) {
        console.log("Update Error");
      });
  };
};

export const updateStreak = (payload) => {
  return {
    type: UPDATE_STREAK,
    payload: payload,
  };
};

export const updateDiaryState = (payload) => {
  return {
    type: HYDRATE_DIARY,
    payload: payload,
  };
};

export const updateDiarySegment = (uuid, payload) => {
  axios
    .post("/diary/update_diary", {
      data: payload,
    })
    .catch(function (error) {
      console.log("Update Error");
    });
  return {
    type: UPDATE_DIARY_SEGMENT,
    uuid: uuid,
    payload: payload,
  };
};

export const replaceRecipe = (uuid, replaceID, type, day, data = null) => {
  let replacement;
  switch (type) {
    case "meal":
      replacement = "meal";
      break;
    case "snack":
      replacement = "snack";
      break;
    case "custom":
      replacement = "custom";
      break;
    default:
      replacement = "recipe";
  }
  if (data) {
    data = JSON.stringify(data);
  }
  const newDiary = {
    date: day,
    planner_uuid: uuid,
    replacement_id: replaceID,
    replacement_type: type,
    type: replacement,
    completed: 0,
    quantity: 0,
    calories: null,
    protein: null,
    carbs: null,
    fat: null,
    optional_data: data,
  };

  axios
    .post("/diary/update_diary", {
      data: newDiary,
    })

    .catch(function (error) {
      console.log("Update Error");
    });

  return {
    type: REPLACE_PLAN_RECIPE,
    payload: newDiary,
  };
};
