import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";

import axios from "../../api/axios-token";
import { Header, Table, Button } from "semantic-ui-react";
import { useQuery } from "react-query";

const IngredientQueue = () => {
  const [ingredients, setIngredients] = useState(null);

  const approveIngredient = (id, name) => {
    axios
      .post("/admin/approve_ingredient", {
        id: id,
        name: name,
      })
      .then(function (response) {
        refetch();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const declineIngredient = (id) => {
    axios
      .post("/admin/decline_ingredient", {
        id: id,
      })
      .then(function (response) {
        refetch();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchIngredients = () => {
    return axios
      .get("/admin/get_ingredients_queue")
      .then(function (response) {
        return response.data.ingredients;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const { isLoading, isError, data, error, refetch } = useQuery(
    "ingredientQueue",
    fetchIngredients
  );

  return (
    <>
      {!data ? (
        "Loading"
      ) : (
        <div>
          <Header as="h3">Added Ingredient Queue</Header>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Number of Requests</Table.HeaderCell>
                <Table.HeaderCell>Similar Ingredients in DB</Table.HeaderCell>
                <Table.HeaderCell>Add to Core DB?</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data
                ? data.map(function (ing) {
                    return (
                      <Table.Row key={ing.id}>
                        <Table.Cell>{ing.name}</Table.Cell>
                        <Table.Cell>{ing.amount}</Table.Cell>
                        <Table.Cell>
                          <ul>
                            {ing.matches.map(function (m, i) {
                              return <li key={i}>{m.name}</li>;
                            })}
                          </ul>
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            onClick={() => declineIngredient(ing.ingredient_id)}
                            negative
                          >
                            Reject
                          </Button>
                          <Button
                            onClick={() =>
                              approveIngredient(ing.ingredient_id, ing.name)
                            }
                            positive
                          >
                            Approve
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                : null}
            </Table.Body>
          </Table>
        </div>
      )}
    </>
  );
};

export default IngredientQueue;
