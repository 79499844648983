import React, { useState, useEffect, useLayoutEffect } from "react";
import { getISODay } from "./dateHelper";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { connect, useDispatch } from "react-redux";
import { Icon } from "semantic-ui-react";
import classes from "./Planner.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPaste, faRecycle } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { getRawWorkoutInfo, parseCustomCard } from "../../utility/helper";
import PlannerEntry from "./PlannerEntry";

const Day = (props) => {
  const [entries, setEntries] = useState([]);

  useLayoutEffect(() => {
    if (props.entries) {
      const newEntries = [];
      props.entries.map(function (s) {
        switch (s.type) {
          case "recipe":
            const recipe = _.find(props.recipes.recipes, function (r) {
              return r.id == s.data;
            });
            if (recipe) {
              const segment = {
                segment_id: s.uuid,
                recipe_id: recipe.id,
                hash: recipe.hash,
                name: recipe.name,
                image: recipe.image,
                type: s.type,
                recipe_type: recipe.type,
                source_method: recipe.source_method,
                segment_order: s.segment_order,
                serves: recipe.serves,
                portions: s.optional_data,
                reduce: s.optional_data2,
                locked: parseInt(s.locked),
              };
              newEntries.push(segment);
            }
            break;
          case "custom":
            let customCard;

            if (parseInt(s.data) > 0) {
              customCard = _.find(props.planner.customCards, function (c) {
                return c.id == s.data;
              });
            } else {
              if (s.optional_data) {
                customCard = parseCustomCard(s.optional_data);
              }
            }

            if (customCard) {
              const segment = {
                segment_id: s.uuid,
                recipe_id: customCard.id,
                name: customCard.name,
                image: null,
                icon: customCard.icon,
                type: s.type,
                source_method: "custom",
                segment_order: s.segment_order,
                locked: parseInt(s.locked),
              };
              newEntries.push(segment);
            }
            break;
          case "meal":
            const meal = _.find(props.recipes.recipes, function (r) {
              return r.id == s.data;
            });
            if (meal) {
              const mealSegment = {
                segment_id: s.uuid,
                recipe_id: meal.id,
                hash: meal.hash,
                name: meal.name,
                image: meal.image,
                type: s.type,
                source_method: meal.source_method,
                segment_order: s.segment_order,
                serves: meal.serves,
                portions: s.optional_data,
                reduce: s.optional_data2,
                locked: parseInt(s.locked),
              };

              newEntries.push(mealSegment);
            }
            break;
          case "workout":
            const workout = _.find(props.planner.workouts, function (w) {
              return w.id == s.data;
            });
            if (workout) {
              const workoutData = getRawWorkoutInfo(workout);

              const segment = {
                segment_id: s.uuid,
                workout_id: workout.id,
                name: workout.name,
                image: workoutData.image,
                type: s.type,
                thumbnail: workout.thumbnail || null,
                source_method: workout.source,
                segment_order: s.segment_order,
                locked: parseInt(s.locked),
              };
              newEntries.push(segment);
            }
            break;
          default:
            return newEntries;
        }
      });

      setEntries(newEntries);
    }
  }, [props.entries, props.recipes]);
  let addStyle = null;
  let addIcon = "add";

  const isoDate = getISODay(props.date);

  if (
    props.planner.dragging &&
    props.planner.dragging != "clipboard" &&
    props.planner.dragging != "leftovers"
  ) {
    if (props.planner.dragging == isoDate) {
      addStyle = { backgroundColor: "red", border: 0 };
      addIcon = "trash";
    } else {
      addIcon = "clone";
      addStyle = { backgroundColor: "teal", border: 0, color: "white" };
    }
  }

  return (
    <>
      {entries && (
        <div className={classes.diaryContainer}>
          <div className={classes.dayHeader}>
            <div className={classes.DayLeft}>
              <h3 className={classes.dayName}>
                {props.day}
                {/* {" - "}
                {props.dateDay} */}
              </h3>
            </div>
            <div className={classes.Toolbar}>
              {props.clipboard ? (
                <div className={classes.Paste} onClick={() => props.paste()}>
                  <FontAwesomeIcon icon={faPaste} />
                </div>
              ) : null}
              {entries.length ? (
                <div
                  data-tour={`clipboard-${props.day}`}
                  className={classes.copyIcon}
                  onClick={() => props.copy()}
                >
                  <FontAwesomeIcon icon={faClone} />
                </div>
              ) : null}{" "}
              {entries.length ? (
                <div
                  className={classes.trashIcon}
                  onClick={() => props.trash()}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>
              ) : (
                <div
                  data-tour={`recycle-${props.day}`}
                  className={classes.recycleIcon}
                  onClick={() => props.recycle()}
                >
                  <FontAwesomeIcon icon={faRecycle} />
                </div>
              )}
            </div>
          </div>
          <div className={classes.recipeContainer}>
            {entries.length < 1 && (
              <Droppable droppableId={`add-${isoDate}`} isCombineEnabled>
                {(provided) => (
                  <div
                    data-tour={`date-${props.day}`}
                    className={classes.addDropBox}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    onClick={() => {
                      props.clickHandler();
                    }}
                  >
                    <Icon style={{ transform: "none" }} name="add" />

                    <span>{provided.placeholder}</span>
                  </div>
                )}
              </Droppable>
            )}

            {entries.length > 0 && (
              <>
                <Droppable droppableId={`delete-${isoDate}`} isCombineEnabled>
                  {(provided) => (
                    <div
                      onClick={() => {
                        props.clickHandler();
                      }}
                      className={classes.addBox}
                      style={addStyle}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <Icon name={addIcon} />
                      <span
                        style={{
                          display: "none",
                        }}
                      >
                        {provided.placeholder}
                      </span>
                    </div>
                  )}
                </Droppable>
                <Droppable
                  direction="horizontal"
                  droppableId={`drop-${isoDate}`}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        className={`${classes.dropBox} ${
                          entries.length > 3 ? "scrolling" : null
                        }`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {entries
                          ? entries.map((d, i) => {
                              return (
                                <Draggable
                                  type="SEGMENT"
                                  key={`segment-${d.segment_id}`}
                                  draggableId={`segment-${d.segment_id}`}
                                  index={i}
                                  isDragDisabled={
                                    parseInt(d.locked) ? true : false
                                  }
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <PlannerEntry
                                        edit={(e) => props.editEntry(e)}
                                        draggableProps={provided.draggableProps}
                                        dragHandleProps={
                                          provided.dragHandleProps
                                        }
                                        locked={
                                          parseInt(d.locked) ? true : false
                                        }
                                        innerRef={provided.innerRef}
                                        key={`segment-${d.segment_id}`}
                                        id={`segment-${d.segment_id}`}
                                        day={props.dayIndex}
                                        data={d}
                                      />
                                    );
                                  }}
                                </Draggable>
                              );
                            })
                          : null}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    recipes: state.recipes,
    planner: state.planner,
  };
};

export default connect(mapStateToProps)(Day);
