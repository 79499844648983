import React, { useState, useRef } from "react";
import classes from "./SimpleImageUpload.module.scss";
import { Icon, Label } from "semantic-ui-react";
const SimpleImageUpload = (props) => {
  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);
  const [imageUploaded, setImageUploaded] = useState();

  const handleImageUpload = (e) => {
    const [file] = e.target.files;

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageUploaded(e.target.result);
        props.imageHandler(file);
        props.imagePreview && props.imagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={imageUploader}
        style={{
          display: "none",
        }}
      />

      {imageUploaded || props.defaultImage ? (
        <div className={classes.simpleUploadContainer}>
          <div
            className={`${classes.uploadedFile} ${
              props.disablePreview ? "hidden" : null
            }`}
            onClick={() => imageUploader.current.click()}
          >
            <img src={imageUploaded || props.defaultImage} />
          </div>
          <div className={classes.buttons}>
            <Label
              onClick={(e) => {
                e.stopPropagation();
                setImageUploaded(null);
                props.imagePreview(null);
                props.imageHandler(null);
                if (props.deleteImage) {
                  props.deleteImage();
                }
              }}
              size="mini"
            >
              Remove
            </Label>
            <Label
              onClick={(e) => {
                e.stopPropagation();
                imageUploader.current.click();
              }}
              size="mini"
            >
              Replace
            </Label>
          </div>
        </div>
      ) : (
        <Icon
          circular
          name="camera"
          onClick={() => imageUploader.current.click()}
        />
      )}
    </div>
  );
};

export default SimpleImageUpload;
