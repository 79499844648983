import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import classes from "./Planner.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Checkbox, Loader, Dimmer } from "semantic-ui-react";
import {
  getRelativeDayInWeek,
  getFullDay,
  getISODay,
  addDays,
} from "./dateHelper";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios-token";
import { format, parse } from "date-fns";
import { enGB } from "date-fns/locale";

import _ from "lodash";

const ShoppingListGroup = (props) => {
  const [selected, setSelected] = useState([]);
  const [addEnabled, setAddEnabled] = useState(true);
  const [updating, setUpdating] = useState(false);
  let d = props.startDay;

  let history = useNavigate();

  useEffect(() => {
    const days = [];
    const startDate = getRelativeDayInWeek(new Date(props.date), d);
    for (let i = 0; i < 7; i++) {
      const dayDate = addDays(new Date(startDate), i);

      days.push({
        day: d,
        dayName: getFullDay(dayDate),
        date: getISODay(dayDate),
        selected: true,
        dateObj: dayDate,
      });
      d++;
    }
    setSelected(days);
  }, [props.date]);

  function toggleSelected(d, toggle) {
    const selectedCopy = [...selected];
    _.set(_.find(selectedCopy, { day: d }), "selected", !toggle);
    setSelected(selectedCopy);
    const days = _.filter(selectedCopy, { selected: true });
    if (days.length > 0) {
      setAddEnabled(true);
    } else {
      setAddEnabled(false);
    }
  }

  function addToShopping() {
    setUpdating(true);
    const days = [];
    _.filter(selected, { selected: true }).map(function (d) {
      days.push(d.date);
    });
    axios
      .post("/planner/add_shopping", {
        shopping: days,
      })
      .then(function (response) {
        props.insertShopping(response.data.shopping);
      })
      .then(function () {
        history("/shopping");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <Modal
        dimmer="blurring"
        open={props.open}
        onClose={() => {
          props.close();
        }}
      >
        <Modal.Header>Send to shopping list</Modal.Header>
        <Modal.Content>
          {updating && (
            <Dimmer active inverted>
              <Loader inverted>Updating Shopping</Loader>
            </Dimmer>
          )}
          <div>
            <ul className={classes.shoppingList}>
              {selected.map(function (d) {
                let formatted = format(d.dateObj, "do LLL", { locale: enGB });

                return (
                  <li key={d.day}>
                    <Checkbox
                      checked={d.selected}
                      toggle
                      onClick={() => toggleSelected(d.day, d.selected)}
                    />
                    {d.dayName} {formatted}
                  </li>
                );
              })}
            </ul>
          </div>
        </Modal.Content>
        <Modal.Actions className={classes.shoppingModalActions}>
          <Button className="rounded" negative onClick={() => props.close()}>
            Cancel
          </Button>
          <Button
            className="rounded"
            color="green"
            disabled={!addEnabled}
            onClick={(res) => addToShopping(res)}
          >
            Add To Shopping
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ShoppingListGroup;
