import React from "react";
import styles from "./Message.module.scss";

const Message = (props) => {
  return (
    <div className={styles.Container}>
      <div className={styles.Header}>{props.header}</div>
      <div className={styles.Content}>{props.body}</div>
      <div className={styles.Footer}>{props.footer}</div>
    </div>
  );
};

export default Message;
