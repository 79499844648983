import React from "react";
import classes from "../Recipes.module.scss";
import { Form } from "semantic-ui-react";
import { convertMinutes } from "../../../utility/helper";

const RecipeMeta = (props) => {
  const PrepTime = () => {
    return (
      <>
        <label>Prep: </label>
        <span>{convertMinutes(props.prepTime)}</span>
      </>
    );
  };
  const CookTime = () => {
    return (
      <>
        <label>Cook: </label>
        <span>{convertMinutes(props.cookTime)}</span>
      </>
    );
  };

  const Servings = () => {
    return (
      <>
        {!props.editing && (
          <>
            <label>Servings: </label>
            <span>{props.serves}</span>
          </>
        )}
        {props.editing && (
          <>
            <label>Servings: </label>
            <Form.Input placeholder="Servings" />
          </>
        )}
      </>
    );
  };

  return (
    <>
      {!props.editing ? (
        <ul className={classes.recipeMeta}>
          {props.prepTime && (
            <li>
              <PrepTime />
            </li>
          )}
          {props.cookTime && (
            <li>
              <CookTime />
            </li>
          )}
          {props.serves && (
            <li>
              <Servings />
            </li>
          )}
        </ul>
      ) : (
        <Form>
          <Form.Group unstackable widths={3}>
            <Form.Input
              value={props.prepTime}
              fluid
              label="Prep Time"
              placeholder="15"
              onChange={(e) => {
                props.updatePrep(e.target.value);
              }}
            />
            <Form.Input
              value={props.cookTime}
              fluid
              label="Cook Time"
              placeholder="30"
              onChange={(e) => {
                props.updateCook(e.target.value);
              }}
            />
            <Form.Input
              value={props.serves}
              fluid
              label="Servings"
              placeholder="1"
              onChange={(e) => {
                props.updateServes(e.target.value);
              }}
            />
          </Form.Group>
        </Form>
      )}
    </>
  );
};

export default RecipeMeta;
