import React, { useRef, useState } from "react";

import {
  Button,
  Grid,
  Dropdown,
  Container,
  Loader,
  Dimmer,
  Icon,
  Header,
} from "semantic-ui-react";
import overlay from "./Overlay.module.scss";
import { useDispatch } from "react-redux";
import { resetOverlay } from "../../store/actions/uiActions";
import { updateOnboarding, loadOverlay } from "../../store/actions/uiActions";
import Slider from "react-slick";
import DeviceWrapper from "./DeviceWrapper";
import axios from "../../api/axios-token";

const Intro = () => {
  const dispatch = useDispatch();
  const [currentSlide, setCurrentSlide] = useState(0);

  const close = () => {
    dispatch(resetOverlay());
    const onboarding = {
      status: 0,
      variable: "INTRODUCTION",
    };
    dispatch(updateOnboarding(onboarding));
  };
  const openQuiz = () => {
    const onboarding = {
      status: 1,
      variable: "INTRODUCTION",
    };
    dispatch(updateOnboarding(onboarding));
    dispatch(loadOverlay("TDEE"));
  };
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const sliderRef = useRef();

  return (
    <div className={overlay.Container}>
      <div className={overlay.Close} onClick={() => close()}>
        <Icon.Group style={{ margin: "1em" }}>
          <Icon style={{ marginRight: 0 }} size="big" name="circle outline" />
          <Icon name="close" />
        </Icon.Group>
      </div>
      <div className={overlay.Centered}>
        <Slider
          beforeChange={(i, n) => {
            setCurrentSlide(n);
          }}
          ref={sliderRef}
          className={overlay.slider}
          {...sliderSettings}
        >
          <div className={`step1 ${overlay.step}`}>
            <h1 className={overlay.StepHeader}>
              Add and tailor any recipe to your needs
            </h1>
            <DeviceWrapper
              topPadding="0.5em"
              device="iPhone6"
              orientation="portrait"
              color="white"
              poster={`${process.env.PUBLIC_URL}/img/onboarding/adjustVideoPoster.jpg`}
              source={`${process.env.PUBLIC_URL}/img/onboarding/adjustVideo.webm`}
              type="video/webm"
            />
          </div>
          <div className={`step2 ${overlay.step}`}>
            <DeviceWrapper
              device="iPhone6"
              orientation="portrait"
              color="white"
              poster={`${process.env.PUBLIC_URL}/img/onboarding/planningVideoPoster.jpg`}
              source={`${process.env.PUBLIC_URL}/img/onboarding/planningVideo.webm`}
              type="video/webm"
            />
            <h1 className={overlay.StepHeader}>Easily plan your week</h1>
          </div>
          <div className={`step3 ${overlay.step}`}>
            <h1 style={{ textAlign: "center" }} className={overlay.StepHeader}>
              Instantly generate your shopping list
            </h1>
            <DeviceWrapper
              device="iPhone6"
              orientation="portrait"
              color="white"
              poster={`${process.env.PUBLIC_URL}/img/onboarding/shoppingListPoster.jpg`}
              source={`${process.env.PUBLIC_URL}/img/onboarding/shoppingList.webm`}
              type="video/webm"
            />
          </div>
          <div className={`step4 ${overlay.step}`}>
            <DeviceWrapper
              device="iPhone6"
              orientation="portrait"
              color="white"
              poster={`${process.env.PUBLIC_URL}/img/onboarding/trackingVideoPoster.jpg`}
              source={`${process.env.PUBLIC_URL}/img/onboarding/trackingVideo.webm`}
              type="video/webm"
            />
            <h1 style={{ textAlign: "center" }} className={overlay.StepHeader}>
              Track your progress to stay on track
            </h1>
          </div>
          <div className={`step5 ${overlay.step}`}>
            <div>
              <h1 className={overlay.StepHeader}>
                Ready to start your lifestyle change?
              </h1>
            </div>
          </div>
        </Slider>
        {currentSlide < 4 ? (
          <Button
            onClick={() => sliderRef.current.slickNext()}
            className={`${overlay.StepButton}`}
          >
            Next
          </Button>
        ) : (
          <div className={overlay.lastStepfooter}>
            <div className={overlay.notNow} onClick={() => close()}>
              <h5 style={{ margin: 0 }}>Not now</h5>
            </div>
            <Button
              onClick={() => openQuiz()}
              className={`${overlay.StepButton}`}
            >
              Take the <span style={{ color: "orange" }}>nutrition</span> quiz
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Intro;
