import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import classes from "../Screens.module.scss";
import { connect } from "react-redux";
import axios from "../../api/axios-token";
import {
  updateRecipes,
  insertRecipe,
  updateRecipe,
  updateMacros,
} from "../../store/actions/recipeActions";
import _ from "lodash";
import { Container } from "semantic-ui-react";
import RecipeForm from "./RecipeForm";
import stripHtml from "string-strip-html";
import amplitude from "amplitude-js";

const RecipeAmend = (props) => {
  const [method, setMethod] = useState("");
  const [title, setTitle] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [cookTime, setcookTime] = useState("");
  const [prepTime, setprepTime] = useState("");
  const [serves, setServes] = useState("1");
  const [description, setDescription] = useState("");
  const [pictures, setPictures] = useState([]);
  const [tags, setTags] = useState([]);
  const [type, setType] = useState("general");
  const [initState, setInitState] = useState(false);
  const [defaultImage, setDefaultimage] = useState([]);
  const [recipeSource, setRecipeSource] = useState(null);
  const [recipeSourceMethod, setSourceMethod] = useState(null);
  const [categories, setCategories] = useState([]);
  const [author, setAuthor] = useState(null);
  const [macroTotals, setMacroTotals] = useState(null);
  const [keywords, setKeywords] = useState(null);
  const navigate = useNavigate();
  const updateIngredients = (array) => {
    const updatedArray = [...ingredients, ...array];

    setIngredients([...ingredients, ...array]);
  };

  const saveRecipe = () => {
    let errors = 0;
    let formData = new FormData();

    if (props.id) {
      formData.append("id", props.id);
    }
    if (pictures.length > 0) {
      if (pictures[0][0] instanceof File || pictures[0][0] instanceof Blob) {
        formData.append("file", pictures[0][0], pictures[0][0].name);
      }
    }

    if (!title) {
      errors++;
    }
    if (!serves) {
      errors++;
    }
    if (ingredients.length < 1) {
      errors++;
    }

    // if (errors > 0) {
    //   alert("Please add some ingredients.");
    //   return false;
    // }

    formData.append("name", title);
    formData.append("serves", serves);
    formData.append("type", type);
    author && formData.append("author", author);
    cookTime && formData.append("cookTime", cookTime);
    prepTime && formData.append("prepTime", prepTime);
    description &&
      formData.append("description", stripHtml(description).result);
    method && formData.append("method", stripHtml(method).result);

    formData.append("tags", tags);
    formData.append("ingredients", JSON.stringify(ingredients));
    formData.append("categories", JSON.stringify(categories));
    try {
      const macros = _.reduce(
        ingredients,
        function (acc, curr) {
          if (curr.macros) {
            acc.calories += Math.round(curr.macros.calories);
            acc.protein += Math.round(curr.macros.protein);
            acc.carbs += Math.round(curr.macros.carbs);
            acc.fat += Math.round(curr.macros.fat);
          }
          return acc;
        },
        { calories: 0, carbs: 0, protein: 0, fat: 0 }
      );
      formData.append("macros", JSON.stringify(macros));
    } catch (error) {
      console.log("could not generate calories");
    }

    if (props.action == "web") {
      formData.append("source_method", "web");
      formData.append("source", recipeSource);
      formData.append("image", defaultImage);
      formData.append("share", true);
      if (keywords) {
        formData.append("keywords", keywords);
      }
    }

    axios
      .post("/recipes/save", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        if (props.id && response.data.action == "UPDATE") {
          props.updateRecipe(response.data, navigate);
          amplitude.getInstance().logEvent("Recipe Updated By User");
        } else {
          if (props.action == "web") {
            amplitude.getInstance().logEvent("Web Recipe Imported By User");
          } else {
            amplitude.getInstance().logEvent("Recipe Inserted By User");
          }
          props.insertRecipe(response.data, navigate);
        }
        _.map(response.data.details[0].ingredients, function (i) {
          const find = _.find(props.rawMacros, {
            id: i.ingredientID,
            unit: i.unit,
          });
          if (!find) {
            console.log("hydrating:" + i.name);
            props.updateMacros(i.ingredientID, i.name, i.unit);
          }
        });
      });

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    //     fetch("http://localhost:8888/webhooks/test", {
    //       // Your POST endpoint
    //       method: "POST",
    //       body: formData,
    //     })
    //       .then(
    //         (response) => console.log(response.text()) // if the response is a JSON object
    //       )
    //       .catch(
    //         (error) => console.log(error) // Handle the error response object
    //       );
  };
  useEffect(() => {
    if (initState) {
      props.initialize();
    }

    if (props.action == "web" && !props.recipes.importedRecipe) {
      navigate("/recipes");
    }
    if (props.action == "create") {
      setCategories(props.defaultCategories);
    }
  });

  const cancelEdit = () => {
    navigate("/recipes/view/" + props.id);
  };

  if (props.action == "edit") {
    let id = props.id;

    let recipe = null;
    recipe = _.find(props.recipes.recipes, { hash: id });

    let recipeDetails = _.find(props.recipes.recipe_details, { id: id });

    if (recipeDetails && !initState) {
      setIngredients(recipeDetails.ingredients || ingredients);
      if (recipeDetails.method || method) {
        setMethod(
          stripHtml(recipeDetails.method).result || stripHtml(method).result
        );
      }
      setServes(recipeDetails.serves || serves);
      setprepTime(recipeDetails.prepTime || prepTime);
      setcookTime(recipeDetails.cookTime || cookTime);
      if (recipe.description || description) {
        setDescription(
          stripHtml(recipe.description).result || stripHtml(description).result
        );
      }
      setCategories(recipe.categories || categories);
      setRecipeSource(recipe.source || recipeSource);
      setSourceMethod(recipe.source_method || recipeSourceMethod);
      if (recipe.image && recipe.source_method == "web") {
        setDefaultimage([`${recipe.image}`]);
      } else {
        setDefaultimage([
          `${process.env.REACT_APP_API}/recipe_images/${recipe.image}`,
        ]);
      }
      setTitle(recipe.name || title);
      setTags(recipe.tagIDs);
      setInitState(true);
    }
  }

  if (props.action == "web" && props.recipes.importedRecipe && !initState) {
    let recipe = props.recipes.importedRecipe;

    setIngredients(recipe.ingredients || ingredients);
    setMethod(stripHtml(method).result);
    setServes(recipe.serves || serves);
    setprepTime(recipe.prepTime || prepTime);
    setcookTime(recipe.cookTime || cookTime);
    setDescription(
      stripHtml(recipe.description).result || stripHtml(description).result
    );
    setDefaultimage([recipe.image]);
    setTitle(recipe.name || title);
    setRecipeSource(recipe.source || recipeSource);
    setSourceMethod(recipe.source_method || recipeSourceMethod);
    setInitState(true);
    setAuthor(recipe.author || author);
    setKeywords(recipe.keywords || null);
  }

  const updateAllIngredients = (array) => {
    setIngredients([...array]);
  };

  const converted_units = {
    cup: "ml/g",
    ml: "ml",
    kg: "g",
    pt: "ml",
    tsp: "ml/g",
    tbsp: "ml/g",
    pinch: "ml/g",
    dash: "ml/g",
    squeeze: "ml",
    whole: "whole",
    handful: "handfuls",
    bunch: "bunch",
    "small bunch": "small bunch",
    slices: "slices",
    g: "g",
    l: "l",
    oz: "g",
    lb: "g",
  };
  const metric_translation = {
    cup: 240,
    ml: 1,
    kg: 1000,
    pt: 480,
    tsp: 5,
    tbsp: 15,
    pinch: 0.5,
    dash: 0.5,
    squeeze: 15,
    whole: 1,
    handful: 1,
    bunch: 1,
    "small bunch": 1,
    slices: 1,
    g: 1,
    l: 1000,
    oz: 28,
    lb: 450,
  };
  const updateIngredient = (id, data) => {
    let ingCopy, updatedIngredient;
    if (id.includes("new-")) {
      updatedIngredient = {
        ..._.find(ingredients, {
          id: id.replace("new-", ""),
        }),
        ...data,
      };
      delete updatedIngredient.macros;

      updatedIngredient.converted_unit =
        converted_units[updatedIngredient.unit];
      updatedIngredient.metric_translation =
        metric_translation[updatedIngredient.unit];
      updatedIngredient.loadingStatus = "refresh";
      ingCopy = ingredients.map((ingredient) =>
        ingredient.riid === id ? { ...updatedIngredient } : ingredient
      );
    } else {
      updatedIngredient = {
        ..._.find(ingredients, {
          riid: id,
        }),
        ...data,
      };

      delete updatedIngredient.macros;
      updatedIngredient.loadingStatus = "refresh";
      updatedIngredient.converted_unit =
        converted_units[updatedIngredient.unit];
      updatedIngredient.metric_translation =
        metric_translation[updatedIngredient.unit];

      ingCopy = ingredients.map((ingredient) =>
        ingredient.riid === id ? { ...updatedIngredient } : ingredient
      );
    }
    setIngredients([...ingCopy]);
    return true;
  };

  const removeIngredient = (id) => {
    console.log(id);
    let newArray = [...ingredients];
    _.remove(newArray, (i) => i.id === id);
    setIngredients(newArray);
  };

  const updateForm = (i, e) => {
    switch (i) {
      case "ingredients":
        setIngredients(e);
        break;
      case "title":
        setTitle(e);
        break;
      case "cookTime":
        setcookTime(e);
        break;
      case "prepTime":
        setprepTime(e);
        break;
      case "method":
        setMethod(e);
        break;
      case "serves":
        setServes(e);
        break;
      case "description":
        setDescription(e);
        break;
      case "type":
        setType(e);
        break;
      case "category":
        setCategories([...e]);
        return true;
      // update category state
      default:
        return null;
    }
  };

  return (
    <>
      <div className={classes.recipeContainer}>
        <Container className={classes.pageContainer}>
          <RecipeForm
            cancelEdit={() => {
              cancelEdit();
            }}
            returnMacroTotals={(totals) => setMacroTotals(totals)}
            action={props.action}
            id={props.id}
            title={title}
            description={description}
            ingredients={ingredients}
            updateAllIngredients={(array) => updateAllIngredients(array)}
            updateForm={(i, e) => updateForm(i, e)}
            method={method}
            tags={tags}
            categories={categories}
            updateTags={(arr) => {
              setTags([...arr]);
            }}
            cookTime={cookTime}
            prepTime={prepTime}
            serves={serves}
            pictures={pictures}
            recipeImage={defaultImage}
            source={recipeSource}
            source_method={recipeSourceMethod}
            saveRecipe={() => saveRecipe()}
            updatePicture={(arr) => setPictures([arr])}
            updateIng={(arr) => updateIngredients(arr)}
            removeIng={(id) => removeIngredient(id)}
            updateIngredient={(id, data) => {
              return updateIngredient(id, data);
            }}
          />
        </Container>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateRecipe: (payload, props) => dispatch(updateRecipe(payload, props)),
    insertRecipe: (payload, props) => dispatch(insertRecipe(payload, props)),
    updateMacros: (id, name) => dispatch(updateMacros(id, name)),
  };
};
const mapStateToProps = (state) => {
  return {
    recipes: state.recipes,
    defaultCategories: state.recipes.default_categories,
    rawMacros: state.recipes.raw_macros,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipeAmend);
