import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Dropdown,
  Container,
  Loader,
  Dimmer,
  Icon,
} from "semantic-ui-react";
import overlay from "./Overlay.module.scss";
import _ from "lodash";
import { round } from "../../utility/helper";
import Slider from "@material-ui/core/Slider";
import axios from "../../api/axios-token";
import { resetOverlay } from "../../store/actions/uiActions";
import { useDispatch, connect } from "react-redux";
import NumberPicker from "../../components/NumberPicker";
import amplitude from "amplitude-js";
import { BrowserView, MobileView } from "react-device-detect";
import { set } from "date-fns";

const TDEE = (props) => {
  const [step, setStep] = useState(1);
  const [goal, setGoal] = useState(props.tdee ? props.tdee.goal : null);
  const [height, setHeight] = useState(0);
  const [heightInches, setHeightInches] = useState(0);
  const [calorieTarget, setCalorieTarget] = useState(null);
  const [snackTarget, setSnackTarget] = useState(null);
  const [heightOptions, setHeightOptions] = useState([]);
  const [weight, setWeight] = useState(0);
  const [weightPounds, setWeightPounds] = useState(0);
  const [weightOptions, setWeightOptions] = useState([]);
  const [weightHalf, setWeightHalf] = useState(0);
  const [heightHalf, setHeightHalf] = useState(0);
  const [manualGoal, setManualGoal] = useState(
    props.tdee && props.tdee.manualTarget ? props.tdee.manualTarget : null
  );

  const [weightUnit, setWeightUnit] = useState(
    props.tdee && props.tdee.weight_unit ? props.tdee.weight_unit : "kg"
  );
  const [heightUnit, setHeightUnit] = useState(
    props.tdee && props.tdee.height_unit ? props.tdee.height_unit : "cm"
  );
  const [realWeight, setRealWeight] = useState(
    props.tdee ? props.tdee.weight : null
  );
  const [realHeight, setRealHeight] = useState(
    props.tdee ? props.tdee.height : null
  );

  const [sex, setSex] = useState(props.tdee ? props.tdee.sex : null);
  const [age, setAge] = useState(props.tdee ? props.tdee.age : null);

  const [exercise, setExercise] = useState(
    props.tdee ? props.tdee.exercise : null
  );
  const [lifestyle, setLifestyle] = useState(
    props.tdee ? props.tdee.lifestyle : null
  );
  const [ratio, setRatio] = useState(
    props.tdee && props.tdee.ratio ? props.tdee.ratio : 90
  );
  const [saving, setSaving] = useState(false);
  const [retake, setRetake] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (name, value) => {
    console.log("change");
  };

  useEffect(() => {
    if (
      props.tdee &&
      props.tdee.ratio &&
      props.ui.state == "ratio" &&
      !retake
    ) {
      setStep(5);
    }
    if (retake) {
      setStep(1);
    }
  }, []);

  useEffect(() => {
    setHeightOptions(getOptions(130, 220, ""));
    setWeightOptions(getOptions(40, 200, ""));
  }, []);

  useEffect(() => {
    if (heightUnit == "cm") {
      setHeightOptions(getOptions(130, 220, ""));

      var result = realHeight - Math.floor(realHeight) !== 0;
      setHeight(Math.floor(realHeight));
      setHeightInches(0);
      if (result) {
        setHeightHalf(0.5);
      } else {
        setHeightHalf(0);
      }
    } else {
      setHeightOptions(getOptions(4, 7, ""));
      let inches = realHeight / 2.54;
      const feet = Math.floor(inches / 12);
      inches = Math.round((inches - feet * 12) * 2) / 2;
      var result = inches - Math.floor(inches) !== 0;
      setHeight(feet);
      setHeightInches(Math.floor(inches));
      if (result) {
        setHeightHalf(0.5);
      } else {
        setHeightHalf(0);
      }
    }
  }, [heightUnit]);

  useEffect(() => {
    if (weightUnit == "kg") {
      setWeightOptions(getOptions(40, 200, ""));

      var result = realWeight - Math.floor(realWeight) !== 0;
      setWeight(Math.floor(realWeight));
      setWeightPounds(0);
      if (result) {
        setWeightHalf(0.5);
      } else {
        setWeightHalf(0);
      }
    } else {
      if (weightUnit == "lbs") {
        setWeightOptions(getOptions(88, 441, ""));
      } else {
        setWeightOptions(getOptions(7, 31, ""));
      }
      let pounds = realWeight * 2.2; // real weight stored in KG
      const stone = Math.floor(pounds / 14);
      let stonePounds = Math.round((pounds - stone * 14) * 2) / 2;
      var result = pounds - Math.floor(pounds) !== 0;
      if (weightUnit == "lbs") {
        pounds = pounds;
        setWeight(Math.floor(pounds));
        setWeightPounds(0);
        if (result) {
          setWeightHalf(0.5);
        } else {
          setWeightHalf(0);
        }
      }
      if (weightUnit == "stone") {
        setWeight(stone);

        setWeightPounds(Math.floor(stonePounds));
        var result = stonePounds - Math.floor(stonePounds) !== 0;
        if (result) {
          setWeightHalf(0.5);
        } else {
          setWeightHalf(0);
        }
      }
    }
  }, [weightUnit]);

  useEffect(() => {
    if (heightUnit == "cm") {
      setRealHeight(parseFloat(height) + parseFloat(heightHalf));
    } else {
      let cm = height * 12 * 2.54;
      cm = cm + heightInches * 2.54 + parseFloat(heightHalf * 2.54);
      cm = Math.round(cm * 2) / 2;
      setRealHeight(cm);
    }
  }, [heightHalf, height, heightInches]);

  useEffect(() => {
    if (weightUnit == "kg") {
      setRealWeight(parseFloat(weight) + parseFloat(weightHalf));
    } else {
      let kg;
      if (weightUnit == "lbs") {
        kg = weight / 2.2; // convert weight to kilograms
        kg += parseFloat(weightHalf) / 2.2;

        setRealWeight(kg);
      } else {
        kg = (weight * 14) / 2.2; // weight is stones here
        kg += weightPounds / 2.2;
        kg += parseFloat(weightHalf) / 2.2;

        setRealWeight(kg);
      }
    }
  }, [weightHalf, weight, weightPounds]);

  const bmr = () => {
    let bmr;
    let s;
    if (sex == 1) {
      s = -161;
    } else {
      s = 5;
    }
    bmr = 10 * realWeight + 6.25 * realHeight - 5 * age + s;

    return round(bmr, 2);
  };

  useEffect(() => {
    if (step > 4) {
      if (manualGoal !== null) {
        setCalorieTarget(manualGoal);
      } else {
        setCalorieTarget(Math.round(tdee(bmr(), goal)));
      }
    }
  }, [
    step,
    manualGoal,
    realHeight,
    realWeight,
    sex,
    exercise,
    lifestyle,
    goal,
  ]);

  const tdee = (bmr, target = null) => {
    let tdeecalc;
    let activity = exercise + lifestyle;
    if (activity > 4) {
      activity = 4;
    }
    switch (activity) {
      case 0:
        tdeecalc = 1.2;
        break;
      case 1:
        tdeecalc = 1.375;
        break;
      case 2:
        tdeecalc = 1.55;
        break;
      case 3:
        tdeecalc = 1.725;
        break;
      case 4:
        tdeecalc = 1.9;
        break;
    }

    if (target === null) {
      return round(bmr * tdeecalc, 2);
    } else {
      if (goal == 0) {
        return round(bmr * tdeecalc - 500, 2);
      }
      if (goal == 1) {
        return round(bmr * tdeecalc, 2);
      }
      if (goal == 2) {
        return round(bmr * tdeecalc, 2) + 300;
      }
    }
  };

  const setGoals = () => {
    if (parseInt(calorieTarget) == 0) {
      alert("Your calorie target cannot be 0");
      return;
    }
    setSaving(true);
    const tdeeGoal = parseInt(calorieTarget);

    const day = tdeeGoal;

    const mealGoal = round(day * (parseFloat(ratio) / 3 / 100), 0);
    const snackGoal = round(day * ((100 - parseFloat(ratio)) / 2 / 100), 0);

    const payload = {
      goal: goal,
      height: realHeight,
      weight: realWeight,
      height_unit: heightUnit,
      weight_unit: weightUnit,
      sex: sex,
      age: age,
      exercise: exercise,
      lifestyle: lifestyle,
      ratio: ratio,
      meal: mealGoal,
      snack: snackGoal,
      dailyTarget: day,
      manualTarget: manualGoal,
    };

    axios
      .post("/profile/update_tdee", {
        tdee: payload,
      })
      .then(function (response) {
        console.log(response);
        setSaving(false);
      })
      .catch(function (error) {
        console.log(error);
        setSaving(false);
        alert(
          "Something went wrong saving your information, try refreshing the page and try again. "
        );
      });
    amplitude.getInstance().logEvent("TDEE Updated");

    props.update(payload);
  };

  const overrideTarget = (e) => {
    const value = e.currentTarget.value;

    const regex = /^\d+\.?\d*$/;
    if (!regex.test(value) && value !== "") {
      return false;
    }

    setManualGoal(value);
  };

  const getOptions = (
    startNumber = 1,
    endNumber,
    prefix = "",
    placeholder = false
  ) => {
    let i = startNumber;
    const options = [];

    if (placeholder) {
      options.push({ key: 0, text: "--", value: null });
    }

    _.times(endNumber - startNumber, (index) => {
      let text = startNumber + index;
      options.push({
        key: index + 1,
        text: `${text}${prefix}`,
        value: startNumber + index,
      });
    });

    return options;
  };

  const active = overlay.Active;
  const radio = overlay.RadioActive;

  const close = () => {
    dispatch(resetOverlay());
  };

  return (
    <div className={overlay.Container}>
      <div className={overlay.Close} onClick={() => close()}>
        <Icon.Group style={{ margin: "1em" }}>
          <Icon style={{ marginRight: 0 }} size="big" name="circle outline" />
          <Icon name="close" />
        </Icon.Group>
      </div>
      {saving && (
        <Dimmer active>
          <Loader>Saving</Loader>
        </Dimmer>
      )}
      {step == 1 && (
        <div className={overlay.Centered}>
          <h2>What's your goal?</h2>
          <div>
            <Button
              onClick={() => {
                setStep(2);
                setGoal(0);
              }}
              className={overlay.giantButton}
            >
              <h2>Lose Fat</h2>
              <h3>Reduce your weight by eating healthier</h3>
            </Button>
          </div>
          <div>
            <Button
              onClick={() => {
                setStep(2);
                setGoal(1);
              }}
              className={overlay.giantButton}
            >
              <h2>Maintain Weight</h2>
              <h3>Sustain your progress</h3>
            </Button>
          </div>
          <div>
            <Button
              onClick={() => {
                setStep(2);
                setGoal(2);
              }}
              className={overlay.giantButton}
            >
              <h2>Gain Muscle</h2>
              <h3>Optimise your strength</h3>
            </Button>
          </div>
        </div>
      )}
      {step > 1 && (
        <div className={overlay.Centered}>
          <div
            style={{ maxWidth: "400px", margin: " 0 auto", textAlign: "left" }}
          >
            <Container>
              <Grid stackable columns="equal" className={overlay.tdeePersonal}>
                {step == 2 && (
                  <>
                    <h2>A little more about you</h2>
                    <Grid.Row style={{ textAlign: "center" }}>
                      <div className={`${overlay.Button} ${overlay.Toggle}`}>
                        <Button
                          onClick={() => {
                            setSex(0);
                          }}
                          className={sex == 0 ? active : null}
                          attached="left"
                          size="large"
                        >
                          Male
                        </Button>
                        <Button
                          onClick={() => {
                            setSex(1);
                          }}
                          className={sex == 1 ? active : null}
                          attached="right"
                          size="large"
                        >
                          Female
                        </Button>
                      </div>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={6} style={{ display: "flex" }}>
                        <label style={{ margin: "auto 0" }}>Your Age</label>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <BrowserView>
                          <div className={overlay.SelectGroup}>
                            <Dropdown
                              basic
                              placeholder="Age"
                              compact
                              selection
                              search
                              onChange={(e, v) => {
                                setAge(v.value);
                              }}
                              options={getOptions(18, 100)}
                              value={age}
                            />
                          </div>
                        </BrowserView>
                        <MobileView>
                          <NumberPicker
                            options={getOptions(18, 100)}
                            value={age}
                            onChange={(e) => {
                              setAge(e.currentTarget.value);
                            }}
                          >
                            {age}
                          </NumberPicker>
                        </MobileView>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={6} style={{ display: "flex" }}>
                        <label style={{ margin: "auto 0" }}>Your Height</label>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <BrowserView>
                          <div className={overlay.SelectGroup}>
                            <Dropdown
                              placeholder=""
                              basic
                              compact
                              selection
                              search
                              value={height}
                              onChange={(e, v) => {
                                setHeight(v.value);
                              }}
                              options={heightOptions}
                            />

                            {heightUnit != "cm" && (
                              <Dropdown
                                placeholder=""
                                basic
                                compact
                                selection
                                search
                                value={heightInches}
                                onChange={(e, v) => {
                                  setHeightInches(v.value);
                                }}
                                options={getOptions(0, 12)}
                              />
                            )}

                            <Dropdown
                              style={{ alignSelf: "stretch" }}
                              placeholder=""
                              basic
                              compact
                              selection
                              search
                              options={[
                                { text: "-", value: 0 },
                                { text: "½", value: 0.5 },
                              ]}
                              value={heightHalf}
                              onChange={(e, v) => {
                                setHeightHalf(v.value);
                              }}
                            />

                            <Dropdown
                              style={{ alignSelf: "stretch" }}
                              placeholder=""
                              basic
                              compact
                              selection
                              search
                              options={[
                                { text: "cm", value: "cm" },
                                { text: "feet + inches", value: "feet" },
                              ]}
                              value={heightUnit}
                              onChange={(e, v) => {
                                setHeightUnit(v.value);
                              }}
                            />
                          </div>
                        </BrowserView>
                        <MobileView>
                          <div className="number-picker-group">
                            <NumberPicker
                              options={getOptions(130, 220)}
                              value={height}
                              onChange={(e) => {
                                setHeight(e.currentTarget.value);
                              }}
                              options={heightOptions}
                            >
                              {height}
                            </NumberPicker>
                            {heightUnit == "feet" && (
                              <NumberPicker
                                options={getOptions(0, 12)}
                                value={heightInches}
                                onChange={(e) => {
                                  setHeightInches(e.currentTarget.value);
                                }}
                              >
                                {heightInches}
                              </NumberPicker>
                            )}
                            <NumberPicker
                              options={[
                                { text: "--", value: 0 },
                                { text: "½", value: 0.5 },
                              ]}
                              value={heightHalf}
                              onChange={(e) => {
                                setHeightHalf(e.currentTarget.value);
                              }}
                            >
                              {heightHalf}
                            </NumberPicker>
                            <NumberPicker
                              options={[
                                { text: "cm", value: "cm" },
                                { text: "feet + inches", value: "feet" },
                              ]}
                              value={heightUnit}
                              onChange={(e) => {
                                setHeightUnit(e.currentTarget.value);
                              }}
                            >
                              {heightUnit}
                            </NumberPicker>
                          </div>
                        </MobileView>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={6} style={{ display: "flex" }}>
                        <label style={{ margin: "auto 0" }}>Your Weight</label>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <BrowserView>
                          <div className={overlay.SelectGroup}>
                            <Dropdown
                              placeholder=""
                              basic
                              compact
                              selection
                              search
                              value={weight}
                              onChange={(e, v) => {
                                setWeight(v.value);
                              }}
                              options={weightOptions}
                            />

                            {weightUnit == "stone" && (
                              <Dropdown
                                placeholder=""
                                basic
                                compact
                                selection
                                search
                                value={weightPounds}
                                onChange={(e, v) => {
                                  setHeightInches(v.value);
                                }}
                                options={getOptions(0, 14)}
                              />
                            )}

                            <Dropdown
                              style={{ alignSelf: "stretch" }}
                              placeholder=""
                              basic
                              compact
                              selection
                              search
                              options={[
                                { text: "-", value: 0 },
                                { text: "½", value: 0.5 },
                              ]}
                              value={weightHalf}
                              onChange={(e, v) => {
                                setWeightHalf(v.value);
                              }}
                            />

                            <Dropdown
                              style={{ alignSelf: "stretch" }}
                              placeholder=""
                              basic
                              compact
                              selection
                              search
                              options={[
                                { text: "kg", value: "kg" },
                                { text: "lbs", value: "lbs" },
                                { text: "stone + pounds", value: "stone" },
                              ]}
                              value={weightUnit}
                              onChange={(e, v) => {
                                setWeightUnit(v.value);
                              }}
                            />
                          </div>
                        </BrowserView>
                        <MobileView>
                          <div className="number-picker-group">
                            <NumberPicker
                              options={weightOptions}
                              value={weight}
                              onChange={(e) => {
                                setWeight(e.currentTarget.value);
                              }}
                            >
                              {weight}
                            </NumberPicker>
                            {weightUnit == "stone" && (
                              <NumberPicker
                                options={getOptions(0, 14, "")}
                                value={weightPounds}
                                onChange={(e) => {
                                  setWeightPounds(e.currentTarget.value);
                                }}
                              >
                                {weightPounds}
                              </NumberPicker>
                            )}
                            <NumberPicker
                              options={[
                                { text: "--", value: 0 },
                                { text: "1/2", value: 0.5 },
                              ]}
                              value={weightHalf}
                              onChange={(e) => {
                                setWeightHalf(e.currentTarget.value);
                              }}
                            >
                              {weightHalf}
                            </NumberPicker>
                            <NumberPicker
                              options={[
                                { text: "kg", value: "kg" },
                                { text: "lbs", value: "lbs" },
                                { text: "stone + lbs", value: "stone" },
                              ]}
                              value={weightUnit}
                              onChange={(e) => {
                                setWeightUnit(e.currentTarget.value);
                              }}
                            >
                              {weightUnit}
                            </NumberPicker>
                          </div>
                        </MobileView>
                      </Grid.Column>
                    </Grid.Row>
                    {age && height && weight && sex != null ? (
                      <Grid.Row>
                        <Grid.Column>
                          <Button onClick={() => setStep(3)} fluid>
                            Continue
                          </Button>
                          <Button
                            style={{ marginTop: "0.5em" }}
                            onClick={() => setStep(step - 1)}
                            fluid
                          >
                            Back
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    ) : null}
                  </>
                )}
                {step == 3 && (
                  <>
                    <Grid.Row>
                      <Grid.Column>
                        <h2>How often do you work out?</h2>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <div className={`${overlay.Button} ${overlay.Radio}`}>
                          <Button
                            onClick={() => {
                              setExercise(0);
                              setStep(step + 1);
                            }}
                            className={exercise == 0 ? radio : null}
                            fluid
                          >
                            Never
                          </Button>
                        </div>
                        <div className={`${overlay.Button} ${overlay.Radio}`}>
                          <Button
                            onClick={() => {
                              setExercise(1);
                              setStep(step + 1);
                            }}
                            className={exercise == 0 ? radio : null}
                            fluid
                          >
                            Rarely
                          </Button>
                        </div>
                        <div className={`${overlay.Button} ${overlay.Radio}`}>
                          <Button
                            onClick={() => {
                              setExercise(2);
                              setStep(step + 1);
                            }}
                            className={exercise == 1 ? radio : null}
                            fluid
                          >
                            Occassionally
                          </Button>
                        </div>
                        <div className={`${overlay.Button} ${overlay.Radio}`}>
                          <Button
                            onClick={() => {
                              setExercise(3);
                              setStep(step + 1);
                            }}
                            className={exercise == 2 ? radio : null}
                            fluid
                          >
                            Regularly
                          </Button>
                        </div>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column>
                        <Button onClick={() => setStep(step + 1)} fluid>
                          Continue
                        </Button>
                        <Button
                          style={{ marginTop: "0.5em" }}
                          onClick={() => setStep(step - 1)}
                          fluid
                        >
                          Back
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
                {step == 4 && (
                  <>
                    <Grid.Row>
                      <Grid.Column>
                        <h2>What's your lifestyle like?</h2>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <div className={`${overlay.Button} ${overlay.Radio}`}>
                          <Button
                            onClick={() => {
                              setLifestyle(0);
                              setStep(step + 1);
                            }}
                            className={lifestyle == 0 ? radio : null}
                            fluid
                          >
                            Not very active
                          </Button>
                        </div>
                        <div className={`${overlay.Button} ${overlay.Radio}`}>
                          <Button
                            onClick={() => {
                              setLifestyle(1);
                              setStep(step + 1);
                            }}
                            className={lifestyle == 1 ? radio : null}
                            fluid
                          >
                            Moderately Active
                          </Button>
                        </div>
                        <div className={`${overlay.Button} ${overlay.Radio}`}>
                          <Button
                            onClick={() => {
                              setLifestyle(2);
                              setStep(step + 1);
                            }}
                            className={lifestyle == 2 ? radio : null}
                            fluid
                          >
                            Phsyically Demanding
                          </Button>
                        </div>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column>
                        <Button onClick={() => setStep(step + 1)} fluid>
                          Continue
                        </Button>
                        <Button
                          style={{ marginTop: "0.5em" }}
                          onClick={() => setStep(step - 1)}
                          fluid
                        >
                          Back
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
                {step == 5 && (
                  <>
                    <Grid.Row>
                      <Grid.Column>
                        <h2>Your calorie target for your goal is</h2>
                        <input
                          value={calorieTarget}
                          onChange={(e) => overrideTarget(e)}
                          className={overlay.calorieTarget}
                          onBlur={() => {
                            if (manualGoal == "") {
                              setManualGoal(null);
                            }
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <h5>
                          You're going to aim for 3 meals a day, but you may
                          want to save some room for snacks to keep you going
                          throughout the day. Use the slider below to adjust
                          your meal target.
                        </h5>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Slider
                          step={0.25}
                          min={75}
                          max={100}
                          value={parseFloat(ratio)}
                          onChange={(e, v) => {
                            setRatio(v);
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <h1>
                          Meal goal:{" "}
                          {round(
                            calorieTarget * (parseFloat(ratio) / 3 / 100),
                            0
                          )}{" "}
                          x 3
                        </h1>
                        <h2>
                          Snack Goal:{" "}
                          {round(
                            calorieTarget *
                              ((100 - parseFloat(ratio)) / 2 / 100),
                            0
                          )}{" "}
                          x 2
                        </h2>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Grid.Row>
                          <Button
                            onClick={() => setGoals()}
                            className={`${overlay.giantButton}`}
                            fluid
                            size="big"
                          >
                            Set my goals
                          </Button>
                          <Button
                            onClick={() => setStep(1)}
                            className={`${overlay.giantButton}`}
                            fluid
                            size="big"
                          >
                            Retake Quiz
                          </Button>
                        </Grid.Row>{" "}
                      </Grid.Column>
                    </Grid.Row>
                    {/* <Grid.Row>
                      <Grid.Column>Details:</Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>Basal Metabolic Rate</Grid.Column>
                      <Grid.Column>{bmr()}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        Estimated Daily Calorie Expenditure
                      </Grid.Column>
                      <Grid.Column>{tdee(bmr())}</Grid.Column>
                    </Grid.Row> */}
                  </>
                )}
              </Grid>
            </Container>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tdee: state.auth.user.options.tdee,
    ui: state.ui,
  };
};

export default connect(mapStateToProps)(TDEE);
