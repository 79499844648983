import * as actionTypes from "../actions/uiActions";

const initialState = {
  ui: { bottomNavActive: "home" },
  recipeScreen: null,
  overlay: null,
  state: null,
  visibleDay: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SWITCH_BOTTOM_NAV:
      state = { ...state, bottomNavActive: action.active };
      return state;
    case actionTypes.RESET_OVERLAY:
      return { ...state, overlay: null, state: null };
    case actionTypes.LOAD_OVERLAY:
      return { ...state, overlay: action.payload, state: action.state };
    case actionTypes.SAVE_CURRENT_DAY:
      return { ...state, visibleDay: action.payload };
    default:
      return state;
  }
};

export default reducer;
