import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import classes from "./Planner.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";
import CustomIcon from "../../components/CustomIcon";
import { Label, Icon } from "semantic-ui-react";
// import { drumstick } from "@fortawesome/free-regular-svg-icons";

function truncate(str, n, useWordBoundary) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1); // the original check
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + "..."
  );
}

const PlannerEntry = (props) => {
  let image = null;
  let workoutImage = null;
  let placeholder = null;
  let icon = null;
  let bodycoach = null;
  let thumbnail = null;
  switch (props.data.type) {
    case "workout":
      placeholder = "workout";
      image = props.data.image;
      workoutImage = classes.workoutImage;

      if (props.data.thumbnail) {
        try {
          thumbnail = JSON.parse(props.data.thumbnail);
        } catch (error) {
          thumbnail = null;
        }
      }
      break;
    case "custom":
      placeholder = "custom";
      image = null;
      icon = props.data.icon;
      break;
    default:
      if (props.data.image == "null" || !props.data.image) {
        image = null;
        placeholder = "recipe";
        if (props.data.type == "meal") {
          placeholder = classes.meal;
        }
      } else {
        if (props.data.source_method == "web") {
          image = props.data.image;
        } else {
          image = `${process.env.REACT_APP_API}/recipe_images/${props.data.image}`;
        }
      }
      if (props.data.recipe_type == "reduced") {
        bodycoach = "reduced";
      }
      if (props.data.recipe_type == "refuel") {
        bodycoach = "refuel";
      }
      if (props.data.recipe_type == "snack") {
        bodycoach = "snack";
      }
      break;
  }
  return (
    <div
      {...props.draggableProps}
      ref={props.innerRef}
      className={`${classes.recipeCard} ${bodycoach}`}
      onClick={() => {
        if (
          (props.edit && props.data.type == "recipe") ||
          props.data.type == "meal"
        ) {
          props.edit(props.data);
        }
      }}
    >
      {parseInt(props.data.locked) ? (
        <div className={classes.lockedOverlay}>
          <Icon style={{ margin: 0 }} name="lock" />
        </div>
      ) : null}
      {props.data.type == "recipe" || props.data.type == "meal" ? (
        <>
          {image ? (
            <div
              {...props.dragHandleProps}
              className={`${classes.recipeImage} ${workoutImage}`}
              style={{
                background: `url(" ${image}")`,
              }}
            ></div>
          ) : (
            <div
              {...props.dragHandleProps}
              className={`${classes.recipeImage} ${workoutImage} ${classes.placeholder} ${placeholder} ${bodycoach}`}
            >
              {props.data.type == "meal" ? (
                <CustomIcon className={classes.placeholderIcon} icon="meal" />
              ) : (
                <FontAwesomeIcon
                  className={classes.placeholderIcon}
                  icon={faUtensils}
                />
              )}
            </div>
          )}
        </>
      ) : null}
      {props.data.type == "workout" && (
        <div
          {...props.dragHandleProps}
          className={`${classes.recipeImage} ${workoutImage} ${
            thumbnail && classes.placeholder
          } ${thumbnail && placeholder} ${thumbnail && classes.workout}`}
        >
          {image && (
            <div
              className={classes.workoutOverlay}
              style={{
                background: `url( ${image})`,
              }}
            ></div>
          )}
          {!image && thumbnail && (
            <div className={classes.customOverlay}>
              <div className={classes.Icon}>
                <CustomIcon icon={thumbnail.image} />
              </div>

              <div className={classes.caption}>{props.data.name}</div>
            </div>
          )}
        </div>
      )}
      {props.data.type == "custom" && (
        <div
          {...props.dragHandleProps}
          className={`${classes.customCard} ${props.data.icon}`}
        >
          <div className={classes.customOverlay}>
            <div className={classes.Icon}>
              <CustomIcon icon={props.data.icon} />
            </div>

            <div className={classes.caption}>{props.data.name}</div>
          </div>
        </div>
      )}

      <span>{truncate(props.data.name, 35, true)}</span>
      {parseInt(props.data.portions) > 1 && (
        <Label className={classes.leftoverNumber} circular color="teal">
          {props.data.portions}
        </Label>
      )}
      {parseInt(props.data.reduce) == 1 && (
        <>
          {props.data.portions > 0 ? (
            <Label className={classes.leftoverNumber} circular color="green">
              {props.data.portions} <Icon style={{ margin: 0 }} name="user" />
            </Label>
          ) : (
            <Label className={classes.leftoverNumber} circular color="red">
              <Icon style={{ margin: 0 }} name="user cancel" />
            </Label>
          )}
        </>
      )}
    </div>
  );
};

export default PlannerEntry;
