import React, { useEffect } from "react";

import { connect } from "react-redux";
import { logOut } from "../../store/actions/authActions";
import { Navigate, useNavigate } from "react-router-dom";

const SignOut = (props) => {
  useEffect(() => {
    props.logout();
  });

  return <Navigate to="/" />;
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logOut()),
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
