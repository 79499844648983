import React, { useState, useEffect } from "react";
import { loadTdee } from "../../store/actions/uiActions";
import { getWaterTarget, round } from "../../utility/helper";
import { Link } from "react-router-dom";
import classes from "./Profile.module.scss";
import {
  Container,
  Grid,
  Menu,
  Divider,
  Button,
  Select,
  Dropdown,
  Icon,
} from "semantic-ui-react";
import { connect, useDispatch } from "react-redux";
import axios from "../../api/axios-token";
import { updateDay } from "../../store/actions/authActions";
import ProfileImage from "./ProfileImage";
import { useNavigate } from "react-router-dom";
import WaterTargetModal from "./WaterTargetModal";
import Slider from "@material-ui/core/Slider";

const ProfileButton = (props) => {
  const dispatch = useDispatch();
  const [waterModal, setWaterModal] = useState(false);

  const [day, setDay] = useState(props.user.options.startDay || 1);
  const history = useNavigate();
  const showQuiz = () => {
    dispatch(loadTdee());
  };

  const updateRatio = () => {
    dispatch(loadTdee("ratio"));
  };

  const dayOptions = [
    { key: 1, value: 1, text: "Monday" },
    { key: 2, value: 2, text: "Tuesday" },
    { key: 3, value: 3, text: "Wednesday" },
    { key: 4, value: 4, text: "Thursday" },
    { key: 5, value: 5, text: "Friday" },
    { key: 6, value: 6, text: "Saturday" },
    { key: 7, value: 7, text: "Sunday" },
  ];

  const changeDay = (value) => {
    setDay(value);
    axios.post("/profile/update_day", {
      day: value,
    });
    dispatch(updateDay(value));
  };

  const direction = props.direction || "right";

  return (
    <>
      <Dropdown
        className={classes.profileDropdown}
        style={{ userSelect: "none" }}
        direction={direction}
        trigger={<ProfileImage size={50} />}
        inline
      >
        <Dropdown.Menu>
          {props.user.options.tdee ? (
            <>
              <Dropdown.Header>
                Calorie goal:{" "}
                <span className="green">
                  {props.user.options.tdee.dailyTarget}kcal
                </span>
              </Dropdown.Header>
              <Dropdown.Header onClick={() => updateRatio()}>
                {" "}
                Meal goal:{" "}
                <span className="green">
                  {props.user.options.tdee.meal}kcal
                </span>
              </Dropdown.Header>
              <Dropdown.Header onClick={() => updateRatio()}>
                {" "}
                Snack goal:{" "}
                <span className="green">
                  {props.user.options.tdee.snack}kcal x 2
                </span>
              </Dropdown.Header>
              {props.user.options.water ? (
                <Dropdown.Header onClick={() => setWaterModal(true)}>
                  Water goal:{" "}
                  <span className="green">
                    {round(props.user.options.water.target / 1000, 1)} litres
                  </span>
                </Dropdown.Header>
              ) : (
                <Dropdown.Header onClick={() => setWaterModal(true)}>
                  Water goal:
                  <span className="green">
                    {props.user.options.tdee.dailyTarget
                      ? round(
                          getWaterTarget(
                            props.user.options.tdee.dailyTarget,
                            props.user.options.tdee.lifestyle,
                            props.user.options.tdee.exercise
                          ) / 1000,
                          1
                        )
                      : 2500}
                  </span>
                </Dropdown.Header>
              )}
            </>
          ) : (
            <Dropdown.Header onClick={() => setWaterModal(true)}>
              Water goal:{" "}
              <span className="green">
                {props.user.options.water && props.user.options.water.target
                  ? round(props.user.options.water.target / 1000, 2)
                  : 2.5}{" "}
                litres
              </span>
            </Dropdown.Header>
          )}
          <Dropdown.Header className="green" onClick={() => showQuiz()}>
            {`${props.user.options.tdee ? "Retake" : "Take"}`} the nutrition
            quiz
          </Dropdown.Header>
          <Divider />
          <Dropdown
            text="Diary Start Day"
            pointing={direction}
            className="link item"
          >
            <Dropdown.Menu direction={direction}>
              {dayOptions.map(function (d, i) {
                let selected = null;
                if (d.value == day) {
                  selected = (
                    <Icon style={{ marginLeft: "1em" }} name="checkmark" />
                  );
                }
                return (
                  <Dropdown.Item
                    onClick={() => changeDay(d.value)}
                    key={`day-select${i}`}
                  >
                    {d.text}
                    {selected}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          <Link to="/progress" className="item">
            Progress
          </Link>
          <Link to="/profile/settings" className="item">
            Profile Settings
          </Link>
          <Dropdown.Item
            onClick={() => {
              history("/recipes/my", { state: { manageRecipes: true } });
            }}
          >
            Recipe Manager
          </Dropdown.Item>
          <Dropdown.Divider />
          <a href="https://www.planeatmeal.com/blog" className="item">
            Blog
          </a>
          <Link to="/policies" className="item">
            Policies
          </Link>
          <Link to="/guide" className="item">
            User Guide
          </Link>
          <Dropdown.Divider />
          <Link to="/sign-out" className="item">
            Sign Out
          </Link>
        </Dropdown.Menu>
      </Dropdown>
      <WaterTargetModal open={waterModal} close={() => setWaterModal(false)} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ProfileButton);
