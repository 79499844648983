import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import classes from "./Planner.module.scss";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { getRawWorkoutInfo, parseCustomCard } from "../../utility/helper";

import PlannerEntry from "./PlannerEntry";

const ClipBoard = (props) => {
  if (props.clipboard.length) {
    const clipBoard = props.clipboard.map((seg, i) => {
      if (
        seg.type === "recipe" ||
        seg.type === "meal" ||
        seg.type === "reduced" ||
        seg.type == "refuel" ||
        seg.type == "general"
      ) {
        const recipe = _.find(props.recipes.recipes, function (r) {
          return r.id == seg.data;
        });
        let type = "recipe";
        if (seg.type == "meal") {
          type = "meal";
        }

        if (recipe) {
          const segment = {
            recipe_id: recipe.id,
            name: recipe.name,
            image: recipe.image,
            type: type,
            source_method: recipe.source_method,
          };
          return segment;
        }
      } else {
        if (seg.type === "custom") {
          let custom;
          if (parseInt(seg.data) > 0) {
            custom = _.find(props.custom, function (c) {
              return c.id == seg.data;
            });
          } else {
            custom = parseCustomCard(seg.optional_data);
          }
          if (!custom) {
            return null;
          }

          const segment = {
            workout_id: custom.id,
            name: custom.name,
            icon: custom.icon,
            type: "custom",
            source_method: "custom",
          };
          return segment;
        } else {
          if (seg.type == "workout") {
            const workout = _.find(props.workouts, function (w) {
              return parseInt(w.id) == parseInt(seg.data);
            });
            if (!workout) {
              return null;
            }

            const workoutData = getRawWorkoutInfo(workout);

            const segment = {
              workout_id: workout.id,
              name: workout.name,
              icon: workoutData.icon,
              image: workoutData.image,
              thumbnail: workoutData.thumbnail,
              type: "workout",
              source_method: workout.source,
            };
            return segment;
          }
        }
      }
    });

    return (
      <div className={classes.clipBoard}>
        <div className={classes.clipBoardTitle}>
          Clipboard
          <div onClick={() => props.clear()} className={classes.trashIcon}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </div>
        </div>

        <Droppable
          isDropDisabled={true}
          direction="horizontal"
          droppableId={`clipboard`}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={classes.Items}
            >
              {clipBoard.map((d, i) => {
                return (
                  <Draggable
                    type="CLIPBOARD"
                    key={`clipboard-${i}`}
                    draggableId={`clipboard-${i}`}
                    index={i}
                  >
                    {(provided, snapshot) => {
                      return (
                        <PlannerEntry
                          draggableProps={provided.draggableProps}
                          dragHandleProps={provided.dragHandleProps}
                          innerRef={provided.innerRef}
                          key={`clipboard-${i}`}
                          id={`clipboard-${i}`}
                          data={d}
                        />
                      );
                    }}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => {
  return {
    recipes: state.recipes,
    workouts: state.planner.workouts,
    custom: state.planner.customCards,
  };
};

export default connect(mapStateToProps)(ClipBoard);
