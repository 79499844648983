import React from "react";
import Avatar from "react-avatar";
import discover from "../Discover/Discover.module.scss";

const Image = (props) => {
  const user = props.user;
  if (!user) {
    return <Avatar size={23} round />;
  }
  if (user.provider) {
    return <Avatar facebookId={user.provider_id} size={23} round />;
  } else {
    return (
      <Avatar name={`${user.first_name} ${user.last_name}`} size={23} round />
    );
  }
};

const UserImage = (props) => {
  return (
    <div className={discover.userImage}>
      <Image user={props.user} />
    </div>
  );
};

export default UserImage;
