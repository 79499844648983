import React, { useEffect, useState } from "react";
import classes from "./Ingredients.module.scss";
import Ingredient from "./Ingredient";
import { Flipper, Flipped } from "react-flip-toolkit";
import { ratioIngredient } from "../../shopping/ShoppingListGroup";
import { Button, Divider, Icon } from "semantic-ui-react";

import Stagger from "react-stagger";
import _ from "lodash";
import pluralize from "pluralize";

const Ingredients = (props) => {
  const [editing, setEditing] = useState(false);
  const [animation, setAnimation] = useState(null);
  const [animationEffect, setAnimationEffect] = useState(0);
  const [ratio, setRatio] = useState(100);
  const [servings, setServings] = useState(parseInt(props.serves));
  const [ingredients, setIngredients] = useState(null);

  const parseDescription = (line) => {
    const descriptiveWords = [
      "sliced",
      "drained",
      "grated",
      "finely",
      "toasted",
      "chopped",
      "diced",
      "shredded",
      "deseeded",
      "to serve",
      "frozen",
      "fresh",
      "crushed",
      "peeled",
      "cut into",
      "separated",
      "deseeded",
    ];
    let description = null;
    for (var i = 0; i < descriptiveWords.length; i++) {
      const test = RegExp(`,\s*(.*${descriptiveWords[i]}.*)`, "gi");
      var match = test.exec(line);
      if (match) {
        description = match[1];
        break;
      }
    }
    return description;
  };

  useEffect(() => {
    if (props.receivePortions) {
      let portions = parseInt(props.receivePortions);

      if (parseInt(portions) < 1) {
        portions = 1;
      }
      let serves = props.receiveServings || 1;

      // if portions are more than servings...  then the portions are actually floor of servings / portions
      // treat serves like 1 meal

      if (serves > 1 && !props.receiveReduced) {
        portions = Math.ceil(portions / serves);
        serves = 1;
      }
      if (serves > 1 && !props.receiveReduced) {
        serves = 1;
      }

      // if reduce enabled servings should be as read

      setServings(props.receivePortions);
      setRatio(Math.round((portions / serves) * 100, 2));
    }
  }, [props.receivePortions, props.receiveReduced]);

  useEffect(() => {
    const ingredientsCopy = [...props.ingredients];
    const ingredientDescriptions = [];
    _.map(ingredientsCopy, function (i, index) {
      const percent = ratio;
      const quantity = parseFloat(i.user_quantity || i.quantity);
      const newAmount = ratioIngredient(i, percent).user_quantity;

      ingredientsCopy[index].ratio = newAmount;
      pluralize.addIrregularRule("chilli", "chillies");
      pluralize.addSingularRule(/chilli$/i, "chilli");
      pluralize.addUncountableRule("leaves");

      if ((quantity == 1) & (i.unit == "whole")) {
        if (pluralize.isPlural(i.name)) {
          ingredientsCopy[index].name = pluralize.singular(i.name);
        }
      }

      if (!i.instructions && props.masterCycle && props.originalIngs) {
        const ing = { name: i.name, index: index };
        ingredientDescriptions.push(ing);
      }
    });
    if (
      props.masterCycle &&
      props.originalIngs &&
      ingredientDescriptions.length > 0
    ) {
      try {
        let originalIngs = props.originalIngs.split("\n");
        ingredientDescriptions.map(function (ing, i) {
          let name = pluralize.isPlural(ing.name)
            ? pluralize.singular(ing.name)
            : ing.name;
          const originalIndex = _.findIndex(originalIngs, function (i) {
            name = name.split(" ");
            name = name.join("|");
            const regex = new RegExp(name, "gi");
            if (i.match(regex)) {
              return true;
            }
          });
          if (originalIndex > -1) {
            let description = parseDescription(originalIngs[originalIndex]);
            if (description) {
              ingredientsCopy[ing.index].instructions = description;
            }
            originalIngs.splice(originalIndex, 1);
          }
        });
      } catch (error) {}
    }

    setIngredients(ingredientsCopy);
  }, [props.ingredients, props.serves, props.editing, ratio]);

  if (!ingredients) {
    return null;
  }

  const editIngredient = () => {
    setEditing(true);
  };

  const adjustRatio = (n) => {
    let portions = servings + n;
    if (portions < 1) {
      portions = 1;
    }
    let serves = props.serves || 1;
    setServings(portions);

    setRatio(Math.round((portions / serves) * 100, 2));
  };

  let editingClass = null;
  props.editing ? (editingClass = "editing") : (editingClass = null);
  const stagger = "stagger";

  return (
    <>
      {!props.editing && props.serves && (
        <div className="no-print">
          <Divider horizontal>Adjust Cooking Amounts</Divider>
          <Button.Group size="mini" fluid>
            <Button onClick={() => adjustRatio(-1)}>-</Button>
            <Button basic>{`${servings} serving${
              servings > 1 ? "s" : ""
            }`}</Button>
            <Button onClick={() => adjustRatio(1)}>+</Button>
          </Button.Group>
        </div>
      )}
      <Flipper
        staggerConfig={{
          // the "default" config will apply to staggered elements without explicit keys
          default: {
            // default direction is forwards
            reverse: true,
            // default is .1, 0 < n < 1
            speed: 0.9,
          },
        }}
        spring="stiff"
        flipKey={`ingredientsTab-${animation}-${ingredients.length}`}
      >
        <ul className={`${classes.Container} ${editingClass} ${stagger}`}>
          {_.map(ingredients, (ingredient, i) => {
            let spacer = " ";
            let unit_spacer = null;
            let unit_class = null;
            const regex = /\bkg\b|\bl\b|\blb\b|\boz\b|\bpt\b|\btsp\b|\btbsp\b|\bml\b|\bg\b/g;

            if (!ingredient.unit.match(regex)) {
              unit_spacer = spacer;
            } else {
              unit_spacer = null;
            }
            if (ingredient.unit == "whole") {
              unit_class = "hidden";
            }
            let key = ingredient.riid || ingredient.uuid || ingredient.id;

            if (props.keyPrefix) {
              key = props.keyPrefix + key;
            }

            return (
              <Flipped key={key} flipId={key + "-flip"} stagger>
                <li
                  onClick={() => {
                    props.editing && editIngredient();
                  }}
                  className={`${classes.Ingredient} ${animation}`}
                >
                  <Ingredient
                    updateIngredient={(id, data) => {
                      return props.updateIngredient(id, data);
                    }}
                    index={i}
                    noRatio={props.noRatio}
                    editing={props.editing}
                    quantity={ingredient.user_quantity || ingredient.quantity}
                    ratio={ingredient.ratio || null}
                    image={ingredient.image}
                    name={ingredient.name}
                    macros={ingredient.macros}
                    instructions={ingredient.instructions}
                    unit={ingredient.unit}
                    ingredientID={ingredient.ingredientID}
                    riid={ingredient.riid}
                    ajaxAttempts={props.ajaxAttempts}
                    loadingStatus={ingredient.status}
                    phrase={ingredient.phrase}
                    removeIng={() => {
                      props.removeIng(ingredient.id || ingredient.uuid);
                    }}
                  />
                </li>
              </Flipped>
            );
          })}
        </ul>
      </Flipper>
    </>
  );
};

export default Ingredients;
