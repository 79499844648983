import React from "react";
import classes from "./Container.module.scss";
const AstroWater = (props) => {
  return (
    <>
      <div className={props.className}>
        <img
          className={classes.astro}
          alt="Spaceman floating on water drifting across the screen"
          src={`${process.env.PUBLIC_URL}/img/astroWater.png`}
        />

        <img
          className={classes.duck}
          alt="Duck floating across the screen"
          src={`${process.env.PUBLIC_URL}/img/astroDuck.png`}
        />
      </div>
    </>
  );
};

export default AstroWater;
