import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import axios from "../../../api/axios-token";
import { Grid, Loader, Input, Icon, Button } from "semantic-ui-react";
import RecipeCard from "../../../components/recipes/recipeCard";
import InfiniteScroll from "react-infinite-scroller";
import RecipePlaceholder from "../../../components/recipes/RecipePlaceholder";
import VerticalCard from "../components/VerticalCard";
import { useParams, useNavigate } from "react-router-dom";
import useWindowSize from "../../../utility/useWindowSize";
import discover from "./Discover.module.scss";
import _ from "lodash";
const Category = () => {
  const { category } = useParams();
  const history = useNavigate();
  const [keyword, setKeyword] = useState(null);
  const [searchText, setSearchText] = useState("");
  if (!category) {
    history("/recipes/browse");
  }
  const queryClient = new useQueryClient();
  const windowSize = useWindowSize();

  const fetchRecipes = ({ pageParam = 0 }) =>
    axios
      .post("/discover/browse_categories", {
        cursor: pageParam,
        category: category,
        keyword: keyword,
      })
      .then((res) => {
        return res.data;
      });

  const search = () => {
    if (searchText) {
      setKeyword(searchText);
    } else {
      setKeyword(null);
    }
  };

  useEffect(() => {
    refetch();
  }, [keyword]);

  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchNextPage,
    refetch,
    hasNextPage,
    error,
  } = useInfiniteQuery(`recipes-${category}`, fetchRecipes, {
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => lastPage.nextCursor ?? undefined,
  });

  return status === "loading" ? (
    <p>Loading...</p>
  ) : status === "error" ? (
    <p>Error: {error.message}</p>
  ) : (
    <>
      <Input
        style={{ display: "none" }}
        value={searchText}
        fluid
        type="text"
        placeholder="Search..."
        action
      >
        <input
          onChange={(e) => {
            setSearchText(e.currentTarget.value);
          }}
        />
        {/* <Select compact options={options} defaultValue='articles' /> */}
        <Button type="button" onClick={() => search()}>
          Search
        </Button>
      </Input>
      <InfiniteScroll
        pageStart={0}
        loadMore={fetchNextPage}
        hasMore={hasNextPage}
        loader={<Loader key={"infinite-loader"} active inline="centered" />}
      >
        <Grid
          columns={windowSize.width > 768 ? 3 : 2}
          style={{ marginTop: "1em" }}
          stackable
        >
          <Grid.Row>
            {_.map(data.pages, (data, i) => {
              return (
                <React.Fragment key={i}>
                  {data.recipes.map((recipe) => {
                    return (
                      <Grid.Column key={recipe.hash}>
                        <VerticalCard
                          onClick={() => {
                            history("/recipes/discover/view/" + recipe.hash);
                          }}
                          id={recipe.hash}
                          recipeName={recipe.name}
                          recipeImage={recipe.image}
                          recipeSource={recipe.source}
                          recipeAuthor={recipe.author}
                          recipeSourceMethod={recipe.source_method}
                          recipeTags={recipe.tagIDs}
                          recipeCategories={recipe.categories}
                          type={recipe.type}
                          user={recipe.user}
                        />
                      </Grid.Column>
                    );
                  })}
                </React.Fragment>
              );
            })}

            {isFetching && !isFetchingMore && (
              <>
                <Grid.Column>
                  <RecipePlaceholder />
                </Grid.Column>
                <Grid.Column>
                  {" "}
                  <RecipePlaceholder />
                </Grid.Column>
              </>
            )}
          </Grid.Row>
        </Grid>
      </InfiniteScroll>
    </>
  );
};

export default Category;
