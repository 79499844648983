import React, { useState } from "react";
import classes from "./Shopping.module.scss";

import AnimateHeight from "react-animate-height";
import { Dropdown, Icon } from "semantic-ui-react";
import _ from "lodash";
import groupArray from "group-array";
import { formatDateDay, parseDate, getFullDay } from "../Planner/dateHelper.js";
import { round } from "../../utility/helper";
import Masonry from "react-masonry-css";

function roundNearest(v, n) {
  return Math.round(v / n) * n;
}

export const ratioIngredient = (ingredient, percent) => {
  if (isNaN(parseInt(percent))) {
    return;
  }
  const ratio = parseInt(percent) / 100;

  let rounding = 1;

  let unit = ingredient.converted_unit;

  if (!ingredient.converted_quantity) {
    unit = ingredient.unit;
  }

  let quantity =
    ingredient.converted_quantity ||
    ingredient.user_quantity ||
    ingredient.quantity;

  quantity = parseFloat(quantity);

  switch (unit) {
    case "g":
      rounding = 1;
      break;
    case "ml":
      rounding = 1;
      break;
    case "whole":
      rounding = 0.5;
      break;
    case "pinch":
      rounding = 1;
      break;
    case "handful":
      rounding = 1;
      break;
    case "tsp":
      rounding = 0.5;
      break;
    case "tbsp":
      rounding = 0.5;
      break;
    case "bunch":
      rounding = 0.5;
      break;
    case "squeeze":
      rounding = 1;
      break;
    case "slices":
      rounding = 1;
      break;
    case "cup":
      rounding = 0.25;
      break;
    case "kg":
      rounding = 0.25;
      break;
    case "l":
      rounding = 0.25;
      break;
    case "lb":
      rounding = 0.25;
      break;
    case "pt":
      rounding = 0.25;
      break;
    default:
      rounding = 1;
  }

  var eggsRegex = /eggs?\b( whites?)?/;

  if (eggsRegex.test(ingredient.name)) {
    rounding = 1;
  }

  let newUserQuantity = quantity;

  if (ratio != 1) {
    newUserQuantity = roundNearest(ratio * quantity, rounding);
  }
  if (newUserQuantity <= 0) {
    newUserQuantity = rounding;
  }

  const newIngredient = {
    ...ingredient,
    user_quantity: newUserQuantity,
  };

  return newIngredient;
};

const ShoppingListGroup = (props) => {
  const [recipeToggle, setRecipeToggle] = useState([]);
  const [hideCupboards, setHideCupboards] = useState([]);
  const [clipboard, setClipboard] = useState([]);

  function toggleRecipe(recipe) {
    const togglesCopy = [...recipeToggle];

    if (
      _.remove(togglesCopy, function (e) {
        return e === recipe;
      }).length < 1
    ) {
      togglesCopy.push(recipe);
    }
    setRecipeToggle(togglesCopy);
  }
  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 2,
    500: 1,
  };

  return (
    <>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {props.parsedShopping
          ? _.map(props.parsedShopping.columns, function (column, i) {
              let displayTitle = "auto";
              if (column.totalCount == column.checkCount) {
                displayTitle = 0;
              }

              return (
                <AnimateHeight key={i} duration={500} height={column.title}>
                  <div className={column.title}>
                    <h5 className={classes.GroupTitle}>
                      {column.header}
                      <span className={classes.totalCount}>
                        ({column.totalCount})
                      </span>
                      <span
                        className={classes.collapse}
                        onClick={() => {
                          props.toggleCollapsed(column.groupId);
                        }}
                      >
                        <Icon
                          color="green"
                          name={
                            props.collapsed.indexOf(column.groupId) > -1
                              ? "angle down"
                              : "angle up"
                          }
                        />
                      </span>
                    </h5>

                    <AnimateHeight
                      key={column.groupId}
                      duration={500}
                      height={
                        props.collapsed.indexOf(column.groupId) > -1
                          ? 0
                          : "auto"
                      }
                    >
                      <ul className={classes.Group}>
                        {/* shopping list item start  */}
                        {_.map(
                          column.shopping,
                          function (ingredient, ingIndex) {
                            let height = 0;
                            if (recipeToggle.indexOf(ingredient.key) > -1) {
                              height = "auto";
                            }

                            let checkedClass = "auto";
                            if (ingredient.checked) {
                              checkedClass = 0;
                            }

                            return (
                              <AnimateHeight
                                key={ingredient.key}
                                duration={500}
                                height={checkedClass}
                              >
                                <li
                                  className={`${classes.shoppingListRow}`}
                                  onClick={() => {
                                    if (props.sort != "recipe") {
                                      toggleRecipe(ingredient.key);
                                    }
                                  }}
                                >
                                  <div className={classes.shoppingTopRow}>
                                    {props.sort == "aisle" && (
                                      <div
                                        key={`${ingredient.ingredient_id}-${ingredient.unit}`}
                                        className={classes.tickBox}
                                        onClick={(e) => {
                                          if (props.sort != "aisle") {
                                            return false;
                                          }
                                          e.preventDefault();
                                          e.stopPropagation();
                                          props.togglePurchase(
                                            ingredient.shopping_ids
                                          );
                                        }}
                                      >
                                        <div
                                          className={`round ${ingredient.indeterminate}`}
                                        >
                                          <input
                                            className="checkbox"
                                            type="checkbox"
                                            checked={ingredient.checked}
                                            onChange={(e) => e.preventDefault()}
                                          />
                                          <label></label>
                                        </div>
                                      </div>
                                    )}

                                    <div className={classes.shoppingItem}>
                                      <div className={classes.Ingredient}>
                                        <span
                                          className={classes.IngredientName}
                                        >
                                          {ingredient.name}
                                        </span>
                                        <span
                                          className={classes.IngredientAmount}
                                        >
                                          {round(ingredient.quantity, 1)}
                                        </span>
                                        <span
                                          className={classes.IngredientUnit}
                                        >
                                          {ingredient.unit}
                                        </span>
                                      </div>
                                      {props.sort == "aisle" && (
                                        <div className={classes.Options}>
                                          <Dropdown
                                            direction="left"
                                            inline
                                            icon={"ellipsis vertical"}
                                          >
                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  props.removeShoppingIds(
                                                    ingredient.shopping_ids
                                                  )
                                                }
                                                icon="trash"
                                                text="Remove"
                                              />
                                              <Dropdown.Item
                                                icon="edit"
                                                text="Adjust Quantity"
                                                onClick={() => {
                                                  props.edit(
                                                    ingredient.ingredient_id,
                                                    ingredient.converted_unit,
                                                    ingredient.quantity,
                                                    ingredient.name,
                                                    column.cupboard_name,
                                                    column.cupboard_id
                                                  );
                                                }}
                                              />
                                              {/* <Dropdown.Item
                                        icon="food"
                                        text="Pantry"
                                        onClick={() => {
                                          props.stock(
                                            unit[0].ingredient_id,
                                            unit[0].converted_unit,
                                            quantity,
                                            unit[0].name
                                          );
                                        }}
                                      /> */}
                                              {props.sort != "recipes" && (
                                                <Dropdown.Item
                                                  icon="eye"
                                                  onClick={() => {
                                                    toggleRecipe(
                                                      ingredient.key
                                                    );
                                                  }}
                                                  text="Toggle View Recipes"
                                                />
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {props.sort != "recipe" && (
                                    <div>
                                      <AnimateHeight
                                        duration={500}
                                        height={height}
                                      >
                                        <div
                                          className={classes.shoppingRecipeName}
                                        >
                                          {_.map(
                                            ingredient.recipes,
                                            function (recipe, i) {
                                              //console.log(recipe);
                                              return (
                                                <div key={i}>
                                                  {recipe.length} <span>x</span>{" "}
                                                  {recipe[0].recipe_name}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </AnimateHeight>
                                    </div>
                                  )}
                                </li>
                              </AnimateHeight>
                            );
                            // end ingredient unit map
                          }
                        )}
                        {/* shopping list item end */}
                      </ul>
                    </AnimateHeight>
                  </div>
                </AnimateHeight>
              );
            })
          : null}
      </Masonry>
    </>
  );
};

export default ShoppingListGroup;
