import React, { useState, useEffect } from "react";
import Slider from "@material-ui/core/Slider";
import axios from "../../api/axios-token";
import { Modal, Button, Label, Header } from "semantic-ui-react";
import { updateWater } from "../../store/actions/authActions";
import { connect, useDispatch } from "react-redux";
import { getWaterTarget, round } from "../../utility/helper";
import classes from "./Profile.module.scss";
import amplitude from "amplitude-js";
const WaterTargetModal = (props) => {
  const dispatch = useDispatch();

  const [waterEdit, setWaterEdit] = useState(null);
  const [waterError, setWaterError] = useState(null);

  const sliderText = (value) => {
    return `${round(value / 1000, 1)}L`;
  };

  const updateWaterTarget = () => {
    let value = parseFloat(waterEdit);
    axios
      .post("/profile/update_water", {
        water: waterEdit,
        waterStep: 250,
      })
      .then(function (response) {
        amplitude.getInstance().logEvent("Adjusted Water Goal");

        dispatch(
          updateWater({
            target: waterEdit,
            step: 250,
          })
        );
      });
    props.close();
  };

  const handleChange = (value) => {
    setWaterEdit(value);
  };
  useEffect(() => {
    if (props.user.options.water && props.user.options.water.target) {
      setWaterEdit(parseInt(props.user.options.water.target));
    } else {
      setWaterEdit(3000);
    }
  }, [props.user.options.water]);

  return (
    <Modal open={props.open}>
      <Modal.Header>Set custom water goal</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Header>Override water goal</Header>
          <div>
            {!props.user.options.tdee ||
            !props.user.options.tdee.dailyTarget ? (
              <p>
                Take our nutritional quiz to automatically set your water goal.
                We recommend a minimum of 2.5 litres per day. You can also
                adjust this manually using the slider below.
              </p>
            ) : (
              <>
                <p>
                  We generate a water goal based on your nutritional quiz. If
                  you would prefer to enter your own water target adjust the
                  slider to your own target.
                </p>
                <p>
                  Our recommendation is{" "}
                  {round(
                    getWaterTarget(
                      props.user.options.tdee.dailyTarget,
                      props.user.options.tdee.exercise,
                      props.user.options.tdee.activity
                    ) / 1000,
                    1
                  )}{" "}
                  litres
                </p>
              </>
            )}
          </div>
        </Modal.Description>

        <Slider
          className={classes.slider}
          value={parseInt(waterEdit)}
          valueLabelFormat={sliderText}
          getAriaValueText={sliderText}
          onChange={(e, v) => handleChange(v)}
          step={100}
          min={2500}
          max={5000}
          valueLabelDisplay="on"
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.close()} color="black">
          Cancel
        </Button>
        <Button
          content="Update water goal"
          labelPosition="right"
          icon="checkmark"
          positive
          onClick={() => updateWaterTarget()}
        />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(WaterTargetModal);
