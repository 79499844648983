import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { connect } from "react-redux";
import {
  updateSelectedRecipes,
  updateSelectedWorkouts,
  clearSelected,
} from "../../store/actions/plannerActions";
import { Button } from "semantic-ui-react";
import classes from "./Planner.module.scss";

const PlannerFooter = (props) => {
  let buttonDisabled = true;
  let buttonText = "";

  if (
    props.selectedRecipes.length +
      props.selectedWorkouts.length +
      props.selectedCards.length >
    0
  ) {
    buttonDisabled = false;
  }
  if (
    props.selectedRecipes.length +
      props.selectedWorkouts.length +
      props.selectedCards.length ==
    1
  ) {
    buttonText = "item ";
  }
  if (
    props.selectedRecipes.length +
      props.selectedWorkouts.length +
      props.selectedCards.length >
    1
  ) {
    buttonText = `${
      props.selectedRecipes.length +
      props.selectedWorkouts.length +
      props.selectedCards.length
    } items `;
  }

  return (
    <div className={classes.Footer}>
      <Button
        disabled={buttonDisabled}
        className="rounded"
        color="green"
        onClick={() => {
          props.addPlannerItems(
            props.selectedRecipes,
            props.selectedWorkouts,
            props.selectedCards,
            props.clearSelected
          );
        }}
      >
        Add {buttonText}to {props.day}
      </Button>
      <Button
        color="red"
        className="rounded"
        onClick={() => {
          props.reset();
        }}
      >
        Cancel
      </Button>
    </div>
  );
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateSelectedRecipes: (array) => dispatch(updateSelectedRecipes(array)),
    updateSelectedWorkouts: (array) => dispatch(updateSelectedWorkouts(array)),

    clearSelected: () => dispatch(clearSelected()),
  };
};

const mapStateToProps = (state) => {
  return {
    selectedRecipes: state.planner.selectedRecipes,
    selectedWorkouts: state.planner.selectedWorkouts,
    selectedCards: state.planner.selectedCards,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlannerFooter);
