import React, { useState, useEffect } from "react";
import _ from "lodash";
import recipes from "./Recipes.module.scss";
import axios from "../../api/axios-token";
import IngredientsBasic from "./components/IngredientsBasic";
import { addTag } from "../../store/actions/recipeActions";
import { useRouter } from "react-router-dom";
import CameraScanner from "./components/CameraScanner";
import Categories from "./components/Categories";
import Tour from "reactour";
import {
  Grid,
  Form,
  Button,
  Dropdown,
  Dimmer,
  Loader,
  Segment,
  Message,
  Modal,
  Icon,
} from "semantic-ui-react";
import ImageUploader from "../../components/upload-image/index.js";
import Macros from "../Recipes/components/Macros";
import MacroBox from "../Recipes/components/MacroBox";
import { connect } from "react-redux";
import amplitude from "amplitude-js";
import { updateOnboarding } from "../../store/actions/uiActions";

const setOptions = (tags) => {
  if (tags) {
    return Object.keys(tags).map((i) => {
      return {
        key: tags[i].id,
        text: tags[i].tag_name,
        value: tags[i].id,
      };
    });
  }
  return true;
};

const RecipeForm = (props) => {
  const [tagOptions, setTagOptions] = useState([]);
  const [loadingIngredients, setloadingIngredients] = useState(false);
  const [textIngredients, settextIngredients] = useState(null);
  const [tags, setTags] = useState([]);
  const [saving, setSaving] = useState(false);
  const [tour, setTour] = useState(false);

  let editIngredients = false;

  useEffect(() => {
    if (props.onboarding) {
      const homeBoarding = _.find(props.onboarding, {
        boarding_variable: "CREATE_RECIPE_ONBOARDING",
      });
      if (homeBoarding.status === null) {
        setTour(true);
      }
    }
  }, []);

  const steps = [
    {
      selector: '[data-tour = "container"]',
      content: (
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column style={{ textAlign: "center" }}>
              <Icon name="lemon" color="yellow" size="huge" />

              <h2>Creating a recipe</h2>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <p>Let's quickly show you around the Create Recipe screen.</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "categories"]',
      content: (
        <div>
          <h2>Categories</h2>
          <>
            <p>
              Categories are quick global tags that let you filter your recipes
              when planning.
            </p>
          </>
        </div>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "tags"]',
      content: (
        <div>
          <h2>Tags</h2>
          <>
            <p>
              Tags are exactly the same as Categories except you can type
              anything you want to here, giving you more flexibility.
            </p>
          </>
        </div>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "ingredients"]',
      content: (
        <div>
          <h2>Adding Ingredients</h2>
          <>
            <p>
              The most important part of any recipe! Type or copy and paste your
              ingredients line by line.
            </p>
          </>
        </div>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "detectIngredients"]',
      position: "top",
      content: (
        <div>
          <h2>Hit the detect ingredients button!</h2>
          <>
            <p>
              We'll then scan line by line and match the ingredients to the
              database and work out the calories.
            </p>
          </>
        </div>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "serves"]',
      content: (
        <div>
          <h2>Add servings</h2>
          <>
            <p>
              Don't forget to update the number of servings to match the recipe.
            </p>
          </>
        </div>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "scan"]',
      content: (
        <div>
          <h2>Scan Text</h2>
          <>
            <p>You can also scan text from a book or a screenshot.</p>
          </>
        </div>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
      stepInteraction: true,
    },
    {
      selector: '[data-tour = "save"]',
      content: (
        <div>
          <>
            <p>
              Don't forget to click save once you're done to add the recipe to
              your collection!.
            </p>
          </>
        </div>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
      stepInteraction: true,
    },
  ];

  const addTag = (tagName) => {
    let newTag = null;
    axios
      .post("/recipes/add_tag", {
        tag_name: tagName,
      })
      .then(function (response) {
        // update tag redux
        amplitude.getInstance().logEvent("Tag Added");

        const tag = response.data.tag;
        newTag = {
          keys: tag.id,
          text: tagName,
          value: tag.id,
        };
        return tag;
      })
      .then(function (response) {
        if (newTag) {
          setTagOptions([...tagOptions, { ...newTag }]);
          setTags([...tags, newTag.value]);
          props.addTag(response);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onDrop = (picture) => {
    props.updatePicture(picture);
  };

  useEffect(() => {
    if (props.recipes.tags) {
      setTagOptions(setOptions(props.recipes.tags));
      setTags(props.value || []);
    }
  }, [props.recipes.tags]);

  useEffect(() => {
    if (props.tags) {
      setTags(props.tags);
    }
  }, [props.tags]);

  useEffect(() => {
    if (props.action == "web") {
      editIngredients = true;
    }
  }, []);

  const detectIngredients = (e) => {
    if (!textIngredients) {
      return;
    }

    setloadingIngredients(true);
    axios
      .post("/recipes/detect_ingredients", {
        ingredients: textIngredients,
      })
      .then(function (response) {
        setloadingIngredients(false);
        const detectedIngredients = response.data.ingredients;
        let ingArray = [];
        const indexStart = props.ingredients.length;

        detectedIngredients.map((i, index) => {
          let ingObject = {
            id: "new-" + (parseInt(index) + parseInt(indexStart)),
            name: i.ingredient.name,
            user_quantity: i.quantity,
            instructions: i.instruction,
            image: i.ingredient.image,
            phrase: i.phrase,
            unit: i.unit,
            apiID: i.ingredient.api_id,
            ingredientID: i.ingredient.ingredientID,
            riid: "new-" + (parseInt(index) + parseInt(indexStart)),
          };

          ingArray.push(ingObject);
        });
        props.updateIng(ingArray);
        settextIngredients(null);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleTakePhoto = (text) => {
    // Do stuff with recognized text...
    console.log("Recognize text: ", text);
  };

  const closeTour = () => {
    setTour(false);
    props.updateOnboarding({ variable: "CREATE_RECIPE_ONBOARDING", status: 0 });
  };
  const endTour = () => {
    setTour(false);
    props.updateOnboarding({ variable: "CREATE_RECIPE_ONBOARDING", status: 1 });
  };

  return (
    <>
      <Grid>
        <Grid.Column>
          <Form
            unstackable
            onSubmit={(e) => {
              if (!props.ingredients.length) {
                if (!textIngredients) {
                  alert("You haven't added any ingredients.");
                } else {
                  alert("You have not detected your ingredients.");
                }
                return;
              }

              setSaving(true);
              props.saveRecipe();
            }}
          >
            <Dimmer inverted active={saving}>
              <Loader>Saving Recipe</Loader>
            </Dimmer>
            <Form.Field>
              <label>Recipe Title</label>
              <Form.Input
                required
                placeholder="Recipe Title"
                value={props.title}
                onChange={(e, v) => props.updateForm("title", v.value)}
              />
            </Form.Field>
            <ImageUploader
              className={recipes.ImageUpload}
              onChange={onDrop}
              withPreview
              withIcon={true}
              buttonText="Upload Recipe Image"
              singleImage
              withLabel
              imgExtension={[".jpg", ".png", ".jpeg"]}
              maxFileSize={10485760}
              defaultImages={props.recipeImage}
            />
            <Form.Group
              className={recipes.Categories}
              width={"equal"}
              style={{ marginBottom: "0.5em" }}
              data-tour="categories"
            >
              <Categories
                action={props.action}
                updateCategories={(e) => props.updateForm("category", e)}
                updateType={(e) => props.updateForm("type", e)}
                categories={props.categories}
              />
            </Form.Group>
            <Form.Field>
              <label>Short Description</label>

              <Form.TextArea
                className={recipes.recipeText}
                value={props.description}
                placeholder=""
                onChange={(e, v) => props.updateForm("description", v.value)}
              />
            </Form.Field>
            {!props.ingredients.length ? (
              <Form.Field data-tour="ingredients">
                <label>Ingredients - Give each ingredient its own line</label>
                <Segment attached style={{ padding: 0 }}>
                  <Dimmer.Dimmable dimmed={loadingIngredients}>
                    <Dimmer active={loadingIngredients} inverted>
                      <Loader>Loading</Loader>
                    </Dimmer>
                    <Form.TextArea
                      style={{ border: "none" }}
                      rows={5}
                      placeholder=""
                      value={textIngredients || ""}
                      onChange={(e, v) => settextIngredients(v.value)}
                    />
                  </Dimmer.Dimmable>
                </Segment>
                <Button.Group attached="bottom">
                  <Button
                    type="button"
                    color="teal"
                    onClick={() => detectIngredients()}
                    data-tour="detectIngredients"
                  >
                    Detect Ingredients
                  </Button>{" "}
                  <CameraScanner
                    text="Scan"
                    color="teal"
                    size="large"
                    updateText={(v) => settextIngredients(v)}
                  />
                </Button.Group>
              </Form.Field>
            ) : (
              <>
                <Form.Field>
                  <label>Ingredients - click an ingredient to edit</label>
                  {props.ingredients && (
                    <Macros
                      returnTotals={(totals) => props.returnMacroTotals(totals)}
                      ingredients={props.ingredients}
                      serves={props.serves}
                      updateIngredients={(array) =>
                        props.updateAllIngredients(array)
                      }
                    >
                      <MacroBox
                        mini
                        hideadjustment
                        hideingredients
                        hideupdatebutton
                      />
                      <IngredientsBasic
                        updateIngredient={(id, data) => {
                          {
                            return props.updateIngredient(id, data);
                          }
                        }}
                        editing={true}
                        removeIng={(id) => props.removeIng(id)}
                      />
                    </Macros>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>Add more ingredients:</label>
                  <Form.Input
                    placeholder="Enter quantity and ingredient"
                    onChange={(e, v) => {
                      settextIngredients(v.value);
                    }}
                  >
                    <input />

                    <Form.Button
                      onClick={(e) => {
                        e.preventDefault();
                        detectIngredients();
                      }}
                    >
                      Search
                    </Form.Button>
                  </Form.Input>
                </Form.Field>
              </>
            )}
            <Form.Field>
              {props.action == "web" && (
                <Message info>
                  <Message.Header>Import from Web</Message.Header>
                  <p>
                    To protect copyright, we don't import recipe instructions -
                    however feel free to type your own in the box below.{" "}
                  </p>
                </Message>
              )}
              <label>Instructions</label>
              <Message
                info
                header="Tips"
                content="Separate recipe steps by a new line and these will be clearly seprated on the recipe view page."
              />
              <Segment attached style={{ padding: 0 }}>
                <Form.TextArea
                  className={recipes.recipeText}
                  onChange={(e, v) => props.updateForm("method", v.value)}
                  rows={6}
                  placeholder=""
                  value={props.method}
                />
              </Segment>
              <Button.Group style={{ overflow: "hidden" }} attached="bottom">
                <CameraScanner
                  text="Scan"
                  color="teal"
                  size="large"
                  updateText={(v) => props.updateForm("method", v)}
                />
              </Button.Group>
            </Form.Field>

            <Form.Group widths={3}>
              <Form.Field style={{ textAlign: "center" }}>
                <label>
                  Prep Time
                  <br />
                  Minutes
                </label>
                <Form.Input
                  type="number"
                  onChange={(e, v) => props.updateForm("prepTime", v.value)}
                  value={props.prepTime}
                />
              </Form.Field>
              <Form.Field style={{ textAlign: "center" }}>
                <label>
                  Cook Time
                  <br />
                  Minutes
                </label>
                <Form.Input
                  type="number"
                  onChange={(e, v) => props.updateForm("cookTime", v.value)}
                  value={props.cookTime}
                />
              </Form.Field>
              <Form.Field
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexFlow: "column",
                  justifyContent: "space-between",
                }}
              >
                <label style={{ display: "flex", alignSelf: "center" }}>
                  Servings
                </label>
                <Form.Input
                  data-tour="serves"
                  required
                  type="number"
                  value={props.serves}
                  onChange={(e, v) => props.updateForm("serves", v.value)}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field data-tour="tags" width={16}>
              <Dropdown
                closeOnChange
                upward
                style={{ marginTop: "1em" }}
                fluid
                selection
                multiple={true}
                search={true}
                options={tagOptions}
                value={tags}
                allowAdditions={true}
                onAddItem={(e, t) => {
                  addTag(t.value);
                }}
                placeholder="Add Tags"
                onChange={(e, t) => {
                  setTags(t.value);
                  props.updateTags(t.value);
                }}
                onSearchChange={() => {
                  // searchChange();
                }}
              />
            </Form.Field>
            <Form.Field>
              <Form.Button
                data-tour="save"
                className={"rounded"}
                fluid
                positive
                type="submit"
              >
                Save Recipe
              </Form.Button>
            </Form.Field>
            {props.id && (
              <Form.Field>
                <Form.Button
                  onClick={(e) => {
                    e.preventDefault();
                    props.cancelEdit();
                  }}
                  className={"rounded"}
                  fluid
                >
                  Cancel
                </Form.Button>
              </Form.Field>
            )}
          </Form>
        </Grid.Column>
        <Tour
          rounded={15}
          showNavigationNumber={false}
          showNumber={false}
          steps={steps}
          isOpen={tour}
          disableInteraction
          onRequestClose={() => closeTour()}
          closeWithMask={false}
          accentColor="#47c73c"
        />
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    recipes: state.recipes,
    onboarding: state.auth.user.onboarding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTag: (payload) => dispatch(addTag(payload)),
    updateOnboarding: (payload) => dispatch(updateOnboarding(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipeForm);
