import React, { useState, useRef } from "react";

import classes from "./Shopping.module.scss";
import { Icon, Input, Dropdown } from "semantic-ui-react";
import _ from "lodash";
import AnimateHeight from "react-animate-height";
import AddItemList from "./AddItemList";
const AddItems = (props) => {
  const [value, setValue] = useState("");

  const purchasedExtras = _.reduce(
    props.extra,
    function (acc, curr) {
      if (props.purchased.indexOf("extra-" + curr.id) < 0) {
        return [...acc, curr];
      } else {
        return [...acc];
      }
    },
    []
  );
  function onEnterPress(e) {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      if (value != "") {
        props.addItem(value);
        setValue("");
      }
    }
  }

  let collapseId = "addItems";
  return (
    <div className={classes.purchasedGroup}>
      <h5 className={classes.GroupTitle}>
        Additional Items
        <span className={classes.totalCount}>({purchasedExtras.length})</span>
        <span
          className={classes.collapse}
          onClick={() => {
            props.toggleCollapsed(collapseId);
          }}
        >
          <Icon
            color="green"
            name={
              props.collapsed.indexOf(collapseId) > -1
                ? "angle down"
                : "angle up"
            }
          />
        </span>
      </h5>
      <AnimateHeight
        key={collapseId}
        duration={500}
        height={props.collapsed.indexOf(collapseId) > -1 ? 0 : "auto"}
      >
        <div>
          <ul className={classes.Group}>
            {_.map(purchasedExtras, function (item, index) {
              let checked = false;
              if (props.purchased.indexOf("extra-" + item.id) > -1) {
                checked = true;
              }
              let iconColor = null;
              if (checked) {
                iconColor = "green";
              }
              let iconText = "plus";
              if (item.quantity < 0) {
                iconText = "minus";
                if (checked) {
                  iconColor = "red";
                }
              }

              if (props.sort == "aisle") {
                const obj = {
                  key: item.id,
                  name: item.name,
                  quantity: "",
                };
              }

              return (
                <AddItemList
                  item={item}
                  key={`extra-${index}`}
                  togglePurchase={(arr) => {
                    props.togglePurchase(arr);
                  }}
                  sort={props.sort}
                  checked={checked}
                  index={index}
                  remove={(id) => props.remove(id)}
                  addItem={(val) => props.addItem(val)}
                  updateItem={(id, val) => props.updateItem(id, val)}
                />
              );
            })}
            <li className={`${classes.shoppingListRow}`}>
              <div className={classes.shoppingTopRow}>
                {props.sort == "aisle" && (
                  <div className={classes.tickBox}>
                    <div className="round">
                      <input
                        className="checkbox"
                        type={"checkbox"}
                        onChange={(e) => e.preventDefault()}
                      />
                      <label htmlFor="checkbox"></label>
                    </div>
                  </div>
                )}
                <div className={classes.shoppingItem}>
                  <div className={classes.Ingredient}>
                    <Input
                      onKeyDown={(e) => onEnterPress(e)}
                      value={value}
                      onChange={(e, d) => setValue(d.value)}
                      transparent
                      placeholder="Add an item..."
                    />
                  </div>
                </div>
              </div>
              <div></div>
            </li>
          </ul>
        </div>
      </AnimateHeight>
    </div>
  );
};

export default AddItems;
