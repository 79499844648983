import axios from "../../api/axios-auth";
import amplitude from "amplitude-js";
//import { useNavigate } from "react-router-dom";
//import { useReducer } from "react";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const START_LOGIN = "START_LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOG_OUT = "LOG_OUT";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const CHECK_AUTH_FAIL = "CHECK_AUTH_FAIL";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const FB_TOKEN_SUCCESS = "FB_TOKEN_SUCCESS";
export const INIT_FB_SIGN_UP = "INIT_FB_SIGN_UP";
export const INIT_FB_LOGIN = "INIT_FB_LOGIN";
export const CANCEL_SIGNUP = "CANCEL_SIGNUP";
export const START_SIGN_UP = "START_SIGN_UP";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const INIT_SIGN_UP = "INIT_SIGN_UP";
export const UPDATE_TDEE = "UPDATE_TDEE";
export const UPDATE_ONBOARDING = "UPDATE_ONBOARDING";
export const UPDATE_START_DAY = "UPDATE_START_DAY";
export const UPDATE_ALWAYS_REDUCE = "UPDATE_ALWAYS_REDUCE";
export const UPDATE_TERMS = "UPDATE_TERMS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const UPDATE_WATER = "UPDATE_WATER";
export const UPDATE_USER_IMAGE = "UPDATE_USER_IMAGE";

export const initSignUp = () => {
  return {
    type: INIT_SIGN_UP,
  };
};

export const updateWater = (payload) => {
  return {
    type: UPDATE_WATER,
    payload: payload,
  };
};

export const startSignup = () => {
  return {
    type: START_SIGN_UP,
  };
};

export const updateUserImage = (userImage, profileImage) => {
  const payload = {
    userImage,
    profileImage,
  };
  return {
    type: UPDATE_USER_IMAGE,
    payload: payload,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const cancelSignup = () => {
  return {
    type: CANCEL_SIGNUP,
  };
};
export const initFacebookLogin = (payload) => {
  return {
    type: INIT_FB_LOGIN,
    payload: payload,
  };
};

export const initFacebookSignup = () => {
  return {
    type: INIT_FB_SIGN_UP,
  };
};
export const facebookTokenSuccess = (response) => {
  return {
    type: FB_TOKEN_SUCCESS,
    user: response,
  };
};

export const refreshTokenSuccess = (response) => {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    user: response,
  };
};

export const startLogin = () => {
  return {
    type: START_LOGIN,
  };
};
export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    user: user,
  };
};

export const loginFail = (error) => {
  return {
    type: LOGIN_FAIL,
    error: error,
  };
};
export const signupFail = (error) => {
  return {
    type: SIGNUP_FAIL,
    error: error,
  };
};

export const userLoggedOut = () => {
  return {
    type: LOG_OUT,
  };
};

export const checkAuthLoginFail = () => {
  localStorage.removeItem("REACT_TOKEN_AUTH");
  localStorage.removeItem("REACT_TOKEN_REFRESH");
  return {
    type: CHECK_AUTH_FAIL,
  };
};

export const authCheckLogin = () => {
  return (dispatch) => {
    const token = localStorage.getItem("REACT_TOKEN_AUTH");
    const refresh = localStorage.getItem("REACT_TOKEN_REFRESH");

    if (!token || token === "undefined") {
      dispatch(checkAuthLoginFail());
    } else {
      axios
        .post("/refresh_token", {
          token: token,
          refresh: refresh,
        })
        .then(function (response) {
          localStorage.setItem("REACT_TOKEN_AUTH", response.data.token);
          localStorage.setItem("REACT_TOKEN_REFRESH", response.data.refresh);
          localStorage.setItem("STREAM_TOKEN", response.data.stream);
          dispatch(loginSuccess(response.data));
        })
        .catch(function (error) {
          //NEEDTODO
          dispatch(checkAuthLoginFail(error));
        });
    }
  };
};

export const startlogOut = () => {
  localStorage.removeItem("REACT_TOKEN_AUTH");
  localStorage.removeItem("REACT_TOKEN_REFRESH");
  localStorage.removeItem("STREAM_TOKEN");
  return {
    type: "RESET",
  };
};

export const logOut = () => async (dispatch) => {
  await Promise.all([dispatch(startlogOut())]);

  return dispatch(userLoggedOut());
};

export const facebookLogin = (payload) => {
  return (dispatch) => {
    if (!payload.id) {
      dispatch(loginFail());
      return;
    }
    dispatch(initFacebookLogin(payload));
    // use axios to test facebook id
    axios
      .post("/facebook_login", {
        id: payload.id,
        email: payload.email,
      })
      .then(function (response) {
        amplitude.getInstance().logEvent("Logged in with Facebook");

        localStorage.setItem("REACT_TOKEN_AUTH", response.data.token);
        localStorage.setItem("REACT_TOKEN_REFRESH", response.data.refresh);

        dispatch(loginSuccess(response.data));
      })
      .catch(function (error) {
        if (!error.response) {
          alert("Could not connect to server");
          dispatch(checkAuthLoginFail());

          return Promise.reject(error);
        }
        if (401) {
          if (error.response.data.message === "USER_NOT_FOUND") {
            dispatch(initFacebookSignup());
          }
          if (error.response.data.message === "EMAIL_EXISTS") {
            const errorMessage = {
              error: true,
              details: [
                "This email address " +
                  payload.email +
                  " is already registered. Login with your email address and connect your facebook account from the Profile Screen.",
              ],
            };

            dispatch(loginFail(errorMessage));
          }
        } else {
          dispatch(loginFail());
        }
        return Promise.reject(error);
      });
    // if good - login successful
    // if failed redirect to signin page and include pre-filled
  };
};

export const signUp = (payload) => {
  return (dispatch) => {
    dispatch(initSignUp());
    axios
      .post("/sign_up", { ...payload })
      .then(function (response) {
        const facebook =
          response.data.user.provider == "facebook" ? true : false;
        if (facebook) {
          amplitude.getInstance().logEvent("Signed up with Facebook");
        } else {
          amplitude.getInstance().logEvent("Signed up with Email");
        }
        localStorage.setItem("REACT_TOKEN_AUTH", response.data.token);
        localStorage.setItem("REACT_TOKEN_REFRESH", response.data.refresh);
        dispatch(loginSuccess(response.data));
      })
      .catch(function (error) {
        //NEEDTODO

        dispatch(signupFail(error.response.data));
      });
  };
};

export const refreshToken = (response) => {
  localStorage.setItem("REACT_TOKEN_AUTH", response.data.token);
  localStorage.setItem("REACT_TOKEN_REFRESH", response.data.refresh);
  localStorage.setItem("STREAM_TOKEN", response.data.stream);

  return (dispatch) => {
    dispatch(refreshTokenSuccess(response.data.user));
  };
};

export const updateTdee = (payload) => {
  return {
    type: UPDATE_TDEE,
    payload: payload,
  };
};

export const updateTerms = (payload) => {
  return (dispatch) => {
    const object = { ...payload, terms: 1 };
    dispatch(updateTermsSuccess(object));
  };
};

export const updateTermsSuccess = (payload) => {
  return {
    type: UPDATE_TERMS,
    payload: payload,
  };
};
export const updateAlwaysReduce = (payload) => {
  return {
    type: UPDATE_ALWAYS_REDUCE,
    payload: payload,
  };
};

export const updateDay = (payload) => {
  return {
    type: UPDATE_START_DAY,
    payload: payload,
  };
};

export const login = (payload) => {
  return (dispatch) => {
    dispatch(startLogin());
    axios
      .post("/signin", {
        email: payload.email,
        password: payload.password,
      })
      .then(function (response) {
        dispatch(loginSuccess(response.data));
        localStorage.setItem("REACT_TOKEN_AUTH", response.data.token);
        localStorage.setItem("REACT_TOKEN_REFRESH", response.data.refresh);
        localStorage.setItem("STREAM_TOKEN", response.data.stream);
      })
      .catch(function (error) {
        //NEEDTODO
        const errorMessage = {
          error: true,
          details: [
            "Could not connect to the server. Check your connection, refresh the app, and try again.",
          ],
        };
        if (error.response && error.response.data) {
          dispatch(loginFail(error.response.data));
        } else {
          dispatch(loginFail(errorMessage));
        }
      });
  };
};
