import React, { useState, useEffect, Component } from "react";

import _ from "lodash";
import { connect } from "react-redux";
import update from "immutability-helper";
import { getMacros } from "../../../utility/macros";
import {
  updateMacrosArray,
  updateIngredientsRatio,
} from "../../../store/actions/recipeActions";

import axios from "../../../api/axios-token";
import axios_local from "axios";
import amplitude from "amplitude-js";

function round(num, decimalPlaces = 0) {
  if (isNaN(num)) {
    return num;
  } else {
    var p = Math.pow(10, decimalPlaces);
    var m = num * p * (1 + Number.EPSILON);
    return Math.round(m) / p;
  }
}

const Macros = (props) => {
  const [macros, setMacros] = useState([]);
  const [ghostMacros, setGhostMacros] = useState([]);

  const [ingredients, setIngredients] = useState([]);
  const [ghostIngredients, setGhostIngredients] = useState([]);
  const [macroPercent, setMacroPercent] = useState(100);
  const [ghostRatio, setGhostRatio] = useState("100");
  const [sliderMarks, setSliderMarks] = useState([]);
  const [ratioArray, setRatioArray] = useState([]);
  const [macroAjax, setmacroAjax] = useState(0);
  const [macroTotals, setMacroTotals] = useState({
    calories: null,
    protein: null,
    carbs: null,
    fat: null,
  });
  const [ghostMacroTotals, setGhostMacroTotals] = useState({
    calories: null,
    protein: null,
    carbs: null,
    fat: null,
  });

  const [calorieTarget, setCalorieTarget] = useState(null);

  useEffect(() => {
    if (props.tdee || props.defaultCalorieTarget) {
      let calorieTargets;
      let userDayCalories;
      if (!props.tdee) {
        userDayCalories = props.defaultCalorieTarget;
        calorieTargets = {
          meal: round(userDayCalories * 0.28, 0),
          snack: round(userDayCalories * 0.08, 0),
        };
      } else {
        calorieTargets = {
          meal: props.tdee.meal,
          snack: props.tdee.snack,
        };
      }

      if (props.type == "snack") {
        setCalorieTarget(calorieTargets.snack);
      } else {
        setCalorieTarget(calorieTargets.meal);
      }
    }
  }, [props.type, props.defaultCalorieTarget, props.tdee]);

  const getClosestRatio = (arr, n) => {
    let index = _.sortedIndex(arr, n);
    return arr[index] == n ? index : index - 1;
  };

  const getClosestRatio_old = (array, x) => {
    return array.reduce((best, current) => {
      //console.log(current);
      return current.calories >= x &&
        (!best || current.calories < best.calories)
        ? current
        : best;
    }, undefined);
  };

  useEffect(() => {
    if (props.ingredients) {
      let refresh = false;

      if (props.ingredients.length != ingredients.length) {
        refresh = true;
      }
      const ingredientArray = [];
      const macroArray = [];
      const serves = props.serves || 1;

      props.ingredients.map((i) => {
        if (!i.user_quantity) {
          i.user_quantity = i.quantity;
        }

        if (i.loadingStatus === "refresh") {
          refresh = true;
        }
        delete i.loadingStatus;
        i.macros = getMacros(i, props.rawMacros, serves);
        const macro = i.macros;
        macroArray.push(macro);
        if (i.user_quantity) {
          ingredientArray.push({ ...i, original_quantity: i.user_quantity });
        } else {
          if (i.quantity) {
            ingredientArray.push({ ...i, original_quantity: i.quantity });
          }
        }
      });
      if (refresh) {
        setmacroAjax(0);
      }

      const ingredientUpdate = async () => {
        const ingredientUpdate = await updateIngMacros(ingredientArray);

        setIngredients([...ingredientUpdate]);

        setGhostIngredients([...ingredientUpdate]);
        setMacros(macroArray);
      };
      ingredientUpdate();
    } else {
      setIngredients([]);
      setGhostIngredients([]);
      setMacros([]);
    }
  }, [props.ingredients, props.rawMacros, props.serves]);

  const updateIngMacros = (ing) => {
    const findArray = [];
    const ingredient_copy = [...ing];

    _.map(ingredient_copy, function (i, index) {
      if (!i.macros || i.macros.error || i.macros.calories == null) {
        findArray.push({
          ingredientID: i.ingredientID,
          name: i.name,
          unit: i.unit,
          loadingStatus: i.loadingStatus || false,
        });
        ingredient_copy[index].status = macroAjax + 1;
      } else {
        ingredient_copy[index].status = 2;
      }
    });

    if (findArray.length > 0) {
      if (!macroAjax) {
        if (!props.local) {
          setmacroAjax(1);
          // props.updateMacrosArray(findArray);
          axios
            .post("/recipes/get_ingredient_macro_array", { macros: findArray })
            .then(function (response) {
              if (response.data.status == "SUCCESS") {
                props.updateMacrosArray(response.data.macros);
                console.log("inject macros");
              }
            })
            .then(function () {
              setmacroAjax(2);
            })
            .catch(function (error) {
              console.log("error processing: ", findArray);
              setmacroAjax(2);
            });
        } else {
          if (props.local) {
            setmacroAjax(1);
            // props.updateMacrosArray(findArray);
            axios_local
              .post(process.env.REACT_APP_API + "/app/auth/get_macros_array", {
                macros: findArray,
              })
              .then(function (response) {
                if (response.data.status == "SUCCESS") {
                  props.updateMacrosArray(response.data.macros);
                  console.log("inject macros");
                }
              })
              .then(function () {
                setmacroAjax(2);
              })
              .catch(function (error) {
                console.log("error processing: ", findArray);
                setmacroAjax(2);
              });
          }
        }
      }
    }
    return ingredient_copy;
  };

  useEffect(() => {
    if (!props.basic) {
      const macroArray = [];
      if (ghostIngredients) {
        ghostIngredients.map((ingredient) => {
          const macro = getMacros(ingredient, props.rawMacros, props.serves);
          macroArray.push(macro);
        });
      }
      // console.log("ghost: ", macroArray);
      setGhostMacros(macroArray);
    }
  }, [ghostIngredients]);

  useEffect(() => {
    let serves = props.serves;
    if (!props.serves) {
      serves = 1;
    }
    if (macros.length > 0) {
      const newTotals = {
        calories: _.sumBy(macros, "calories"),
        protein: _.sumBy(macros, "protein"),
        carbs: _.sumBy(macros, "carbs"),
        fat: _.sumBy(macros, "fat"),
      };
      newTotals.proteinPercent = round(
        ((newTotals.protein * 4) / newTotals.calories) * 100,
        0
      );
      newTotals.carbsPercent = round(
        ((newTotals.carbs * 4) / newTotals.calories) * 100,
        0
      );
      newTotals.fatPercent = round(
        ((newTotals.fat * 8) / newTotals.calories) * 100,
        0
      );
      newTotals.caloriePercent = round(
        (newTotals.calories / calorieTarget) * 100,
        0
      );
      setMacroTotals(newTotals);
      if (props.returnTotals) {
        props.returnTotals(newTotals);
      }
    } else {
      setMacroTotals({ calories: null, protein: null, carbs: null, fat: null });
      if (props.returnTotals) {
        props.returnTotals({
          calories: null,
          protein: null,
          carbs: null,
          fat: null,
        });
      }
    }
  }, [macros, props.serves]);

  useEffect(() => {
    if (!calorieTarget) {
      return;
    }
    if (ghostMacros.length > 0) {
      // map through 70 - 130 and find calories for each one....
      const detectionArray = [];
      const detectionIndex = [0];
      var i;
      for (i = 60; i < 140; i++) {
        const ghost = [];
        ratioIngredients(ghostIngredients, i).map((ingredient) => {
          const macro = getMacros(ingredient, props.rawMacros, props.serves);
          ghost.push(macro);
        });

        const sum = _.sumBy(ghost, "calories");
        detectionArray.push({
          amount: i,
          calories: sum,
        });
        detectionIndex.push(Math.round(sum));
      }
      let closestRatioIndex = getClosestRatio(detectionIndex, calorieTarget);
      if (closestRatioIndex > detectionArray.length - 1) {
        closestRatioIndex = detectionArray.length - 1;
      }
      const closestRatio = detectionArray[closestRatioIndex];

      setGhostRatio(closestRatio);
    }
  }, [ghostMacros, calorieTarget]);

  useEffect(() => {
    if (macros.length > 0) {
      updateIngredients(ingredients, macroPercent);
    }
  }, [macroPercent]);

  const changeRatio = (value) => {
    setMacroPercent(value);
    if (props.returnRatio) {
      props.returnRatio(value);
    }
  };

  function roundNearest(v, n) {
    return Math.round(v / n) * n;
  }

  const ghostIngredientHandler = (ingredientArray, percent) => {
    const newIngredients = ratioIngredients(ingredientArray, percent);
    setGhostIngredients(newIngredients);
  };

  const updateIngredients = (ingredientArray, percent) => {
    const newIngredients = ratioIngredients(ingredientArray, percent);

    // push up state if neccessary, if not adjust locally..
    const macroArray = [];
    newIngredients.map((i) => {
      if (!i.user_quantity) {
        i.user_quantity = i.quantity;
      }
      const updatedIngredients = [];

      i.macros = getMacros(i, props.rawMacros, props.serves);
      const macro = i.macros;
      macroArray.push(macro);
      if (i.user_quantity) {
        updatedIngredients.push({ ...i, original_quantity: i.user_quantity });
      } else {
        if (i.quantity) {
          updatedIngredients.push({ ...i, original_quantity: i.quantity });
        }
      }
    });
    setMacros(macroArray);
    if (props.updateIngredients) {
      props.updateIngredients(newIngredients);
    } else {
      setIngredients(newIngredients);
    }
  };

  const ratioIngredients = (ingredientArray, percent) => {
    if (isNaN(parseInt(percent))) {
      return;
    }
    const ratio = parseInt(percent) / 100;
    const newIngredients = [];

    ingredientArray.map((i) => {
      let rounding = 1;

      switch (i.unit) {
        case "g":
          rounding = 1;
          break;
        case "ml":
          rounding = 1;
          break;
        case "whole":
          rounding = 0.5;
          break;
        case "pinch":
          rounding = 1;
          break;
        case "handful":
          rounding = 1;
          break;
        case "tsp":
          rounding = 0.5;
          break;
        case "tbsp":
          rounding = 0.5;
          break;
        case "bunch":
          rounding = 0.5;
          break;
        case "squeeze":
          rounding = 1;
          break;
        case "slices":
          rounding = 1;
          break;
        case "cup":
          rounding = 0.25;
          break;
        case "kg":
          rounding = 0.25;
          break;
        case "l":
          rounding = 0.25;
          break;
        case "lb":
          rounding = 0.25;
          break;
        case "pt":
          rounding = 0.25;
          break;
        default:
          rounding = 1;
      }

      var eggsRegex = /eggs?\b( whites?)?/;

      if (eggsRegex.test(i.name)) {
        rounding = 1;
      }

      let newUserQuantity = roundNearest(ratio * i.original_quantity, rounding);
      if (newUserQuantity <= 0) {
        newUserQuantity = rounding;
      }
      const macro = getMacros(i, props.rawMacros, props.serves);
      const newIngredient = {
        ...i,
        user_quantity: newUserQuantity,
        macros: macro,
      };
      newIngredients.push(newIngredient);
    });
    return newIngredients;
  };

  const saveIngredients = () => {
    const data = {
      userRatio: macroPercent,
      recipeID: props.recipeID,
      ingredients: ingredients,
    };

    axios
      .post("/recipes/update_user_ingredients", data)
      .then(function (response) {
        if (response.data.success === "RECIPE_INGREDIENTS_UPDATED") {
          props.updateIngredientsRatio(data);
          amplitude.getInstance().logEvent("Recipe Ingredients Adjusted");
          setMacroPercent(100);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const properties = {
    type: props.type,
    macroTotals: macroTotals,
    changeRatio: (r) => changeRatio(r),
    saveIngredients: () => saveIngredients(),
    ingredients: [...ingredients],
    calorieTarget: calorieTarget,
    targetRatio: ghostRatio,
    ajaxAttempts: macroAjax,
    round: (v, r) => round(v, r),
    macroPercent: macroPercent,
    tdeeCompleted: props.tdee && props.tdee.dailyTarget ? true : false,
  };

  const updateChildrenWithProps = React.Children.map(
    props.children,
    (child, i) => {
      if (child) {
        return React.cloneElement(child, {
          //this properties are available as a props in child components
          ...properties,
        });
      }
    }
  );

  return <div className="macrosInjected">{updateChildrenWithProps}</div>;
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateIngredientsRatio: (payload) =>
      dispatch(updateIngredientsRatio(payload)),
    updateMacrosArray: (id, name, unit) =>
      dispatch(updateMacrosArray(id, name, unit)),
  };
};
const mapStateToProps = (state) => {
  return {
    rawMacros: state.recipes.raw_macros,
    defaultCalorieTarget: state.auth.defaultCalorieTarget,
    tdee: state.auth.user.options.tdee,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Macros);
