import React, { useState, useEffect } from "react";

import { Modal, Container, Grid, Button, Table, Icon } from "semantic-ui-react";
import _ from "lodash";
import SimpleCalorieBar from "../../screens/Recipes/Discover/SimpleCalorieBar";
import classes from "./Planner.module.scss";
import { format, parse } from "date-fns";
import { enGB } from "date-fns/locale";
import { parseDate } from "../Planner/dateHelper";

const NutritionAdvanced = (props) => {
  const [macroGroup, setMacroGroup] = useState([]);

  useEffect(() => {
    const recipeMacros = props.macros;

    const macroGroup = _(recipeMacros)
      .groupBy("date")
      .map((date, id) => ({
        date: id,
        calories: _.sumBy(date, "calories"),
        protein: _.sumBy(date, "protein"),
        carbs: _.sumBy(date, "carbs"),
        fat: _.sumBy(date, "fat"),
        recipes: [...date],
      }))
      .value();

    setMacroGroup(macroGroup);
  }, [props.macros]);

  return (
    <Modal
      open={props.open}
      size="large"
      closeIcon={true}
      onClose={() => props.close()}
    >
      <Modal.Header>Advanced Nutrition</Modal.Header>
      <Modal.Content>
        <Table celled striped className={classes.advancedNutritionTable}>
          {_.map(macroGroup, function (day, index) {
            const macroDate = parse(day.date, "yyyyMMdd", new Date());

            return (
              <Table.Body key={`day-${index}`}>
                <Table.Row key={"day-" + index}>
                  <Table.HeaderCell style={{ padding: "1em" }}>
                    {format(macroDate, "EEEE do LLLL y", {
                      locale: enGB,
                    })}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">
                    <SimpleCalorieBar
                      macroTotals={day}
                      color="vividGreen"
                      textColor="white"
                      style={{ maxWidth: "25em", marginLeft: "auto" }}
                      target={props.dailyTarget || null}
                    />
                  </Table.HeaderCell>
                </Table.Row>

                {_.map(day.recipes, function (recipe, index) {
                  return (
                    <Table.Row key={`${day.date}-${index}`}>
                      <Table.Cell>{recipe.name}</Table.Cell>

                      <Table.Cell textAlign="right">
                        <SimpleCalorieBar
                          macroTotals={recipe}
                          style={{ maxWidth: "20em", marginLeft: "auto" }}
                          target={
                            recipe.targetType == "snack"
                              ? props.snackTarget || null
                              : props.mealTarget || null
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            );
          })}
        </Table>
      </Modal.Content>
    </Modal>
  );
};

export default NutritionAdvanced;
