import React, { Component } from "react";
import PropTypes from "prop-types";
import { Message, Grid, Container, Button } from "semantic-ui-react";
import LandingHome from "./screens/Landing/LandingHome";
import LogRocket from "logrocket";
export default class ErrorBoundary extends Component {
  state = {
    error: "",
    errorInfo: "",
    hasError: false,
  };
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log({ error, errorInfo });
    this.setState({ errorInfo });
    if (process.env.NODE_ENV === "production") {
      LogRocket.captureException(error);
    }
  }
  render() {
    const { hasError, errorInfo } = this.state;
    if (hasError) {
      return (
        <LandingHome>
          <Container style={{ height: "calc(100% - 50px)" }}>
            <Grid verticalAlign="middle" style={{ height: "100%" }}>
              <Grid.Row>
                <Grid.Column>
                  <Message warning>
                    <Message.Header>Whoops!</Message.Header>
                    <p>
                      Something went wrong on this page. The event has been
                      logged and will be fixed as soon as possible - usually
                      reloading the page solves the problem, however if you see
                      this message repeatedly please contact us at
                      support@planeatmeal.com
                    </p>
                  </Message>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered>
                <Grid.Column width={4}>
                  <Button
                    fluid
                    color="teal"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Reload this page
                  </Button>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Button
                    fluid
                    color="teal"
                    onClick={() => {
                      window.location.href = "https://www.planeatmeal.com";
                    }}
                  >
                    To Home Page
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </LandingHome>
      );
    }

    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
