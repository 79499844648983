import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import axios from "../api/axios-token";
import { injectMacros } from "../store/actions/recipeActions";

const converted_units = {
  cup: "ml/g",
  ml: "ml",
  kg: "g",
  pt: "ml",
  tsp: "ml/g",
  tbsp: "ml/g",
  pinch: "ml/g",
  dash: "ml/g",
  squeeze: "ml",
  whole: "whole",
  handful: "handfuls",
  bunch: "bunch",
  "small bunch": "small bunch",
  slices: "slices",
  g: "g",
  l: "l",
  oz: "g",
  lb: "g",
};
const metric_translation = {
  cup: 240,
  ml: 1,
  kg: 1000,
  pt: 480,
  tsp: 5,
  tbsp: 15,
  pinch: 0.5,
  dash: 0.5,
  squeeze: 15,
  whole: 1,
  handful: 1,
  bunch: 1,
  "small bunch": 1,
  slices: 1,
  g: 1,
  l: 1000,
  oz: 28,
  lb: 450,
};

export const getMacros = (ingredient, rawMacros, serves = 1) => {
  let macro = _.find(rawMacros, {
    id: ingredient.ingredientID,
    macro_unit: ingredient.unit,
  });

  if (!macro) {
    macro = _.find(rawMacros, {
      id: ingredient.ingredientID.toString(),
      macro_unit: ingredient.converted_unit,
    });
  }
  if (!macro) {
    macro = _.find(rawMacros, {
      id: ingredient.ingredientID.toString(),
    });
  }

  if (!macro) {
    const ingredientMacro = {
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0,
      error: 1,
      details: macro,
    };
    return ingredientMacro;
  }

  if (ingredient.converted_unit == "small bunch") {
    ingredient.converted_unit = "ml/g";
  }

  if (macro.calories === null) {
    const ingredientMacro = {
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0,
      error: 1,
      details: null,
    };
    return ingredientMacro;
  }

  if (!ingredient.converted_unit) {
    ingredient.converted_unit = converted_units[ingredient.unit];
  }
  if (!ingredient.metric_translation) {
    ingredient.metric_translation = metric_translation[ingredient.unit];
  }

  if (macro.macro_unit != ingredient.unit) {
    switch (macro.macro_unit) {
      case "bunch":
        macro.macro_unit = "ml/g";
        macro.metric_translation = 120;
        break;
      case "handful":
        macro.macro_unit = "ml/g";
        macro.metric_translation = 120;
        break;
      case "sm bunch":
        macro.macro_unit = "ml/g";
        macro.metric_translation = 60;
        break;
    }
  }

  ingredient.metric_translation = parseFloat(ingredient.metric_translation);

  const quantity = ingredient.user_quantity || ingredient.quantity;

  // console.log("ingredient name", ingredient.name);
  // console.log("quantity", quantity);
  // console.log("converted unit", ingredient.converted_unit);
  // console.log("macro", macro);

  // if like for like..  tsp = tsp , whole = whole.. just times by quantity
  // if the weight is 100 it could be a rogue entry... so avoiding that
  if (
    macro.macro_unit == ingredient.unit &&
    macro.weight != 100 &&
    ingredient.converted_unit == "ml/g"
  ) {
    const ingredientMacro = {
      calories: (macro.calories * quantity) / serves,
      protein: (macro.protein * quantity) / serves,
      carbs: (macro.carbs * quantity) / serves,
      fat: (macro.fat * quantity) / serves,
      details: macro,

      mode: "macro unit == ingredient unit",
    };

    return ingredientMacro;
  }

  if (macro.macro_unit == "g" && ingredient.unit == "g") {
    // divide calories per 100g then times by quantity
    const ingredientMacro = {
      calories: ((macro.calories / macro.weight) * quantity) / serves,
      protein: ((macro.protein / macro.weight) * quantity) / serves,
      carbs: ((macro.carbs / macro.weight) * quantity) / serves,
      fat: ((macro.fat / macro.weight) * quantity) / serves,
      details: macro,

      mode: "g==g",
    };
    return ingredientMacro;
  }
  if (macro.macro_unit == "g" && ingredient.converted_unit == "g") {
    // divide calories per 100g then times by quantity

    const ingredientMacro = {
      calories:
        ((macro.calories / macro.weight) *
          (quantity * ingredient.metric_translation)) /
        serves,
      protein:
        ((macro.protein / macro.weight) *
          (quantity * ingredient.metric_translation)) /
        serves,
      carbs:
        ((macro.carbs / macro.weight) *
          (quantity * ingredient.metric_translation)) /
        serves,
      fat:
        ((macro.fat / macro.weight) *
          (quantity * ingredient.metric_translation)) /
        serves,
      mode: "metric translation g=g",
      details: macro,
    };
    return ingredientMacro;
  }
  if (
    (macro.macro_unit == "ml" || macro.macro_unit == "ml/g") &&
    ingredient.converted_unit == "ml"
  ) {
    // divide calories per 100ml then times by quantity
    const ingredientMacro = {
      calories:
        ((macro.calories / macro.weight) *
          (quantity * ingredient.metric_translation)) /
        serves,
      protein:
        ((macro.protein / macro.weight) *
          (quantity * ingredient.metric_translation)) /
        serves,
      carbs:
        ((macro.carbs / macro.weight) *
          (quantity * ingredient.metric_translation)) /
        serves,
      fat:
        ((macro.fat / macro.weight) *
          (quantity * ingredient.metric_translation)) /
        serves,
      mode: "liquid metric translation",
      details: macro,
    };
    return ingredientMacro;
  }

  // if it's whole then just use the serving/whole weight..

  if (
    macro.weight != 100 &&
    (ingredient.unit == "whole" || ingredient.unit == "slices")
  ) {
    const ingredientMacro = {
      calories: (macro.calories * quantity) / serves,
      protein: (macro.protein * quantity) / serves,
      carbs: (macro.carbs * quantity) / serves,
      fat: (macro.fat * quantity) / serves,
      details: macro,
      mode: "whole / slice",
    };

    return ingredientMacro;
  }
  // if a volume conversion - work out the converted quantity

  if (
    (ingredient.converted_unit == "ml/g" || macro.converted_unit == "ml/g") &&
    macro.volume_conversion
  ) {
    const translatedQuantity =
      (ingredient.metric_translation / 5) * // gets teaspoons
      quantity *
      macro.volume_conversion; // gets in grams

    const ingredientMacro = {
      calories: ((macro.calories / macro.weight) * translatedQuantity) / serves,
      protein: ((macro.protein / macro.weight) * translatedQuantity) / serves,
      carbs: ((macro.carbs / macro.weight) * translatedQuantity) / serves,
      fat: ((macro.fat / macro.weight) * translatedQuantity) / serves,
      mode: "metric translation w volume conv",
      details: macro,
    };

    return ingredientMacro;
  }

  if (
    (macro.macro_unit == "ml/g" || macro.macro_unit == "g") &&
    ingredient.unit == "g"
  ) {
    // divide calories per 100g then times by quantity

    const ingredientMacro = {
      calories: ((macro.calories / macro.weight) * quantity) / serves,
      protein: ((macro.protein / macro.weight) * quantity) / serves,
      carbs: ((macro.carbs / macro.weight) * quantity) / serves,
      fat: ((macro.fat / macro.weight) * quantity) / serves,
      mode: "g==g",
      details: macro,
    };
    return ingredientMacro;
  }

  if (ingredient.converted_unit == "ml/g") {
    const translatedQuantity =
      (ingredient.metric_translation / 5) * // gets teaspoons
      quantity *
      5; // fall back to 5g per teaspoon

    const ingredientMacro = {
      calories: ((macro.calories / macro.weight) * translatedQuantity) / serves,
      protein: ((macro.protein / macro.weight) * translatedQuantity) / serves,
      carbs: ((macro.carbs / macro.weight) * translatedQuantity) / serves,
      fat: ((macro.fat / macro.weight) * translatedQuantity) / serves,
      mode: "metric translation",
      details: macro,
    };

    return ingredientMacro;
  }

  if (
    (ingredient.converted_unit == "g" || ingredient.converted_unit == "ml") &&
    (macro.macro_unit == "ml" || macro.macro_unit == "g")
  ) {
    const ingredientMacro = {
      calories: ((macro.calories / macro.weight) * quantity) / serves,
      protein: ((macro.protein / macro.weight) * quantity) / serves,
      carbs: ((macro.carbs / macro.weight) * quantity) / serves,
      fat: ((macro.fat / macro.weight) * quantity) / serves,
      mode: "fallback g==ml",
      details: macro,
    };

    return ingredientMacro;
  }

  if (
    macro.macro_unit == "whole" &&
    (ingredient.converted_unit == "g" || ingredient.converted_unit == "ml")
  ) {
    const translatedQuantity = quantity * ingredient.metric_translation;
    const ingredientMacro = {
      calories: ((macro.calories / macro.weight) * translatedQuantity) / serves,
      protein: ((macro.protein / macro.weight) * translatedQuantity) / serves,
      carbs: ((macro.carbs / macro.weight) * translatedQuantity) / serves,
      fat: ((macro.fat / macro.weight) * translatedQuantity) / serves,
      mode: "whole serving / weight",
      details: macro,
    };
    return ingredientMacro;
  }

  // last resorts

  // console.log(
  //   "Need to convert: ",
  //   ingredient.name,
  //   "ing unit:",
  //   ingredient.unit,
  //   "ing id: ",
  //   ingredient.id
  // );

  const ingredientMacro = {
    calories: 0,
    protein: 0,
    carbs: 0,
    fat: 0,
    error: 1,
    details: null,
  };
  return ingredientMacro;
};

export const getRecipeMacros = (recipes, recipeState) => {
  recipes.map(function (m, r) {
    // foreach recipe get it's macro breakdown

    if (m.id != "custom") {
      let recipeDetails = _.find(recipeState.recipe_details, { real_id: m.id });
      let recipe = _.find(recipeState.recipes, { id: m.id });
      if (!recipeDetails || !recipe) {
      } else {
        recipes[r].ingredients = recipeDetails.ingredients;
        recipes[r].name = recipe.name;
        recipes[r].targetType = recipe.type == "snack" ? "snack" : "meal";
        recipes[r].serves = recipeDetails.serves; // first get recipe ingredients..
        let calories = 0;
        let protein = 0;
        let carbs = 0;
        let fat = 0;
        recipes[r].ingredients.map(function (m, i) {
          recipes[r].ingredients[i].macros = getMacros(
            m,
            recipeState.raw_macros
          );
          if (recipes[r].ingredients[i].macros) {
            calories +=
              recipes[r].ingredients[i].macros.calories / recipes[r].serves;
            protein +=
              recipes[r].ingredients[i].macros.protein / recipes[r].serves;
            carbs += recipes[r].ingredients[i].macros.carbs / recipes[r].serves;
            fat += recipes[r].ingredients[i].macros.fat / recipes[r].serves;
          }
        });
        recipes[r].calories = calories;
        recipes[r].protein = protein;
        recipes[r].carbs = carbs;
        recipes[r].fat = fat;
      }
    } else {
      recipes[r].calories = parseFloat(m.customData.assumedCalories || 0);
      recipes[r].protein = parseFloat(m.customData.assumedProtein || 0);
      recipes[r].carbs = parseFloat(m.customData.assumedCarbs || 0);
      recipes[r].fat = parseFloat(m.customData.assumedFat || 0);
      recipes[r].targetType = m.customData.treatAs || "custom";
      recipes[r].name = m.customData.name;
    }
  });
  // console.log('get recipe macros:',recipes);
  return recipes;
};
