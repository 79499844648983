import React from "react";
import axios from "axios";

import {
  Container,
  Grid,
  Header,
  Menu,
  Segment,
  Placeholder,
} from "semantic-ui-react";
import { useQuery, useQueryClient } from "react-query";
import useWindowSize from "../../utility/useWindowSize";
import landing from "../Landing/Landing.module.scss";
import { NavLink, useParams } from "react-router-dom";
const Legal = ({ match }) => {
  const queryClient = new useQueryClient();
  const window = useWindowSize();
  const params = useParams();
  const getSimplePrivacy = () =>
    axios
      .get("https://www.iubenda.com/api/privacy-policy/82211088/no-markup")
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

  const getPrivacy = () =>
    axios
      .get("https://www.iubenda.com/api/privacy-policy/82211088/no-markup")
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

  const getTerms = () =>
    axios
      .get(
        "https://www.iubenda.com/api/terms-and-conditions/82211088/no-markup"
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

  const getCookies = () =>
    axios
      .get(
        "https://www.iubenda.com/api/privacy-policy/82211088/cookie-policy/no-markup"
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

  const getData = (type) => {
    switch (type) {
      case "privacy":
        return getPrivacy;
      case "terms-and-conditions":
        return getTerms;
      case "cookies":
        return getCookies;

      default:
        return getSimplePrivacy;
    }
  };

  let policy = "privacy";
  if (params.type) {
    policy = params.type;
    if (params.subtype) {
      if (params.subtype == "simple") {
        policy = "privacy";
      }
    }
  }

  const data = useQuery(policy, getData(policy), {
    refetchOnWindowFocus: false,
  });

  let myHTML = null;
  if (data.data) {
    myHTML = data.data.content;
  }

  return (
    <Container style={{ flex: 1 }}>
      <Grid centered>
        <Grid.Column computer={4} mobile={16} tablet={16}>
          <Menu pointing secondary vertical={window.width > 991}>
            <NavLink className="item" active="active" to="/policies/privacy">
              Privacy Policy
            </NavLink>

            <NavLink className="item" active="active" to="/policies/cookies">
              Cookies Policy
            </NavLink>
            <NavLink
              className="item"
              active="active"
              to="/policies/terms-and-conditions"
            >
              Terms and Conditions
            </NavLink>
          </Menu>
        </Grid.Column>
        <Grid.Column computer={12} mobile={16} tablet={16}>
          <Segment padded="very" style={{ borderRadius: "5px" }}>
            {!data.data ? (
              <>
                <Placeholder fluid>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line /> <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line /> <Placeholder.Line />
                    <Placeholder.Line /> <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              </>
            ) : (
              <div
                className={landing.policies}
                dangerouslySetInnerHTML={{ __html: myHTML }}
              />
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default Legal;
