import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";

import discover from "./Discover.module.scss";

import axios from "../../../api/axios-token";

const TopicCard = (props) => {
  const [following, setFollowing] = useState(props.following);

  const followTopic = (id) => {
    axios.post("/discover/follow_topic", { topic: id }).then((res) => {
      setFollowing(true);
      return res.data;
    });
  };

  const unfollowTopic = (id) => {
    axios.post("/discover/unfollow_topic", { topic: id }).then((res) => {
      setFollowing(false);
      return res.data;
    });
  };

  let style = null;
  if (props.image) {
    style = {
      background: `url("${props.image}"),  url(${process.env.PUBLIC_URL}/img/placeholder.png)`,
    };
  }
  let followstyle = `${discover.follow}`;
  let followingText = "Follow";
  if (following) {
    followstyle = `${discover.follow} ${discover.following}`;
    followingText = "Unfollow";
  }
  return (
    <div className={discover.topicCard} style={style}>
      <div
        style={{ display: "none" }}
        className={followstyle}
        onClick={(e) => {
          e.stopPropagation();
          !following ? followTopic(props.id) : unfollowTopic(props.id);
        }}
      >
        {followingText}
      </div>
      <h2>{props.title}</h2>
    </div>
  );
};

export default TopicCard;
