import React, { useState } from "react";

import classes from "../Screens.module.scss";

import { Container, Dimmer, Loader } from "semantic-ui-react";

import RecipeAmend from "./RecipeAmend";

const RecipeWeb = (props) => {
  const [loaded, setLoaded] = useState(false);

  const recipeLoaded = () => {
    setLoaded(true);
  };
  return (
    <>
      <div>
        <Container className={classes.pageContainer}>
          <Dimmer active={!loaded} inverted>
            <Loader>Loading Recipe</Loader>
          </Dimmer>
          <RecipeAmend action="web" initialize={() => recipeLoaded()} />
        </Container>
      </div>
    </>
  );
};

export default RecipeWeb;
