import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { Card, Grid, Label, Icon } from "semantic-ui-react";
import classes from "./Workouts.module.scss";
import { getWorkoutInfo } from "../../utility/helper";
import CustomIcon from "../../components/CustomIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";

const WorkoutCard = (props) => {
  const workout = getWorkoutInfo(props);

  return (
    <Grid.Column className={classes.WorkoutCard}>
      <div>
        <Card onClick={() => props.toggleWorkout()}>
          <div
            className={`${classes.Image} attached`}
            style={{ background: `url(${workout.image})` }}
          >
            {workout.type == "icon" && (
              <div className="workoutIcon">
                <CustomIcon icon={workout.icon} />
                <h4>{workout.name}</h4>
              </div>
            )}
            {props.selected && (
              <div className={classes.SelectedImage}>
                <Icon circular inverted color="green" name="check" />
              </div>
            )}
          </div>
          <Label className={classes.cycleName} color="blue" attached="top left">
            {workout.cycle}
          </Label>
          <Label
            className={classes.workoutName}
            color="blue"
            attached="bottom left"
          >
            {workout.name}
          </Label>
        </Card>
        <span className={classes.workoutCaption}> {props.name}</span>
      </div>
    </Grid.Column>
  );
};

export default WorkoutCard;
