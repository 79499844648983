import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import recipes from "./Recipes.module.scss";
import { connect } from "react-redux";
import IngredientsTab from "./components/IngredientsBasic";
import Instructions from "./components/Instructions";
import RecipeImage from "../../components/recipes/recipeImage";
import Macros from "./components/Macros";
import MacroBox from "./components/MacroBox";
import { Tab, Dropdown, Segment, Icon } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import SimpleCalorieBar from "./Discover/SimpleCalorieBar";
import Skeleton from "../../components/skeleton";
import useWindowSize from "../../utility/useWindowSize";
import Tag from "../../components/recipes/tag";
import nl2br from "react-nl2br";
import {
  getUserRecipeDetails,
  updateRecipes,
  getRecipes,
  removeRecipe,
} from "../../store/actions/recipeActions";
import RecipeMeta from "./components/RecipeMeta";
import { useNavigate } from "react-router-dom";

import _ from "lodash";

import { Container, Loader, Grid, Confirm, Dimmer } from "semantic-ui-react";
import Recipes from "../Recipes";

const RecipeDetails = (props) => {
  const [method, setMethod] = useState(null);
  const [ingredients, setIngredients] = useState(null);
  const [cookTime, setcookTime] = useState(null);
  const [prepTime, setprepTime] = useState(null);
  const [removeRecipe, showRemoveRecipe] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [serves, setServes] = useState(null);
  const [categories, setCategories] = useState(null);
  const [originalActive, setOriginalActive] = useState(false);

  const [initState, setInitState] = useState(false);

  const editing = false;
  const size = useWindowSize();
  let history = useNavigate();

  let { id } = useParams();

  if (!id && props.embed) {
    id = props.id;
  }

  let recipe = null;

  recipe = _.find(props.recipes.recipes, { hash: id });
  let recipeDetails = _.find(props.recipes.recipe_details, { id: id });
  let macros = null;

  const updateIngredient = (id, data) => {
    let updatedIngredient = {
      ..._.find(ingredients, {
        id: id,
      }),
      ...data,
    };

    const ingCopy = ingredients.map((ingredient) =>
      ingredient.id === updatedIngredient.id
        ? { ...updatedIngredient }
        : ingredient
    );
    setIngredients(ingCopy);
    return true;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!deleting) {
      let recipeDetails = _.find(props.recipes.recipe_details, { id: id });
      setIngredients([...recipeDetails.ingredients]);
    }
  }, [props.recipes.recipe_details]);

  if (recipeDetails && !initState) {
    setIngredients(recipeDetails.ingredients);
    setMethod(recipeDetails.method);
    setServes(recipeDetails.serves);
    setprepTime(recipeDetails.prepTime);
    setcookTime(recipeDetails.cookTime);
    setCategories(recipe.categories);
    setInitState(true);
  }
  if (recipeDetails) {
    // macros = _.find(props.recipes.macros, { recipeID: id });
  }
  const startEditing = (id) => {
    history({
      pathname: "/recipes/edit/" + id,
    });
  };

  const archiveRecipe = (id) => {
    showRemoveRecipe(false);
    setDeleting(true);
    props.removeRecipe(id, history);
  };

  const Categories = (props) => {
    if (!props.categories) {
      return null;
    }
    return props.categories
      .filter(function (el) {
        return el.enabled === true;
      })
      .map(function (c, index) {
        return <Tag key={`category-${index}`} label={c.category_name} />;
      });
  };

  const options = [
    {
      key: "edit",
      icon: "edit",
      text: "Edit Recipe",
      value: "edit",
      onClick: () => startEditing(recipe.hash),
    },
    {
      key: "delete",
      icon: "delete",
      text: "Remove Recipe",
      value: "delete",
      onClick: () => showRemoveRecipe(true),
    },
    { key: "share", icon: "hide", text: "Share Recipe", value: "hide" },
  ];
  let panes;

  panes = [
    {
      menuItem: "Ingredients",
      render: () => (
        <Tab.Pane attached={false}>
          {!ingredients ? (
            <Skeleton />
          ) : (
            <>
              <IngredientsTab
                updateIngredient={(id, data) => updateIngredient(id, data)}
                editing={editing}
                ingredients={ingredients}
                originalIngs={recipeDetails.original_ingredients}
                masterCycle={recipe.master_cycle}
                serves={!props.embed ? serves : null}
                receivePortions={
                  props.receivePortions ? props.receivePortions : null
                }
                receiveServings={
                  props.receiveServings ? props.receiveServings : null
                }
                receiveReduced={
                  props.receiveReduced !== null ? props.receiveReduced : null
                }
              />
              {recipeDetails.original_ingredients && (
                <div
                  className={`${recipes.originalIngredients} ${
                    originalActive ? "active" : null
                  }`}
                >
                  <h4 onClick={() => setOriginalActive(!originalActive)}>
                    <Icon color="green" name="file pdf outline" />
                    Original PDF Ingredients
                    <Icon
                      style={{ float: "right" }}
                      color="green"
                      name={originalActive ? "angle down" : "angle up"}
                    />
                  </h4>
                  <div className={recipes.originalIngs}>
                    {nl2br(recipeDetails.original_ingredients)}
                  </div>
                </div>
              )}
            </>
          )}
        </Tab.Pane>
      ),
    },
  ];

  if (props.embed || size.width < 768) {
    panes = [
      ...panes,
      {
        menuItem: "Instructions",
        render: () => (
          <Tab.Pane attached={false}>
            <Instructions
              editing={editing}
              instructions={method}
              source={recipe.source}
              source_method={recipe.source_method}
              description={recipe.description}
              updateMethod={(m) => setMethod(m)}
            />
          </Tab.Pane>
        ),
      },
    ];
  }

  if (!props.embed) {
    panes = [
      ...panes,
      {
        menuItem: "Nutrition",

        render: () => (
          <Tab.Pane attached={false}>
            <Macros
              type={recipe.type}
              data={macros}
              recipeID={id}
              serves={serves}
              ingredients={ingredients}
            >
              <MacroBox />
            </Macros>
          </Tab.Pane>
        ),
      },
    ];
  }

  return (
    <>
      <div
        className="recipeView A4"
        style={{ maxWidth: "767px", margin: "0 auto" }}
      >
        <Dimmer active={deleting}>
          <Loader>Deleting Recipe</Loader>
        </Dimmer>
        <div>
          {!recipe ? (
            <Loader active inline="centered" />
          ) : (
            <>
              <Segment
                style={{ margin: size.width < 768 ? 0 : "1em" }}
                basic={size.width < 768 || props.embed}
              >
                <Grid relaxed="very" columns={!props.embed ? 2 : 1} stackable>
                  <Grid.Row>
                    <Grid.Column style={{ paddingBottom: "0" }}>
                      <Grid.Row>
                        {!props.embed && (
                          <div>
                            <div className={recipes.header}>
                              <h1 className={recipes.title} as="h1">
                                {recipe.name}
                              </h1>
                              <div className={`${recipes.editButton} no-print`}>
                                <Dropdown
                                  icon={<FontAwesomeIcon icon={faEllipsisV} />}
                                  basic
                                  floating
                                  direction="left"
                                  className="button icon"
                                  options={options}
                                  trigger={<></>}
                                />
                                <Confirm
                                  header="Remove Recipe"
                                  content="Are you sure you want to remove this recipe?"
                                  open={removeRecipe}
                                  onCancel={() => showRemoveRecipe(false)}
                                  onConfirm={() => archiveRecipe(recipe.hash)}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {!props.embed && (
                          <div>
                            <RecipeImage
                              image={recipe.image}
                              source_method={recipe.source_method}
                            />
                          </div>
                        )}
                        <div className={recipes.Content}>
                          <div className={recipes.Meta}>
                            <RecipeMeta
                              serves={serves}
                              cookTime={cookTime}
                              prepTime={prepTime}
                              editing={editing}
                            />
                            <Macros
                              type={recipe.type}
                              data={macros}
                              recipeID={id}
                              serves={serves}
                              ingredients={ingredients}
                            >
                              <SimpleCalorieBar />
                            </Macros>
                          </div>
                          <div
                            style={{ marginTop: "0.5em" }}
                            className={recipes.CategoriesWrapper}
                          >
                            <Categories categories={categories} />
                          </div>
                        </div>
                      </Grid.Row>
                      {!props.embed && size.width > 767 && (
                        <Grid.Row>
                          <Instructions
                            editing={editing}
                            instructions={method}
                            source={recipe.source}
                            source_method={recipe.source_method}
                            description={recipe.description}
                            updateMethod={(m) => setMethod(m)}
                          />
                        </Grid.Row>
                      )}
                    </Grid.Column>
                    <Grid.Column>
                      <Tab
                        className={recipes.TabsOverride}
                        menu={{ secondary: true }}
                        panes={panes}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </>
          )}
        </div>
      </div>
    </>
  );
};

// const mapDispatchToProps = (dispatch) => {
//   return;
// };
const mapDispatchToProps = (dispatch) => {
  return {
    updateRecipes: (payload, history) =>
      dispatch(updateRecipes(payload, history)),
    getUserRecipeDetails: () => dispatch(getUserRecipeDetails()),
    getRecipes: () => dispatch(getRecipes()),
    removeRecipe: (id, history) => dispatch(removeRecipe(id, history)),
  };
};
const mapStateToProps = (state) => {
  return {
    recipes: state.recipes,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipeDetails);
