import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getUserRecipeDetails,
  updateRecipes,
  getRecipes,
  importRecipe,
  updateFilterCategories,
} from "../../store/actions/recipeActions";
import {
  updateSelectedWorkouts,
  clearSelected,
} from "../../store/actions/plannerActions";

import {
  Grid,
  Input,
  Icon,
  Divider,
  Button,
  Container,
  Placeholder,
  Modal,
} from "semantic-ui-react";
import recipeclasses from "./Recipes.module.scss";
import RecipeFilters from "./components/RecipeFilters";
import RecipesList from "./components/RecipesList";

import MealCreate from "../Recipes/MealCreate";

const MyRecipes = (props) => {
  let history = useNavigate();

  const [recipes, setRecipes] = useState({
    filter: "",
    display: [],
    recipesLoaded: false,
  });
  const [categories, setCategories] = useState([]);
  const [filterValue, setfilterValue] = useState("");
  const [staticCategories, setStaticCategories] = useState([]);
  const [toggledCategories, setToggledCategories] = useState([]);
  const [recipesMutate, setRecipesMutate] = useState();
  const [createMeal, setCreateMeal] = useState(false);

  const startManaging = () => {
    if (props.startManaging) {
      props.startManaging();
    }
  };
  const recipeNavigate = (l) => {
    history("/recipes/view/" + l);
  };
  const toggleCategory = (categoryID, toggle) => {
    if (props.view == "snacks" && categoryID == 4) {
      return false;
    }
    const categoryCopy = categories;
    _.find(categoryCopy, { id: categoryID }).enabled = toggle;

    setCategories([...categoryCopy]);
    props.updateFilterCategories([...categoryCopy]);
    const reduced = _.reduce(
      categoryCopy,
      function (acc, cat) {
        if (cat.enabled) {
          return [...acc, cat.id];
        } else {
          return acc;
        }
      },
      []
    );
    setToggledCategories(reduced);
  };
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }
  const filterRecipes = () => {
    // console.log(category_attachments);

    if (recipesMutate) {
      let category_ids = toggledCategories;
      let filter = _.trim(filterValue);
      let checkCategories = [];

      if (category_ids.length > 0) {
        let recipe_attachments = [];

        checkCategories = _.filter(recipesMutate, (r) => {
          if (category_ids.every((i) => r.filterIDs.includes(i))) {
            return true;
          }
        });
      }

      let checkFilter = null;
      if (checkCategories.length < 1 && category_ids.length < 1) {
        checkCategories = [...recipesMutate];
      }
      if (filter == "") {
        setRecipes({
          filter: filter,
          display: [...checkCategories],
          recipesLoaded: true,
        });
        return;
      } else {
        checkFilter = _.filter(checkCategories, (r) => {
          let pattern = new RegExp(escapeRegExp(filterValue), "gi");
          if (r.name.match(pattern)) {
            return true;
          }
          if (r.ingredients.match(pattern)) {
            return true;
          }
          if (r.tags.includes(filterValue)) {
            return true;
          }
        });

        setRecipes({
          filter: filter,
          display: [...checkFilter],
          recipesLoaded: true,
        });
        return;
      }
    }
  };

  // if (props.recipesLoaded && !recipes.display) {
  //   setRecipes({ ...recipes, display: recipesMutate });
  // }

  useEffect(() => {
    if (props.recipes) {
      const recipeCopy = _.filter(props.recipes, { archive: "0" });
      if (props.view == "meals") {
        _.remove(recipeCopy, function (r) {
          return r.type != "meal";
        });
      }
      if (props.view == "snacks") {
        _.remove(recipeCopy, function (r) {
          return r.type != "snack";
        });
      }
      setRecipes({ filter: "", display: [...recipeCopy] });
      setRecipesMutate([...recipeCopy]);
      if (props.recipe_filter_category && props.user_tags) {
        const filters = props.recipe_filter_category;
        const userTags = _.reduce(
          props.user_tags,
          function (acc, curr) {
            const tag = {
              id: "tag-" + curr.id,
              category_name: curr.tag_name.toLowerCase(),
              type: "tag",
              color: curr.tag_color,
            };
            const finder = _.find(filters, {
              category_name: tag.category_name,
            });
            if (finder) {
              return [...acc];
            } else {
              return [...acc, tag];
            }
          },
          []
        );

        const filter_categorys = [...filters, ...userTags];
        setStaticCategories(filter_categorys);
        setCategories([...filter_categorys]);
        if (props.view == "snacks") {
          setToggledCategories(["4"]);
        }
      }
    }
  }, [props.recipes, props.view]);

  useEffect(() => {
    if (props.recipes.length == 0) {
      setRecipes({
        filter: "",
        display: null,
        recipesLoaded: true,
      });
    } else {
      if (filterValue.length > 0 || toggledCategories.length > 0) {
        filterRecipes();
      } else {
        setRecipes({
          filter: "",
          display: _.filter(recipesMutate, { archive: "0" }),
          recipesLoaded: true,
        });
      }
    }
  }, [filterValue, toggledCategories, recipesMutate]);

  // const Filters = React.memo((props) => {
  //   return props.categories.map((category) => {
  //     return (
  //       <TagButton
  //         toggle={category.enabled}
  //         toggleCategory={() => {}}
  //         key={category.id}
  //         label={`${category.category_name}`}
  //       />
  //     );
  //   });
  // });)

  return (
    <>
      <>
        <Grid reversed="computer tablet" stackable>
          <Grid.Row width="equal">
            {props.view == "meals" && props.createMealEnabled && (
              <Grid.Column computer={4} tablet={4} mobile={16}>
                <Modal
                  style={{ zIndex: 2001 }}
                  onClose={() => setCreateMeal(false)}
                  onOpen={() => setCreateMeal(true)}
                  open={createMeal}
                  trigger={<Button fluid>Create Meal</Button>}
                >
                  <Modal.Header>Create a Meal</Modal.Header>
                  <Modal.Content>
                    <Modal.Description>
                      <MealCreate
                        createMealCallback={() => {
                          setCreateMeal(false);
                        }}
                      />
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button color="red" onClick={() => setCreateMeal(false)}>
                      Cancel
                    </Button>
                  </Modal.Actions>
                </Modal>
              </Grid.Column>
            )}
            <Grid.Column
              computer={props.createMealEnabled ? 12 : 16}
              tablet={props.createMealEnabled ? 12 : 16}
              mobile={16}
              data-tour="search"
            >
              <Input
                value={filterValue}
                onChange={(e, v) => {
                  setfilterValue(v.value);
                }}
                fluid
                icon
                placeholder="Search for a recipe or an ingredient name..."
              >
                <input />
                <Icon name="search" />
              </Input>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row data-tour="filters" style={{ marginTop: "-2em" }}>
            <Grid.Column>
              <Divider horizontal>Filters</Divider>
              <div className={recipeclasses.filters}>
                {categories.length > 0 ? (
                  <RecipeFilters
                    toggleCategory={(id, enabled) =>
                      toggleCategory(id, enabled)
                    }
                    categories={staticCategories}
                    toggled={toggledCategories}
                  />
                ) : (
                  <div>
                    <Placeholder fluid>
                      <Placeholder.Header>
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </div>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <RecipesList
          recipes={recipes}
          toggle={props.toggle}
          view={props.view}
          mode={props.mode}
          managerMode={() => startManaging()}
          recipeNavigate={(r) => recipeNavigate(r)}
          showMealCreate={() => setCreateMeal(true)}
          mealCreateCallback={() => setCreateMeal(false)}
        />
      </>
    </>
  );
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateRecipes: (payload) => dispatch(updateRecipes(payload)),
    getUserRecipeDetails: () => dispatch(getUserRecipeDetails()),
    getRecipes: () => dispatch(getRecipes()),
    importRecipe: (url) => dispatch(importRecipe(url, props)),
    updateFilterCategories: (payload) =>
      dispatch(updateFilterCategories(payload)),
    updateSelectedWorkouts: (array) => dispatch(updateSelectedWorkouts(array)),
    clearSelected: () => dispatch(clearSelected()),
  };
};

const mapStateToProps = (state) => {
  return {
    recipes: state.recipes.recipes,
    category_attachments: state.recipes.category_attachments,
    tag_attachments: state.recipes.tag_attachments,
    recipe_filter_category: state.recipes.recipe_filter_category,
    user_tags: state.recipes.tags,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyRecipes);
