import React, { useState, useEffect, useCallback } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import classes from "./Ingredients.module.scss";
import { Image, Form, Dropdown } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import UnitSelector from "./UnitSelector";
import axios from "../../../api/axios-token";

import _ from "lodash";

const getOptions = (ingredients) => {
  console.log(ingredients);
  if (ingredients) {
    return _.map(ingredients, function (i, index) {
      return {
        key: i.id,
        text: i.name,
        value: i.id,
      };
    });
  } else {
    return [];
  }
};

const IngredientSearch = (props) => {
  const [editing, setEditing] = useState(false);
  const [searchQuery, setsearchQuery] = useState("");
  const [unit, setUnit] = useState("g");
  const [quantity, setQuantity] = useState(100);
  const [isFetching, setFetching] = useState(false);
  const [search, setSearch] = useState(true);

  const [value, setValue] = useState("");
  const [ingName, setingName] = useState("");
  const [instructions, setInstructions] = useState(null);

  const [options, setOptions] = useState(getOptions(null));
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [searchValue, setsearchValue] = useState([]);
  const [multiple, setMultiple] = useState(false);
  const [allowAdd, setAllowAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChange = (e, v) => {
    if (v.value) {
      const newName = _.find(options, {
        value: v.value,
      });
      if (newName) {
        setValue(v.value);
        setingName(newName.text);
        setsearchQuery(newName.text);
        props.callback(v.value, newName.text);
      }
    }
  };

  const handleSearchChange = (e) => {
    setsearchQuery(e.searchQuery.toLowerCase());
    setAllowAdd(false);
    debouncedSearch(e.searchQuery.toLowerCase());
  };

  const debouncedSearch = useCallback(
    _.debounce((e) => ingredientSearch(e), 300),
    []
  );

  const ingredientSearch = (ing) => {
    setLoading(true);
    setFetching(true);
    let oldstring = ing;
    const regex = /^\d*\s*/g;
    let check = oldstring.replace(regex, "");

    if (check) {
      ing = check;
    }
    if (!ing) {
      ing = oldstring;
    }
    let search = ing;
    setsearchQuery(ing);
    let foundIngredients;
    axios
      .post("/recipes/ingredient_search", {
        ingredient: search.trim(),
      })
      .then(function (response) {
        foundIngredients = response.data.ingredients;
      })
      .then(function () {
        if (foundIngredients && foundIngredients.length > 0) {
          setsearchQuery(search);
          setOptions(getOptions(foundIngredients));
        } else {
          setOptions(defaultOptions);
        }
      })
      .then(function () {
        setOptionsLoaded(true);
      })
      .then(function () {
        setAllowAdd(true);
        setLoading(false);
        setFetching(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setValue("");
    setOptions(null);
    setsearchQuery("");
  }, [props.checkChange]);

  const addIng = (ingName) => {
    let newIng = null;
    const optionsCopy = options;
    axios
      .post("/recipes/add_ingredient", {
        ingredient: ingName,
      })
      .then(function (response) {
        // update option

        const ingredient = response.data.ingredient;
        newIng = {
          keys: ingredient.id,
          text: ingredient.name,
          value: ingredient.id,
        };
        return ingredient;
      })
      .then(function (response) {
        if (newIng) {
          //ingredientSearch(newIng.text);
          // setOptions([...optionsCopy, newIng]);

          Promise.resolve()
            .then(() => {
              if (options) {
                setOptions([...options, newIng]);
              } else {
                setOptions([newIng]);
              }
            })
            .then(() => {
              setValue(newIng.value);
              setingName(newIng.text);
              props.callback(newIng.value, newIng.text);
            });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <Form.Group widths="equal">
        <Form.Dropdown
          className="rounded"
          closeOnBlur
          style={{ textTransform: "capitalize" }}
          label="Ingredient"
          fluid
          selection
          multiple={multiple}
          search={search}
          searchQuery={searchQuery}
          options={options}
          value={value}
          allowAdditions={allowAdd}
          loading={loading}
          onAddItem={(e, t) => {
            debouncedSearch.cancel();
            addIng(t.value);
          }}
          placeholder="Search for ingredient"
          onChange={(e, v) => {
            handleChange(e, v);
          }}
          onSearchChange={(e, v) => {
            handleSearchChange(v);
          }}
          loading={isFetching}
        />
      </Form.Group>
    </>
  );
};
export default IngredientSearch;
