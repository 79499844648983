import React from "react";
import { Button, Modal, Loader } from "semantic-ui-react";
import Message from "../../../components/Message";
import MacroBox from "../components/MacroBox";
import { loadTdee } from "../../../store/actions/uiActions";
import { useDispatch } from "react-redux";

const ImportRecipe = (props) => {
  const dispatch = useDispatch();

  const showTdee = () => {
    dispatch(loadTdee());
  };

  let reduce = "reduce";
  if (props.tdeeCompleted && 100 - props.targetRatio.amount < 0) {
    reduce = "increase";
  }

  return (
    <div className={props.className}>
      {props.tdeeCompleted ? (
        <>
          <Message
            header="Boost Tip"
            body={
              <p>{`To fit with your weekly goals this recipe ${reduce} this recipe by ${
                props.targetRatio.amount
                  ? Math.abs(100 - props.targetRatio.amount)
                  : "--"
              }%`}</p>
            }
          />
          <MacroBox
            ingredients={props.ingredients}
            macroTotals={props.macroTotals}
            sliderOnly
            targetRatio={props.targetRatio}
            macroPercent={props.macroPercent}
            hideingredients
            hideupdatebutton
            changeRatio={(r) => props.changeRatio(r)}
          />
        </>
      ) : (
        <Message
          header="Boost Tip"
          body={
            <p>
              Get any recipe tailored to your needs! Take our{" "}
              <em>nutrition analysis quiz</em> and we will personalize your
              recipes based on your own data!
            </p>
          }
          footer={
            <Button
              className="rounded"
              color="green"
              onClick={() => showTdee()}
            >
              Take the quiz
            </Button>
          }
        />
      )}
      <Modal
        trigger={
          <Button fluid color="green" className="rounded">
            Import recipe to your collection
          </Button>
        }
        header="Import recipe"
        content="Import this recipe into your collection with the current ingredient quantities?"
        actions={[
          "Cancel",
          {
            key: "yes",
            content: "Yes",
            positive: true,
            onClick: () => {
              props.import();
            },
          },
        ]}
      />
    </div>
  );
};

export default ImportRecipe;
