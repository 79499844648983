import React, { useState, useEffect } from "react";
import { loadTdee } from "../../store/actions/uiActions";
import classes from "./Profile.module.scss";
import Message from "../../components/Message";
import { getWaterTarget, round } from "../../utility/helper";
import { Link, Route, Routes } from "react-router-dom";
import {
  Segment,
  Grid,
  Dropdown,
  Statistic,
  Button,
  Image,
  Icon,
  Modal,
  Header,
  Input,
  Label,
} from "semantic-ui-react";
import { connect, useDispatch } from "react-redux";
import axios from "../../api/axios-token";
import { updateDay } from "../../store/actions/authActions";
import ProfileImage from "./ProfileImage";
import { useNavigate } from "react-router-dom";
import Content from "../Home/StatsEvent";
import { fromUnixTime, format } from "date-fns";
import WaterTargetModal from "./WaterTargetModal";
import amplitude from "amplitude-js";

const Profile = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [day, setDay] = useState(props.user.options.startDay || 1);
  const [waterModal, setWaterModal] = useState(false);

  const showQuiz = () => {
    dispatch(loadTdee("ratio"));
  };

  const dayOptions = [
    { key: 1, value: 1, text: "Monday" },
    { key: 2, value: 2, text: "Tuesday" },
    { key: 3, value: 3, text: "Wednesday" },
    { key: 4, value: 4, text: "Thursday" },
    { key: 5, value: 5, text: "Friday" },
    { key: 6, value: 6, text: "Saturday" },
    { key: 7, value: 7, text: "Sunday" },
  ];

  const changeDay = (value) => {
    setDay(value);
    axios.post("/profile/update_day", {
      day: value,
    });
    amplitude.getInstance().logEvent("Change Diary Day");

    dispatch(updateDay(value));
  };
  const created_on = format(fromUnixTime(props.user.created_on), "LLLL, y");
  return (
    <div
      style={{
        padding: "1em 1em 0",
        marginBottom: "1em",
        height: "100%",
        display: "flex",
      }}
    >
      <Grid columns="equal" className={classes.container}>
        <Grid.Row className={classes.header}>
          <Grid.Column
            floated="left"
            style={{
              padding: "0.2em",
              marginLeft: "0.2em",
              textAlign: "center",
            }}
          >
            <ProfileImage size={75} />
          </Grid.Column>
          <Grid.Column width={10} verticalAlign="middle">
            <h1 className={classes.name}>{props.user.first_name}</h1>
            <h3 className={classes.date_joined}>
              <Icon name="clock outline" />
              Joined {created_on}
            </h3>
          </Grid.Column>
          <Grid.Column style={{ padding: 0 }} floated="right" width={2}>
            <Dropdown
              className={classes.cog}
              style={{ userSelect: "none" }}
              trigger={<Icon size="big" color="grey" name="cog" />}
              inline
              direction="left"
            >
              <Dropdown.Menu>
                <Dropdown
                  text=" Diary Start Day"
                  pointing="left"
                  className="link item"
                >
                  <Dropdown.Menu direction="left">
                    {dayOptions.map(function (d, i) {
                      let selected = null;
                      if (d.value == day) {
                        selected = (
                          <Icon
                            style={{ marginLeft: "1em" }}
                            name="checkmark"
                          />
                        );
                      }
                      return (
                        <Dropdown.Item
                          onClick={() => changeDay(d.value)}
                          key={`day-select${i}`}
                        >
                          {d.text}
                          {selected}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown.Item>
                  <Link to="/progress" className="item">
                    Progress
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to="/profile/settings" className="item">
                    Profile Settings
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    history("/recipes/my", {
                      state: { manageRecipes: true },
                    });
                  }}
                >
                  Recipe Manager
                </Dropdown.Item>

                <Dropdown.Item>
                  <a href="https://www.planeatmeal.com/blog" className="item">
                    Blog
                  </a>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to="/policies" className="item">
                    Policies
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to="/guide" className="item">
                    User Guide
                  </Link>
                </Dropdown.Item>

                <Dropdown.Item>
                  <Link to="/sign-out" className="item">
                    Sign Out
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ background: "#b5dab5", flex: 1 }}>
          <Grid.Column width={16}>
            <Segment className="square">
              <h4 style={{ textAlign: "center" }}>Your Stats</h4>
              <div className={classes.stats}>
                <div className={classes.statsItem}>
                  <div>
                    <Image
                      className={classes.statImage}
                      src={`${process.env.PUBLIC_URL}/img/ui/streak.svg`}
                    />
                  </div>
                  <div>
                    <Statistic size="mini">
                      <Statistic.Value>{props.streak}</Statistic.Value>
                      <Statistic.Label>Current Streak</Statistic.Label>
                    </Statistic>
                  </div>
                </div>
                <div className={classes.statsItem}>
                  <div>
                    <Icon
                      className={classes.statImage}
                      name="line graph"
                      color="teal"
                      size="big"
                    />
                  </div>
                  <div>
                    <Content minimal type="highest_day_streak" />
                  </div>
                </div>
                <div className={classes.statsItem}>
                  <div>
                    <Icon
                      className={classes.statImage}
                      name="lightning"
                      color="yellow"
                      size="big"
                    />
                  </div>
                  <div>
                    <Statistic size="mini">
                      <Statistic.Value>{props.xp.total}</Statistic.Value>
                      <Statistic.Label>Energy Points</Statistic.Label>
                    </Statistic>
                  </div>
                </div>
                <div className={classes.statsItem}>
                  <div>
                    <Icon
                      className={classes.statImage}
                      name="food"
                      color="blue"
                      size="big"
                    />
                  </div>
                  <div>
                    <Content minimal type="meal_stats" />
                  </div>
                </div>
              </div>
            </Segment>
            <Segment className="square">
              <h4>Your Nutrition Goals</h4>
              {props.user.options.tdee &&
              props.user.options.tdee.dailyTarget ? (
                <>
                  <p>
                    Calorie Target:{" "}
                    <span className={classes.goal}>
                      {props.user.options.tdee.dailyTarget} kcals
                    </span>
                  </p>
                  <p>
                    Meal Goal:{" "}
                    <span className={classes.goal}>
                      {props.user.options.tdee.meal} x 3
                    </span>{" "}
                    + Snack Goal:{" "}
                    <span className={classes.goal}>
                      {props.user.options.tdee.snack} x 2
                    </span>
                    <span
                      onClick={() => {
                        showQuiz();
                      }}
                      className={classes.edit}
                    >
                      [EDIT]
                    </span>
                  </p>

                  {props.user.options.water ? (
                    <p>
                      Water Target:
                      <span className={classes.goal}>
                        {" "}
                        {round(props.user.options.water.target / 1000, 1)}{" "}
                        litres
                      </span>
                      <span
                        onClick={() => {
                          setWaterModal(true);
                        }}
                        className={classes.edit}
                      >
                        [EDIT]
                      </span>
                    </p>
                  ) : (
                    <p>
                      Water Target:{" "}
                      <span className={classes.goal}>
                        {" "}
                        {round(
                          getWaterTarget(
                            props.user.options.tdee.dailyTarget,
                            props.user.options.tdee.exercise,
                            props.user.options.tdee.lifestyle
                          ) / 1000,
                          1
                        )}{" "}
                        litres
                      </span>
                      <span
                        onClick={() => {
                          setWaterModal(true);
                        }}
                        className={classes.edit}
                      >
                        [EDIT]
                      </span>
                    </p>
                  )}

                  <Button
                    onClick={() => dispatch(loadTdee())}
                    className="rounded"
                    color="green"
                  >
                    Retake the nutrition quiz
                  </Button>
                </>
              ) : (
                <>
                  <p>
                    Water Target:{" "}
                    <span className={classes.goal}>
                      {" "}
                      {props.user.options.water &&
                      props.user.options.water.target
                        ? round(props.user.options.water.target / 1000, 2)
                        : 2.5}{" "}
                      litres
                    </span>
                    <span
                      onClick={() => {
                        setWaterModal(true);
                      }}
                      className={classes.edit}
                    >
                      [EDIT]
                    </span>
                  </p>
                  <Message
                    header="Personalize your goals"
                    body={
                      <>
                        <p>
                          Take our <em>nutrition analysis quiz</em> and
                          personalize your goals and recipes based on your own
                          data!
                        </p>
                        <p>Default Water goal:</p>
                      </>
                    }
                    footer={
                      <Button
                        className="rounded"
                        color="green"
                        onClick={() => showQuiz()}
                      >
                        Take the quiz
                      </Button>
                    }
                  />
                </>
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <WaterTargetModal open={waterModal} close={() => setWaterModal(false)} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    xp: state.xp,
    streak: state.diary.streak,
  };
};

export default connect(mapStateToProps)(Profile);
