import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Icon, Menu } from "semantic-ui-react";
import { Link, useLocation, NavLink, Grid } from "react-router-dom";
import { switchBottomNav } from "../store/actions/uiActions";
import classes from "./Container.module.scss";
const BottomNav = (props) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/shopping")) {
      props.switchBottomNav("shopping");
    }
    if (location.pathname.startsWith("/recipes")) {
      props.switchBottomNav("recipes");
    }
    if (location.pathname.startsWith("/planner")) {
      props.switchBottomNav("planner");
    }
    if (location.pathname.startsWith("/profile")) {
      props.switchBottomNav("profile");
    }
  }, []);

  useEffect(() => {
    const imageList = ["recipes", "home", "calendar", "shopping", "profile"];
    imageList.forEach((image) => {
      new Image().src = `${process.env.PUBLIC_URL}/img/ui/${image}-active.svg`;
    });
  }, []);

  return (
    <Menu className={classes.bottomNav} icon="labeled" fluid widths={5}>
      <NavLink
        activeClassName="active"
        className={`item ${classes.home}`}
        to="/home"
        state={{ reset: true }}
      >
        <div className={classes.bottomNavButton} />
      </NavLink>

      <NavLink
        activeClassName="active"
        className={`item ${classes.planner}`}
        to="/planner"
      >
        <div className={classes.bottomNavButton} />
      </NavLink>
      <NavLink
        data-tour="recipeMenu"
        activeClassName="active"
        className={`item ${classes.recipes}`}
        to="/recipes"
      >
        <div className={classes.bottomNavButton} />
      </NavLink>
      <NavLink
        activeClassName="active"
        className={`item ${classes.shopping}`}
        to="/shopping"
      >
        <div className={classes.bottomNavButton} />
      </NavLink>
      <NavLink
        activeClassName="active"
        className={`item ${classes.profile}`}
        to="/profile"
      >
        <div className={classes.bottomNavButton} />
      </NavLink>
    </Menu>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchBottomNav: (payload) => dispatch(switchBottomNav(payload)),
  };
};
const mapStateToProps = (state) => {
  return {
    ui: state.ui,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomNav);
