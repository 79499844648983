import React from "react";
import View from "./Discover/View";
import Home from "./Discover/DiscoverHome";
import { Route, Routes } from "react-router-dom";
import Browse_old from "./Discover/Browse_old";
import Category from "./Discover/Category";
const DiscoverRecipes = () => {
  return (
    <>
      <Routes>
        <Route path={`view/:id/:edit`} element={<View />} />
        <Route path={`view/:id`} element={<View />} />
        <Route path={`browse/:category`} element={<Category />} />
        <Route path={`browse`} element={<Browse_old />} />
        <Route path={`/`} element={<Home />} />
      </Routes>
    </>
  );
};

export default DiscoverRecipes;
