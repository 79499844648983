import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { connect as stream } from "getstream";
import { Container, Card, Image, Icon, Grid } from "semantic-ui-react";

import { connect } from "react-redux";

import {
  StreamApp,
  NotificationDropdown,
  FlatFeed,
  LikeButton,
  Activity,
  CommentField,
  CommentList,
  StatusUpdateForm,
  NotificationFeed,
} from "react-activity-feed";

const Community = (props) => {
  const [profile, setProfile] = useState(null);
  const [client, setClient] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [clientFeed, setClientFeed] = useState(null);
  const [userID, setuserID] = useState(props.auth.user.id);
  let token = localStorage.getItem("STREAM_TOKEN");

  useEffect(() => {
    // let token = localStorage.getItem("STREAM_TOKEN");
    //  const connection = connect("6juq67qbj4wj", token, "101136");
    // setClient(connection);
    // setUserToken(token);
  }, []);

  return (
    <>
      <Container>
        <StreamApp
          apiKey="6juq67qbj4wj"
          appId="101136"
          token={localStorage.getItem("STREAM_TOKEN")}
        >
          {props.posting && (
            <StatusUpdateForm feedGroup="user" userId={props.auth.user.id} />
          )}
          <FlatFeed
            options={{ reactions: { recent: true } }}
            notify
            userId="global"
            feedGroup="user"
            Header=""
            Activity={(props) => (
              <Activity
                {...props}
                Footer={() => (
                  <div style={{ padding: "8px 16px" }}>
                    <LikeButton
                      {...props}
                      onToggleReaction={(kind, activity, data, options) => {
                        console.log(kind, activity, data, options);
                        let actorID = activity.actor.id;
                        let targetIDs = [];

                        targetIDs.push(`notification:${actorID}`);
                        targetIDs.push(`timeline_aggregated:${userID}`);

                        console.log(targetIDs);
                        props.onToggleReaction(kind, activity, data, {
                          ...options,
                          targetFeeds: targetIDs,
                        });
                      }}
                    />
                    <CommentField
                      activity={props.activity}
                      onAddReaction={(kind, activity, data, options) => {
                        console.log(kind, activity, data, options);
                        let actorID = activity.actor.id;
                        let targetIDs = [];

                        targetIDs.push(`notification:${actorID}`);
                        targetIDs.push(`timeline_aggregated:${userID}`);
                        console.log(targetIDs);
                        props.onAddReaction(kind, activity, data, {
                          ...options,
                          targetFeeds: targetIDs,
                        });
                      }}
                    />
                    <CommentList activityId={props.activity.id} />
                  </div>
                )}
              />
            )}
          />
        </StreamApp>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Community);
