import React, { useState, useEffect } from "react";
import classes from "./Container.module.scss";
import logo from "../logo.png";
import {
  useNavigate,
  Route,
  Routes,
  NavLink,
  useLocation,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountUp from "react-countup";
import { StreamApp, NotificationDropdown } from "react-activity-feed";
import { Menu, Grid, Container, Dropdown, Image } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import DayComplete from "./DayComplete";
import AstroWater from "./AstroWater";
import ProfileButton from "../screens/Profile/ProfileButton";
import useWindowSize from "../utility/useWindowSize";
import {
  faCaretSquareLeft,
  faUtensils,
  faBolt,
  faShoppingBag,
  faUserAstronaut,
} from "@fortawesome/free-solid-svg-icons";

import _ from "lodash";

const StatusBar = (props) => {
  const [total, setTotal] = useState(null);
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  let location = useLocation();

  useEffect(() => {
    setTotal(props.total);
  }, [props.total]);

  useEffect(() => {
    // console.log(props.bubbles);
  }, [props.bubbles]);

  var handleClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const friendOptions = [
    {
      key: "Jenny Hess",
      text: "Jenny Hess",
      value: "Jenny Hess",
      image: { avatar: true, src: "/images/avatar/small/jenny.jpg" },
    },
    {
      key: "Elliot Fu",
      text: "Elliot Fu",
      value: "Elliot Fu",
      image: { avatar: true, src: "/images/avatar/small/elliot.jpg" },
    },
    {
      key: "Stevie Feliciano",
      text: "Stevie Feliciano",
      value: "Stevie Feliciano",
      image: { avatar: true, src: "/images/avatar/small/stevie.jpg" },
    },
    {
      key: "Christian",
      text: "Christian",
      value: "Christian",
      image: { avatar: true, src: "/images/avatar/small/christian.jpg" },
    },
  ];

  const BackButton = () => (
    <div className={`${classes.backButton} no-print`}>
      <FontAwesomeIcon onClick={handleClick} icon={faCaretSquareLeft} />
    </div>
  );

  return (
    <div className={classes.statusBar}>
      <Container>
        <Grid>
          <Grid.Row style={{ paddingBottom: 0, paddingTop: " 0.8em" }}>
            <Grid.Column
              computer={2}
              mobile={8}
              tablet={8}
              verticalAlign="middle"
            >
              <div className={classes.statusLeft}>
                <Routes>
                  <Route path={`/recipes/my`} />
                  <Route path={`/recipes/`} />
                  <Route path={`/recipes/discover`} />
                  <Route path={`/profile`} />
                  <Route path={`/planner`} />
                  <Route path={`/shopping`} />
                  <Route path={`/home`} />
                  <Route path={`/`} />
                  <Route path="*" element={<BackButton />} />
                </Routes>

                {windowSize.width < 992 ? (
                  <div className={classes.logo}>
                    <img src={logo} />
                  </div>
                ) : (
                  <Image
                    className={classes.logoText}
                    fluid
                    src={`${process.env.PUBLIC_URL}/img/PlanEatMealLogoText.png`}
                  />
                )}
              </div>
              {/* <StreamApp
        apiKey="6juq67qbj4wj"
        appId="101136"
        token={localStorage.getItem("STREAM_TOKEN")}
      >
        <NotificationDropdown
          className="notificationLogo"
          Icon={
            <div className={classes.logo}>
              <img src={logo} />
            </div>
          }
          notify
          userId={props.auth.user.id}
          width={300}
          right={false}
          Placeholder={
            <div className="notifyPlaceholder">
              No notifications to display.
            </div>
          }
        />
      </StreamApp> */}
            </Grid.Column>
            {_.map(props.bubbles, function (b, index) {
              // ignore old bubbles on re-render
              if (b.time) {
                if (Date.now() - b.time > 9000) {
                  return null;
                }
              }
              const height = window.innerHeight;
              const width = window.innerWidth;
              let reward_type;
              let left = b.x;
              let top = b.y - 20;

              if (height - top < 150) {
                top = top - 100;
              }

              if (width - left < 150) {
                left = left - 100;
              }
              let xpIcon;

              if (b.type === "water") {
                xpIcon = (
                  <img src={`${process.env.PUBLIC_URL}/img/water_drop.png`} />
                );
                reward_type = "bubble";
              }
              if (b.type === "diary") {
                xpIcon = <FontAwesomeIcon icon={faUtensils} />;
                reward_type = "bubble";
              }
              if (b.type === "shopping") {
                xpIcon = <FontAwesomeIcon icon={faShoppingBag} />;
                reward_type = "bubble";
              }
              if (b.type === "star") {
                reward_type = "star";
              }
              if (b.type === "water_goal") {
                reward_type = "waterMan";
              }
              let caption = `+${b.amount}`;
              let uuid = b.uuid;
              return (
                <React.Fragment key={uuid}>
                  {reward_type === "bubble" && (
                    <div
                      style={{
                        top: `${top}px`,
                        left: `${left}px`,
                      }}
                      className={classes.bubble}
                    >
                      <div className={classes.xpIcon}>{xpIcon}</div>
                      <div className={classes.xpCaption}>{caption}</div>
                    </div>
                  )}
                  {reward_type === "star" && (
                    <DayComplete key={uuid} className={classes.Rocket} />
                  )}
                  {reward_type === "waterMan" && (
                    <AstroWater className={classes.AstroWater} />
                  )}
                </React.Fragment>
              );
            })}

            <Grid.Column width={9} only="computer" className="no-print">
              <Grid centered>
                <Grid.Row only="computer">
                  <Menu className={classes.topMenu} secondary>
                    <NavLink
                      to="/home"
                      state={{ reset: true }}
                      activeClassName="active"
                      className="item"
                    >
                      Home
                    </NavLink>
                    <NavLink
                      to="/planner"
                      activeClassName="active"
                      className="item"
                    >
                      Planner
                    </NavLink>
                    <NavLink
                      data-tour="topRecipeMenu"
                      to="/recipes"
                      activeClassName="active"
                      className="item"
                    >
                      Recipes
                    </NavLink>
                    <NavLink
                      to="/shopping"
                      activeClassName="active"
                      className="item"
                    >
                      Shopping
                    </NavLink>
                  </Menu>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column
              computer={4}
              mobile={8}
              tablet={8}
              style={{ display: "flex", alignItems: "center" }}
              className={`no-print`}
            >
              {windowSize.width > 991 && (
                <span className={classes.profileLink}>
                  <ProfileButton direction="left" />
                </span>
              )}
              <div data-tour="status" className={classes.Stats}>
                <div className={classes.Total}>
                  <div className={classes.Icon}>
                    <FontAwesomeIcon
                      style={{ color: "yellow" }}
                      icon={faBolt}
                    />
                  </div>

                  {total && (
                    <CountUp
                      delay={2}
                      preserveValue
                      start={props.preTotal}
                      end={total}
                    />
                  )}
                </div>
                <div className={classes.Streak}>
                  <img
                    style={{ width: ".9rem", marginRight: "0.3em" }}
                    src={`${process.env.PUBLIC_URL}/img/ui/streak.svg`}
                  />
                  {props.streak}
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};

export default StatusBar;
