import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import classes from "./Shopping.module.scss";
import _ from "lodash";
import groupArray from "group-array";
import AnimateHeight from "react-animate-height";
import { formatDateDay, parseDate, getFullDay } from "../Planner/dateHelper.js";
import { Icon } from "semantic-ui-react";
import { round } from "../../utility/helper";

const PurchasedGroup = (props) => {
  const [recipeToggle, setRecipeToggle] = useState([]);
  const [data, setData] = useState([]);
  const purchases = [];

  const getQuantity = (q) => {
    if (parseInt(q.servings) == 1 || parseInt(q.reduce)) {
      return parseFloat(q.quantity) * parseFloat(q.portions);
    } else {
      const totalServings = Math.ceil(q.portions / parseInt(q.servings));
      return parseFloat(q.quantity) * totalServings;
    }
  };

  function toggleRecipe(recipe) {
    const togglesCopy = [...recipeToggle];

    if (
      _.remove(togglesCopy, function (e) {
        return e === recipe;
      }).length < 1
    ) {
      togglesCopy.push(recipe);
    }
    setRecipeToggle(togglesCopy);
  }

  // NEED TO SUBTRACT AMOUNT OR/ADD PANTRY

  _.map(props.data, function (group) {
    // console.log(group);
    _.map(group, function (item) {
      if (props.purchased.indexOf(item.shopping_id) > -1) {
        purchases.push({
          day: item.iso_date,
          ingredientName: item.name,
          amount: getQuantity(item),
          unit: item.converted_unit,
          purchased: 1,
          aisle_name: item.cupboard_name,
          ingredient_id: item.ingredient_id,
          shopping_id: item.shopping_id,
          aisle: item.cupboard_id,
          recipe: item.recipe_id,
          recipe_name: item.recipe_name,
        });
      } else {
        purchases.push({
          day: item.iso_date,
          ingredientName: item.name,
          amount: getQuantity(item),
          unit: item.converted_unit,
          purchased: 0,
          aisle_name: item.cupboard_name,
          ingredient_id: item.ingredient_id,
          shopping_id: item.shopping_id,
          aisle: item.cupboard_id,
          recipe: item.recipe_id,
          recipe_name: item.recipe_name,
        });
      }
    });
  });

  const additionalPurchases = _.reduce(
    props.purchased,
    function (arr, curr) {
      if (curr.includes("add")) {
        return [...arr, curr];
      } else {
        return [...arr];
      }
    },
    []
  );

  const additionalItems = _.reduce(
    props.purchased,
    function (arr, curr) {
      if (curr.includes("extra")) {
        return [...arr, curr];
      } else {
        return [...arr];
      }
    },
    []
  );

  additionalPurchases.map(function (id) {
    const item = _.find(props.additional, {
      id: id.replace("add-", ""),
    });
    if (item) {
      let newItem = {
        ingredientName: item.name,
        ingredient_id: item.ingredient_id,
        amount: parseFloat(item.quantity),
        unit: item.unit,
        purchased: 1,
        shopping_id: "add-" + item.id,
      };

      let additionalInfo = _.find(props.shopping, {
        ingredient_id: item.ingredient_id,
        converted_unit: item.unit,
      });

      if (props.sort == "aisle") {
        purchases.push({
          ...newItem,
          cupboard_id: additionalInfo.cupboard_id,
          cupboard_name: additionalInfo.cupboard_name,
          aisle_name: additionalInfo.cupboard_name,
          aisle: additionalInfo.cupboard_id,
          recipe: "Adjusted Quantities",
          recipe_name: "Adjusted Quantities",
        });
      } else {
        purchases.push({
          ...newItem,
          aisle_name: "Adjusted Quantities",
          aisle: "Adjusted Quantities",
          recipe: "Adjusted Quantities",
          recipe_name: "Adjusted Quantities",
          cupboard_name: "Adjusted Quantities",
          day: "Adjusted Quantities",
        });
      }
    }
  });

  additionalItems.map(function (id) {
    const item = _.find(props.extra, {
      id: id.replace("extra-", ""),
    });
    if (item) {
      let newItem = {
        ingredientName: item.name,
        ingredient_id: item.id,
        amount: 0,
        unit: "extraItem",
        purchased: 1,
        shopping_id: "extra-" + item.id,
      };

      if (props.sort == "aisle") {
        purchases.push({
          ...newItem,
          cupboard_id: "Additional Items",
          cupboard_name: "Additional Items",
          aisle_name: "Additional Items",
          aisle: "Additional Items",
          recipe: "Additional Items",
          recipe_name: "Additional Items",
        });
      } else {
        purchases.push({
          ...newItem,
          aisle_name: "Additional Items",
          aisle: "Additional Items",
          recipe: "Additional Items",
          recipe_name: "Additional Items",
          cupboard_name: "Additional Items",
          day: "Additional Items",
        });
      }
    }
  });

  const group = groupArray(purchases, props.sort, "ingredient_id", "unit");
  //   _.map(props.purchased, function (purchased) {
  //     const item = _.find(props.shopping, { shopping_id: purchased });
  //     console.log(item);
  //     if (item) {
  //       purchases.push(item);
  //     }
  //   });
  //   const group = groupArray(purchases, "ingredient_id", "converted_unit");
  let purchasedLength = _.filter(purchases, { purchased: 1 }).length;

  if (props.sort == "aisle") {
    purchasedLength -= _.filter(purchases, {
      recipe_name: "Adjusted Quantities",
      purchased: 1,
    }).length;
  }

  let collapseId = "purchased";
  return (
    <>
      <div className={`${classes.purchasedGroup} no-print`}>
        <h5 className={classes.GroupTitle}>
          Purchased
          <span className={classes.totalCount}>({purchasedLength})</span>
          <span
            className={classes.collapse}
            onClick={() => {
              props.toggleCollapsed(collapseId);
            }}
          >
            <Icon
              color="green"
              name={
                props.collapsed.indexOf(collapseId) > -1
                  ? "angle down"
                  : "angle up"
              }
            />
          </span>
        </h5>
        <AnimateHeight
          key={collapseId}
          duration={500}
          height={props.collapsed.indexOf(collapseId) > -1 ? 0 : "auto"}
        >
          {_.map(group, function (aisle, aisleIndex) {
            let totalCount = 0;
            let checkCount = 0;

            _.map(aisle, function (unit) {
              _.map(unit, function (ing) {
                totalCount++;
                let checkPurchase = 0;
                _.map(ing, function (item) {
                  if (props.purchased.indexOf(item.shopping_id) > -1) {
                    checkPurchase++;
                  }
                });

                if (checkPurchase == ing.length) {
                  checkCount++;
                }
              });
            });
            let displayTitle = "auto";
            if (checkCount == 0) {
              displayTitle = 0;
            }
            let header = "";

            //    console.log(props.data[aisleIndex]);

            if (props.data[aisleIndex]) {
              switch (props.sort) {
                case "aisle":
                  header = props.data[aisleIndex][0].cupboard_name;
                  break;
                case "recipe":
                  header = props.data[aisleIndex][0].recipe_name;
                  break;
                case "day":
                  const date = parseDate(props.data[aisleIndex][0].iso_date);

                  header = getFullDay(date) + " " + formatDateDay(date);
                  break;
              }
            }
            if (aisleIndex == "Adjusted Quantities") {
              header = "Adjusted Quantities";
            }

            if (aisleIndex == "Additional Items") {
              header = "Additional Items";
            }

            return (
              <AnimateHeight
                key={aisleIndex}
                duration={500}
                height={displayTitle}
              >
                <div key={aisleIndex}>
                  <h5 className={classes.subAisle}>{header}</h5>

                  <ul className={classes.Group}>
                    {_.map(aisle, function (unit, unitIndex) {
                      return _.map(unit, function (ingredient, ingIndex) {
                        let quantity = _.sumBy(ingredient, function (o) {
                          return round(parseFloat(o.amount), 1);
                        });
                        const shopping_ids = _.reduce(
                          ingredient,
                          function (acc, curr) {
                            return [...acc, curr.shopping_id];
                          },
                          []
                        );
                        // const purchasedAdditional = _.find(props.additional, {
                        //   ingredient_id: String(unitIndex),
                        //   unit: String(ingIndex),
                        // });

                        // if (purchasedAdditional) {
                        //   if (
                        //     props.purchased.indexOf(
                        //       "add-" + purchasedAdditional.id
                        //     ) > -1
                        //   ) {
                        //     shopping_ids.push("add-" + purchasedAdditional.id);
                        //     quantity += parseFloat(
                        //       purchasedAdditional.quantity
                        //     );
                        //   }
                        // }

                        const ingredientName = ingredient[0].ingredientName;
                        const ingredientUnit = ingredient[0].unit;
                        const checkedClass = ingredient[0].purchased
                          ? "auto"
                          : 0;
                        const checked = ingredient[0].purchased ? true : false;

                        let recipes = shopping_ids.reduce(function (acc, si) {
                          const rec = _.find(props.shopping, {
                            shopping_id: si,
                          });
                          if (rec) {
                            acc.push(rec);
                          }
                          return acc;
                        }, []);
                        recipes = _.groupBy(recipes, "recipe_id");

                        const keyId = shopping_ids.join("-");

                        let height = 0;
                        if (recipeToggle.indexOf(keyId) > -1) {
                          height = "auto";
                        }

                        let iconColor = null;
                        if (checked) {
                          iconColor = "teal";
                        }
                        let iconText = "plus";
                        if (quantity < 0) {
                          iconText = "minus";
                          if (checked) {
                            iconColor = "red";
                          }
                        }

                        return (
                          <AnimateHeight
                            key={"purchased" + keyId}
                            duration={500}
                            height={checkedClass}
                          >
                            <li
                              className={`${classes.shoppingListRow}`}
                              onClick={() => {
                                if (props.sort != "recipe") {
                                  toggleRecipe(shopping_ids);
                                }
                              }}
                            >
                              <div className={classes.shoppingTopRow}>
                                <div
                                  key={`purchased-${ingIndex}-${unitIndex}`}
                                  className={classes.tickBox}
                                  onClick={(e) => {
                                    if (props.sort != "aisle") {
                                      return false;
                                    }
                                    e.preventDefault();
                                    e.stopPropagation();
                                    props.togglePurchase(shopping_ids);
                                  }}
                                >
                                  {aisleIndex == "Adjusted Quantities" ? (
                                    <div className={classes.additional}>
                                      <Icon
                                        inverted
                                        color={iconColor}
                                        circular
                                        name={iconText}
                                        size="small"
                                      />
                                    </div>
                                  ) : (
                                    <div className={`round`}>
                                      <input
                                        className="checkbox"
                                        type="checkbox"
                                        checked={checked}
                                        onChange={(e) => e.preventDefault()}
                                      />
                                      <label></label>
                                    </div>
                                  )}
                                </div>
                                <div className={classes.shoppingItem}>
                                  <div className={classes.Ingredient}>
                                    <span className={classes.IngredientName}>
                                      {ingredientName}
                                    </span>
                                    {ingredientUnit != "extraItem" && (
                                      <>
                                        <span
                                          className={classes.IngredientAmount}
                                        >
                                          {round(quantity, 1)}
                                        </span>
                                        <span
                                          className={classes.IngredientUnit}
                                        >
                                          {ingredientUnit}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <AnimateHeight duration={500} height={height}>
                                  <div className={classes.shoppingRecipeName}>
                                    {_.map(recipes, function (recipe, i) {
                                      return (
                                        <div key={i}>
                                          {recipe.length} <span>x</span>{" "}
                                          {recipe[0].recipe_name}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </AnimateHeight>
                              </div>
                            </li>
                          </AnimateHeight>
                        );
                      });
                    })}
                  </ul>
                </div>
              </AnimateHeight>
            );
          })}
        </AnimateHeight>
      </div>
    </>
  );
};

export default PurchasedGroup;
