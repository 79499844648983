import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";

import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import PlannerEntry from "./PlannerEntry";
import drawer from "./Drawer.module.scss";
import planner from "./Planner.module.scss";
import { useDispatch, connect } from "react-redux";
import { Grid, Container, Button, Label, Checkbox } from "semantic-ui-react";
import { updateSegment } from "../../store/actions/plannerActions";
import _ from "lodash";
import RecipeDetails from "../Recipes/RecipeDetails";
import { updateAlwaysReduce } from "../../store/actions/authActions";
import axios from "../../api/axios-token";
import useWindow from "../../utility/useWindowSize";

const Drawer = (props) => {
  const [servings, setServes] = useState(1);
  const [reduced, setReduced] = useState(null);
  const [portions, setPortions] = useState(1);
  const [shoppingPortions, setShoppingPortions] = useState(1);
  const [recipePreview, setRecipePreview] = useState(false);
  const dispatch = useDispatch();
  const windowSize = useWindow();
  const isMobile = windowSize.width < 992 ? true : false;

  const adjustPortion = (n, data) => {
    let currPortion = parseInt(data.optional_data);
    currPortion += n;
    if (currPortion < 0) {
      currPortion = 0;
    }

    const newData = {
      ...data,
      optional_data: currPortion,
    };
    updateData(newData);
  };

  const toggleAlwaysReduce = (value) => {
    let toggle = 0;
    if (value) {
      toggle = 1;
    }
    dispatch(updateAlwaysReduce(toggle));
    axios
      .post("/profile/update_always_reduce", {
        toggle: toggle,
      })
      .catch(function (error) {
        console.log(error);
        console.log(
          "Something went wrong saving your information, try refreshing the page and try again. "
        );
      });
  };

  const toggleReduced = () => {
    // setReduced(!reduced);
    let reduced;
    if (!data.optional_data2) {
      reduced = 1;
    } else {
      reduced = 0;
    }
    const newData = {
      ...data,
      optional_data2: reduced,
    };
    updateData(newData);
  };

  const updateData = (data) => {
    dispatch(updateSegment(data));
  };

  let day = _.find(props.data, { data: [{ uuid: props.recipe.segment_id }] });
  if (!day.data) {
    return null;
  }
  let segment = _.find(day.data, { uuid: props.recipe.segment_id });

  let data;
  if (segment) {
    data = segment;
  }

  const recipe = props.recipe;
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const anchor = isMobile ? "bottom" : "right";

  return !data ? null : (
    <SwipeableDrawer
      anchor={anchor}
      open={props.display}
      onClose={() => {
        props.toggle(null);
      }}
      onOpen={() => {}}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <div
        className={drawer.Container}
        style={{ padding: "1em", maxWidth: "400px" }}
      >
        <Container style={{ maxWidth: "400px", padding: "0 1em" }}>
          <Grid columns={2}>
            <Grid.Row style={{ paddingBottom: 0 }}>
              <Grid.Column>
                <PlannerEntry
                  key={`edit_option`}
                  data={{
                    ...recipe,
                    reduce: data.optional_data2,
                    portions: data.optional_data,
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <h4>{recipe.name}</h4>
                <Button
                  disabled={recipe.serves == 1 || parseInt(recipe.locked) == 1}
                  content="Reduce"
                  color={!data.optional_data2 ? "grey" : "green"}
                  onClick={() => toggleReduced(data)}
                  icon="food"
                  label={{
                    as: "a",
                    basic: true,
                    pointing: "right",
                    content: `Serves ${recipe.serves}`,
                  }}
                  labelPosition="left"
                />
                <div
                  className={drawer.alwaysReduce}
                  style={{ opacity: `${recipe.serves > 1 ? 1 : 0.9}` }}
                >
                  <Checkbox
                    toggle
                    disabled={
                      recipe.serves == 1 || parseInt(recipe.locked) == 1
                    }
                    checked={parseInt(props.user.always_reduce) ? true : false}
                    onChange={(d, v) => toggleAlwaysReduce(v.checked)}
                  />{" "}
                  Always reduce
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row
              style={{ padding: isMobile ? "1em" : 0, textAlign: "center" }}
              columns={isMobile ? 1 : 2}
            >
              <Grid.Column>
                <h4 style={{ textAlign: "left" }}>Portions to make:</h4>
                <Button.Group fluid>
                  <Button
                    disabled={parseInt(recipe.locked) == 1}
                    onClick={() => adjustPortion(-1, data)}
                  >
                    -
                  </Button>
                  <Button basic>{data.optional_data}</Button>
                  <Button
                    disabled={parseInt(recipe.locked) == 1}
                    onClick={() => adjustPortion(1, data)}
                  >
                    +
                  </Button>
                </Button.Group>
                {parseInt(data.optional_data) === 0 && (
                  <Label basic color="red" pointing>
                    0 portions are not included in shopping lists
                  </Label>
                )}
              </Grid.Column>
              {isMobile && (
                <Grid.Column
                  style={{
                    marginTop: isMobile ? "1em" : "auto",
                    display: "flex",
                  }}
                >
                  <Button
                    fluid
                    positive
                    style={{ marginTop: "auto", marginBottom: "5px" }}
                    onClick={() => setRecipePreview(!recipePreview)}
                  >
                    {recipePreview ? "Hide" : "Preview"} Recipe
                  </Button>
                </Grid.Column>
              )}
            </Grid.Row>
            {(recipePreview || !isMobile) && (
              <Grid.Row>
                <RecipeDetails
                  embed
                  id={recipe.hash}
                  receivePortions={parseInt(data.optional_data)}
                  receiveServings={parseInt(recipe.serves)}
                  receiveReduced={!data.optional_data2 ? false : true}
                />
              </Grid.Row>
            )}
          </Grid>
        </Container>
      </div>
    </SwipeableDrawer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user.options,
  };
};

export default connect(mapStateToProps)(Drawer);
