import axios from "../../api/axios-token";
import _ from "lodash";
import { addDays, getISODay } from "../../screens/Planner/dateHelper";

export const INSERT_DAY = "INSERT_DAY";
export const UPDATE_DAY = "UPDATE_DAY";
export const INIT_PLANNER = "INIT_PLANNER";
export const REMOVE_SEGMENT = "REMOVE_SEGMENT";
export const INIT_DRAG = "INIT_DRAG";
export const STOP_DRAG = "STOP_DRAG";
export const ClEAR_DAY = "CLEAR_DAY";
export const UPDATE_SELECTED_RECIPES = "UPDATE_SELECTED_RECIPES";
export const UPDATE_SELECTED_WORKOUTS = "UPDATE_SELECTED_WORKOUTS";
export const UPDATE_SELECTED_CARDS = "UPDATE_SELECTED_CARDS";
export const CLEAR_SELECTED = "CLEAR_SELECTED";
export const UPDATE_SEGMENT = "UPDATE_SEGMENT";
export const INSERT_CARD = "INSERT_CARD";
export const ARCHIVE_CARD = "ARCHIVE_CARD";
export const UPDATE_CARD = "UPDATE_CARD";
export const PLANNER_CHANGE_WEEKS = "PLANNER_CHANGE_WEEKS";

export const insertDaySuccess = (date, data) => {
  return {
    type: INSERT_DAY,
    payload: { date: date, data: data },
  };
};

export const updateSelectedRecipes = (array) => {
  return {
    type: UPDATE_SELECTED_RECIPES,
    payload: array,
  };
};

export const insertCard = (payload) => {
  return {
    type: INSERT_CARD,
    payload: payload,
  };
};

export const archiveCard = (payload) => {
  axios.post("/planner/archive_card", {
    cardID: payload,
  });
  return {
    type: ARCHIVE_CARD,
    payload: payload,
  };
};

export const updateCard = (id, payload) => {
  return (dispatch) => {
    dispatch(updateCardSuccess(id, payload));
    axios.post("/planner/update_card", {
      cardID: id,
      data: payload,
    });
  };
};
export const updateCardSuccess = (id, payload) => {
  return {
    type: UPDATE_CARD,
    id: id,
    payload: payload,
  };
};

export const clearSelected = () => {
  return {
    type: CLEAR_SELECTED,
  };
};
export const updateSelectedWorkouts = (array) => {
  return {
    type: UPDATE_SELECTED_WORKOUTS,
    payload: array,
  };
};
export const updateSelectedCards = (array) => {
  return {
    type: UPDATE_SELECTED_CARDS,
    payload: array,
  };
};

export const updateDaySuccess = (date, data) => {
  return {
    type: UPDATE_DAY,
    payload: { date: date, data: data },
  };
};

export const clearDaySuccess = (day) => {
  return {
    type: ClEAR_DAY,
    payload: day,
  };
};

export const initPlannerSuccess = (payload) => {
  return {
    type: INIT_PLANNER,
    payload: {
      planner: payload.planner,
      workouts: payload.workouts,
      customCards: payload.customCards,
    },
  };
};

export const plannerWeeksSuccess = (payload) => {
  return {
    type: PLANNER_CHANGE_WEEKS,
    payload: {
      planner: payload.planner,
    },
  };
};

export const removeSegmentSuccess = (date, segmentID) => {
  return {
    type: REMOVE_SEGMENT,
    date: date,
    segment: segmentID,
  };
};

export const initPlanner = (startDate, endDate) => {
  if (!startDate) {
    let currentDate = new Date();
    let prevDate = new Date();
    endDate = getISODay(addDays(currentDate, 14));
    startDate = getISODay(addDays(prevDate, -14));
  }

  return (dispatch) => {
    axios
      .post("/planner/init", {
        startDate: startDate,
        endDate: endDate,
      })
      .then(function (response) {
        dispatch(initPlannerSuccess(response.data));
      })
      .catch(function (error) {
        console.log("error updating planner");
      });
  };
};

export const plannerWeeks = (startDate, endDate) => {
  if (!startDate) {
    return;
  }
  if (!endDate) {
    endDate = new Date(startDate);
    addDays(endDate, 7);
  }

  endDate = getISODay(addDays(new Date(endDate), 7));
  startDate = getISODay(addDays(new Date(startDate), -7));
  return (dispatch) => {
    axios
      .post("/planner/init", {
        startDate: startDate,
        endDate: endDate,
      })
      .then(function (response) {
        dispatch(plannerWeeksSuccess(response.data));
      })
      .catch(function (error) {
        console.log("error updating planner");
      });
  };
};

export const clearDay = (date) => {
  return (dispatch) => {
    dispatch(clearDaySuccess(date));
    axios
      .post("/planner/clearday", {
        date: date,
      })
      // .then(function (response) {
      //   dispatch(clearDaySuccess(response.data.planner));
      // })
      .catch(function (error) {
        console.log("error updating planner");
      });
  };
};

export const insertDay = (date, data, optional_data = null) => {
  return (dispatch) => {
    const dataArray = [];

    _.map(data, function (d, i) {
      if (!d.optional_data) {
        if (d.type == "recipe" || d.type == "meal") {
          d.optional_data = 1;
        }
      }

      let segmentOrder = i;

      if (d.segment_order) {
        segmentOrder = d.segment_order.toString();
      }

      const optimist = {
        data: d.id.toString(),
        date: date.toString(),
        id: null,
        optional_data: d.optional_data,
        optional_data2: d.optional_data2,
        segment_order: segmentOrder,
        type: d.type,
        userid: "me",
        uuid: d.uuid,
      };

      dataArray.push(optimist);
    });

    dispatch(insertDaySuccess(date, dataArray));
    axios
      .post("/planner/insert", {
        date: date,
        data: data,
        optional_data: optional_data,
      })
      .then(function (response) {
        // console.log(response.data.planner);
        // dispatch(insertDaySuccess(date, response.data.planner));
      })
      .catch(function (error) {
        console.log("error updating planner");
      });
  };
};

export const removeSegment = (date, segmentID) => {
  return (dispatch) => {
    dispatch(removeSegmentSuccess(date, segmentID));
    axios
      .post("/planner/remove", {
        segment: segmentID,
      })
      .then(function (response) {
        //dispatch(removeSegmentSuccess(date,segmentID));
      });
  };
};

export const initdragStart = (day) => {
  return {
    type: INIT_DRAG,
    day: day,
  };
};
export const stopDrag = () => {
  return {
    type: STOP_DRAG,
  };
};

export const updateDay = (
  date,
  data,
  optional_data = null,
  optional_data2 = null
) => {
  return (dispatch) => {
    dispatch(updateDaySuccess(date, data));
    axios
      .post("/planner/update", {
        date: date,
        data: data,
        optional_data: optional_data,
        optional_data2: optional_data2,
      })
      .then(function (response) {
        dispatch(updateDaySuccess(date, response.data.planner));
      })
      .catch(function (error) {
        console.log("error updating planner");
      });
  };
};

export const updateSegmentSuccess = (data) => {
  return {
    type: UPDATE_SEGMENT,
    payload: data,
  };
};

export const updateSegment = (data) => {
  return (dispatch) => {
    dispatch(updateSegmentSuccess(data));
    axios
      .post("/planner/update_segment", {
        data: data,
      })
      .then(function (res) {})
      .catch(function (error) {
        console.log("error updating planner");
      });
  };
};
