import React, { useState, useRef, useEffect } from "react";

import classes from "./Shopping.module.scss";
import { Dropdown, Input } from "semantic-ui-react";

const ItemInput = (props) => {
  const inputRef = useRef(null);

  function onEditPress(e) {
    if (e.keyCode == 13 && e.shiftKey == false) {
      if (props.value != "") {
        // props.addItem(value);
        props.updateItem();
        props.close();
      } else {
        props.close();
      }
    }
  }

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <Input
      ref={inputRef}
      onKeyDown={(e) => onEditPress(e)}
      value={props.value}
      onChange={(e, d) => props.change(d.value)}
      transparent
      placeholder="Edit item..."
      focus={true}
    />
  );
};

const AddItemList = (props) => {
  const [editing, setEditing] = useState(false);
  const [editValue, setEditingValue] = useState("");
  const listRef = useRef(null);
  const editingValue = useRef(editValue);

  const item = props.item;

  const handleClick = (e) => {
    if (listRef.current.contains(e.target)) {
      return;
    }
    // outside click
    updateItem();
  };

  const updateItem = () => {
    const value = editingValue.current;

    if (value) {
      setEditing(false);

      props.updateItem(item.id, value);
    } else {
      setEditingValue("");
      setEditing(false);
    }
  };

  useEffect(() => {
    editingValue.current = editValue;
  }, [editValue]);

  useEffect(() => {
    if (editing) {
      document.addEventListener("mousedown", handleClick);
    } else {
      document.removeEventListener("mousedown", handleClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [editing]);

  return (
    <li
      ref={listRef}
      key={`extra-${props.index}`}
      className={`${classes.shoppingListRow}`}
    >
      <div className={classes.shoppingTopRow}>
        {props.sort == "aisle" && (
          <div
            className={classes.tickBox}
            onClick={(e) => {
              e.stopPropagation();

              props.togglePurchase(["extra-" + item.id]);
            }}
          >
            <div className={`round`}>
              <input
                className="checkbox"
                type="checkbox"
                checked={props.checked}
                onChange={(e) => e.preventDefault()}
              />
              <label></label>
            </div>
          </div>
        )}
        <div className={classes.shoppingItem}>
          <div className={classes.Ingredient}>
            {!editing ? (
              <span className={classes.IngredientName}>{item.name}</span>
            ) : (
              <ItemInput
                close={() => {
                  setEditing(false);
                }}
                change={(v) => {
                  setEditingValue(v);
                }}
                value={editValue}
                updateItem={() => {
                  updateItem();
                }}
              />
            )}
          </div>

          <div className={classes.Options}>
            <Dropdown direction="left" inline icon={"ellipsis vertical"}>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => props.remove(item.id)}
                  icon="trash"
                  text="Remove"
                />
                <Dropdown.Item
                  onClick={() => {
                    setEditingValue(item.name);
                    setEditing(true);
                  }}
                  icon="edit"
                  text="Edit"
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div></div>
    </li>
  );
};

export default AddItemList;
