import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Segment,
  Form,
  Button,
  Message,
} from "semantic-ui-react";
import zxcvbn from "zxcvbn";
import "./PasswordStrength.css";
import classes from "../Landing/Landing.module.scss";
import axios from "../../api/axios-auth";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = () => {
  const [hash, setHash] = useState(null);
  const [error, setError] = useState(null);
  const history = useNavigate();
  const params = useParams();
  const [password, setPassword] = useState({
    text: "",
    errors: null,
    strength: zxcvbn(""),
    strengthClass: "Weak",
  });
  const [passwordConfirm, setPasswordConfirm] = useState("");

  if (!params.code) {
    history("/sign-in");
  }
  useEffect(() => {
    axios
      .post("/reset_password_hash", {
        code: params.code,
      })
      .then(function (response) {
        if (response.data.status) {
          setHash(response.data.hash);
        } else {
          alert(
            "Something went wrong, please try requesting the reset email again."
          );
        }
      })
      .catch(function (error) {
        alert(
          "Something went wrong, please try requesting the reset email again."
        );
      });
  }, []);

  const createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return "Weak";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "Weak";
    }
  };
  const passwordChangeListener = (e) => {
    const testedResult = zxcvbn(e.target.value);
    const passwordLabel = createPasswordLabel(testedResult);
    setError(null);
    setPassword({
      text: e.target.value,
      error: null,
      strength: testedResult,
      strengthClass: passwordLabel,
    });
  };

  const passwordSubmit = () => {
    let error;

    if (password.strength.score < 3) {
      setError("The password strength is too weak.");
      return;
    }
    if (password.text != passwordConfirm) {
      setError("The passwords do not match");
      return;
    }
    axios
      .post("/reset_password", {
        code: params.code,
        user_id: hash,
        new: password.text,
      })
      .then(function (response) {
        if (response.data.status) {
          alert("password reset");
        } else {
          alert(
            "Something went wrong, please try requesting the reset email again."
          );
        }
      })
      .catch(function (error) {
        alert(
          "Something went wrong, please try requesting the reset email again."
        );
      });
  };

  return (
    <Container
      className={classes.Container}
      fluid
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/landing/spheres.png)`,
      }}
    >
      <Grid
        style={{ height: "100vh", padding: "1em" }}
        centered
        verticalAlign="middle"
      >
        <Grid.Row>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Segment>
              <Form>
                <Form.Field>
                  <label>Enter new Password:</label>
                  <input
                    placeholder="Enter a strong password"
                    type="password"
                    value={password.text}
                    onChange={(e) => {
                      passwordChangeListener(e);
                    }}
                  />

                  <>
                    <progress
                      attached="bottom"
                      className={`password-strength-meter-progress strength-${password.strengthClass}`}
                      value={password.strength.score}
                      max="4"
                    />
                    <label
                      style={{
                        textAlign: "right",
                        fontWeight: 400,
                        marginTop: "0.5em",
                      }}
                      className="password-strength-meter-label"
                    >
                      {password && (
                        <>
                          <strong>Password strength:</strong>{" "}
                          {password.strengthClass}
                        </>
                      )}
                    </label>
                  </>
                </Form.Field>
                <Form.Field>
                  <label>Retype Password:</label>
                  <input
                    type="password"
                    onChange={(e) => {
                      setError(null);
                      setPasswordConfirm(e.target.value);
                    }}
                    placeholder="Re-type your password"
                  />
                </Form.Field>
                {error && (
                  <Message negative>
                    <Message.Header>Error</Message.Header>
                    <p>{error}</p>
                  </Message>
                )}
                <div style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => passwordSubmit()}
                    type="button"
                    className="rounded"
                    color="green"
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default ResetPassword;
