import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import AnimateHeight from "react-animate-height";
import { Icon } from "semantic-ui-react";
import classes from "./Shopping.module.scss";
import { round } from "../../utility/helper";
import _ from "lodash";
const AdditionalGroup = (props) => {
  const purchased = [];
  _.map(props.additional, function (i) {
    if (props.purchased.indexOf("add-" + i.id) < 0) {
      purchased.push(i);
    }
  });
  let collapseId = "additional";
  return (
    <>
      {purchased.length > 0 ? (
        <div className={classes.purchasedGroup}>
          <h5 className={classes.GroupTitle}>
            Adjusted Quantities
            <span className={classes.totalCount}>({purchased.length})</span>
            <span
              className={classes.collapse}
              onClick={() => {
                props.toggleCollapsed(collapseId);
              }}
            >
              <Icon
                color="green"
                name={
                  props.collapsed.indexOf(collapseId) > -1
                    ? "angle down"
                    : "angle up"
                }
              />
            </span>
          </h5>
          <AnimateHeight
            key={collapseId}
            duration={500}
            height={props.collapsed.indexOf(collapseId) > -1 ? 0 : "auto"}
          >
            <div>
              <ul className={classes.Group}>
                {_.map(purchased, function (item, index) {
                  let checked = false;
                  if (props.purchased.indexOf("add-" + item.id) > -1) {
                    checked = true;
                  }
                  let iconColor = null;
                  if (checked) {
                    iconColor = "green";
                  }
                  let iconText = "plus";
                  if (item.quantity < 0) {
                    iconText = "minus";
                    if (checked) {
                      iconColor = "red";
                    }
                  }

                  return (
                    <li key={index} className={`${classes.shoppingListRow}`}>
                      <div className={classes.shoppingTopRow}>
                        <div
                          key={`add-${index}`}
                          className={classes.tickBox}
                          onClick={(e) => {
                            if (props.sort != "aisle") {
                              return false;
                            }

                            e.preventDefault();
                            props.togglePurchase(["add-" + item.id]);
                          }}
                        >
                          {/* <div className={`round`}>
                          <input
                            className="checkbox"
                            type="checkbox"
                            id="checkbox"
                            checked={checked}
                            onChange={(e) => e.preventDefault()}
                          />
                          <label htmlFor="checkbox"></label>
                        </div> */}
                          <div className={classes.additional}>
                            <Icon
                              inverted={iconColor ? true : false}
                              circular
                              color={iconColor}
                              name={iconText}
                              size="small"
                            />
                          </div>
                        </div>
                        <div className={classes.shoppingItem}>
                          <div className={classes.Ingredient}>
                            <span className={classes.IngredientName}>
                              {item.name}
                            </span>
                            <span className={classes.IngredientAmount}>
                              {item.quantity}
                            </span>
                            <span className={classes.IngredientUnit}>
                              {item.unit}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </AnimateHeight>
        </div>
      ) : null}
    </>
  );
};

export default AdditionalGroup;
