import React from "react";

const DayComplete = (props) => {
  return (
    <div className={props.className}>
      <img src={`${process.env.PUBLIC_URL}/img/star.svg`} />
      <div className="caption">DAY COMPLETE!</div>
      <div className="score">+50</div>
    </div>
  );
};

export default DayComplete;
