import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import axios from "../../api/axios-token";
import classes from "./Progress.module.scss";
import SimpleImageUpload from "../../components/SimpleImageUpload";
import { useQuery, useQueryClient } from "react-query";
import uuid from "react-uuid";
import _ from "lodash";
import {
  Table,
  Container,
  Header,
  Grid,
  Input,
  Button,
  Icon,
  Modal,
  Image,
  Form,
  Loader,
  Confirm,
  Dimmer,
  Message,
  Menu,
  Label,
} from "semantic-ui-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  format,
  parse,
  endOfMonth,
  startOfMonth,
  getUnixTime,
  differenceInCalendarDays,
  addDays,
  addMonths,
  getMonth,
} from "date-fns";
import intlFormat from "date-fns/intlFormat";
import ProgressImages from "./ProgressImages";
import {
  Charts,
  ChartContainer,
  ChartRow,
  YAxis,
  LineChart,
  Resizable,
  Baseline,
  styler,
  AreaChart,
  Legend,
} from "react-timeseries-charts";
import { TimeSeries, TimeRange } from "pondjs";
import { getMonthFirstDay } from "../Planner/dateHelper";

const Progress = () => {
  const [add, setAdd] = useState(false);
  const [weightUnit, setWeightUnit] = useState("kg");
  const [measureUnit, setMeasureUnit] = useState("inches");
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState(null);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [updateID, startUpdate] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [images, setImage] = useState({ front: null, side: null, back: null });
  const [deleting, setDeleting] = useState(false);
  const [progressPics, setProgressPics] = useState(null);
  const [rangeDate, setRangeDate] = useState(startOfMonth(new Date()));
  const [rangeEndDate, setRangeEndDate] = useState(endOfMonth(new Date()));
  const [rangeLength, setRangeLength] = useState(1);
  const [range, setRange] = useState();
  const [savedImages, setSavedImages] = useState({
    front: null,
    side: null,
    back: null,
  });
  const [deletedImages, setDeletedImages] = useState({
    front: null,
    side: null,
    back: null,
  });
  const [progressDate, setProgressData] = useState(
    format(new Date(), "yyyy-MM-dd HH:mm:ss")
  );
  const [confirmDelete, deleteProgress] = useState(null);

  const getProgress = () =>
    axios
      .get("/profile/progress")
      .then(function (response) {
        return response.data.progress;
      })
      .catch(function (error) {
        console.log(error);
      });

  const queryClient = new useQueryClient();
  const progressData = useQuery("progress", getProgress);

  useEffect(() => {
    const monthEnd = endOfMonth(
      addMonths(new Date(rangeDate), rangeLength - 1)
    );
    setRangeEndDate(monthEnd);

    // console.log(monthEnd);
    // const newRange = new TimeRange(rangeDate, monthEnd);
    setRange(new TimeRange(rangeDate, monthEnd));
  }, [rangeDate, rangeLength]);

  const deleteImage = (type) => {
    if (type) {
      switch (type) {
        case "front":
          setDeletedImages({ ...deletedImages, front: true });
          setSavedImages({ ...savedImages, front: null });
          break;
        case "side":
          setDeletedImages({ ...deletedImages, side: true });
          setSavedImages({ ...savedImages, side: null });
          break;
        case "back":
          setDeletedImages({ ...deletedImages, back: true });
          setSavedImages({ ...savedImages, back: null });

          break;
      }
    }
  };
  const emptyData = {
    weight: "",
    waist: "",
    hips: "",
    chest: "",
    leftArm: "",
    rightArm: "",
    leftLeg: "",
    rightLeg: "",
  };
  const [data, setData] = useState({ ...emptyData });

  useEffect(() => {
    if (updateID) {
      setAdd(true);

      if (progressData.data) {
        // console.log(progressData.data);
        const newdata = { ..._.find(progressData.data, { id: updateID }) };
        delete newdata.date_added;
        delete newdata.userid;
        const date = parse(
          newdata.progressDate,
          "yyyy-MM-dd HH:mm:ss",
          new Date()
        );

        handleDateChange(date);
        setSavedImages({
          front: newdata.front,
          side: newdata.side,
          back: newdata.back,
        });
        delete newdata.front;
        delete newdata.back;
        delete newdata.side;
        delete newdata.progressDate;
        setData({ ...newdata });
      }
    } else {
      setAdd(false);
    }
  }, [updateID]);

  useEffect(() => {
    let formatDate = format(selectedDate, "yyyy-MM-dd HH:mm:ss");
    setProgressData(formatDate);
  }, [selectedDate]);

  // store weight in metric? kg / cm

  const saveProgress = () => {
    let formData = new FormData();
    if (images.front instanceof File || images.front instanceof Blob) {
      formData.append("front", images.front);
    }
    if (images.side instanceof File || images.side instanceof Blob) {
      formData.append("side", images.side);
    }
    if (images.back instanceof File || images.back instanceof Blob) {
      formData.append("back", images.back);
    }

    var empty = true;
    var i;

    let progress = Object.values(data);
    for (i = 0; i < progress.length - 1; i++) {
      if (progress[i] !== "") {
        empty = false;
        break;
      }
    }

    if (empty) {
      setErrors(["You have not inputted any progress"]);
      return;
    }

    formData.append("data", JSON.stringify(data));
    formData.append("deletedImages", JSON.stringify(deletedImages));
    formData.append("progressDate", progressDate);

    axios
      .post("/profile/save_progress", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        if (response.data.success) {
          progressData.refetch();
          setAdd(false);
          startUpdate(false);
          setSaving(false);
        } else {
          if (response.data.errors) {
            setErrors([response.data.errors]);
            setSaving(false);
            return false;
          } else {
            setErrors([
              "An unknown error occured, try refreshing the page or checking your connection.",
            ]);
            setSaving(false);
            return false;
          }
        }
      });
  };

  const ErrorMessage = () => {
    return (
      <div style={{ padding: "1em", margin: "2em", fontSize: "1.5em" }}>
        <Header>An error occured:</Header>
        <ul>
          {_.map(errors, function (e) {
            return <li>{e}</li>;
          })}
        </ul>
      </div>
    );
  };

  const imageHandler = (type, imageFile) => {
    if (type && imageFile) {
      switch (type) {
        case "front":
          setImage({ ...images, front: imageFile });
          break;
        case "side":
          setImage({ ...images, side: imageFile });
          break;
        case "back":
          setImage({ ...images, back: imageFile });
          break;
      }
    }
  };

  const updateMeasurement = (type, value) => {
    const regex = /^\d+\.?\d*$/;
    if (!regex.test(value) && value != "") {
      return false;
    }

    switch (type) {
      case "weight":
        setData({ ...data, weight: value });
        break;
      case "waist":
        setData({ ...data, waist: value });
        break;
      case "hips":
        setData({ ...data, hips: value });
        break;
      case "chest":
        setData({ ...data, chest: value });
        break;
      case "leftArm":
        setData({ ...data, leftArm: value });
        break;
      case "rightArm":
        setData({ ...data, rightArm: value });
        break;
      case "leftLeg":
        setData({ ...data, leftLeg: value });
        break;
      case "rightLeg":
        setData({ ...data, rightLeg: value });
        break;
    }
  };

  const tableData = {
    id: [],
    weight: [],
    waist: [],
    hips: [],
    chest: [],
    rightArm: [],
    leftArm: [],
    rightLeg: [],
    leftLeg: [],
    images: [],
    progressPics: [],
    progressDate: [],
  };

  if (progressData.data && progressData.data.length) {
    _.map(progressData.data, function (p) {
      const dateparse = parse(
        p.progressDate,
        "yyyy-MM-dd HH:mm:ss",
        new Date()
      );

      let date = intlFormat(new Date(dateparse), {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      tableData.id.push(p.id);
      tableData.weight.push(p.weight);
      tableData.waist.push(p.waist);
      tableData.hips.push(p.hips);
      tableData.chest.push(p.chest);
      tableData.rightArm.push(p.rightArm);
      tableData.leftArm.push(p.leftArm);
      tableData.rightLeg.push(p.rightLeg);
      tableData.leftLeg.push(p.leftLeg);

      const images = {
        front: p.front,
        side: p.side,
        back: p.back,
      };

      for (const i in images) {
        if (images[i]) {
          tableData.progressPics.push(images[i]);
        }
      }
      tableData.images.push(images);
      tableData.progressDate.push(date);
    });
  }

  const getAverageReduction = (
    weightStart,
    weightEnd,
    startDate,
    endDate,
    monthStart,
    monthEnd
  ) => {
    const dayDifference = differenceInCalendarDays(startDate, endDate);
    const weightAverage = ((weightStart - weightEnd) / dayDifference) * 7;
    let averageDate = new Date(monthStart);
    const points = [];
    let weightLoss = weightStart;
    while (differenceInCalendarDays(averageDate, monthEnd) < 0) {
      points.push([averageDate, weightLoss]);
      averageDate = addDays(averageDate, 7);
      weightLoss += weightAverage;
    }

    return points;
  };

  const graphWeightData = _.reduce(
    progressData.data,
    function (acc, curr) {
      const date = parse(curr.progressDate, "yyyy-MM-dd HH:mm:ss", new Date());
      if (curr) {
        return [...acc, [new Date(date), parseFloat(curr.weight)]];
      } else {
        return acc;
      }
    },
    []
  );

  const deleteProgressConfirm = () => {
    const id = confirmDelete;
    deleteProgress(null);
    setDeleting(true);
    axios
      .post("/profile/delete_progress", {
        id: confirmDelete,
      })
      .then(function (response) {
        if (response.data.success) {
          progressData.refetch();
          setDeleting(false);
        } else {
          if (response.data.errors) {
            setErrors([response.data.errors]);
            setDeleting(false);
            return false;
          } else {
            setErrors([
              "An unknown error occured, try refreshing the page or checking your connection.",
            ]);
            setDeleting(false);
            return false;
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getTableCell = (type) => {
    if (type == "images") {
      return _.map(tableData.images, function (i, index) {
        return (
          <Table.Cell key={"image-" + index}>
            <div
              className={classes.progressPics}
              onClick={() => {
                tableData.progressPics.length
                  ? setProgressPics(true)
                  : setProgressPics(false);
              }}
            >
              <ProgressImages
                open={progressPics}
                close={() => {
                  setProgressPics(false);
                }}
                images={tableData.progressPics}
              />
              <div data-private>
                <h3>Front</h3>
                {i.front && (
                  <img
                    src={`${process.env.REACT_APP_API}/user_images/${i.front}`}
                  />
                )}
              </div>
              <div data-private>
                <h3>Side</h3>
                {i.side && (
                  <img
                    src={`${process.env.REACT_APP_API}/user_images/${i.side}`}
                  />
                )}
              </div>
              <div data-private>
                <h3>Back</h3>
                {i.back && (
                  <img
                    src={`${process.env.REACT_APP_API}/user_images/${i.back}`}
                  />
                )}
              </div>
            </div>
          </Table.Cell>
        );
      });
    } else {
      if (tableData[type].length) {
        return _.map(tableData[type], function (d, i) {
          return (
            <Table.Cell key={"weight" + i}>
              <span data-private>{d}</span>
            </Table.Cell>
          );
        });
      }
    }
  };
  var monthStart = startOfMonth(new Date());
  var monthEnd = endOfMonth(new Date());

  const series = new TimeSeries({
    name: "Weight",
    columns: ["time", "value"],
    points: graphWeightData,
  });

  const startDate =
    graphWeightData && graphWeightData.length > 1
      ? graphWeightData[0][0]
      : null;

  const weightLossTrend =
    graphWeightData && graphWeightData.length > 1
      ? getAverageReduction(
          graphWeightData[0][1],
          graphWeightData[graphWeightData.length - 1][1],
          graphWeightData[0][0],
          graphWeightData[graphWeightData.length - 1][0],
          startDate,
          rangeEndDate
        )
      : [];

  const series2 = new TimeSeries({
    name: "Trend",
    columns: ["time", "value"],
    points: weightLossTrend,
  });

  const style = {
    value: {
      normal: { stroke: "green", fill: "none", strokeWidth: 3 },
      highlighted: { stroke: "#5a98cb", fill: "none", strokeWidth: 1 },
      selected: { stroke: "steelblue", fill: "none", strokeWidth: 1 },
      muted: {
        stroke: "steelblue",
        fill: "none",
        opacity: 0.4,
        strokeWidth: 1,
      },
    },
  };

  const style2 = {
    value: {
      normal: { stroke: "orange", fill: "none", strokeWidth: 1 },
      highlighted: { stroke: "#5a98cb", fill: "none", strokeWidth: 1 },
      selected: { stroke: "steelblue", fill: "none", strokeWidth: 1 },
      muted: {
        stroke: "steelblue",
        fill: "none",
        opacity: 0.4,
        strokeWidth: 1,
      },
    },
    label: {
      fill: "green",
    },
  };

  const legend = styler([
    { key: "weight", color: "green", width: 2 },
    { key: "prediction", color: "orange", width: 2 },
  ]);

  const baselineStyle = {
    line: {
      stroke: "green",
      strokeWidth: 1,
      opacity: 0.4,
      strokeDasharray: "none",
    },
    label: {
      fill: "green",
    },
  };

  const baselineStyleLite = {
    line: {
      stroke: "green",
      strokeWidth: 2,
      opacity: 0.5,
    },
    label: {
      fill: "green",
    },
  };

  const baselineStyleExtraLite = {
    line: {
      stroke: "green",
      strokeWidth: 2,
      opacity: 0.2,
      strokeDasharray: "1,1",
    },
    label: {
      fill: "green",
    },
  };

  return (
    <Container style={{ padding: "1em 0" }}>
      <Dimmer active={deleting}>
        <Loader>Deleting</Loader>
      </Dimmer>
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column>
            <Header>Your Progress</Header>
            <div className={classes.container}>
              <Table unstackable definition striped color="green" inverted>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    {progressData.isLoading ? (
                      <Table.HeaderCell>
                        <Loader size="small" active inline />
                      </Table.HeaderCell>
                    ) : progressData.data && progressData.data.length ? (
                      <>
                        {_.map(tableData.progressDate, function (t) {
                          return (
                            <Table.HeaderCell key={t}>{t}</Table.HeaderCell>
                          );
                        })}
                      </>
                    ) : (
                      <Table.HeaderCell></Table.HeaderCell>
                    )}
                  </Table.Row>
                </Table.Header>

                {progressData.isLoading ? (
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Weight</Table.Cell>
                      <Table.Cell>
                        <Loader size="small" active inline />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Waist</Table.Cell>
                      <Table.Cell>
                        <Loader size="small" active inline />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Hips</Table.Cell>
                      <Table.Cell>
                        <Loader size="small" active inline />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Chest</Table.Cell>
                      <Table.Cell>
                        <Loader size="small" active inline />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Left Arm</Table.Cell>
                      <Table.Cell>
                        <Loader size="small" active inline />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Right Arm</Table.Cell>
                      <Table.Cell>
                        <Loader size="small" active inline />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Left Leg</Table.Cell>
                      <Table.Cell>
                        <Loader size="small" active inline />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Right Leg</Table.Cell>
                      <Table.Cell>
                        <Loader size="small" active inline />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Progress Pics</Table.Cell>
                      <Table.Cell>
                        <div className={classes.progressPics}>
                          <div>
                            <h3>Front</h3>
                            <Loader size="small" active inline />
                          </div>
                          <div>
                            <h3>Side</h3>
                            <Loader size="small" active inline />
                          </div>
                          <div>
                            <h3>Back</h3>
                            <Loader size="small" active inline />
                          </div>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                ) : tableData["progressDate"].length ? (
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Weight</Table.Cell>
                      {getTableCell("weight")}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Waist</Table.Cell>
                      {getTableCell("waist")}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Hips</Table.Cell>
                      {getTableCell("hips")}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Chest</Table.Cell>
                      {getTableCell("chest")}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Left Arm</Table.Cell>
                      {getTableCell("leftArm")}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Right Arm</Table.Cell>
                      {getTableCell("rightArm")}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Left Leg</Table.Cell>
                      {getTableCell("leftLeg")}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Right Leg</Table.Cell>
                      {getTableCell("rightLeg")}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Progress Pics</Table.Cell>
                      {getTableCell("images")}
                    </Table.Row>
                  </Table.Body>
                ) : (
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Weight</Table.Cell>
                      <Table.Cell rowSpan={9}>
                        {" "}
                        <Message
                          className={classes.intro}
                          icon="area graph"
                          header="Chart your progress"
                          content={
                            <p>
                              Begin by adding your progress by clicking the{" "}
                              <Icon
                                className={classes.add}
                                name="add"
                                circular
                              />{" "}
                              icon. Choose as many or as few measurements as you
                              want to track. Try to monitor your progress once a
                              week, or once a month. Avoid spending time
                              stepping on the scales every day as your weight
                              can fluctuate by large amounts which can be
                              demotivating and hamper your progress.
                            </p>
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Waist</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Hips</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Chest</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Left Arm</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Right Arm</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Left Leg</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Right Leg</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Progress Pics</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                )}

                <Table.Footer>
                  <Table.Row>
                    <Table.Cell>
                      <Modal
                        style={{ justifyContent: "center" }}
                        onClose={() => setAdd(false)}
                        onOpen={() => setAdd(true)}
                        open={add}
                        trigger={
                          <Icon className={classes.add} name="add" circular />
                        }
                      >
                        <Modal.Header>Add Progress</Modal.Header>
                        <Modal.Content image>
                          <Image
                            size="medium"
                            src={`${process.env.PUBLIC_URL}/img/progress.png`}
                            wrapped
                          />
                          <Modal.Description>
                            <Header>Track your progress</Header>
                            <p>
                              We recommend tracking your progress once every few
                              weeks and no more than once per week.
                            </p>

                            <Form className={classes.newForm}>
                              <Form.Field inline>
                                <label>Date</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                  />
                                </MuiPickersUtilsProvider>
                              </Form.Field>
                              <Form.Field inline>
                                <label>Weight ({weightUnit})</label>
                                <Input
                                  data-private
                                  value={data.weight}
                                  onChange={(e, v) =>
                                    updateMeasurement("weight", v.value)
                                  }
                                  placeholder="0.00"
                                />
                              </Form.Field>
                              <Form.Field inline>
                                <label>Waist ({measureUnit})</label>
                                <Input
                                  data-private
                                  value={data.waist}
                                  onChange={(e, v) =>
                                    updateMeasurement("waist", v.value)
                                  }
                                  placeholder="0.00"
                                />
                              </Form.Field>
                              <Form.Field inline>
                                <label>Hips ({measureUnit})</label>
                                <Input
                                  data-private
                                  value={data.hips}
                                  onChange={(e, v) =>
                                    updateMeasurement("hips", v.value)
                                  }
                                  placeholder="0.00"
                                />
                              </Form.Field>
                              <Form.Field inline>
                                <label>Chest ({measureUnit})</label>
                                <Input
                                  data-private
                                  value={data.chest}
                                  onChange={(e, v) =>
                                    updateMeasurement("chest", v.value)
                                  }
                                  placeholder="0.00"
                                />
                              </Form.Field>
                              <Form.Field inline>
                                <label>Left Arm ({measureUnit})</label>
                                <Input
                                  data-private
                                  value={data.leftArm}
                                  onChange={(e, v) =>
                                    updateMeasurement("leftArm", v.value)
                                  }
                                  placeholder="0.00"
                                />
                              </Form.Field>
                              <Form.Field inline>
                                <label>Right Arm ({measureUnit})</label>
                                <Input
                                  data-private
                                  value={data.rightArm}
                                  onChange={(e, v) =>
                                    updateMeasurement("rightArm", v.value)
                                  }
                                  placeholder="0.00"
                                />
                              </Form.Field>
                              <Form.Field inline>
                                <label>Left Leg ({measureUnit})</label>
                                <Input
                                  data-private
                                  value={data.leftLeg}
                                  onChange={(e, v) =>
                                    updateMeasurement("leftLeg", v.value)
                                  }
                                  placeholder="0.00"
                                />
                              </Form.Field>
                              <Form.Field inline>
                                <label>Right Leg ({measureUnit})</label>
                                <Input
                                  data-private
                                  value={data.rightLeg}
                                  onChange={(e, v) =>
                                    updateMeasurement("rightLeg", v.value)
                                  }
                                  placeholder="0.00"
                                />
                              </Form.Field>
                              <Form.Field
                                inline
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <label>Progress Pic Front</label>
                                <div
                                  data-private
                                  style={{ display: "inline-block" }}
                                >
                                  <SimpleImageUpload
                                    defaultImage={
                                      savedImages.front &&
                                      `${process.env.REACT_APP_API}/user_images/${savedImages.front}`
                                    }
                                    imageHandler={(imageFile) =>
                                      imageHandler("front", imageFile)
                                    }
                                    deleteImage={() => deleteImage("front")}
                                  />
                                </div>
                              </Form.Field>
                              <Form.Field inline>
                                <label>Progress Pic Side</label>
                                <div
                                  data-private
                                  style={{ display: "inline-block" }}
                                >
                                  <SimpleImageUpload
                                    defaultImage={
                                      savedImages.side &&
                                      `${process.env.REACT_APP_API}/user_images/${savedImages.side}`
                                    }
                                    imageHandler={(imageFile) =>
                                      imageHandler("side", imageFile)
                                    }
                                    deleteImage={() => deleteImage("side")}
                                  />
                                </div>
                              </Form.Field>
                              <Form.Field inline>
                                <label>Progress Pic Back</label>
                                <div
                                  data-private
                                  style={{ display: "inline-block" }}
                                >
                                  <SimpleImageUpload
                                    deleteImage={() => deleteImage("back")}
                                    defaultImage={
                                      savedImages.back &&
                                      `${process.env.REACT_APP_API}/user_images/${savedImages.back}`
                                    }
                                    imageHandler={(imageFile) =>
                                      imageHandler("back", imageFile)
                                    }
                                  />
                                </div>
                              </Form.Field>
                            </Form>
                          </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                          <Button
                            color="black"
                            onClick={() => {
                              setAdd(false);
                              startUpdate(null);
                              setData({ ...emptyData });
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            content="Track my progress"
                            labelPosition="right"
                            icon="checkmark"
                            onClick={() => saveProgress()}
                            positive
                          />
                        </Modal.Actions>
                      </Modal>
                    </Table.Cell>
                    {_.map(tableData.id, function (i) {
                      return (
                        <Table.Cell key={i}>
                          <Button
                            onClick={() => startUpdate(i)}
                            basic
                            icon
                            inverted
                          >
                            <Icon name="edit" />
                          </Button>
                          <Button
                            onClick={() => deleteProgress(i)}
                            basic
                            icon
                            inverted
                          >
                            <Icon name="trash" />
                          </Button>
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                </Table.Footer>
              </Table>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {graphWeightData.length && range ? (
              <Grid stackable>
                <Grid.Row align="left">
                  <Grid.Column>
                    <Header>Weight Chart</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <Button
                      size="mini"
                      icon
                      attached="left"
                      onClick={() =>
                        setRangeDate(addMonths(new Date(rangeDate), -1))
                      }
                    >
                      <Icon name="caret left" />
                    </Button>
                    <Input size="mini" value={format(rangeDate, "MMMM yyyy")} />
                    <Button
                      size="mini"
                      icon
                      attached="right"
                      onClick={() =>
                        setRangeDate(addMonths(new Date(rangeDate), 1))
                      }
                    >
                      <Icon name="caret right" />
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Menu compact size="mini">
                      <Menu.Item
                        name="editorials"
                        active={rangeLength === 1}
                        onClick={() => setRangeLength(1)}
                      >
                        1 Month
                      </Menu.Item>

                      <Menu.Item
                        onClick={() => setRangeLength(3)}
                        name="reviews"
                        active={rangeLength === 3}
                      >
                        3 Months
                      </Menu.Item>

                      <Menu.Item
                        onClick={() => setRangeLength(12)}
                        name="upcomingEvents"
                        active={rangeLength === 12}
                      >
                        1 Year
                      </Menu.Item>
                    </Menu>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Resizable>
                      <ChartContainer timeRange={range} format="%d">
                        <ChartRow>
                          <YAxis
                            id="weight"
                            label="Kg"
                            min={
                              series.length > 1
                                ? series2.min()
                                : series.min() - 10
                            }
                            max={
                              series.length > 1
                                ? series2.max()
                                : series.max() + 10
                            }
                            format=".2f"
                          />
                          <Charts>
                            <LineChart
                              axis="weight"
                              series={series}
                              style={style}
                              label="Weight"
                              interpolation="curveBasis"
                            />
                            <LineChart
                              label="Trend"
                              axis="weight"
                              style={style2}
                              series={series2}
                            />

                            {/* <Baseline
                          axis="weight"
                          style={baselineStyleLite}
                          value={series.max()}
                          label="Max"
                          position="right"
                        />
                        <Baseline
                          axis="weight"
                          style={baselineStyleLite}
                          value={series.min()}
                          label="Min"
                          position="right"
                        /> */}

                            <Baseline
                              axis="weight"
                              style={baselineStyle}
                              value={series.avg()}
                              label="Avg"
                            />
                          </Charts>
                        </ChartRow>
                      </ChartContainer>
                    </Resizable>{" "}
                    <Legend
                      type="line"
                      align="right"
                      style={legend}
                      categories={[
                        { key: "weight", label: "Weight" },
                        { key: "prediction", label: "Prediction" },
                      ]}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Modal
        open={errors ? true : false}
        header="Error!"
        centered
        content={<ErrorMessage />}
        actions={[
          {
            key: "done",
            content: "Ok",
            positive: true,
            onClick: () => setErrors(null),
          },
        ]}
      />
      <Confirm
        header="Delete progress"
        content="Are you sure you want to delete this progress record?"
        open={confirmDelete ? true : false}
        onCancel={() => deleteProgress(null)}
        onConfirm={() => deleteProgressConfirm()}
      />
    </Container>
  );
};

export default Progress;
