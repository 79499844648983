import React from "react";
import { connect } from "react-redux";
import Avatar from "react-avatar";

const ProfileImage = (props) => {
  const user = props.user;
  const size = props.size || 100;

  let image = props.user.options.profileImage || "tomato";

  if (image == "facebook" && user.provider) {
    return (
      <Avatar
        style={{
          border: "2px solid green",
          padding: "2px",
          boxSizing: "content-box",
        }}
        facebookId={user.provider_id}
        size={size}
        round
      />
    );
  } else {
    if (image == "user" && user.options.userImage) {
      return (
        <Avatar
          style={{
            border: "2px solid green",
            padding: "2px",
            boxSizing: "content-box",
          }}
          src={`${process.env.REACT_APP_API}/user_images/${user.options.userImage}`}
          name={`${user.first_name} ${user.last_name}`}
          size={size}
          round
        />
      );
    } else {
      return (
        <Avatar
          style={{
            border: "2px solid green",
            padding: "2px",
            boxSizing: "content-box",
          }}
          src={`${process.env.PUBLIC_URL}/avatars/${image}.png`}
          name={`${user.first_name} ${user.last_name}`}
          size={size}
          round
        />
      );
    }
  }
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ProfileImage);
