import React, { useState } from "react";
import {
  StreamApp,
  NotificationDropdown,
  FlatFeed,
  LikeButton,
  Activity,
  CommentField,
  CommentList,
  StatusUpdateForm,
  NotificationFeed,
  Notification,
} from "react-activity-feed";

import { connect } from "react-redux";

import "react-activity-feed/dist/index.css";
import { Container, Tab, Dropdown, Icon, Button } from "semantic-ui-react";
import Community from "./Community";
import classes from "./Stream.module.scss";

const Stream = (props) => {
  const [posting, setPosting] = useState(false);
  const panes = [
    {
      menuItem: "My Stream",
      render: () => (
        <Tab.Pane as="Container" attached={false}>
          {" "}
          <StreamApp
            apiKey="6juq67qbj4wj"
            appId="101136"
            token={localStorage.getItem("STREAM_TOKEN")}
          >
            {posting && (
              <StatusUpdateForm
                feedGroup="user_private"
                userId={props.auth.user.id}
              />
            )}
            <NotificationFeed
              userId={props.auth.user.id}
              feedGroup="timeline_aggregated"
            />

            <FlatFeed
              options={{ reactions: { recent: true } }}
              notify
              feedGroup="timeline_flat"
              Activity={(props) => (
                <Activity
                  {...props}
                  Footer={() => (
                    <div style={{ padding: "8px 16px" }}>
                      <LikeButton
                        {...props}
                        onToggleReaction={(kind, activity, data, options) => {
                          console.log(kind, activity, data, options);
                          let actorID = activity.actor.id;
                          let targetIDs = [];

                          targetIDs.push(`notification:${actorID}`);
                          targetIDs.push(
                            `timeline_aggregated:${props.auth.user.id}`
                          );

                          console.log(targetIDs);
                          props.onToggleReaction(kind, activity, data, {
                            ...options,
                            targetFeeds: targetIDs,
                          });
                        }}
                      />
                      <CommentField
                        activity={props.activity}
                        onAddReaction={(kind, activity, data, options) => {
                          console.log(kind, activity, data, options);
                          let actorID = activity.actor.id;
                          let targetIDs = [];

                          targetIDs.push(`notification:${actorID}`);
                          targetIDs.push(
                            `timeline_aggregated:${props.auth.user.id}`
                          );
                          console.log(targetIDs);
                          props.onAddReaction(kind, activity, data, {
                            ...options,
                            targetFeeds: targetIDs,
                          });
                        }}
                      />
                      <CommentList activityId={props.activity.id} />
                    </div>
                  )}
                />
              )}
            />
          </StreamApp>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Community Stream",
      render: () => (
        <Tab.Pane as="Container" attached={false}>
          <Community posting={posting} />
        </Tab.Pane>
      ),
    },
  ];
  const options = [
    {
      key: "web",
      content: (
        <Button fluid color="yellow">
          <Icon name="add" />
          New Post
        </Button>
      ),
      value: "web",
      onClick: () => setPosting(true),
    },
  ];
  return (
    <Container>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      <div className={classes.addButton}>
        <Dropdown
          icon={<Icon size="big" name="add" />}
          floating
          upward
          direction="left"
          className="button icon"
          options={options}
          trigger={<></>}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Stream);
