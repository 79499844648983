import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import classes from "../Home.module.scss";
import { Icon, Button } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDrumstickBite,
  faUtensils,
  faRunning,
} from "@fortawesome/free-solid-svg-icons";
import CustomIcon from "../../../components/CustomIcon";
import RecipeImage from "../../../components/recipes/recipeImage";
import { useNavigate } from "react-router-dom";
import { getRawWorkoutInfo } from "../../../utility/helper";

function iconSwitch(props) {
  switch (props.type) {
    case "meal":
      return <CustomIcon className={classes.placeholderIcon} icon="meal" />;
    case "custom":
      return (
        <CustomIcon className={classes.placeholderIcon} icon={props.icon} />
      );
    case "workout":
      return (
        <FontAwesomeIcon className={classes.placeholderIcon} icon={faRunning} />
      );
    default:
      return (
        <FontAwesomeIcon
          className={classes.placeholderIcon}
          icon={faUtensils}
        />
      );
  }
}

function openUrl(url) {
  window.open(url, "_blank");
}

const RecipeCard = (props) => {
  const checked = props.completed;
  const navigate = useNavigate();

  let checkingClass = null;

  if (!checked) {
    checkingClass = classes.unchecking;
  }
  if (checked) {
    checkingClass = classes.checked;
  }

  let disabledClass = null;
  if (props.disabled) {
    disabledClass = "disabled";
  }
  let workout;
  let placeholder = null;
  if (!props.image) {
    placeholder = classes.placeholder;
  }
  let subtype = null;
  let type = classes.recipe;
  if (props.type == "meal") {
    type = classes.meal;
  }

  if (props.type == "snack") {
    type = classes.snack;
  }
  if (props.type == "custom") {
    subtype = props.icon;
    if (subtype != "treat" && subtype != "eatout") {
      subtype = "customCard";
    }
    type = classes.custom;
  }
  if (props.type == "workout") {
    subtype = props.subtype;
    type = classes.workout;

    workout = getRawWorkoutInfo(props.data);
  }

  return (
    <>
      {props.type == "snack" ? (
        <div className={`${classes.Snack} ${disabledClass}`}>
          <div
            onClick={() =>
              props.hash && navigate("/recipes/view/" + props.hash)
            }
            className={classes.content}
          >
            {/* <h6>Snack</h6> */}
            <h5>{props.title}</h5>
          </div>
          <div className={`${classes.Options} ${checkingClass}`}>
            <div
              onClick={(e) => {
                if (props.disabled) {
                  return;
                }
                if (!checked) {
                  props.toggleCompleted(true);
                } else {
                  props.toggleCompleted(false);
                }
              }}
              className={classes.check}
            >
              <Icon name="check" />
            </div>
            <div
              className={classes.swap}
              onClick={() => {
                if (props.disabled) {
                  return;
                }
                props.swap();
              }}
              className={classes.swap}
            >
              Swap
            </div>
          </div>
        </div>
      ) : (
        <>
          {props.type == "workout" ? (
            <div
              className={`${classes.DaySegment} ${disabledClass} ${placeholder} ${type} ${subtype}`}
            >
              <div className={classes.workoutWrap}>
                <div className={classes.workoutTopRow}>
                  {workout.type == "icon" ? (
                    <div className={classes.Image}>
                      <CustomIcon
                        className={classes.placeholderIcon}
                        icon={workout.icon}
                      />
                    </div>
                  ) : (
                    <div className={classes.Image}>{iconSwitch(props)}</div>
                  )}
                  <div className={classes.Content}>
                    <div>
                      <h3>
                        {props.source == "bodycoach" &&
                          `Cycle ${workout.cycle.replace("C", "")} `}
                        {workout.name}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className={classes.workoutBottomRow}>
                  <div>
                    {props.data.video1_url || props.data.video2_url ? (
                      <Button.Group attached="bottom">
                        {props.data.video1_url ? (
                          <Button
                            basic
                            onClick={() => openUrl(props.data.video1_url)}
                          >
                            <Icon name="tv" />
                            {props.data.video1_title || "Video Link 1"}
                          </Button>
                        ) : null}
                        {props.data.video2_url ? (
                          <Button
                            basic
                            onClick={() => openUrl(props.data.video1_url)}
                          >
                            <Icon name="tv" />
                            {props.data.video2_title || "Video Link 2"}
                          </Button>
                        ) : null}
                      </Button.Group>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={`${classes.Options} ${checkingClass}`}>
                <div
                  className={classes.check}
                  onClick={() => {
                    if (props.disabled) {
                      return;
                    }
                    if (!checked) {
                      props.toggleCompleted(true);
                    } else {
                      props.toggleCompleted(false);
                    }
                  }}
                >
                  <p>
                    <Icon name="check" />
                  </p>
                </div>
                {/* <div
               onClick={() => {
                 if (props.disabled) {
                   return;
                 }
                 props.swap();
               }}
               className={classes.swap}
             >
               <p>Swap</p>
             </div> */}
              </div>
            </div>
          ) : (
            <div
              className={`${classes.DaySegment} ${disabledClass} ${placeholder} ${type} ${subtype}`}
            >
              <div
                className={classes.Image}
                onClick={() =>
                  props.hash && navigate("/recipes/view/" + props.hash)
                }
              >
                {props.image ? (
                  <RecipeImage
                    className="diaryImage"
                    image={props.image}
                    source={props.source}
                    source_method={props.source_method}
                  />
                ) : (
                  iconSwitch(props)
                )}
              </div>
              <div
                className={classes.Content}
                onClick={() =>
                  props.hash && navigate("/recipes/view/" + props.hash)
                }
              >
                <h5>{props.title}</h5>
              </div>
              <div className={`${classes.Options} ${checkingClass}`}>
                <div
                  className={classes.check}
                  onClick={() => {
                    if (props.disabled) {
                      return;
                    }
                    if (!checked) {
                      props.toggleCompleted(true);
                    } else {
                      props.toggleCompleted(false);
                    }
                  }}
                >
                  <p>
                    <Icon name="check" />
                  </p>
                </div>
                <div
                  onClick={() => {
                    if (props.disabled) {
                      return;
                    }
                    props.swap();
                  }}
                  className={classes.swap}
                >
                  <p>Swap</p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default RecipeCard;
