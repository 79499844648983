import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Icon, Label, Button } from "semantic-ui-react";
import classes from "./RecipeManager.module.scss";
import {
  addTag,
  applyTags,
  removeTags,
  applyCats,
  removeCats,
  removeRecipes,
} from "../../store/actions/recipeActions";
import {
  updateSelectedRecipes,
  clearSelected,
} from "../../store/actions/plannerActions";
import { TagButton } from "../../components/recipes/tag";
import { useToasts } from "react-toast-notifications";

import _ from "lodash";
const RecipeManager = (props) => {
  const [categoryContainer, showCategorieContainer] = useState(false);
  const [tagContainer, showTagContainer] = useState(false);
  const [deleteContainer, showDeleteContainer] = useState(false);

  const [tagsSelected, setTagsSelected] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const { addToast } = useToasts();

  useEffect(() => {
    props.clearSelected();
    addToast("Recipe Management Activated", {
      appearance: "info",
      autoDismiss: true,
    });

    if (window.navigator && window.navigator.vibrate) {
      window.navigator.vibrate(200);
      // Vibration API is supported
    }
  }, []);

  useEffect(() => {
    // get the enabled tags for each recipes and push to an array?
    if (showTagContainer) {
      let selectedTags = [];
      let selectedCategories = [];
      _.map(props.selected, function (curr) {
        const recipe = _.find(props.recipes, { id: curr });
        const tags = _.reduce(
          recipe.tagIDs,
          function (acc, curr) {
            return [...acc, curr];
          },
          []
        );
        selectedTags = [...selectedTags, ...tags];
      });
      const tagCount = _.countBy(selectedTags);
      const tagLength = props.selected.length;
      const tags = _.reduce(
        tagCount,
        function (t, curr, i) {
          if (curr == tagLength) {
            return [...t, i];
          } else {
            return t;
          }
        },
        []
      );
      setTagsSelected(tags);
    }
  }, [props.selected, props.recipes, tagContainer]);
  useEffect(() => {
    if (showCategorieContainer) {
      let selectedTags = [];
      let selectedCategories = [];
      _.map(props.selected, function (curr) {
        const recipe = _.find(props.recipes, { id: curr });
        const categories = _.reduce(
          recipe.categories,
          function (acc, curr) {
            if (curr.enabled) {
              return [...acc, curr.category_id];
            } else {
              return acc;
            }
          },
          []
        );
        selectedCategories = [...selectedCategories, ...categories];
      });
      const categoryCount = _.countBy(selectedCategories);
      const catLength = props.selected.length;
      const cats = _.reduce(
        categoryCount,
        function (t, curr, i) {
          if (curr == catLength) {
            return [...t, i];
          } else {
            return t;
          }
        },
        []
      );

      setCategoriesSelected(cats);
    }
  }, [props.selected, props.recipes, categoryContainer]);

  useEffect(() => {
    const userCats = _.filter(props.user_categories, { locked: "0" });
    setCategories([...props.default_categories, ...userCats]);
  }, [props.user_categories, props.default_categories]);

  const toggleTag = (id, state) => {
    if (state) {
      props.applyTags(props.selected, id);
    } else {
      props.removeTags(props.selected, id);
    }
  };

  const toggleCat = (id, state) => {
    if (state) {
      props.applyCats(props.selected, id);
    } else {
      props.removeCats(props.selected, id);
    }
  };

  const toggleRecipe = (id) => {
    const currentRecipes = [...props.selected];

    if (
      _.find(currentRecipes, function (o) {
        return o == id;
      })
    ) {
      _.remove(currentRecipes, function (el) {
        return el == id;
      });
    } else {
      currentRecipes.push(id);
    }

    props.updateSelectedRecipes(currentRecipes);
  };

  const removeRecipes = () => {
    props.removeRecipes(props.selected);
    props.clearSelected();
  };

  // const categories = _.reduce(
  //   recipe.categories,
  //   function (acc, curr) {
  //     if (curr.enabled) {
  //       return [...acc, curr.id];
  //     } else {
  //       return acc;
  //     }
  //   },
  //   []
  // );
  // selectedCategories = [...selectedCategories, ...categories];

  let tagWrapper = null;
  let catWrapper = null;
  let deleteWrapper = null;
  if (tagContainer) {
    tagWrapper = classes.open;
  }
  if (categoryContainer) {
    catWrapper = classes.open;
  }
  if (deleteContainer) {
    deleteWrapper = classes.open;
  }
  const tags = props.tags;
  return (
    <div className={classes.managerWrapper}>
      <div className={classes.managerContent}>
        <div className={classes.managerHeader}>
          <Label
            onClick={() => {
              props.clearSelected();
              props.close();
            }}
            style={{ marginBottom: "1.5em" }}
            color="grey"
          >
            Close
          </Label>
          <Label style={{ marginBottom: "1.5em" }} color="green">
            Recipe Manager Mode
          </Label>
        </div>
        <div className={classes.managerActions}>
          <div className={`${classes.tagWrapper} ${catWrapper}`}>
            <Icon
              size="big"
              circular
              inverted
              color="green"
              name="folder open outline"
              onClick={() => showCategorieContainer(!categoryContainer)}
            />
            <div className={classes.tagContainer}>
              <h4>Add/Remove Categories</h4>
              <div className={classes.tags}>
                {_.map(categories, function (c) {
                  const toggled =
                    categoriesSelected.indexOf(c.id) > -1 ? true : false;
                  return (
                    <TagButton
                      toggleCategory={() => toggleCat(c.id, !toggled)}
                      toggle={toggled}
                      label={c.category_name}
                    />
                  );
                })}
              </div>
            </div>
          </div>

          <div className={`${classes.tagWrapper} ${tagWrapper}`}>
            <Icon
              size="big"
              circular
              inverted
              color="teal"
              name="tags"
              onClick={() => showTagContainer(!tagContainer)}
            />
            <div className={classes.tagContainer}>
              <h4>Add/Remove Tags</h4>
              <div className={classes.tags}>
                {_.map(tags, function (t) {
                  const toggled =
                    tagsSelected.indexOf(t.id) > -1 ? true : false;
                  return (
                    <TagButton
                      toggleCategory={() => toggleTag(t.id, !toggled)}
                      toggle={toggled}
                      label={t.tag_name}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className={`${classes.tagWrapper} ${deleteWrapper}`}>
            <Icon
              size="big"
              circular
              inverted
              color="red"
              name="trash"
              onClick={() => showDeleteContainer(!deleteContainer)}
            />
            <div className={classes.tagContainer}>
              <h4>Archive Recipes</h4>
              <div className={classes.tags}>
                {props.selected.length < 1 ? (
                  <h4>Select some recipes to archive</h4>
                ) : (
                  <>
                    <Button
                      style={{ width: "100%", marginBottom: "0.5em" }}
                      size="small"
                      color="red"
                      onClick={() => removeRecipes()}
                    >
                      Archive
                    </Button>
                    {_.map(props.selected, function (id) {
                      const recipe = _.find(props.recipes, { id: id });
                      if (recipe) {
                        return (
                          <Label
                            key={`delete-${id}`}
                            style={{ marginBottom: "0.5em" }}
                            color="grey"
                          >
                            {recipe.name}
                            <Icon
                              name="delete"
                              onClick={() => toggleRecipe(id)}
                            />
                          </Label>
                        );
                      }
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tags: state.recipes.tags,
    selected: state.planner.selectedRecipes,
    recipes: state.recipes.recipes,
    default_categories: state.recipes.default_categories,
    user_categories: state.recipes.user_categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTag: (payload) => dispatch(addTag(payload)),
    updateSelectedRecipes: (payload) =>
      dispatch(updateSelectedRecipes(payload)),
    clearSelected: () => dispatch(clearSelected()),
    applyTags: (recipes, tag) => dispatch(applyTags(recipes, tag)),
    applyCats: (recipes, tag) => dispatch(applyCats(recipes, tag)),
    removeCats: (recipes, tag) => dispatch(removeCats(recipes, tag)),
    removeTags: (recipes, tag) => dispatch(removeTags(recipes, tag)),
    removeRecipes: (recipes) => dispatch(removeRecipes(recipes)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipeManager);
