import React, { useEffect } from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import classes from "./Screens.module.scss";
import Container from "../UI/ContainerBox";
import RecipesHome from "./Recipes/RecipesHome";
import RecipeDetails from "../screens/Recipes/RecipeDetails";
import RecipeEdit from "../screens/Recipes/RecipeEdit";
import RecipeCreate from "./Recipes/RecipeCreate";
import RecipeWeb from "./Recipes/RecipeWeb";
import RecipePDF from "./Recipes/RecipePDF";

import { getRecipes } from "../store/actions/recipeActions";
import { connect, useDispatch } from "react-redux";
const Recipes = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    !props.recipes.recipesLoaded && dispatch(getRecipes());
  }, []);
  return (
    <>
      <Routes>
        <Route path={`create`} element={<RecipeCreate />} />
        <Route path={`web`} element={<RecipeWeb />} />
        <Route path={`pdf`} element={<RecipePDF />} />
        <Route path={`edit/:id`} element={<RecipeEdit />} />
        <Route path={`view/:id`} element={<RecipeDetails />} />
        <Route path="/*" element={<RecipesHome />} />
      </Routes>
      <Outlet />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRecipes: () => dispatch(getRecipes()),
  };
};
const mapStateToProps = (state) => {
  return {
    recipes: state.recipes,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Recipes);
