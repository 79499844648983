import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Button } from "semantic-ui-react";

const Categories = (props) => {
  const [categories, setCategories] = useState(null);
  useEffect(() => {
    if (props.action == "create") {
      const cats = _.reduce(
        props.categories,
        function (acc, curr) {
          delete curr.id;
          return [...acc, curr];
        },
        []
      );
      setCategories(cats);
    } else {
      setCategories(props.categories);
    }
  }, [props.categories]);

  const toggleCategory = (category) => {
    let categoryCopy = [...categories];
    let updatedCategory = _.find(categoryCopy, {
      category_name: category,
    });
    updatedCategory.enabled = !updatedCategory.enabled;
    let type = "general";
    if (updatedCategory.category_group == "type" && updatedCategory.enabled) {
      _.map(categoryCopy, function (c, i) {
        let category_id = c.category_id;
        let updated_id = updatedCategory.category_id;
        if (c.category_group == "type" && category_id != updated_id) {
          categoryCopy[i].enabled = false;
        } else {
          switch (c.category_name) {
            case "reduced carb":
              type = "reduced";
              break;
            case "high carb":
              type = "refuel";
              break;
            case "snack":
              type = "snack";
              break;
          }
        }
      });
    }
    props.updateType(type);
    props.updateCategories(categoryCopy);
  };

  if (categories) {
    return categories.map(function (c, index) {
      if (c.locked == 0) {
        return (
          <Button
            key={c.category_name}
            onClick={(e) => {
              e.preventDefault();
              toggleCategory(c.category_name);
            }}
            color="green"
            size="tiny"
            fluid
            compact
            basic={!c.enabled}
          >
            {c.category_name}
          </Button>
        );
      }
    });
  } else {
    return null;
  }
};

export default Categories;
