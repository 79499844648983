import React, { useState } from "react";
import { getISODay, formatDate, parseDate } from "../Planner/dateHelper";

import axios from "../../api/axios-token";
import { useQuery, useQueryClient } from "react-query";
import { Feed, Statistic, Header, Placeholder } from "semantic-ui-react";

const api = (type) => {
  const getWater = () =>
    axios
      .get("/profile/water_stats")
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  const getHighestDay = () =>
    axios
      .get("/profile/highest_streak")
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  const getCurrentLogStreak = () =>
    axios
      .get("/profile/current_log_streak")
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  const getMealStats = () =>
    axios
      .get("/profile/meal_stats")
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

  const getMaxLogStreak = () =>
    axios
      .get("/profile/highest_log_streak")
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

  switch (type) {
    case "water":
      return getWater;
    case "highest_day_streak":
      return getHighestDay;
    case "log_streak":
      return getCurrentLogStreak;
    case "highest_log_streak":
      return getMaxLogStreak;
    case "meal_stats":
      return getMealStats;
  }
};

const Content = ({ type, minimal }) => {
  const queryClient = new useQueryClient();
  let contentData = {
    label: (
      <Placeholder.Header>
        <Placeholder.Line />
      </Placeholder.Header>
    ),
    text: (
      <Placeholder>
        {" "}
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder>
    ),
    image: (
      <Placeholder style={{ height: 150, width: 150 }}>
        <Placeholder.Image />
      </Placeholder>
    ),
  };
  const feed = useQuery(type, api(type));
  let quantity,
    quantityText,
    text,
    date,
    title,
    label,
    unit,
    day,
    currDay,
    caption,
    dateFormat;
  if (feed.isSuccess && feed.data) {
    switch (type) {
      case "water":
        quantity = feed.data.streak;
        if (quantity >= 1000) {
          quantity = Math.round(quantity / 1000, 1);
          unit = "litres";
          quantityText = " " + unit;
        } else {
          quantity = Math.round(quantity);
          unit = "ml";
          quantityText = " " + unit;
        }
        text = `You currently average ${quantityText} per day.`;
        if (feed.data.streak === null) {
          text =
            "Once you start logging your water, your average will appear here.";
        }
        title = "Water Average";
        label = (
          <Statistic size="mini">
            <Statistic.Value>{quantity}</Statistic.Value>
            <Statistic.Label>{unit}</Statistic.Label>
          </Statistic>
        );
        contentData = { text, title, label };
        break;
      case "meal_stats":
        if (feed.data.streak === null) {
          quantity = 0;
        } else {
          quantity = feed.data.streak;
        }
        day = "meal";
        if (quantity > 1) {
          day = "meals";
        }
        caption = "Meals Eaten";
        text = `You have logged ${quantity} ${day}.`;
        title = "Meals Logged";
        if (feed.data.streak === null) {
          text = "You haven't logged any meals yet.";
        }
        label = (
          <Statistic size="mini">
            <Statistic.Value>{quantity}</Statistic.Value>
            <Statistic.Label>Meals Eaten</Statistic.Label>
          </Statistic>
        );
        contentData = { text, title, label };
        break;
      case "log_streak":
        if (feed.data.streak === null) {
          quantity = 0;
        } else {
          quantity = feed.data.streak.streak;
        }
        day = "day";
        if (quantity > 1) {
          day = "days";
        }
        text = `You have logged meals for ${quantity} ${day} in a row.`;
        title = "Highest Log Streak";
        if (feed.data.streak === null) {
          text = "You haven't tracked any meals yet.";
        }
        label = (
          <Statistic size="mini">
            <Statistic.Value>{quantity}</Statistic.Value>
            <Statistic.Label>{day}</Statistic.Label>
          </Statistic>
        );
        contentData = { text, title, label };
        break;
      case "highest_day_streak":
        if (feed.data.streak === null) {
          contentData = null;
          break;
        }
        quantity = feed.data.streak.quantity;
        date = feed.data.streak.date;
        currDay = getISODay(new Date());

        dateFormat = formatDate(parseDate(date));
        caption = minimal ? "Longest Streak" : null;
        day = "day";
        if (quantity > 1) {
          day = "days";
        }
        if (currDay == date) {
          text = `You current streak of ${quantity} ${day} is your current record!`;
        } else {
          text = `Your highest day streak was ${quantity} ${day}, ending on ${dateFormat}`;
        }
        title = "Day Record";
        label = (
          <Statistic size="mini">
            <Statistic.Value>{`${quantity}${
              minimal ? " " + day : ""
            }`}</Statistic.Value>
            <Statistic.Label>{caption || day}</Statistic.Label>
          </Statistic>
        );
        contentData = { text, title, label, caption };
        break;
      case "highest_log_streak":
        if (feed.data.streak === null) {
          contentData = null;
          break;
        }

        quantity = feed.data.streak.streak;
        date = feed.data.streak.date;
        currDay = getISODay(new Date());
        dateFormat = formatDate(parseDate(date));

        day = "day";
        if (quantity > 1) {
          day = "days";
        }
        if (currDay == date) {
          text = `You have tracked food for ${quantity} ${day}. This is your current record. `;
        }
        text = `Your highest food tracking streak was ${quantity} ${day}, ending on ${dateFormat}`;
        title = "Diary Streak Record";

        label = (
          <Statistic size="mini">
            <Statistic.Value>{quantity}</Statistic.Value>
            <Statistic.Label>{caption || day}</Statistic.Label>
          </Statistic>
        );
        contentData = { text, title, label };
    }
  }

  return (
    <>
      {contentData ? (
        <>
          {minimal ? (
            <>{contentData.label}</>
          ) : (
            <Feed.Event>
              {contentData.label && (
                <Feed.Label
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  {contentData.label}
                </Feed.Label>
              )}
              <Feed.Content style={{ marginTop: 0 }}>
                <Header as="h5" style={{ margin: "0.2em 0" }}>
                  {contentData.title}
                </Header>
                <Feed.Content>{contentData.text}</Feed.Content>
              </Feed.Content>
            </Feed.Event>
          )}
        </>
      ) : null}
    </>
  );
};

export default Content;
