import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import discover from "./Discover.module.scss";
import { round } from "../../../utility/helper";
import _ from "lodash";

const SimpleCalorieBar = (props) => {
  let protein,
    carbs,
    fat,
    calories,
    caloriePercent,
    carbsPercent,
    fatPercent,
    proteinPercent,
    display = null;

  if (props.macroTotals) {
    calories = round(props.macroTotals.calories, 0);
    protein = round(props.macroTotals.protein, 0);
    carbs = round(props.macroTotals.carbs, 0);
    fat = round(props.macroTotals.fat, 0);

    if (props.macroTotals.proteinPercent) {
      proteinPercent = round(props.macroTotals.proteinPercent, 0);
      carbsPercent = round(props.macroTotals.carbsPercent, 0);
      fatPercent = round(props.macroTotals.fatPercent, 0);
      caloriePercent = round(props.macroTotals.caloriePercent, 0);
    } else {
      let calorieTarget;
      if (props.target) {
        calorieTarget = props.target;
      }

      proteinPercent = round(((protein * 4) / calories) * 100, 0);
      carbsPercent = round(((carbs * 4) / calories) * 100, 0);
      fatPercent = round(((fat * 8) / calories) * 100, 0);
      caloriePercent = round((calories / calorieTarget) * 100, 0);
    }
  } else {
    let newTotals;
    let calorieTarget;
    if (props.tdee && props.tdee.dailyTarget) {
      calorieTarget = props.tdee.dailyTarget;
    }
    if (props.recipes) {
      const macroTotals = props.recipes.filter(function (recipe) {
        return !Number.isNaN(recipe.calories);
      });

      // console.log(macroTotals);
      if (macroTotals.length > 0) {
        newTotals = {
          calories: _.sumBy(macroTotals, "calories"),
          protein: _.sumBy(macroTotals, "protein"),
          carbs: _.sumBy(macroTotals, "carbs"),
          fat: _.sumBy(macroTotals, "fat"),
        };

        newTotals.proteinPercent = round(
          ((newTotals.protein * 4) / newTotals.calories) * 100,
          0
        );
        newTotals.carbsPercent = round(
          ((newTotals.carbs * 4) / newTotals.calories) * 100,
          0
        );
        newTotals.fatPercent = round(
          ((newTotals.fat * 8) / newTotals.calories) * 100,
          0
        );
        if (calorieTarget) {
          newTotals.caloriePercent = round(
            (newTotals.calories / calorieTarget) * 100,
            0
          );
        } else {
          newTotals.caloriePercent = "--";
        }
        newTotals.calories = round(newTotals.calories, 0);
        newTotals.protein = round(newTotals.protein, 0);
        newTotals.carbs = round(newTotals.carbs, 0);
        newTotals.fat = round(newTotals.fat, 0);
        newTotals.proteinPercent = round(newTotals.proteinPercent, 0);
        newTotals.carbsPercent = round(newTotals.carbsPercent, 0);
        newTotals.fatPercent = round(newTotals.fatPercent, 0);
      } else {
        const emptyCal = <span>--&nbsp;</span>;
        newTotals = {
          calories: emptyCal,
          protein: emptyCal,
          carbs: emptyCal,
          fat: emptyCal,
          proteinPercent: 0,
          carbsPercent: 0,
          fatPercent: 0,
          caloriePercent: 0,
        };
      }
    } else {
      const emptyCal = <span>--&nbsp;</span>;
      newTotals = {
        calories: emptyCal,
        protein: emptyCal,
        carbs: emptyCal,
        fat: emptyCal,
        proteinPercent: 0,
        carbsPercent: 0,
        fatPercent: 0,
        caloriePercent: 0,
      };
    }
    calories = newTotals.calories;
    protein = newTotals.protein;
    carbs = newTotals.carbs;
    fat = newTotals.fat;
    proteinPercent = newTotals.proteinPercent;
    carbsPercent = newTotals.carbsPercent;
    fatPercent = newTotals.fatPercent;
    caloriePercent = newTotals.caloriePercent;
  }
  let lightGreen = `#c2ecbf`;
  let green = "#47c73c";
  let orange = "#fdc117";
  let lightOrange = "#ffe9ab";

  let primaryColor = green;
  let lightColor = lightGreen;
  let textColor = green;
  if (props.color == "orange") {
    primaryColor = orange;
    lightColor = lightOrange;
  }
  if (props.color == "white") {
    primaryColor = "#ffffff";
    lightColor = "#cccccc";
  }
  if (props.color == "vividGreen") {
    primaryColor = "#00ff14";
    lightColor = "#bcfdba";
  }

  if (props.textColor == "white") {
    textColor = "#ffffff";
  }

  const macroStyles = buildStyles({
    // Rotation of path and trail, in number of turns (0-1)
    rotation: 0.65,

    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    strokeLinecap: "round",
    // Can specify path transition in more detail, or remove it entirely
    // pathTransition: 'none',
    textColor: textColor,
    // Colors
    pathColor: primaryColor,

    trailColor: lightColor,
  });

  return (
    <>
      <ul className={discover.Macros} style={props.style}>
        <li>
          <CircularProgressbar
            strokeWidth={12}
            value={proteinPercent}
            text={`${proteinPercent}%`}
            styles={macroStyles}
          />
          <span>{`${protein}g protein`}</span>
        </li>
        <li>
          <CircularProgressbar
            strokeWidth={12}
            value={carbsPercent}
            text={`${carbsPercent}%`}
            styles={macroStyles}
          />
          <span>{`${carbs}g carbs`}</span>
        </li>

        <li>
          <CircularProgressbar
            strokeWidth={12}
            value={fatPercent}
            text={`${fatPercent}%`}
            styles={macroStyles}
          />
          <span>{`${fat}g fat`}</span>
        </li>

        <li>
          <CircularProgressbar
            strokeWidth={12}
            value={caloriePercent}
            text={`${caloriePercent}%`}
            styles={macroStyles}
          />
          <span>{`${calories} kcal`}</span>
        </li>
      </ul>
    </>
  );
};

export default SimpleCalorieBar;
