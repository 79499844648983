import React from "react";
import "html5-device-mockups";

const DeviceWrapper = (props) => {
  const topPadding = props.topPadding || 0;
  return (
    <div className="device-wrapper">
      <div
        className="device"
        data-device={props.device}
        data-orientation={props.orientation}
        data-color={props.color}
      >
        <div className="screen" style={{ paddingTop: topPadding }}>
          <video autoPlay playsInline muted loop poster={props.poster}>
            <source src={props.source} type={props.type} />
          </video>
        </div>
        <div className="button"></div>
      </div>
    </div>
  );
};

export default DeviceWrapper;
