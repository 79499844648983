import React, { useState, useEffect } from "react";
import { useInfiniteQuery } from "react-query";
import axios from "../../../api/axios-token";
import { Header, Loader } from "semantic-ui-react";
import RecipePlaceholder from "../../../components/recipes/RecipePlaceholder";
import VerticalCard from "../components/VerticalCard";
import discover from "./Discover.module.scss";
import Slider from "react-slick";
import InfiniteScroll from "react-infinite-scroller";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Browse = (props) => {
  const [recipeData, setRecipeData] = useState([]);
  const fetchRecipes = (key, cursor = 0) =>
    axios
      .post("/discover/get_recipes", {
        category: props.category,
        cursor: cursor,
      })
      .then((res) => {
        return res.data;
      });
  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchNextPage,
    refetch,
    hasNextPage,
    error,
  } = useInfiniteQuery("recipes", fetchRecipes, {
    getNextPageParam: (lastGroup, allGroups) => lastGroup.nextCursor,
  });

  useEffect(() => {
    const newData = [];

    if (data) {
      data.pages.map(function (d) {
        d.recipes.map(function (recipe) {
          newData.push(recipe);
        });
      });
      setRecipeData([...newData]);
    }
  }, [data]);
  let slides = recipeData.length;

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: slides < 5 ? slides : 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slides < 4 ? slides : 4,
          slidesToScroll: slides < 4 ? slides : 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: slides < 3 ? slides : 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: slides < 2 ? slides : 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return status === "loading" ? (
    <p>Loading...</p>
  ) : status === "error" ? (
    <p>Error: {error.message}</p>
  ) : (
    <>
      <Header as="h3">Latest Recipes</Header>
      {recipeData.length ? (
        // <Slider {...settings} className={discover.Row}>
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchNextPage}
          hasMore={hasNextPage}
          loader={<Loader key={"infinite-loader"} active inline="centered" />}
        >
          <>
            {recipeData.map((recipe) => {
              console.log(recipe);
              return (
                <div
                  onClick={() => {
                    props.history(
                      "/recipes/discover/view/" + recipe.hash + "/edit"
                    );
                  }}
                >
                  <VerticalCard
                    key={recipe.hash}
                    id={recipe.hash}
                    recipeName={recipe.name}
                    recipeImage={recipe.image}
                    recipeSource={recipe.source}
                    recipeSourceMethod={recipe.source_method}
                    recipeTags={recipe.tagIDs}
                    recipeCategories={recipe.categories}
                    type={recipe.type}
                    user={recipe.user}
                  />
                </div>
              );
            })}
          </>
        </InfiniteScroll>
      ) : // </Slider>
      null}
    </>
  );
};

export default Browse;
