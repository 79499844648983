import React, { useState, useEffect, useLayoutEffect } from "react";
import { getISODay, isSameDay, dateDifference } from "../Planner/dateHelper";
import { Link } from "react-router-dom";
import classes from "./Home.module.scss";
import { connect } from "react-redux";
import _ from "lodash";
import {
  getRecipes,
  resetRecipeFilters,
} from "../../store/actions/recipeActions";
import HomeCard from "./components/RecipeCard";
import { Message, Modal, Button, Icon, Container } from "semantic-ui-react";
import { round, getWaterTarget, parseCustomCard } from "../../utility/helper";
import { getRecipeMacros } from "../../utility/macros";
import { updateXp } from "../../store/actions/xpActions";
import useMousePosition from "../../utility/useMousePosition";
import { clearSelected } from "../../store/actions/plannerActions";
import {
  updateDiary,
  updateDiarySegment,
  replaceRecipe,
} from "../../store/actions/diaryActions";
import DayPlan from "./DayPlan";

import { differenceInCalendarDays } from "date-fns";

import SwitchBrowser from "./SwitchBrowser";
import SimpleCalorieBar from "../Recipes/Discover/SimpleCalorieBar";
import PlaceHolderCard from "./components/PlaceHolderCard";
import amplitude from "amplitude-js";

const HomeDay = (props) => {
  const [dateFormatted, setDateFormatted] = useState(null);
  const [recipeData, setRecipeData] = useState(null);
  const [workoutData, setWorkoutData] = useState(null);
  const [nutritionData, setNutritionData] = useState(null);
  const [snackData, setSnackData] = useState(null);
  const [water, setWater] = useState(0);
  const [waterWidth, setWaterWidth] = useState(0);
  const [emptyText, setemptyText] = useState(
    "Looks like you didn't plan for this day."
  );
  const [isactiveDay, setIsactiveDay] = useState(false);
  const [disableDay, setDisableDay] = useState(false);
  const [stateLoaded, setStateLoaded] = useState(false);
  const [switchModal, setSwitchModal] = useState(false);
  const [switchID, setSwitchID] = useState(null);
  const [switchType, setSwitchType] = useState(null);
  const [waterTarget, setWaterTarget] = useState(null);
  const [waterStep, setWaterStep] = useState(null);

  //const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const mousePosition = useMousePosition();

  if (!stateLoaded) {
    if (props.recipeState.recipeDetailsLoaded) {
      setStateLoaded(true);
    }
  }

  useEffect(() => {
    if (props.water) {
      setWaterTarget(props.water.target);
    } else {
      if (props.tdee && props.tdee.dailyTarget) {
        const watertarget = getWaterTarget(
          props.tdee.dailyTarget,
          props.tdee.exercise,
          props.tdee.lifestyle
        );
        setWaterTarget(watertarget);
      } else {
        setWaterTarget(props.defaultWater);
      }
    }

    if (props.water) {
      setWaterStep(props.water.step);
    } else {
      setWaterStep(props.defaultWaterStep);
    }
  }, []);

  useLayoutEffect(() => {
    if (props.activeDay && stateLoaded) {
      const data = _.find(props.planner, {
        date: parseInt(getISODay(props.activeDay)),
      });
      const recipes = [];
      const currDay = new Date();
      const workouts = [];
      let customCalories = 0;

      const waterProps = _.reduce(
        props.diary,
        function (a, c) {
          if (c.date == getISODay(props.activeDay) && c.type == "water") {
            return a + parseInt(c.quantity);
          } else {
            return a;
          }
        },
        0
      );
      if (waterProps) {
        setWater(waterProps);
        const newWidth = (waterProps / waterTarget) * 100;
        setWaterWidth(newWidth);
      } else {
        setWater(0);
        setWaterWidth(0);
      }

      let disabled = false;
      if (getISODay(currDay) != getISODay(props.activeDay)) {
        setIsactiveDay(false);
        if (dateDifference(props.activeDay, currDay) < -7) {
          disabled = true;
        }
        if (dateDifference(props.activeDay, currDay) >= 1) {
          disabled = true;
        }
      } else {
        setIsactiveDay(true);
      }

      setDisableDay(disabled);

      if (data) {
        _.map(data.data, function (r) {
          const diary_check = _.find(props.diary, function (diary) {
            if (
              diary.planner_uuid === r.uuid &&
              (diary.type == "meal" ||
                diary.type == "recipe" ||
                diary.type == "snack" ||
                diary.type == "custom") &&
              diary.replacement_id !== null
            ) {
              return true;
            }
          });

          if (diary_check) {
            r.data = diary_check.replacement_id;
            r.type = diary_check.replacement_type;
            if (
              diary_check.replacement_type == "custom" &&
              diary_check.replacement_id == 0
            ) {
              r.optional_data = diary_check.optional_data;
            }
          }

          if (r.type === "recipe" || r.type == "meal" || r.type == "snack") {
            const recipe = _.find(props.recipes, { id: r.data });
            if (recipe) {
              recipe.segment_order = r.segment_order;
              recipe.planner_id = r.uuid;
              recipe.diary = _.find(props.diary, { planner_uuid: r.uuid });

              recipes.push({ ...recipe });
            }
          }
          if (r.type === "custom") {
            let custom;

            if (parseInt(r.data) > 0) {
              custom = _.find(props.cards, { id: parseInt(r.data) });
            } else {
              custom = parseCustomCard(r.optional_data);
            }

            if (custom) {
              custom.planner_id = r.uuid;
              custom.segment_order = r.segment_order;
              custom.diary = _.find(props.diary, { planner_uuid: r.uuid });
              customCalories += custom.assumedCalories;
              recipes.push({ ...custom });
            }
          }

          if (r.type == "workout") {
            const workout = _.find(props.workouts, { id: r.data });
            if (workout) {
              workout.planner_id = r.uuid;
              workout.diary = _.find(props.diary, { planner_uuid: r.uuid });
              workouts.push({ ...workout });
            }
          }
        });
      }
      setNutritionData(getRecipeMacros([...recipes], props.recipeState));
      const snacks = _.remove(recipes, {
        categories: [{ category_name: "snack", enabled: true }],
      });

      setRecipeData(recipes);
      setSnackData(snacks);

      setWorkoutData(workouts);
    }
  }, [props.activeDay, props.diary, props.planner]);

  useEffect(() => {
    const dayCheck = _.find(props.diary, {
      type: "day_bonus",
      completed: 1,
      date: parseInt(getISODay(props.activeDay)),
    });

    if (!dayCheck) {
      const completeCheck = _.reduce(
        props.diary,
        function (a, c) {
          if (
            c.completed &&
            c.date === parseInt(getISODay(props.activeDay)) &&
            c.type != "snack" &&
            c.type != "streak" &&
            c.type != "water"
          ) {
            return [...a, c];
          } else {
            return a;
          }
        },
        []
      );

      if (
        recipeData &&
        workoutData &&
        recipeData.length > 2 &&
        completeCheck.length == recipeData.length + workoutData.length
      ) {
        const dayCompleteObject = {
          date: parseInt(getISODay(props.activeDay)),
          type: "day_bonus",
          replacement_id: null,
          calories: null,
          protein: null,
          carbs: null,
          fat: null,
          quantity: null,
          planner_uuid: null,
          completed: 1,
        };
        props.updateXp({
          type: "star",
          amount: 10,
        });
        props.updateDiary(dayCompleteObject);
        amplitude.getInstance().logEvent("Completed Day");
      }
    }
  }, [props.diary]);

  function initSwap(planner_id) {
    //alert(planner_id);
    props.resetRecipeFilters();
    props.clearSelected();
    setSwitchType("main");
    setSwitchID(planner_id);
    setSwitchModal(true);
  }

  function initSwapSnack(planner_id) {
    props.clearSelected();
    props.resetRecipeFilters("snack");
    setSwitchType("snacks");
    setSwitchID(planner_id);
    setSwitchModal(true);
  }

  function switchRecipe() {
    setSwitchModal(false);

    let copyObject;
    let id;
    let type;

    if (props.selectedRecipes.length) {
      id = props.selectedRecipes[0];

      copyObject = _.find(props.recipes, { id: props.selectedRecipes[0] });

      switch (copyObject.type) {
        case "meal":
          type = "meal";
          break;
        case "snack":
          type = "snack";
          break;

        default:
          type = "recipe";
      }
    }

    if (copyObject && copyObject.type == "snack" && switchType != "snacks") {
      return false;
    }
    let data;

    if (props.selectedCards.length) {
      type = "custom";
      id = props.selectedCards[0];

      if (id.toString().includes("custom")) {
        data = _.find(props.cards, { id: id });
        console.log(data);
        id = 0;
        copyObject = true;
      } else {
        copyObject = _.find(props.cards, { id: props.selectedCards[0] });
      }
    }

    if (copyObject && switchID) {
      props.replaceRecipe(
        switchID,
        id,
        type,
        parseInt(getISODay(props.activeDay)),
        data
      );
      setSwitchID(false);
      setSwitchType(null);
      amplitude.getInstance().logEvent("Switched Recipe");
    }
    props.clearSelected();
  }

  function markCompleted(uuid, completed, diary, type) {
    if (completed) {
      props.updateXp({
        type: "diary",
        amount: 25,
        x: mousePosition.x,
        y: mousePosition.y,
      });
      amplitude.getInstance().logEvent("Completed Diary Segment");
    } else {
      props.updateXp({
        type: "diary",
        amount: -25,
      });
      amplitude.getInstance().logEvent("Undo Diary Segment");
    }

    const currDay = _.find(props.planner, {
      date: parseInt(getISODay(props.activeDay)),
    });

    let segment;
    let replacement_id = null;
    let replacement_type = null;
    let diaryData = null;
    if (!diary) {
      segment = _.find(currDay.data, { uuid: uuid });
    } else {
      // rewrite JSON again if needed?

      if (diary.data) {
        try {
          diaryData = JSON.parse(diary.data);
        } catch (e) {
          diaryData = diary.data;
        }
      }

      segment = {
        data: diary.replacement_id,
        type: diary.replacement_type || diary.type,
      };
      replacement_id = diary.replacement_id;
      replacement_type = diary.replacement_type;
    }

    if (type == "snack") {
      segment.type = "snack";
    }
    if (type == "workout") {
      segment.type = "workout";
    }

    const diaryCopy = diary;
    if (diaryCopy) {
      delete diaryCopy.date_updated;
      delete diaryCopy.userid;
    }
    let diaryItem = {
      ...diaryCopy,
      date: parseInt(getISODay(props.activeDay)),
      planner_uuid: uuid,
      replacement_id: replacement_id,
      replacement_type: replacement_type,
      type: segment.type,
      completed: completed ? 1 : 0,
      quantity: 0,
      data: diaryData,
    };
    let recipe_data = null;
    let macros = null;

    // if its a food get the macros..
    if (
      segment.type == "recipe" ||
      segment.type == "snack" ||
      segment.type == "meal"
    ) {
      if (segment.data) {
        macros = getRecipeMacros([{ id: segment.data }], props.recipeState);
        recipe_data = segment.data;
        diaryItem = {
          ...diaryItem,
          data: { recipe_id: recipe_data },
        };
      }
    }

    if (macros && macros[0]) {
      diaryItem = {
        ...diaryItem,
        calories: macros[0].calories,
        protein: macros[0].protein,
        carbs: macros[0].carbs,
        fat: macros[0].fat,
      };
    }

    if (segment.type == "custom") {
      let segment_data = segment.optional_data;
      let customCarddata;
      if (diary && diary.optional_data) {
        try {
          customCarddata = JSON.parse(diary.optional_data);
        } catch (e) {
          customCarddata = diary.optional_data;
        }
      }
      if (!segment_data) {
        if (segment.data == 1) {
          segment_data = "treat_meal";
        } else {
          if (segment.data == 2) {
            segment_data = "ate_out";
          } else {
            segment_data = "custom_card";
          }
        }
      } else {
        try {
          customCarddata = JSON.parse(segment_data);
        } catch (e) {
          customCarddata = null;
        }
        segment_data = "custom_card";
      }
      if (!isNaN(parseInt(segment.data))) {
        const findCard = _.find(props.cards, { id: parseInt(segment.data) });
        if (findCard) {
          diaryItem = {
            ...diaryItem,
            calories: parseFloat(findCard.assumedCalories) || null,
            protein: parseFloat(findCard.assumedProtein) || null,
            carbs: parseFloat(findCard.assumedCarbs) || null,
            fat: parseFloat(findCard.assumedFat) || null,
          };
        } else {
          if (customCarddata) {
            diaryItem = {
              ...diaryItem,
              calories: parseFloat(customCarddata.assumedCalories) || null,
              protein: parseFloat(customCarddata.assumedProtein) || null,
              carbs: parseFloat(customCarddata.assumedCarbs) || null,
              fat: parseFloat(customCarddata.assumedFat) || null,
              optional_data: JSON.stringify(customCarddata),
            };
          }
        }
      } else {
        if (customCarddata) {
          diaryItem = {
            ...diaryItem,
            calories: parseFloat(customCarddata.assumedCalories) || null,
            protein: parseFloat(customCarddata.assumedProtein) || null,
            carbs: parseFloat(customCarddata.assumedCarbs) || null,
            fat: parseFloat(customCarddata.assumedFat) || null,
            optional_data: JSON.stringify(customCarddata),
          };
        }
      }
      diaryItem = {
        ...diaryItem,
        data: { id: segment.data, custom: segment_data },
      };
    }

    props.updateDiarySegment(uuid, diaryItem);
  }
  function increaseWater() {
    const waterObject = {
      date: parseInt(getISODay(props.activeDay)),
      type: "water",
      replacement_id: null,
      calories: null,
      protein: null,
      carbs: null,
      fat: null,
      quantity: waterStep,
      planner_uuid: null,
      completed: 1,
    };

    const oldWidth = (water / waterTarget) * 100;
    const newWidth = ((water + waterStep) / waterTarget) * 100;
    setWaterWidth(newWidth);

    if (water + waterStep > 0 && newWidth <= 100) {
      props.updateXp({
        type: "water",
        amount: 25,
        x: mousePosition.x,
        y: mousePosition.y,
      });
    }

    if (newWidth >= 100 && oldWidth < 100) {
      props.updateXp({
        type: "water_goal",
        amount: 0,
      });
      amplitude.getInstance().logEvent("Hit Water Goal");
    } else {
      amplitude.getInstance().logEvent("Logged Water");
    }

    props.updateDiary(waterObject);
    setWater(water + waterStep);
  }

  let disabledClass = null;
  if (disableDay) {
    disabledClass = "disabled";
  }
  let swapDisabled = true;

  if (props.selectedRecipes.length + props.selectedCards.length > 0) {
    swapDisabled = false;
  }
  let dayPlanner = false;
  if (
    _.find(recipeData, function (r) {
      return r.segment_order > 999;
    })
  ) {
    dayPlanner = _.reduce(
      recipeData,
      function (acc, curr) {
        return [...acc, curr.segment_order];
      },
      []
    );
  }

  const dayTemplate = [1000, 2000, 3000];
  const daySnack = [4000, 5000];
  const dayWorkout = [6000];

  const hideDayPlanner =
    differenceInCalendarDays(new Date(), props.activeDay) > 3;

  if (
    recipeData &&
    recipeData.length < 1 &&
    snackData &&
    snackData.length < 1 &&
    workoutData &&
    workoutData.length < 1
  ) {
    dayPlanner = true;
  }

  return (
    <>
      {!recipeData && (
        <>
          <PlaceHolderCard />
          <PlaceHolderCard />
          <PlaceHolderCard />
          <PlaceHolderCard />
        </>
      )}

      {!dayPlanner ? (
        <>
          {_.map(recipeData, function (r, i) {
            return (
              <HomeCard
                id={r.id}
                key={r.planner_id}
                disabled={disableDay}
                type={r.type}
                title={r.name}
                hash={r.hash || null}
                image={r.image}
                icon={r.icon}
                source={r.source}
                source_method={r.source_method}
                swap={() => {
                  initSwap(r.planner_id);
                }}
                completed={r.diary ? parseInt(r.diary.completed) : 0}
                toggleCompleted={(b) => {
                  markCompleted(r.planner_id, b, r.diary, r.type);
                }}
              />
            );
          })}
        </>
      ) : (
        <>
          {hideDayPlanner ? (
            <Message negative>
              <Message.Header>No planner entries found.</Message.Header>
              <p>{emptyText}</p>
            </Message>
          ) : (
            <>
              {_.map(dayTemplate, function (r, i) {
                const recipe = _.find(recipeData, {
                  segment_order: r.toString(),
                });

                if (recipe) {
                  return (
                    <HomeCard
                      key={recipe.planner_id}
                      disabled={disableDay}
                      type={recipe.type}
                      title={recipe.name}
                      hash={recipe.hash || null}
                      image={recipe.image}
                      icon={recipe.icon}
                      source={recipe.source}
                      recipeID={recipe.id}
                      source_method={recipe.source_method}
                      swap={() => {
                        initSwap(recipe.planner_id);
                      }}
                      completed={
                        recipe.diary ? parseInt(recipe.diary.completed) : 0
                      }
                      toggleCompleted={(b) => {
                        markCompleted(
                          recipe.planner_id,
                          b,
                          recipe.diary,
                          recipe.type
                        );
                      }}
                    />
                  );
                } else {
                  return (
                    <DayPlan
                      key={`dayPlan-${r}`}
                      date={parseInt(getISODay(props.activeDay))}
                      order={r}
                    />
                  );
                }
              })}
            </>
          )}
        </>
      )}
      <div className={classes.snacks}>
        {!dayPlanner ? (
          <>
            {_.map(snackData, function (r, i) {
              return (
                <HomeCard
                  disabled={disableDay}
                  swap={() => {
                    initSwapSnack(r.planner_id);
                  }}
                  toggleCompleted={(b) => {
                    markCompleted(r.planner_id, b, parseInt(r.diary), r.type);
                  }}
                  hash={r.hash || null}
                  key={`snack-${i}`}
                  type="snack"
                  completed={r.diary ? parseInt(r.diary.completed) : 0}
                  title={r.name}
                />
              );
            })}{" "}
          </>
        ) : (
          !hideDayPlanner && (
            <>
              {_.map(daySnack, function (r) {
                const recipe = _.find(snackData, {
                  segment_order: r.toString(),
                });

                if (recipe) {
                  return (
                    <HomeCard
                      key={recipe.planner_id}
                      disabled={disableDay}
                      type={recipe.type}
                      title={recipe.name}
                      hash={recipe.hash || null}
                      recipeID={r.id}
                      masterID={r.master_recipe}
                      image={recipe.image}
                      icon={recipe.icon}
                      source={recipe.source}
                      source_method={recipe.source_method}
                      swap={() => {
                        initSwap(recipe.planner_id);
                      }}
                      completed={
                        recipe.diary ? parseInt(recipe.diary.completed) : 0
                      }
                      toggleCompleted={(b) => {
                        markCompleted(
                          recipe.planner_id,
                          b,
                          recipe.diary,
                          recipe.type
                        );
                      }}
                    />
                  );
                } else {
                  return (
                    <DayPlan
                      key={`dayPlan-${r}`}
                      type="snack"
                      date={parseInt(getISODay(props.activeDay))}
                      order={r}
                    />
                  );
                }
              })}
            </>
          )
        )}
      </div>

      <div className="workouts">
        {_.map(workoutData, function (r, i) {
          return (
            <HomeCard
              key={r.planner_id}
              disabled={disableDay}
              type="workout"
              subtype={r.type}
              source={r.source}
              data={r}
              //   swap={() => {
              //     initSwap(r.planner_id);
              //   }}
              completed={r.diary ? parseInt(r.diary.completed) : 0}
              toggleCompleted={(b) => {
                markCompleted(r.planner_id, b, r.diary, "workout");
              }}
            />
          );
        })}
      </div>

      <div
        data-tour={isSameDay(props.activeDay) ? "water" : null}
        className={`${classes.waterTrackerContainer} ${disabledClass}`}
      >
        <div className={classes.image}>
          <img src={`${process.env.PUBLIC_URL}/img/WaterGlass.png`} />
        </div>
        <div className={classes.barContainer}>
          <div className={classes.barCaption}>
            {water / 1000}l / {round(waterTarget / 1000, 1)}l
          </div>
          <div className={classes.barBackground}>
            <div
              className={classes.barFill}
              style={{ width: `${waterWidth}%` }}
            ></div>
          </div>
        </div>
        <button
          disabled={disableDay}
          className={classes.plusButton}
          onClick={() => {
            if (!disableDay) {
              increaseWater();
            }
          }}
        >
          +
        </button>
      </div>
      <div>
        <div className={classes.waterTracker}></div>
      </div>

      {dayPlanner && !hideDayPlanner && workoutData.length < 1 && (
        <DayPlan
          date={parseInt(getISODay(props.activeDay))}
          order={dayWorkout[0]}
          type="workout"
        />
      )}
      {nutritionData && nutritionData.length > 0 && (
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}
          className="ui message"
        >
          <div
            className={classes.macroHeader}
            style={{ display: "block", width: "25%" }}
          >
            <h3>Macro Overview</h3>
          </div>
          <div style={{ display: "flex" }}>
            <SimpleCalorieBar tdee={props.tdee} recipes={nutritionData} />
          </div>
        </div>
      )}
      {switchModal && (
        <Modal open={true} size="fullscreen" className={classes.swapModal}>
          <Modal.Header>Swap Meal</Modal.Header>
          <Modal.Content image scrolling>
            <Modal.Description>
              <Container>
                <SwitchBrowser
                  mode={switchType}
                  clear={() => {
                    props.clearSelected();
                  }}
                />
              </Container>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() => {
                props.clearSelected();
                setSwitchModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                switchRecipe();
              }}
              primary
              disabled={swapDisabled}
            >
              Swap <Icon name="chevron right" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRecipes: () => dispatch(getRecipes()),
    resetRecipeFilters: (enable) => dispatch(resetRecipeFilters(enable)),
    clearSelected: () => dispatch(clearSelected()),
    updateXp: (payload) => dispatch(updateXp(payload)),
    updateDiary: (payload) => dispatch(updateDiary(payload)),
    updateDiarySegment: (id, payload) =>
      dispatch(updateDiarySegment(id, payload)),
    replaceRecipe: (uuid, replaceID, type, day, data = null) =>
      dispatch(replaceRecipe(uuid, replaceID, type, day, data)),
  };
};
const mapStateToProps = (state) => {
  return {
    recipes: state.recipes.recipes,
    cards: state.planner.customCards,
    planner: state.planner.planner,
    recipeState: state.recipes,
    diary: state.diary.diary,
    workouts: state.planner.workouts,
    selectedRecipes: state.planner.selectedRecipes,
    selectedWorkouts: state.planner.selectedWorkouts,
    selectedCards: state.planner.selectedCards,
    water: state.auth.user.options.water,
    defaultWater: state.auth.defaultWaterTarget,
    defaultWaterStep: state.auth.defaultWaterStep,
    tdee: state.auth.user.options.tdee,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeDay);
