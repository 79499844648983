import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import axios from "../../../api/axios-token";
import { useQuery, useQueryClient } from "react-query";
import _ from "lodash";
import TopicCard from "./TopicCard";
import { Grid, Container } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
const Browse = () => {
  const fetchTopics = (key, cursor = 0) =>
    axios.get("/discover/get_topics").then((res) => {
      return res.data.topics;
    });
  const queryClient = new useQueryClient();

  const { isLoading, isError, data, error } = useQuery(
    "discover-browse",
    fetchTopics,
    {
      refetchOnWindowFocus: false,
    }
  );
  let history = useNavigate();
  return (
    <>
      <Container>
        <h2>Discover Recipes</h2>
        {isLoading ? null : (
          <Grid columns={4} doubling>
            {_.map(data, function (topic) {
              return (
                <Grid.Column
                  key={topic.id}
                  onClick={() => {
                    history("/recipes/discover/browse/" + topic.slug);
                  }}
                >
                  <TopicCard {...topic} />
                </Grid.Column>
              );
            })}
          </Grid>
        )}{" "}
      </Container>
    </>
  );
};
export default Browse;
