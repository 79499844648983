import React, { useState, useEffect, useRef } from "react";
import uuid from "react-uuid";
import arrayMove from "array-move";
import Week from "./Planner/Week";
import {
  getRelativeDayInWeek,
  getFullDay,
  getISODay,
  formatDateDay,
  parseDate,
  addDays,
} from "./Planner/dateHelper";
import _ from "lodash";
import { DragDropContext } from "react-beautiful-dnd";
import Helmet from "react-helmet";
import {
  Button,
  Header,
  Container,
  Modal,
  Tab,
  Icon,
  Image as Img,
  Grid,
  Confirm,
} from "semantic-ui-react";
import classes from "./Planner/Planner.module.scss";
import {
  initPlanner,
  insertDay,
  updateDay,
  removeSegment,
  initdragStart,
  stopDrag,
  clearDay,
  updateSelectedRecipes,
  updateSelectedWorkouts,
  clearSelected,
  plannerWeeks,
} from "../store/actions/plannerActions";
import { insertShopping } from "../store/actions/shoppingActions";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";

import { getRecipes } from "../store/actions/recipeActions";
import { updateOnboarding } from "../store/actions/uiActions";
import { parseCustomCard } from "../utility/helper";

import RecipeBrowser from "./Recipes/MyRecipes";
import WorkoutsBrowser from "./Planner/WorkoutsBrowser";
import NutritionOverview from "./Planner/NutritionOverview";
import ClipBoard from "./Planner/ClipBoard";
import Leftovers from "./Planner/Leftovers";
import PlannerFooter from "./Planner/PlannerFooter";
import SelectDayModal from "./Planner/SelectDayModal";
import Tour from "reactour";
import CardBrowser from "./Planner/CardBrowser";
import amplitude from "amplitude-js";
import printJS from "print-js";
import { setWeek } from "date-fns";

const Planner = (props) => {
  const [date, setDate] = useState(null);
  const [weekEnd, setweekEnd] = useState(null);
  const [dateFormatted, setDateFormatted] = useState(null);

  const [recipeModal, setRecipeModal] = useState(false);
  const [activeDay, setActiveDay] = useState(null);

  const [entries, setEntries] = useState(null);
  const [weekEntries, setWeekEntries] = useState([]);

  const [clipboard, setClipboard] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [leftOvers, setLeftOvers] = useState([]);
  const [tabIndex, setTabIndex] = useState(1);
  const [shoppingOpen, setShoppingOpen] = useState(false);
  const modalScroll = useRef(null);
  const [tour, setTour] = useState(false);
  const [weekCopy, setWeekCopy] = useState([]);
  const [confirmPasteWeek, setConfirmPasteWeek] = useState(false);
  const [showAdvancedNutrition, setShowAdvancedNutrition] = useState(false);
  const [modal, dispatchModal] = React.useReducer(modalReducer, {
    open: false,
    callback: undefined,
    text: "",
    title: "",
    info: false,
  });

  const closeTour = () => {
    setTour(false);
    props.updateOnboarding({ variable: "PLANNER_ONBOARDING", status: 0 });
  };
  const endTour = () => {
    setTour(false);
    props.updateOnboarding({ variable: "PLANNER_ONBOARDING", status: 1 });
  };
  const userStartDay = parseInt(props.startDay || 1);

  useEffect(() => {
    if (props.onboarding) {
      const homeBoarding = _.find(props.onboarding, {
        boarding_variable: "PLANNER_ONBOARDING",
      });

      if (homeBoarding.status === null) {
        setTour(true);
      }
    }
  }, []);

  useEffect(() => {
    const imagesPreload = [
      "Recipe.svg",
      "Workout.svg",
      "Custom.svg",
      "Meal.svg",
    ];
    imagesPreload.map(function (image) {
      const newImage = new Image();
      newImage.src = `${process.env.PUBLIC_URL}/img/${image}`;
      // window[image] = newImage;
    });
  }, []);

  useEffect(() => {
    function plannerInit() {
      if (!props.recipesLoaded) {
        props.getRecipes();
      }
      let currentDate = new Date();
      let prevDate = new Date();
      currentDate = addDays(currentDate, 14);

      prevDate = addDays(prevDate, -14);
      props.initPlanner(getISODay(prevDate), getISODay(currentDate));
    }

    const newDate = getRelativeDayInWeek(new Date(), userStartDay);

    setDate(newDate);
    setDateFormatted(
      formatDateDay(getRelativeDayInWeek(new Date(), userStartDay))
    );
    const weekEnd = addDays(getRelativeDayInWeek(new Date(), userStartDay), 6);
    setweekEnd(formatDateDay(weekEnd));

    plannerInit();
  }, [props.startDay]);

  useEffect(() => {
    if (props.plannerLoaded && props.recipesLoaded) {
      setEntries(props.planner);
    }
  }, [props.planner, props.plannerLoaded, props.recipesLoaded]);

  useEffect(() => {
    var i;
    let d = userStartDay;
    let weekRecipes = [];

    if (entries && props.recipes && props.recipesLoaded) {
      const startDay = getRelativeDayInWeek(new Date(date), d);
      for (i = 0; i < 7; i++) {
        const dayDate = addDays(new Date(startDay), i);

        let dayEntries = _.find(entries, function (p) {
          return p.date == getISODay(dayDate);
        });

        if (dayEntries) {
          dayEntries = [...dayEntries.data];
        } else {
          dayEntries = [];
        }
        d++;
        weekRecipes = [...weekRecipes, ...dayEntries];
      }

      weekRecipes = weekRecipes.filter(function (item) {
        if (
          (item.type === "recipe" || item.type === "meal") &&
          item.optional_data2 != 1
        ) {
          return item;
        }
      });
      let result = _(weekRecipes)
        .groupBy("data")
        .map((data, id) => {
          const totalPortions = _.sumBy(data, function (r) {
            if (r.optional_data === null) {
              return 1;
            }
            return parseFloat(r.optional_data);
          });
          const recipe = _.find(props.recipes, { id: id.toString() });
          if (recipe) {
            return {
              recipeID: id.toString(),
              portions: totalPortions,
              servings: parseInt(recipe.serves),
              type: recipe.type,
            };
          }
        })
        .value()
        .filter((data) => {
          if (data && data.servings > 1) {
            return data;
          }
        });

      result.map((data, id) => {
        if (data.portions % data.servings > 0) {
          result[id].leftovers =
            data.servings - (data.portions % data.servings);
        } else {
          result[id].leftovers = 0;
        }
      });

      const leftOvers = _.filter(result, function (r) {
        return r.leftovers > 0;
      });
      setLeftOvers(leftOvers);
    } // end if
  }, [dateFormatted, entries, props.recipes]);

  useEffect(() => {
    if (props.recipes) {
      let d = userStartDay;
      let i;
      const startDay = getRelativeDayInWeek(new Date(date), d);

      const recipes = [];
      for (i = 0; i < 7; i++) {
        const dayDate = addDays(new Date(startDay), i);

        let dayEntries = _.find(entries, function (p) {
          return p.date == getISODay(dayDate);
        });

        if (dayEntries) {
          dayEntries = [...dayEntries.data];
        } else {
          dayEntries = [];
        }

        d++;
        dayEntries.map(function (day) {
          if (
            day.type === "recipe" ||
            day.type === "meal" ||
            day.type == "custom"
          ) {
            let optional_data = 1;
            if (day.optional_data && day.type !== "custom") {
              optional_data = parseInt(day.optional_data);
            }
            if (day.type == "custom") {
              let calories = 900;
              let custom;
              if (parseInt(day.data) > 0) {
                custom = _.find(props.customCards, {
                  id: parseInt(day.data),
                });
              } else {
                custom = parseCustomCard(day.optional_data);
              }
              if (custom) {
                calories = parseInt(custom.assumedCalories);

                recipes.push({
                  date: day.date,
                  customID: day.data,
                  customData: custom,
                  id: "custom",
                  calories: calories,
                  fat: 0,
                  carbs: 0,
                  protein: 0,
                });
              }
            } else {
              recipes.push({
                date: day.date,
                id: day.data,
                portions: optional_data,
              });
            }
          }
        });
      }

      setWeekEntries(recipes);
    }
  }, [
    dateFormatted,
    entries,
    props.startDay,
    props.recipes,
    props.recipesLoaded,
  ]);

  function copyPaste(day) {
    const copiedItems = _.find(entries, { date: parseInt(day) });

    const clipboardItems = [...clipboard, ...copiedItems.data];

    setClipboard(clipboardItems);

    //get day recipes
  }
  function resetScrollposition() {
    if (!scrollPosition) {
      window.scrollTo(0, scrollPosition);
    }
    setScrollPosition(0);
  }
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const printPlan = () => {
    amplitude.getInstance().logEvent("Print Plan");
    const ISOdate = getISODay(date);

    const url =
      "https://www.planandshop.uk/webhooks/planeat_planner/" +
      props.hash +
      "/" +
      ISOdate;

    if (isMobile) {
      openInNewTab(url);
      return;
    }

    printJS({
      printable: url,
      type: "pdf",
      showModal: true,
      modalMessage: "Generating printable plan...",
      onError: () => {
        openInNewTab(url);
      },
    });
  };

  function modalReducer(modal, action) {
    switch (action.type) {
      case "close":
        return { open: false };
      case "open":
        return { open: true };
      case "confirmTrashModal":
        return {
          info: false,
          title: "Clear day",
          text: <p>Would you like to clear this day?</p>,
          callback: () => {
            action.callback();
          },
          open: true,
        };
      case "portionQuestion":
        return {
          title: "Leftovers",
          text: (
            <>
              <p>
                These recipes provide more than 1 serving. Utilise all servings
                to prevent food wastage.
              </p>
              <p>
                You can adjust the number of servings by clicking the item in
                the planner. Alternatively, click the recipe and choose "Reduce
                to 1" to automatically reduce the ingredients in your shopping
              </p>
            </>
          ),
          callback: () => {
            return;
          },
          open: true,
          info: true,
        };
      default:
        throw new Error("Unsupported action...");
    }
  }
  function nextWeek() {
    if (!date) {
      return null;
    }

    const currentDate = new Date(date);
    const nextDate = addDays(currentDate, 7);
    setDate(nextDate);
    setDateFormatted(formatDateDay(nextDate));
    const weekEnd = new Date(nextDate);
    addDays(weekEnd, 6);
    setweekEnd(formatDateDay(weekEnd));
    props.plannerWeeks(nextDate, weekEnd);
  }

  function prevWeek() {
    if (!date) {
      return null;
    }
    const currentDate = new Date(date);
    const nextDate = addDays(currentDate, -7);
    setDate(nextDate);

    setDateFormatted(formatDateDay(nextDate));
    const weekEnd = new Date(nextDate);
    addDays(weekEnd, 6);
    setweekEnd(formatDateDay(weekEnd));
    props.plannerWeeks(nextDate, weekEnd);
  }

  const trash = (day) => {
    dispatchModal({
      type: "confirmTrashModal",
      callback: () => confirmDay(day),
    });
  };

  const confirmDay = (day) => {
    props.clearDay(day);
  };

  const recycleDay = (day) => {
    amplitude.getInstance().logEvent("Recycled day");

    const lastDay = parseDate(day);
    const prevWeek = getISODay(addDays(lastDay, -7));

    const dayCopy = _.find(props.planner, function (p) {
      return p.date == prevWeek;
    });
    if (dayCopy && typeof dayCopy.data !== "undefined") {
      const dayArray = [];
      dayCopy.data.map(function (el) {
        const recipeObject = {
          id: el.data,
          uuid: uuid(),
          type: el.type,
          optional_data: el.optional_data,
          optional_data2: el.optional_data2,
        };
        dayArray.push({ ...recipeObject });
      });
      props.insertDay(day, dayArray);
    }
  };

  const copyWeek = (startDay) => {
    // create an array for each day of the week ...

    var i;
    const week = [];
    for (i = 0; i < 7; i++) {
      const dayCopy = _.find(props.planner, function (p) {
        return p.date == parseInt(startDay) + i;
      });
      const dayObject = {
        date: parseInt(startDay) + i,
        segments: dayCopy || [],
      };
      week.push(dayObject);
    }
    setWeekCopy(week);
  };

  const pasteWeek = (startDay) => {
    if (weekCopy.length) {
      let i = 0;
      _.map(weekCopy, function (day) {
        const dayArray = [];
        const weekDay = getISODay(addDays(new Date(startDay), i));
        _.map(day.segments.data, function (el) {
          const recipeObject = {
            id: el.data,
            uuid: uuid(),
            type: el.type,
            optional_data: el.optional_data,
            optional_data2: el.optional_data2 || null,
          };
          dayArray.push({ ...recipeObject });
        });
        props.insertDay(weekDay, dayArray);
        i++;
      });
    }
    amplitude.getInstance().logEvent("Copy Week");
    setWeekCopy([]);
    setConfirmPasteWeek(false);
  };

  const pasteClipboard = (day) => {
    const dayArray = _.find(props.planner, function (p) {
      return p.date == day;
    });
    let newArray;
    if (dayArray && typeof dayArray.data !== "undefined") {
      const recipeArray = [];
      clipboard.map(function (el) {
        const recipeObject = {
          ...el,
          uuid: uuid(),
        };
        recipeArray.push({ ...recipeObject });
      });
      newArray = [...dayArray.data, ...recipeArray];

      props.updateDay(day, newArray);
    } else {
      newArray = [...clipboard];
      const recipeArray = [];
      clipboard.map(function (el) {
        const recipeObject = {
          id: el.data,
          uuid: uuid(),
          type: el.type,
          optional_data: el.optional_data,
          optional_data2: el.optional_data2,
        };
        recipeArray.push({ ...recipeObject });
      });
      props.insertDay(day, recipeArray);
    }
    // clearClipboard();
  };

  const removeClipboardItem = (index) => {
    const newClipboard = [...clipboard];
    newClipboard.splice(index, 1);

    setClipboard(newClipboard);
  };

  const clearClipboard = () => {
    setClipboard([]);
  };
  const onDragStart = (result) => {
    props.initdragStart(result.source.droppableId.replace("drop-", ""));
  };
  const onDragEnd = (result) => {
    props.stopDrag();
    let destinationDate;
    if (result.reason == "CANCEL" || result.destination == null) {
      return;
    }

    if (
      result.destination.droppableId.includes("delete") &&
      result.draggableId.includes("segment")
    ) {
      if (
        result.destination.droppableId.replace("delete-", "") ==
        result.source.droppableId.replace("drop-", "")
      ) {
        props.removeSegment(
          result.source.droppableId.replace("drop-", ""),
          result.draggableId.replace("segment-", "")
        );

        return;
      }
    }

    // START OF LEFTOVERS

    if (result.draggableId.includes("leftover")) {
      if (result.destination.droppableId == "leftovers") {
        return false;
      }

      if (
        result.destination.droppableId.includes("add") ||
        result.destination.droppableId.includes("delete")
      ) {
        const recipe = leftOvers[result.source.index];
        destinationDate = result.destination.droppableId
          .replace("add-", "")
          .replace("delete-", "");
        let type = recipe.type || "recipe";
        if (recipe.type == "snack") {
          type = "recipe";
        }
        if (recipe.type == "reduced") {
          type = "recipe";
        }
        if (recipe.type == "refuel") {
          type = "recipe";
        }
        if (recipe.type == "general") {
          type = "recipe";
        }

        const recipeObject = {
          id: recipe.recipeID,
          uuid: uuid(),
          type: type,
          optional_data: 1,
          optional_data2: null,
        };

        const recipeArray = [recipeObject];
        props.insertDay(destinationDate, recipeArray);

        return;
      }

      let updateItem, updateSegment;
      const updateID = result.draggableId.replace("leftover-", "");
      let segmentPromise = new Promise((resolve, reject) => {
        updateItem = leftOvers[result.source.index];
        resolve(updateItem);
      }).then(() => {
        if (!updateItem) {
          return false;
        }
        // get the new day array
        const recipe = updateItem;
        let type = "recipe";
        if (recipe.type == "meal") {
          type = "meal";
        }
        destinationDate = result.destination.droppableId.includes("delete-")
          ? result.destination.droppableId.replace("delete-", "")
          : result.destination.droppableId.replace("drop-", "");

        const updateSegment = {
          id: "portion-" + uuid(),
          uuid: uuid(),
          data: recipe.recipeID,
          type: type,
          optional_data: 1,
          optional_data2: null,
          userid: "me",
          date: destinationDate,
          locked: null,
        };
        // optional data 1 because it is a leftover, option data 2 is null because it is not reduced if it is a leftover?

        const dayArray = [
          ..._.find(props.planner, function (p) {
            return p.date == destinationDate;
          }).data,
        ];

        dayArray.push({ ...updateSegment });
        // add to the new array
        arrayMove.mutate(dayArray, -1, result.destination.index);

        props.updateDay(destinationDate, dayArray);

        return;
      });
      return;
    }

    // START OF CLIPBOARD
    if (result.draggableId.includes("clipboard")) {
      if (result.destination.droppableId == "clipboard") {
        return false;
      }

      if (
        result.destination.droppableId.includes("add") ||
        result.destination.droppableId.includes("delete")
      ) {
        const recipe = clipboard[result.source.index];
        destinationDate = result.destination.droppableId
          .replace("add-", "")
          .replace("delete-", "");
        let type = recipe.type;
        if (recipe.type == "snack") {
          type = "recipe";
        }
        if (recipe.type == "reduced") {
          type = "recipe";
        }
        if (recipe.type == "refuel") {
          type = "recipe";
        }
        if (recipe.type == "general") {
          type = "recipe";
        }

        const recipeObject = {
          id: recipe.data,
          uuid: uuid(),
          type: type,
          optional_data: recipe.optional_data,
          optional_data2: recipe.optional_data2,
        };
        const recipeArray = [recipeObject];

        props.insertDay(destinationDate, recipeArray);
        if (!result.destination.droppableId.includes("delete")) {
          removeClipboardItem(result.source.index);
        }
        return;
      }

      let updateItem, updateSegment;
      const updateID = result.draggableId.replace("clipboard-", "");
      let segmentPromise = new Promise((resolve, reject) => {
        updateItem = clipboard[result.source.index];
        resolve(updateItem);
      }).then(() => {
        if (!updateItem) {
          return false;
        }
        // get the new day array
        updateSegment = { ...updateItem };
        // console.log(updateSegment);
        updateSegment.id = updateSegment.id + "-copy";
        updateSegment.uuid = uuid();
        const dayArray = [
          ..._.find(props.planner, function (p) {
            return (
              p.date == result.destination.droppableId.replace("drop-", "")
            );
          }).data,
        ];
        dayArray.push({ ...updateSegment });
        // add to the new array
        arrayMove.mutate(dayArray, -1, result.destination.index);

        props.updateDay(
          result.destination.droppableId.replace("drop-", ""),
          dayArray
        );
        removeClipboardItem(result.source.index);

        return;
      });
      return;
    }
    if (
      result.destination.droppableId.includes("add-") ||
      result.destination.droppableId.includes("delete-")
    ) {
      const recipeArray = [];
      const segmentID = result.draggableId.replace("segment-", "");

      const originalDay = result.source.droppableId.replace("drop-", "");

      const dayArray = _.find(props.planner, { data: [{ uuid: segmentID }] });
      const isoDay = result.destination.droppableId
        .replace("add-", "")
        .replace("delete-", "");
      const segment = _.find(dayArray.data, { uuid: segmentID });
      const segmentArray = [];
      const newObject = {
        id: segment.data,
        uuid: uuid(),
        type: segment.type,
        optional_data: segment.optional_data,
        optional_data2: segment.optional_data2,
      };
      segmentArray.push({ ...newObject });

      props.insertDay(isoDay, segmentArray);
      if (result.destination.droppableId.includes("add")) {
        props.removeSegment(originalDay, segmentID);
      }

      return;
    }

    if (result.destination.droppableId == result.source.droppableId) {
      // same day, just a change of order

      // make copy of day array

      const dayArray = [
        ..._.find(props.planner, function (p) {
          return p.date == result.destination.droppableId.replace("drop-", "");
        }).data,
      ];

      arrayMove.mutate(dayArray, result.source.index, result.destination.index);

      dayArray.map(function (d, i) {
        dayArray[i].segment_order = i;
      });

      props.updateDay(
        result.destination.droppableId.replace("drop-", ""),
        dayArray
      );
      // send to action to update the planner database
    } else {
      // different days
      // get a copy of the segment
      let updateItem;
      const updateID = result.draggableId.replace("segment-", "");
      let segmentPromise = new Promise((resolve, reject) => {
        updateItem = _.find(props.planner, { data: [{ uuid: updateID }] });

        resolve(updateItem);
      }).then(() => {
        if (!updateItem) {
          return false;
        }

        const updateSegment = _.remove(updateItem.data, {
          uuid: result.draggableId.replace("segment-", ""),
        })[0];

        props.removeSegment(
          result.source.droppableId.replace("drop-", ""),
          result.draggableId.replace("segment-", "")
        );

        // get the new day array
        const dayArray = [
          ..._.find(props.planner, function (p) {
            return (
              p.date == result.destination.droppableId.replace("drop-", "")
            );
          }).data,
        ];
        dayArray.push({ ...updateSegment });
        // add to the new array
        arrayMove.mutate(dayArray, -1, result.destination.index);

        props.updateDay(
          result.destination.droppableId.replace("drop-", ""),
          dayArray
        );
      });
    }
  };

  const showRecipeModal = (dayDate) => {
    setScrollPosition(window.pageYOffset);

    setActiveDay(dayDate);
    setRecipeModal(true);
  };

  const addPlannerItems = (recipes, workouts, cards, callback) => {
    const isoDay = getISODay(activeDay);
    const itemArray = [];
    // props add optional reduce
    const always_reduce = props.user.always_reduce;

    recipes.map(function (el) {
      const type = _.find(props.recipes, { id: el });

      let recipeType = "recipe";
      if (type.type == "meal") {
        recipeType = "meal";
      }
      const recipeObject = {
        id: el,
        uuid: uuid(),
        type: recipeType,
        optional_data: 1,
        optional_data2: type.serves > 1 ? always_reduce : null,
      };
      itemArray.push({ ...recipeObject });
    });

    workouts.map(function (el) {
      const workoutObject = {
        id: el,
        uuid: uuid(),
        type: "workout",
        optional_data: null,
        optional_data2: null,
      };
      itemArray.push({ ...workoutObject });
    });
    cards.map(function (el) {
      let optional_data;

      if (el.toString().includes("custom")) {
        optional_data = _.find(props.customCards, { id: el });
        optional_data = JSON.stringify(optional_data);
        el = 0;
      }
      const cardObject = {
        id: el,
        uuid: uuid(),
        type: "custom",
        optional_data: optional_data || null,
        optional_data2: null,
      };
      itemArray.push({ ...cardObject });
    });

    var eventProperties = {
      recipes: recipes.length,
      workouts: workouts.length,
      cards: cards.length,
    };
    amplitude
      .getInstance()
      .logEvent("Added Items to Planner", { eventProperties });

    props.insertDay(isoDay, itemArray);
    setActiveDay(null);

    setRecipeModal(false);
    resetScrollposition();
    return callback();
  };

  const TabIcon = (props) => {
    let title, img;
    switch (props.icon) {
      case "workout":
        title = "Workouts";
        img = "Workout";
        break;
      case "recipe":
        title = "Recipes";
        img = "Recipe";
        break;
      case "meal":
        title = "Meals";
        img = "Meal";
        break;
      case "custom":
        title = "Custom";
        img = "Custom";
        break;
    }

    return (
      <div className={classes.tabMenuItem}>
        <Img src={`${process.env.PUBLIC_URL}/img/${img}.svg`} />
        <span>{title}</span>
      </div>
    );
  };

  const RecipeModal = (props) => {
    let dayString = "plan";
    if (props.day) {
      dayString = getFullDay(props.day);
    }

    const panes = [
      {
        menuItem: {
          key: "workout",
          name: "Workouts",
          content: <TabIcon key="workout" icon="workout" />,
        },
        render: () => (
          <Tab.Pane attached={false}>
            <WorkoutsBrowser />
          </Tab.Pane>
        ),
      },
      {
        menuItem: {
          key: "recipes",
          name: "Recipes",
          content: <TabIcon key="recipe" icon="recipe" />,
        },
        render: () => (
          <Tab.Pane attached={false}>
            <RecipeBrowser
              view="recipes"
              mode="planner"
              selectedRecipes={props.selectedRecipes}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: {
          key: "meals",
          name: "Meals",
          content: <TabIcon key="meal" icon="meal" />,
        },

        render: () => (
          <Tab.Pane attached={false}>
            <RecipeBrowser
              createMealEnabled
              view="meals"
              mode="planner"
              selectedRecipes={props.selectedRecipes}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: {
          key: "custom",
          name: "Custom",
          content: <TabIcon key="custom" icon="custom" />,
        },
        render: () => (
          <Tab.Pane attached={false}>
            <CardBrowser />
          </Tab.Pane>
        ),
      },
    ];

    return (
      <div className={classes.Modal}>
        <div ref={modalScroll} className={classes.ModalContainer}>
          <Tab
            onTabChange={(e, d) => setTabIndex(d.activeIndex)}
            className={classes.TabContainer}
            menu={{
              fluid: true,
              widths: 4,
              borderless: true,
              attached: false,
              tabular: false,
            }}
            panes={panes}
            activeIndex={tabIndex}
          />
        </div>

        <PlannerFooter
          addPlannerItems={(recipes, workouts, cards, callback) => {
            addPlannerItems(recipes, workouts, cards, callback);
          }}
          day={dayString}
          reset={() => {
            resetScrollposition();
            setRecipeModal(false);
            setActiveDay(null);
          }}
        />
      </div>
    );
  };

  const steps = [
    {
      selector: '[data-tour = "container"]',
      content: (
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <Icon color="blue" name="calendar" size="huge" />
            </Grid.Column>
            <Grid.Column>
              <h2>Welcome to your planner</h2>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <p>
                This is where you'll plan your weekly recipes. Let's take a look
                around.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "nutrition"]',
      content: (
        <div>
          <h2>This is your nutrition overview</h2>
          <p>
            This will show you your daily average intake against your targets.{" "}
          </p>
          <p>
            The bars on the right show each day in more detail, against the
            daily average. This is a lifestyle, and therefore you need balance.
            It's ususal for weekends to be a little heavier.
          </p>
        </div>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "plannerWeeks"]',
      content: (
        <div>
          <h2>Week selector</h2>
          <p>You can cycle through previous weeks and plan in advance. </p>
          <p>
            You can choose a day for your week to start in your profile section.
          </p>
        </div>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "date-Monday"]',
      content: (
        <div>
          <p>
            To start planning click on a{" "}
            <Icon style={{ transform: "none" }} name="add" /> button for that
            day to open the recipe selection window. Your chosen recipes will
            appear here
          </p>
        </div>
      ),
      position: "bottom",
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "container"]',
      content: (
        <div>
          <h3>Drag and Drop</h3>
          <Img
            fluid
            src={`${process.env.PUBLIC_URL}/img/onboarding/plannerMove.gif`}
          />
          <p>
            Drag and drop your recipes to re-order them or move them to
            different days.
          </p>
        </div>
      ),

      style: { padding: "3em", fontSize: "1.2em" },
      position: "bottom",
    },
    {
      selector: '[data-tour = "container"]',
      content: (
        <div>
          <h3>Deleting Recipes</h3>
          <Img
            fluid
            src={`${process.env.PUBLIC_URL}/img/onboarding/plannerDelete.gif`}
          />
          <p>Delete plan segments by dragging them to the red trash can.</p>
        </div>
      ),

      style: { padding: "3em", fontSize: "1.2em" },
      position: "bottom",
    },
    {
      selector: '[data-tour = "container"]',
      content: (
        <div>
          <h3>Clipboard</h3>
          <Img
            fluid
            src={`${process.env.PUBLIC_URL}/img/onboarding/plannerClipboard.gif`}
          />
          <p>
            Copy your recipes, they will appear in a visual clipboard at the
            top. Discard any copies you do not want to use{" "}
          </p>
        </div>
      ),
      stepInteraction: true,
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "recycle-Monday"]',
      content: (
        <div>
          <h3>Recycling Days</h3>
          <p>
            You'll find you may want to re-use previous weeks. This button gives
            you a quick way of copying over the previous weeks day.
          </p>
        </div>
      ),

      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "container"]',
      content: (
        <div>
          <h3>Handling extra servings</h3>
          <Img
            fluid
            src={`${process.env.PUBLIC_URL}/img/onboarding/plannerLeftovers.gif`}
          />
          <p>
            Lots of recipes are for more than one person. Recipes with extra
            servings appear in the 'Leftovers' box to use on other days. However
            there is another way to deal with these...
          </p>
        </div>
      ),

      style: { padding: "3em", fontSize: "1.2em", marginTop: "-150px" },
    },
    {
      selector: '[data-tour = "container"]',
      content: (
        <div>
          <h3>Segment Edit</h3>
          <Img
            fluid
            src={`${process.env.PUBLIC_URL}/img/onboarding/editSegment.gif`}
          />
          <p>
            Clicking a recipe opens the edit recipe drawer. You can amend
            portion sizes and preview your recipes here.
          </p>
        </div>
      ),
      stepInteraction: true,
      style: { padding: "3em", fontSize: "1.2em" },
    },
  ];

  return (
    <>
      {entries && (
        <>
          <SelectDayModal
            close={() => setShoppingOpen(false)}
            open={shoppingOpen}
            startDay={userStartDay}
            date={date}
            insertShopping={(res) => {
              props.insertShopping(res);
            }}
          />
          <Modal
            size="small"
            open={modal.open}
            onClose={() => dispatchModal({ type: "close" })}
          >
            <Modal.Header>{modal.title}</Modal.Header>
            <Modal.Content>
              <div>{modal.text}</div>
            </Modal.Content>
            <Modal.Actions>
              {!modal.info && (
                <Button
                  negative
                  onClick={() => dispatchModal({ type: "close" })}
                >
                  Cancel
                </Button>
              )}
              <Button
                positive
                onClick={() => {
                  modal.callback();
                  dispatchModal({ type: "close" });
                }}
              >
                Ok
              </Button>
            </Modal.Actions>
          </Modal>
          {recipeModal ? (
            <RecipeModal {...props} day={activeDay} />
          ) : (
            <Container
              className={`${classes.PlannerWrapper}`}
              data-tour="plannerContainer"
              style={{ paddingTop: "1em" }}
            >
              <Helmet>
                <body className="A4 landscape" />
              </Helmet>
              <Tour
                rounded={15}
                showNavigationNumber={false}
                showNumber={false}
                steps={steps}
                isOpen={tour}
                disableInteraction
                accentColor="#47c73c"
                onRequestClose={() => closeTour()}
                lastStepNextButton={
                  <Button
                    OnClick={() => endTour()}
                    className="rounded"
                    color="green"
                  >
                    Let's start planning
                  </Button>
                }
              />
              <Grid className="">
                <Grid.Row>
                  <Grid.Column>
                    <Header as="h2">Meal Planner</Header>
                  </Grid.Column>
                </Grid.Row>

                <div className="mobileOnly">
                  <Grid.Row>
                    <div
                      data-tour="plannerWeeks"
                      className={classes.weekContainer}
                    >
                      <div>
                        <Button onClick={() => prevWeek()}>{"<"}</Button>
                      </div>
                      <h2>
                        {dateFormatted} - {weekEnd}
                      </h2>
                      <div>
                        <Button onClick={() => nextWeek()}>{">"}</Button>
                      </div>
                    </div>
                  </Grid.Row>
                </div>

                <DragDropContext
                  onDragStart={(e) => onDragStart(e)}
                  onDragEnd={(e) => onDragEnd(e)}
                >
                  <Grid.Row reversed="computer">
                    <Grid.Column
                      className="no-print"
                      computer={4}
                      mobile={16}
                      tablet={16}
                    >
                      <NutritionOverview
                        date={date}
                        weekEntries={weekEntries}
                        showAdvanced={showAdvancedNutrition}
                        closeAdvanced={() => setShowAdvancedNutrition(false)}
                      />

                      <Grid.Row className="no-print" only="computer">
                        <Grid padded>
                          <Grid.Row>
                            <Grid.Column
                              style={{ padding: "0.5em" }}
                              mobile={8}
                              tablet={8}
                              computer={16}
                            >
                              <Button
                                size="tiny"
                                fluid
                                className={`${classes.addShoppingButton} rounded no-print`}
                                color="orange"
                                icon
                                labelPosition="left"
                                onClick={() => {
                                  amplitude
                                    .getInstance()
                                    .logEvent("Advanced Nutrition");
                                  setShowAdvancedNutrition(true);
                                }}
                              >
                                <Icon name="magnify" />
                                Advanced Nutrition
                              </Button>
                            </Grid.Column>
                            <Grid.Column
                              style={{ padding: "0.5em" }}
                              mobile={8}
                              tablet={8}
                              computer={16}
                            >
                              <Button
                                size="tiny"
                                fluid
                                icon
                                labelPosition="left"
                                className={`${classes.addShoppingButton} rounded no-print`}
                                color="yellow"
                                style={{ color: "green" }}
                                onClick={() => {
                                  printPlan();
                                }}
                              >
                                <Icon name="print" />
                                Print Plan
                              </Button>
                            </Grid.Column>
                            <Grid.Column
                              style={{ padding: "0.5em" }}
                              mobile={8}
                              tablet={8}
                              computer={16}
                            >
                              <Button
                                size="tiny"
                                fluid
                                className={`${classes.addShoppingButton} rounded no-print`}
                                color="green"
                                icon
                                labelPosition="left"
                                onClick={() => {
                                  setShoppingOpen(true);
                                }}
                              >
                                <Icon name="shopping basket" />
                                Add to Shopping
                              </Button>
                            </Grid.Column>

                            <Grid.Column
                              style={{ padding: "0.5em" }}
                              mobile={8}
                              tablet={8}
                              computer={16}
                            >
                              <Button.Group
                                size="tiny"
                                className={classes.copyWeek}
                              >
                                {weekCopy.length == 0 ? (
                                  <Button
                                    size="tiny"
                                    labelPosition="left"
                                    color="teal"
                                    content="Copy Week"
                                    icon="copy"
                                    onClick={() => {
                                      copyWeek(getISODay(date));
                                    }}
                                  />
                                ) : (
                                  <>
                                    <Button
                                      className="paste"
                                      size="tiny"
                                      labelPosition="left"
                                      color="black"
                                      content="Paste"
                                      icon="paste"
                                      onClick={() => {
                                        setConfirmPasteWeek(true);
                                      }}
                                    />

                                    <Confirm
                                      open={confirmPasteWeek}
                                      onCancel={() =>
                                        setConfirmPasteWeek(false)
                                      }
                                      onConfirm={() => pasteWeek(date)}
                                      content="Paste the entire content of the copied week onto this week?"
                                    />
                                  </>
                                )}
                                {weekCopy.length == 0 ? null : (
                                  <Button
                                    size="tiny"
                                    labelPosition="right"
                                    icon="cancel"
                                    content="Cancel"
                                    onClick={() => {
                                      setWeekCopy([]);
                                    }}
                                  />
                                )}
                              </Button.Group>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Row>

                      <Leftovers
                        modal={() => {
                          dispatchModal({ type: "portionQuestion" });
                        }}
                        leftovers={leftOvers}
                      />
                      <ClipBoard
                        clear={() => clearClipboard()}
                        clipboard={clipboard}
                      />
                    </Grid.Column>
                    <Grid.Column computer={12} mobile={16} tablet={16}>
                      <div className="desktopOnly">
                        <div
                          data-tour="plannerWeeks"
                          className={classes.weekContainer}
                        >
                          <div>
                            <Button onClick={() => prevWeek()}>{"<"}</Button>
                          </div>
                          <h2>
                            {dateFormatted} - {weekEnd}
                          </h2>
                          <div>
                            <Button onClick={() => nextWeek()}>{">"}</Button>
                          </div>
                        </div>
                      </div>

                      <Week
                        clipboard={clipboard.length ? true : false}
                        entries={entries}
                        date={date}
                        startDay={userStartDay}
                        modal={(dayDate) => {
                          resetScrollposition();
                          showRecipeModal(dayDate);
                        }}
                        trash={(day) => trash(day)}
                        recycle={(day) => recycleDay(day)}
                        pasteClipboard={(day) => pasteClipboard(day)}
                        copyPaste={(day) => copyPaste(day)}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </DragDropContext>
              </Grid>
            </Container>
          )}
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    insertDay: (date, data) => dispatch(insertDay(date, data)),
    updateDay: (date, data) => dispatch(updateDay(date, data)),
    getRecipes: () => dispatch(getRecipes()),
    initPlanner: (s, e) => dispatch(initPlanner(s, e)),
    removeSegment: (d, s) => dispatch(removeSegment(d, s)),
    initdragStart: (d) => dispatch(initdragStart(d)),
    stopDrag: () => dispatch(stopDrag()),
    clearDay: (day) => dispatch(clearDay(day)),
    updateSelectedRecipes: (array) => dispatch(updateSelectedRecipes(array)),

    updateSelectedWorkouts: (array) => dispatch(updateSelectedWorkouts(array)),
    clearSelected: () => dispatch(clearSelected()),
    insertShopping: (response) => dispatch(insertShopping(response)),
    updateOnboarding: (payload) => dispatch(updateOnboarding(payload)),
    plannerWeeks: (start, end) => dispatch(plannerWeeks(start, end)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user.options,
    startDay: state.auth.user.options.startDay,
    recipes: state.recipes.recipes,
    recipesLoaded: state.recipes.recipesLoaded,
    planner: state.planner.planner,
    customCards: state.planner.customCards,
    plannerLoaded: state.planner.plannerLoaded,
    onboarding: state.auth.user.onboarding,
    hash: state.auth.user.id,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Planner);
