import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";

import { Tab, Image as Img } from "semantic-ui-react";
import RecipeBrowser from "../Recipes/MyRecipes";
import MealBrowser from "../Recipes/MyRecipes";
import WorkoutsBrowser from "../Planner/WorkoutsBrowser";
import classes from "../Planner/Planner.module.scss";
import CardBrowser from "../Planner/CardBrowser";
const SwitchBrowser = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    const imagesPreload = ["Recipe.svg", "Custom.svg", "Meal.svg"];
    imagesPreload.map(function (image) {
      const newImage = new Image();
      newImage.src = `${process.env.PUBLIC_URL}/img/${image}`;
      // window[image] = newImage;
    });
  }, []);
  const panes = [
    {
      menuItem: {
        name: "Recipes",
        content: (
          <div className={classes.tabMenuItem}>
            <Img src={`${process.env.PUBLIC_URL}/img/Recipe.svg`} />
            <span>Recipes</span>
          </div>
        ),
      },
      render: () => (
        <Tab.Pane attached={false}>
          <RecipeBrowser
            view="recipes"
            mode="planner"
            toggle
            // selectedRecipes={props.selectedRecipes}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        name: "Meals",
        content: (
          <div className={classes.tabMenuItem}>
            <Img src={`${process.env.PUBLIC_URL}/img/Meal.svg`} />
            <span>Meals</span>
          </div>
        ),
      },
      render: () => (
        <Tab.Pane attached={false}>
          <RecipeBrowser
            createMealEnabled
            view="meals"
            mode="planner"
            toggle
            // selectedRecipes={props.selectedRecipes}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        name: "Custom",
        content: (
          <div className={classes.tabMenuItem}>
            <Img src={`${process.env.PUBLIC_URL}/img/Custom.svg`} />
            <span>Custom</span>
          </div>
        ),
      },
      render: () => (
        <Tab.Pane attached={false}>
          <CardBrowser toggle />
        </Tab.Pane>
      ),
    },
  ];
  return (
    <>
      {props.mode == "workouts" ? (
        <WorkoutsBrowser />
      ) : (
        <>
          {props.mode == "snacks" ? (
            <RecipeBrowser view="snacks" mode="planner" toggle />
          ) : (
            <Tab
              onTabChange={(e, d) => {
                props.clear();
                setTabIndex(d.activeIndex);
              }}
              menu={{
                fluid: true,
                widths: 3,
                borderless: true,
                attached: false,
                tabular: false,
              }}
              panes={panes}
              activeIndex={tabIndex}
            />
          )}
        </>
      )}
    </>
  );
};

export default SwitchBrowser;
