import React, { useState } from "react";
import SwitchBrowser from "./SwitchBrowser";
import {
  getRelativeDayInWeek,
  getISODay,
  addDays,
  getShortDay,
  dateDifference,
  getDateISO,
} from "../Planner/dateHelper";

import { isMobile } from "react-device-detect";
import classes from "./Home.module.scss";
import { connect } from "react-redux";
import _ from "lodash";
import {
  getRecipes,
  resetRecipeFilters,
} from "../../store/actions/recipeActions";
import { updateXp } from "../../store/actions/xpActions";
import {
  updateDay,
  clearSelected,
  insertDay,
} from "../../store/actions/plannerActions";
import {
  Message,
  Grid,
  Modal,
  Icon,
  Button,
  Container,
  Image,
} from "semantic-ui-react";
import { parseCustomCard } from "../../utility/helper";

import uuid from "react-uuid";
import amplitude from "amplitude-js";
const DayPlan = (props) => {
  let title, segmentOrder, segmentType, img;

  const [plannerModal, showModal] = useState(false);
  const [switchType, setSwitchType] = useState("main");

  switch (props.order) {
    case 1000:
      title = "Add Breakfast";
      img = "Breakfast.svg";
      break;
    case 2000:
      title = "Add Lunch";
      img = "Lunch.svg";
      break;
    case 3000:
      title = "Add Dinner";
      img = "Dinner.svg";
      break;
    case 4000:
      title = "Add Snack";
      img = "Snack.svg";
      break;
    case 5000:
      title = "Add Snack";
      img = "Snack.svg";
      break;
    case 6000:
      title = "Add Workout";
      img = "Workout.svg";

      break;
  }

  const addPlannerItems = () => {
    let segment, segmentType, date, segmentOrder, segmentServes, optionalData;
    date = props.date;

    segmentOrder = props.order;

    if (props.selectedRecipes.length) {
      const id = props.selectedRecipes[0];
      segment = _.find(props.recipes, { id: id });
      segmentType = "recipe";

      if (segment.type == "meal") {
        segmentType = "meal";
      }

      segmentServes = segment.serves;
      optionalData = 1;
    }

    if (props.selectedCards.length) {
      const id = props.selectedCards[0];
      segment = _.find(props.cards, function (i) {
        return i.id == id;
      });

      if (id.toString().includes("custom")) {
        segment = { ...segment };
        optionalData = segment;
        optionalData = JSON.stringify(optionalData);
        segment.id = 0;
      }
      segmentType = "custom";
      if (!segment) {
        return false;
      }
    }

    if (props.selectedCards.length || props.selectedRecipes.length) {
      const id = segment.id;
      const always_reduce = props.alwaysReduce;
      const recipeObject = {
        id: id,
        uuid: uuid(),
        type: segmentType,
        optional_data: optionalData,
        optional_data2: segmentServes > 1 ? always_reduce : null,
        segment_order: segmentOrder,
      };

      const recipeArray = [recipeObject];
      props.insertDay(date, recipeArray);
    } else {
      if (props.selectedWorkouts.length) {
        const itemArray = [];
        props.selectedWorkouts.map(function (el) {
          const workoutObject = {
            id: el,
            uuid: uuid(),
            type: "workout",
            optional_data: null,
            optional_data2: null,
          };
          itemArray.push({ ...workoutObject });
        });
        amplitude.getInstance().logEvent("Planned with Diary View");

        props.insertDay(date, itemArray);
      }
    }
    props.clearSelected();
  };

  return (
    <>
      <Modal
        open={plannerModal}
        size="fullscreen"
        className={classes.swapModal}
      >
        <Modal.Header>Select a {props.type || "meal"}</Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            <Container>
              <SwitchBrowser
                mode={
                  props.type == "snack"
                    ? "snacks"
                    : props.type == "workout"
                    ? "workouts"
                    : "main"
                }
                clear={() => {
                  props.clearSelected();
                }}
              />
            </Container>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() => {
              props.clearSelected();
              showModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            onClick={() => {
              addPlannerItems();
              showModal(false);
            }}
          >
            Add <Icon name="chevron right" />
          </Button>
        </Modal.Actions>
      </Modal>
      <Message
        className={`${classes.dayPlan} ${props.type || "meal"}`}
        onClick={() => showModal(true)}
      >
        <div className={classes.image}>
          <Image fluid src={`${process.env.PUBLIC_URL}/img/${img}`} />
        </div>
        <div className={classes.text}>
          <h2>
            {title} <Icon style={{ float: "right" }} name="plus circle" />
          </h2>
        </div>
      </Message>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRecipes: () => dispatch(getRecipes()),
    resetRecipeFilters: (enable) => dispatch(resetRecipeFilters(enable)),
    clearSelected: () => dispatch(clearSelected()),
    updateDay: (date, data) => dispatch(updateDay(date, data)),
    insertDay: (date, data) => dispatch(insertDay(date, data)),
  };
};
const mapStateToProps = (state) => {
  return {
    recipes: state.recipes.recipes,
    cards: state.planner.customCards,
    planner: state.planner.planner,
    recipeState: state.recipes,
    selectedRecipes: state.planner.selectedRecipes,
    selectedWorkouts: state.planner.selectedWorkouts,
    selectedCards: state.planner.selectedCards,
    alwaysReduce: state.auth.user.options.always_reduce,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DayPlan);
