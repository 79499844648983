import React from "react";
import { hydrate, render } from "react-dom";

import App from "./App";

import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistedStore } from "./store/store";
import { Loader } from "semantic-ui-react";

import "./index.css";

import "../node_modules/semantic-ui-less/semantic.less";

const AppWithRouter = (
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistedStore}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(AppWithRouter, rootElement);
} else {
  render(AppWithRouter, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
