import axios from "../../api/axios-token";
import _ from "lodash";
import uuid from "react-uuid";
import amplitude from "amplitude-js";
export const UPDATE_RECIPES = "UPDATE_RECIPES";
export const UPDATE_USER_RECIPE_DETAILS = "UPDATE_USER_RECIPE_DETAILS";
export const UPDATE_RECIPE_MACROS = "UPDATE_RECIPE_MACROS";
export const UPDATE_USER_TAGS = "UPDATE_USER_TAGS";
export const ADD_RECIPE = "ADD_RECIPE";
export const SAVE_RECIPE = "SAVE_RECIPE";
export const IMPORT_WEB_RECIPE = "IMPORT_WEB_RECIPE";
export const UPDATE_FILTER_CATEGORIES = "UPDATE_FILTER_CATEGORIES;";
export const INJECT_MACROS = "INJECT_MACROS";
export const RESET_RECIPE_FILTERS = "RESET_RECIPE_FILTERS";
export const UPDATE_INGREDIENTS_RATIO = "UPDATE_INGREDIENTS_RATIO";
export const INJECT_MACROS_ARRAY = "INJECT_MACROS_ARRAY";
export const UPDATE_RECIPE_SCREEN = "UPDATE_RECIPE_SCREEN";
export const REMOVE_RECIPE = "REMOVE_RECIPE";
export const REMOVE_RECIPES = "REMOVE_RECIPES";

export const APPLY_TAG = "APPLY_TAG";
export const REMOVE_TAG = "REMOVE_TAG";
export const APPLY_CATEGORY = "APPLY_CATEGORY";
export const REMOVE_CATEGORY = "REMOVE_CATEGORY";
export const UPDATE_ING_MACROS = "UPDATE_ING_MACROS";

export const updateRecipes = (payload) => {
  return {
    type: UPDATE_RECIPES,
    payload: payload,
  };
};

export const updateUserRecipeDetails = (payload) => {
  return {
    type: UPDATE_USER_RECIPE_DETAILS,
    payload: payload,
  };
};

export const updateRecipeScreen = (payload) => {
  return { type: UPDATE_RECIPE_SCREEN, payload: payload };
};
export const updateUserRecipeMacros = (payload) => {
  return {
    type: UPDATE_RECIPE_MACROS,
    payload: payload,
  };
};

export const injectMacros = (payload) => {
  return {
    type: INJECT_MACROS,
    payload: payload,
  };
};
export const injectMacrosArray = (payload) => {
  return {
    type: INJECT_MACROS_ARRAY,
    payload: payload,
  };
};

export const updateFilterCategories = (payload) => {
  return {
    type: UPDATE_FILTER_CATEGORIES,
    payload: payload,
  };
};

export const getUserRecipeDetails = () => {
  return (dispatch) => {
    //  dispatch(getUserRecipeMacros());
    axios
      .get("/recipes/recipe_details_user")
      .then(function (response) {
        dispatch(updateUserRecipeDetails(response.data));
      })
      .catch(function (error) {
        //NEEDTODO
        console.log(error);
      });
  };
};
export const getUserRecipeMacros = () => {
  return (dispatch) => {
    axios
      .get("/recipes/recipe_macros_user")
      .then(function (response) {
        dispatch(updateUserRecipeMacros(response.data));
      })
      .catch(function (error) {
        //NEEDTODO
        console.log(error);
      });
  };
};

export const resetRecipeFilters = (payload) => {
  return {
    type: RESET_RECIPE_FILTERS,
    payload: payload,
  };
};

export const addRecipe = (payload) => {
  return {
    type: ADD_RECIPE,
    payload: payload,
  };
};

export const insertRecipe = (payload, history) => {
  return (dispatch) => {
    dispatch(addRecipe(payload));
    return history("/recipes/view/" + payload.id);
  };
};

export const applyTagsSuccess = (array, tagID) => {
  return {
    type: APPLY_TAG,
    payload: {
      recipes: array,
      tag: tagID,
    },
  };
};

export const removeTagsSuccess = (array, tagID) => {
  return {
    type: REMOVE_TAG,
    payload: {
      recipes: array,
      tag: tagID,
    },
  };
};

export const applyTags = (array, tagID) => {
  return (dispatch) => {
    dispatch(applyTagsSuccess(array, tagID));
    axios
      .post("/recipes/add_tags", {
        recipes: array,
        tag: tagID,
      })
      .catch(function (error) {
        console.log("something went wrong");
        console.log(error);
      });
  };
};

export const removeTags = (array, tagID) => {
  return (dispatch) => {
    dispatch(removeTagsSuccess(array, tagID));
    axios
      .post("/recipes/remove_tags", {
        recipes: array,
        tag: tagID,
      })
      .catch(function (error) {
        console.log("something went wrong");
        console.log(error);
      });
  };
};

export const applyCatsSuccess = (array, tagID) => {
  return {
    type: APPLY_CATEGORY,
    payload: {
      recipes: array,
      tag: tagID,
    },
  };
};

export const removeCatsSuccess = (array, tagID) => {
  return {
    type: REMOVE_CATEGORY,
    payload: {
      recipes: array,
      tag: tagID,
    },
  };
};

export const applyCats = (array, tagID) => {
  return (dispatch) => {
    dispatch(applyCatsSuccess(array, tagID));
    axios
      .post("/recipes/add_categories", {
        recipes: array,
        category: tagID,
      })
      .catch(function (error) {
        console.log("something went wrong");
        console.log(error);
      });
  };
};

export const removeCats = (array, tagID) => {
  return (dispatch) => {
    dispatch(removeCatsSuccess(array, tagID));
    axios
      .post("/recipes/remove_categories", {
        recipes: array,
        category: tagID,
      })
      .catch(function (error) {
        console.log("something went wrong");
        console.log(error);
      });
  };
};

export const insertMeal = (payload, props) => {
  return (dispatch) => {
    dispatch(addRecipe(payload));
  };
};

export const addTag = (payload) => {
  return {
    type: UPDATE_USER_TAGS,
    payload: payload,
  };
};

export const updateRecipeState = (payload) => {
  return {
    type: SAVE_RECIPE,
    payload: payload,
  };
};

export const updateRecipe = (payload, history) => {
  return async (dispatch) => {
    await dispatch(updateRecipeState(payload));
    history("/recipes/view/" + payload.id);
  };
};

export const updateMeal = (payload, props) => {
  return (dispatch) => {
    dispatch(updateRecipeState(payload));
  };
};

export const updateMacros = (id, name, unit) => {
  return (dispatch) => {
    axios
      .post("/recipes/get_ingredient_macro", {
        ingredientID: id,
        ingredientName: name,
        unit: unit,
      })
      .then(function (response) {
        if (response.data.status == "SUCCESS") {
          dispatch(injectMacros(response.data.macros));
        }
      })
      .catch(function (error) {
        console.log("error processing: ", name);
        console.log(error);
      });
  };
};

export const updateMacrosArray = (array) => {
  return (dispatch) => {
    dispatch(injectMacrosArray(array));
  };
};

export const importWebRecipe = (payload) => {
  const detectedIngredients = payload.ingredients;
  let ingArray = [];
  const indexStart = 0;

  detectedIngredients.map((i, index) => {
    const id = uuid();
    let ingObject = {
      id: "new-" + id,
      name: i.ingredient.name,
      user_quantity: i.quantity,
      metric_translation: i.metric_translation,
      converted_unit: i.converted_unit,
      instructions: i.instruction,
      image: i.ingredient.image,
      unit: i.unit,
      ingredientID: i.ingredient.ingredientID,
      apiID: i.ingredient.api_id,
      phrase: i.phrase,
      riid: "new-" + id,
    };

    ingArray.push(ingObject);
  });

  return {
    type: IMPORT_WEB_RECIPE,
    payload: { ...payload, ingredients: ingArray },
  };
};

export const importRecipe = (url, history) => {
  return (dispatch) => {
    axios
      .post("/recipes/import", {
        url: url,
      })
      .then(function (response) {
        amplitude.getInstance().logEvent("Web Import Success");

        dispatch(importWebRecipe(response.data.recipe));
      })
      .then(function () {
        return history("/recipes/web");
      })
      .catch(function (error) {
        return history("/recipes", { state: { webError: true } });
      });
  };
};

export const getRecipes = () => {
  return (dispatch) => {
    dispatch(getUserRecipeDetails());
    axios
      .get("/recipes/get_recipes")
      .then(function (response) {
        dispatch(updateRecipes(response.data));
      })
      .catch(function (error) {
        //NEEDTODO
        console.log(error);
      });
  };
};

export const updateIngredientsRatio = (payload) => {
  return {
    type: UPDATE_INGREDIENTS_RATIO,
    payload: payload,
  };
};

export const removeRecipeSuccess = (payload) => {
  return {
    type: REMOVE_RECIPE,
    payload: payload,
  };
};

export const removeRecipesSuccess = (payload) => {
  return {
    type: REMOVE_RECIPES,
    payload: payload,
  };
};

export const removeRecipes = (payload) => {
  return (dispatch) => {
    dispatch(removeRecipesSuccess(payload));
    axios
      .post("/recipes/archive_recipes", {
        recipes: payload,
      })
      .catch(function (error) {
        console.log("something went wrong communicating to server");
      });
  };
};

export const removeRecipe = (payload, history) => {
  return (dispatch) => {
    axios
      .post("/recipes/archive_recipe", {
        hash: payload,
      })
      .then(function (response) {
        dispatch(removeRecipeSuccess(payload));
      })
      .then(function () {
        history("/recipes/my");
      })
      .catch(function (error) {
        alert("Permission denied to update recipe");
      });
  };
};

export const updateIngredientMacrosSuccess = (id, macros) => {
  return {
    type: UPDATE_ING_MACROS,
    payload: { ...macros },
  };
};

export const updateIngredientMacros = (id, macros) => {
  // update DB with the macros / and ingredientID

  // update Redux
  return (dispatch) => {
    dispatch(updateIngredientMacrosSuccess(id, macros));

    axios.post("/recipes/update_user_ingredient_macro", {
      macros: macros,
    });
  };
};
