import React, { useState, useEffect } from "react";
import Home from "./Home";
import {
  Segment,
  Grid,
  Container,
  Header,
  Image,
  Card,
  Feed,
} from "semantic-ui-react";
import useWindowSize from "../../utility/useWindowSize";
import Tour from "reactour";
import { updateOnboarding } from "../../store/actions/uiActions";
import _ from "lodash";
import Stats from "./Stats";
import { connect, useDispatch } from "react-redux";

import Timeline from "./Timeline";
import classes from "./Home.module.scss";

const HomeScreen = (props) => {
  const [tour, setTour] = useState(false);
  useEffect(() => {
    if (props.onboarding) {
      const homeBoarding = _.find(props.onboarding, {
        boarding_variable: "HOME_ONBOARDING",
      });
      if (homeBoarding.status === null) {
        setTour(true);
      }
    }
  }, []);

  const closeTour = () => {
    setTour(false);

    dispatch(updateOnboarding({ variable: "HOME_ONBOARDING", status: 0 }));
  };
  const endTour = () => {
    setTour(false);
    dispatch(updateOnboarding({ variable: "HOME_ONBOARDING", status: 1 }));
  };

  const size = useWindowSize();
  const dispatch = useDispatch();
  const steps = [
    {
      selector: '[data-tour = "container"]',
      content: (
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <Image src={`${process.env.PUBLIC_URL}/img/logo@4x.png`} />
            </Grid.Column>
            <Grid.Column>
              <Header as="h2">Welcome to your home screen</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <p>
                This screen will provide an overview of the meals you have
                chosen for the day and where you will track your meals and
                progress.
              </p>
              <p>
                At the moment, it's looking a little empty as you won't have
                planned anything yet... To do that, we'll first need some
                recipes! Let's take a look at the recipe page...
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "status"]',
      content: (
        <div>
          <h2>This is your energy bar</h2>
          <p>
            This is an easy way to monitor your progress. You'll gain energy
            points for tracking your meals and water regularly.{" "}
          </p>
          <p>
            The number on the left tracks your energy points, and the number on
            the right tracks your login streak. Remember: consistency will be
            the key to your success.
          </p>
        </div>
      ),
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector: '[data-tour = "water"]',
      content: (
        <div>
          <h2>This is your water tracker</h2>
          <p>
            It's vital that you maintain your water intake for your progress.
            Water helps flush the body of toxins, and also helps cravings and
            hunger levels.
          </p>
          <p>Click the + sign to track a cup of water for today.</p>
        </div>
      ),
      position: "bottom",
      stepInteraction: true,
      style: { padding: "3em", fontSize: "1.2em" },
    },
    {
      selector:
        size.width > 991
          ? '[data-tour = "topRecipeMenu"]'
          : '[data-tour = "recipeMenu"]',
      content: (
        <div>
          <h3>Before we start planning, we'll need some recipes. </h3>
          <p>This is the recipes button. </p>
          <p>Click it to head to the recipe page.</p>
        </div>
      ),
      stepInteraction: true,
      style: { padding: "3em", fontSize: "1.2em" },
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
        endTour();
      },
    },
  ];

  return (
    <>
      {size.width < 700 ? (
        <>
          <Tour
            rounded={15}
            showNavigationNumber={false}
            showNumber={false}
            steps={steps}
            isOpen={tour}
            accentColor="#47c73c"
            disableInteraction
            onRequestClose={() => closeTour()}
          />
          <Home />
        </>
      ) : (
        <Container className={classes.homeContainer} fluid>
          <Container>
            <Tour
              rounded={15}
              showNavigationNumber={false}
              showNumber={false}
              steps={steps}
              isOpen={tour}
              accentColor="#47c73c"
              disableInteraction
              onRequestClose={() => closeTour()}
            />
            <Grid centered>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Card style={{ marginTop: "1em" }}>
                    <Card.Content>
                      <Card.Header>Activity</Card.Header>
                    </Card.Content>
                    <Card.Content>
                      <Timeline />
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Segment
                    style={{
                      overflow: "hidden",
                      margin: "1em",
                      padding: 0,
                      height: size.height - 130 || "100%",
                    }}
                  >
                    <Home />
                  </Segment>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Grid.Row>
                    <Card style={{ marginTop: "1em" }}>
                      <Card.Content>
                        <Stats />
                      </Card.Content>
                    </Card>
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    onboarding: state.auth.user.onboarding,
  };
};

export default connect(mapStateToProps)(HomeScreen);
