import * as actionTypes from "../actions/authActions";

const initialState = {
  user: { options: { tdee: null, water: null } },
  loggingIn: false,
  loggedIn: false,
  error: null,
  loading: true,
  registering: false,
  signUp: false,
  facebook: null,
  defaultCalorieTarget: 2000,
  defaultWaterTarget: 2500,
  defaultWaterStep: 250,
};

const reducer = (state = initialState, action) => {
  let userCopy;
  let userOptions;
  switch (action.type) {
    case actionTypes.START_LOGIN:
      state = { ...state, loggingIn: true };
      return state;
    case actionTypes.LOGIN_SUCCESS:
      state = {
        ...state,
        loggingIn: false,
        user: action.user.user,
        loggedIn: true,
        loading: false,
        error: null,
      };
      return state;

    case actionTypes.REFRESH_TOKEN_SUCCESS:
      state = {
        ...state,
        loggingIn: false,
        user: action.user,
        loggedIn: true,
        loading: false,
        error: null,
      };
      return state;
    case actionTypes.LOGIN_FAIL:
      state = {
        ...state,
        loggedIn: false,
        loggingIn: false,
        error: action.error,
      };
      return state;
    case actionTypes.CHECK_AUTH_FAIL:
      state = { ...state, ...initialState, loading: false };
      return state;
    case actionTypes.INIT_FB_LOGIN:
      state = { ...state, loggingIn: true, facebook: action.payload };
      return state;
    case actionTypes.INIT_FB_SIGN_UP:
      state = { ...state, loading: false, loggingIn: false, signUp: true };
      return state;
    case actionTypes.START_SIGN_UP:
      state = { ...state, signUp: true };
      return state;
    case actionTypes.INIT_SIGN_UP:
      state = { ...state, loggingIn: true };
      return state;
    case actionTypes.CANCEL_SIGNUP:
      state = { ...state, ...initialState, loading: false };
      return state;
    case actionTypes.SIGNUP_FAIL:
      state = {
        ...state,
        registering: false,
        loggingIn: false,
        error: action.error,
      };
      return state;
    case actionTypes.CLEAR_ERRORS:
      state = {
        ...state,
        error: false,
      };
    case actionTypes.LOG_OUT:
      state = { ...state, ...initialState, loading: false };

      return state;
    case actionTypes.UPDATE_TDEE:
      userCopy = state.user;
      userOptions = userCopy.options;
      userOptions.tdee = action.payload;
      return { ...state, user: { ...userCopy, options: { ...userOptions } } };
    case actionTypes.UPDATE_USER_IMAGE:
      userCopy = state.user;
      userOptions = userCopy.options;
      userOptions.userImage = action.payload.userImage;
      userOptions.profileImage = action.payload.profileImage;
      return { ...state, user: { ...userCopy, options: { ...userOptions } } };
    case actionTypes.UPDATE_WATER:
      userCopy = state.user;
      userOptions = userCopy.options;
      userOptions.water = action.payload;
      return { ...state, user: { ...userCopy, options: { ...userOptions } } };
    case actionTypes.UPDATE_START_DAY:
      userCopy = state.user;
      userOptions = userCopy.options;
      userOptions.startDay = action.payload;
      return { ...state, user: { ...userCopy, options: { ...userOptions } } };
    case actionTypes.UPDATE_ALWAYS_REDUCE:
      userCopy = state.user;
      userOptions = userCopy.options;
      userOptions.always_reduce = action.payload;
      return { ...state, user: { ...userCopy, options: { ...userOptions } } };
    case actionTypes.UPDATE_TERMS:
      userCopy = state.user;
      return { ...state, user: { ...userCopy, ...action.payload } };
    case actionTypes.UPDATE_ONBOARDING:
      let onboardingCopy = { ...state.user };
      delete onboardingCopy.onboarding;
      onboardingCopy.onboarding = { ...action.payload };
      return { ...state, user: { ...onboardingCopy } };
    default:
      return state;
  }
};

export default reducer;
