import React from "react";
import { Card, Grid, Icon, Placeholder } from "semantic-ui-react";
import recipes from "./Recipes.module.scss";

const RecipePlaceholder = (props) => {
  return (
    <Card fluid className={`${recipes.Card}`}>
      <Card.Content className={`${recipes.recipeContainer}`}>
        <Grid>
          <Grid.Column width={8} className={recipes.imageColumn}>
            <Placeholder style={{ height: 150, width: 150 }}>
              <Placeholder.Image />
            </Placeholder>
          </Grid.Column>

          <Grid.Column className={recipes.recipeCard} width={8}>
            <Placeholder>
              <Placeholder.Line length="full" />
              <Placeholder.Line length="medium" />
              <Placeholder.Line length="long" />
              <Placeholder.Line length="short" />
              <Placeholder.Line length="short" />
              <Placeholder.Line length="very short" />
            </Placeholder>
          </Grid.Column>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default RecipePlaceholder;
