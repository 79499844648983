import React from "react";
import recipes from "../../screens/Recipes/Recipes.module.scss";
import { Segment, Header, Icon } from "semantic-ui-react";
const recipeImage = (props) => {
  let image = null;
  if (props.image) {
    if (props.source_method === "web") {
      image = props.image;
    } else {
      image = `${process.env.REACT_APP_API}/recipe_images/${props.image}`;
    }
  }

  return (
    <>
      {image ? (
        <div
          style={{
            background: `url( "${image}")`,
          }}
          className={recipes.Image}
        ></div>
      ) : (
        <Segment
          basic
          placeholder
          style={{ margin: "0 -1em", background: "#ddd", minHeight: "auto" }}
        >
          <Header icon style={{ margin: 0 }}>
            <Icon
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#ccc",
              }}
              name="image outline"
            />
          </Header>
        </Segment>
      )}
    </>
  );
};

export default recipeImage;
