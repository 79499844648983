const getRecipes = () => {
  return [
    {
      name: "House Style Lamb Curry",
      image: "lamb_curry.jpg",
      id: "1x1ZLoED",
      serves: "1",
      prepTime: null,
      cookTime: null,
      user_ratio: null,
      real_id: "1461258",
      ingredients: [
        {
          id: "694",
          ingredientID: "519",
          image: "Food C225 Steak.svg",
          default_quantity: "130",
          unit: "g",
          instructions: null,
          name: "sirloin steak",
          riid: "694",
          user_quantity: "130",
          converted_unit: "g",
          metric_translation: "1",
        },
        {
          id: "695",
          ingredientID: "10",
          image: null,
          default_quantity: "2",
          unit: "tsp",
          instructions: null,
          name: "olive oil",
          riid: "695",
          user_quantity: "1.5",
          converted_unit: "ml/g",
          metric_translation: "5",
        },
        {
          id: "696",
          ingredientID: "156",
          image: null,
          default_quantity: "210",
          unit: "g",
          instructions: null,
          name: "sweet potatoes",
          riid: "696",
          user_quantity: "210",
          converted_unit: "g",
          metric_translation: "1",
        },
        {
          id: "697",
          ingredientID: "749",
          image: null,
          default_quantity: "1",
          unit: "whole",
          instructions: null,
          name: "portobello mushrooms",
          riid: "697",
          user_quantity: "1",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "698",
          ingredientID: "13",
          image: "Food C238 Garlic.svg",
          default_quantity: "1",
          unit: "whole",
          instructions: null,
          name: "garlic cloves",
          riid: "698",
          user_quantity: "1",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "699",
          ingredientID: "78",
          image: null,
          default_quantity: "1",
          unit: "handful",
          instructions: null,
          name: "spinach",
          riid: "699",
          user_quantity: "1",
          converted_unit: "handfuls",
          metric_translation: "1",
        },
        {
          id: "700",
          ingredientID: "9204",
          image: "Food C234 Yogurt.svg",
          default_quantity: "1",
          unit: "tbsp",
          instructions: null,
          name: "greek yoghurt",
          riid: "700",
          user_quantity: "1",
          converted_unit: "ml/g",
          metric_translation: "15",
        },
        {
          id: "701",
          ingredientID: "61",
          image: null,
          default_quantity: "1",
          unit: "tsp",
          instructions: null,
          name: "dijon mustard",
          riid: "701",
          user_quantity: "1",
          converted_unit: "ml/g",
          metric_translation: "5",
        },
      ],
    },

    {
      id: "84rN60K4",
      name: "Pad Thai",
      image: "pad_thai.jpg",
      serves: "1",
      prepTime: null,
      cookTime: null,
      user_ratio: null,
      real_id: "1461272",
      ingredients: [
        {
          id: "827",
          ingredientID: "1328",
          image: "Food C40 Coconut.svg",
          default_quantity: "1",
          unit: "tsp",
          instructions: null,
          name: "coconut oil",
          riid: "827",
          user_quantity: "1",
          converted_unit: "ml/g",
          metric_translation: "5",
        },
        {
          id: "828",
          ingredientID: "9205",
          image: null,
          default_quantity: "1",
          unit: "whole",
          instructions: null,
          name: "small onion",
          riid: "828",
          user_quantity: "0.5",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "829",
          ingredientID: "22",
          image: "Food C25 Carrots.svg",
          default_quantity: "1",
          unit: "whole",
          instructions: null,
          name: "carrots",
          riid: "829",
          user_quantity: "0.5",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "830",
          ingredientID: "13",
          image: "Food C238 Garlic.svg",
          default_quantity: "1",
          unit: "whole",
          instructions: null,
          name: "garlic cloves",
          riid: "830",
          user_quantity: "1",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "831",
          ingredientID: "729",
          image: "Food C05 Kale.svg",
          default_quantity: "105",
          unit: "g",
          instructions: null,
          name: "kale",
          riid: "831",
          user_quantity: "105",
          converted_unit: "g",
          metric_translation: "1",
        },
        {
          id: "832",
          ingredientID: "9241",
          image: null,
          default_quantity: "30",
          unit: "g",
          instructions: null,
          name: "uncooked brown rice",
          riid: "832",
          user_quantity: "30",
          converted_unit: "g",
          metric_translation: "1",
        },
        {
          id: "833",
          ingredientID: "9523",
          image: "Food C147 Baked chicken.svg",
          default_quantity: "125",
          unit: "g",
          instructions: null,
          name: "cooked chicken breast",
          riid: "833",
          user_quantity: "125",
          converted_unit: "g",
          metric_translation: "1",
        },
        {
          id: "834",
          ingredientID: "411",
          image: null,
          default_quantity: "2",
          unit: "tsp",
          instructions: null,
          name: "fish sauce",
          riid: "834",
          user_quantity: "2",
          converted_unit: "ml/g",
          metric_translation: "5",
        },
        {
          id: "835",
          ingredientID: "9253",
          image: null,
          default_quantity: "1",
          unit: "tbsp",
          instructions: null,
          name: "sriracha chilli sauce",
          riid: "835",
          user_quantity: "1",
          converted_unit: "ml/g",
          metric_translation: "15",
        },
        {
          id: "836",
          ingredientID: "7",
          image: "Food C227 Boiled Eggs.svg",
          default_quantity: "1",
          unit: "whole",
          instructions: null,
          name: "eggs",
          riid: "836",
          user_quantity: "1",
          converted_unit: "whole",
          metric_translation: "1",
        },
      ],
    },
    {
      name: "Chicken Teriyaki",
      image: "teriyaki.png",
      id: "k4oNbMEW",
      serves: "1",
      prepTime: null,
      cookTime: null,
      user_ratio: null,
      real_id: "1461299",
      ingredients: [
        {
          id: "1087",
          ingredientID: "1203",
          image: null,
          default_quantity: "30",
          unit: "ml",
          instructions: null,
          name: "dark soy sauce",
          riid: "1087",
          user_quantity: "30",
          converted_unit: "ml",
          metric_translation: "1",
        },
        {
          id: "1088",
          ingredientID: "17",
          image: "Food C172 Sugar.svg",
          default_quantity: "2",
          unit: "tsp",
          instructions: null,
          name: "brown sugar",
          riid: "1088",
          user_quantity: "1.5",
          converted_unit: "ml/g",
          metric_translation: "5",
        },
        {
          id: "1089",
          ingredientID: "242",
          image: null,
          default_quantity: "2",
          unit: "tsp",
          instructions: null,
          name: "rice vinegar",
          riid: "1089",
          user_quantity: "1.5",
          converted_unit: "ml/g",
          metric_translation: "5",
        },
        {
          id: "1090",
          ingredientID: "154",
          image: null,
          default_quantity: "1",
          unit: "tsp",
          instructions: null,
          name: "sesame oil",
          riid: "1090",
          user_quantity: "0.5",
          converted_unit: "ml/g",
          metric_translation: "5",
        },
        {
          id: "1091",
          ingredientID: "13",
          image: "Food C238 Garlic.svg",
          default_quantity: "1",
          unit: "whole",
          instructions: null,
          name: "garlic cloves",
          riid: "1091",
          user_quantity: "1",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "1092",
          ingredientID: "10843",
          image: null,
          default_quantity: "165",
          unit: "g",
          instructions: null,
          name: "chicken thigh fillets",
          riid: "1092",
          user_quantity: "165",
          converted_unit: "g",
          metric_translation: "1",
        },
        {
          id: "1093",
          ingredientID: "755",
          image: null,
          default_quantity: "2",
          unit: "tsp",
          instructions: null,
          name: "toasted sesame seeds",
          riid: "1093",
          user_quantity: "1.5",
          converted_unit: "ml/g",
          metric_translation: "5",
        },
        {
          id: "1094",
          ingredientID: "9270",
          image: "Food C14 Broccoli.svg",
          default_quantity: "105",
          unit: "g",
          instructions: null,
          name: "tenderstem broccoli",
          riid: "1094",
          user_quantity: "105",
          converted_unit: "g",
          metric_translation: "1",
        },
        {
          id: "1095",
          ingredientID: "4582",
          image: null,
          default_quantity: "55",
          unit: "g",
          instructions: null,
          name: "edamame beans",
          riid: "1095",
          user_quantity: "55",
          converted_unit: "g",
          metric_translation: "1",
        },
        {
          id: "1096",
          ingredientID: "9180",
          image: "food C243 Leaf.svg",
          default_quantity: "1",
          unit: "handful",
          instructions: null,
          name: "green salad leaves",
          riid: "1096",
          user_quantity: "1",
          converted_unit: "handfuls",
          metric_translation: "1",
        },
        {
          id: "1097",
          ingredientID: "9836",
          image: null,
          default_quantity: "30",
          unit: "g",
          instructions: null,
          name: "pickled ginger",
          riid: "1097",
          user_quantity: "30",
          converted_unit: "g",
          metric_translation: "1",
        },
      ],
    },
    {
      id: "X4RAZv7W",
      name: "Chicken Korma",
      image: "chicken_korma.png",
      serves: "1",
      prepTime: null,
      cookTime: null,
      master_cycle: "C2",
      user_ratio: null,
      real_id: "1461300",
      ingredients: [
        {
          id: "1098",
          ingredientID: "1328",
          image: "Food C40 Coconut.svg",
          default_quantity: "1",
          unit: "tsp",
          instructions: null,
          name: "coconut oil",
          riid: "1098",
          user_quantity: "1",
          converted_unit: "ml/g",
          metric_translation: "5",
        },
        {
          id: "1099",
          ingredientID: "9336",
          image: null,
          default_quantity: "2",
          unit: "tsp",
          instructions: null,
          name: "thai green curry paste",
          riid: "1099",
          user_quantity: "2",
          converted_unit: "ml/g",
          metric_translation: "5",
        },
        {
          id: "1100",
          ingredientID: "156",
          image: null,
          default_quantity: "70",
          unit: "g",
          instructions: null,
          name: "sweet potatoes",
          riid: "1100",
          user_quantity: "70",
          converted_unit: "g",
          metric_translation: "1",
        },
        {
          id: "1101",
          ingredientID: "36",
          image: "Food C236 Onion.svg",
          default_quantity: "1",
          unit: "whole",
          instructions: null,
          name: "red onion",
          riid: "1101",
          user_quantity: "0.5",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "1102",
          ingredientID: "9216",
          image: "Food C12 Chilli.svg",
          default_quantity: "1",
          unit: "whole",
          instructions: null,
          name: "red chilli",
          riid: "1102",
          user_quantity: "0.5",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "1103",
          ingredientID: "9223",
          image: "food C247 Bell Pepper.svg",
          default_quantity: "1",
          unit: "whole",
          instructions: null,
          name: "red pepper",
          riid: "1103",
          user_quantity: "0.5",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "1104",
          ingredientID: "342",
          image: "Food C82 Coconut Drink.svg",
          default_quantity: "95",
          unit: "ml",
          instructions: null,
          name: "coconut milk",
          riid: "1104",
          user_quantity: "95",
          converted_unit: "ml",
          metric_translation: "1",
        },
        {
          id: "1105",
          ingredientID: "728",
          image: null,
          default_quantity: "75",
          unit: "g",
          instructions: null,
          name: "sugar snap pea",
          riid: "1105",
          user_quantity: "75",
          converted_unit: "g",
          metric_translation: "1",
        },
        {
          id: "1106",
          ingredientID: "9298",
          image: null,
          default_quantity: "125",
          unit: "g",
          instructions: null,
          name: "raw king prawns",
          riid: "1106",
          user_quantity: "125",
          converted_unit: "g",
          metric_translation: "1",
        },
        {
          id: "1107",
          ingredientID: "411",
          image: null,
          default_quantity: "2",
          unit: "tsp",
          instructions: null,
          name: "fish sauce",
          riid: "1107",
          user_quantity: "1.5",
          converted_unit: "ml/g",
          metric_translation: "5",
        },
        {
          id: "1108",
          ingredientID: "17",
          image: "Food C172 Sugar.svg",
          default_quantity: "1",
          unit: "tsp",
          instructions: null,
          name: "brown sugar",
          riid: "1108",
          user_quantity: "0.5",
          converted_unit: "ml/g",
          metric_translation: "5",
        },
        {
          id: "1109",
          ingredientID: "98",
          image: null,
          default_quantity: "1",
          unit: "whole",
          instructions: null,
          name: "lime juice",
          riid: "1109",
          user_quantity: "0.5",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "1110",
          ingredientID: "359",
          image: "Food C09 Coriander Leaf.svg",
          default_quantity: "1",
          unit: "sm bunch",
          instructions: null,
          name: "coriander",
          riid: "1110",
          user_quantity: "1",
          converted_unit: "small bunch",
          metric_translation: "1",
        },
      ],
    },
    {
      id: "kWL3eYLD",
      serves: "1",
      prepTime: null,
      cookTime: null,
      user_ratio: null,
      real_id: "1461549",
      name: "Breakfast Omelette",
      image: "breakfast_omelette.png",
      ingredients: [
        {
          id: "3126",
          ingredientID: "2037",
          image: "Food C09 Coriander Leaf.svg",
          default_quantity: "1",
          unit: "handful",
          instructions: null,
          name: "rocket",
          riid: "3126",
          user_quantity: "1",
          converted_unit: "handfuls",
          metric_translation: "1",
        },
        {
          id: "3127",
          ingredientID: "156",
          image: null,
          default_quantity: "75",
          unit: "g",
          instructions: null,
          name: "sweet potatoes",
          riid: "3127",
          user_quantity: "75",
          converted_unit: "g",
          metric_translation: "1",
        },
        {
          id: "3128",
          ingredientID: "10",
          image: null,
          default_quantity: "1",
          unit: "tsp",
          instructions: null,
          name: "olive oil",
          riid: "3128",
          user_quantity: "1",
          converted_unit: "ml/g",
          metric_translation: "5",
        },
        {
          id: "3129",
          ingredientID: "643",
          image: "Food C07 Spring Onion.svg",
          default_quantity: "30",
          unit: "g",
          instructions: null,
          name: "spring onions",
          riid: "3129",
          user_quantity: "30",
          converted_unit: "g",
          metric_translation: "1",
        },
        {
          id: "3130",
          ingredientID: "7",
          image: "Food C227 Boiled Eggs.svg",
          default_quantity: "2",
          unit: "whole",
          instructions: null,
          name: "eggs",
          riid: "3130",
          user_quantity: "2",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "3131",
          ingredientID: "62",
          image: "Food C203 Fried Egg.svg",
          default_quantity: "1",
          unit: "whole",
          instructions: null,
          name: "egg whites",
          riid: "3131",
          user_quantity: "1",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "3132",
          ingredientID: "635",
          image: null,
          default_quantity: "1",
          unit: "whole",
          instructions: null,
          name: "roasted red pepper",
          riid: "3132",
          user_quantity: "0.5",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "3133",
          ingredientID: "9189",
          image: null,
          default_quantity: "1",
          unit: "whole",
          instructions: null,
          name: "plain bagel",
          riid: "3133",
          user_quantity: "1",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "3134",
          ingredientID: "9490",
          image: null,
          default_quantity: "1",
          unit: "tbsp",
          instructions: null,
          name: "chilli sauce",
          riid: "3134",
          user_quantity: "1",
          converted_unit: "ml/g",
          metric_translation: "15",
        },
      ],
    },
    {
      id: "qDnGK36W",
      serves: "1",
      name: "Bangers and Mash",
      image: "bangers_and_mash.png",
      prepTime: null,
      cookTime: null,
      user_ratio: null,
      real_id: "1461550",
      ingredients: [
        {
          id: "3135",
          ingredientID: "543",
          image: "Food C10 Potato.svg",
          default_quantity: "210",
          unit: "g",
          instructions: null,
          name: "new potatoes",
          riid: "3135",
          user_quantity: "210",
          converted_unit: "g",
          metric_translation: "1",
        },
        {
          id: "3136",
          ingredientID: "9191",
          image: "Food C101 Sausages.svg",
          default_quantity: "3",
          unit: "whole",
          instructions: null,
          name: "chicken sausages",
          riid: "3136",
          user_quantity: "3",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "3137",
          ingredientID: "10",
          image: null,
          default_quantity: "1",
          unit: "tsp",
          instructions: null,
          name: "olive oil",
          riid: "3137",
          user_quantity: "1",
          converted_unit: "ml/g",
          metric_translation: "5",
        },
        {
          id: "3138",
          ingredientID: "13",
          image: "Food C238 Garlic.svg",
          default_quantity: "1",
          unit: "whole",
          instructions: null,
          name: "garlic cloves",
          riid: "3138",
          user_quantity: "1",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "3139",
          ingredientID: "643",
          image: "Food C07 Spring Onion.svg",
          default_quantity: "55",
          unit: "g",
          instructions: null,
          name: "spring onions",
          riid: "3139",
          user_quantity: "55",
          converted_unit: "g",
          metric_translation: "1",
        },
        {
          id: "3140",
          ingredientID: "9303",
          image: null,
          default_quantity: "105",
          unit: "g",
          instructions: null,
          name: "tinned chickpeas",
          riid: "3140",
          user_quantity: "105",
          converted_unit: "g",
          metric_translation: "1",
        },
        {
          id: "3141",
          ingredientID: "7",
          image: "Food C227 Boiled Eggs.svg",
          default_quantity: "1",
          unit: "whole",
          instructions: null,
          name: "eggs",
          riid: "3141",
          user_quantity: "1",
          converted_unit: "whole",
          metric_translation: "1",
        },
        {
          id: "3142",
          ingredientID: "61",
          image: null,
          default_quantity: "1",
          unit: "tsp",
          instructions: null,
          name: "dijon mustard",
          riid: "3142",
          user_quantity: "1",
          converted_unit: "ml/g",
          metric_translation: "5",
        },
        {
          id: "3143",
          ingredientID: "124",
          image: "Food C23 Herb.svg",
          default_quantity: "1",
          unit: "sm bunch",
          instructions: null,
          name: "parsley",
          riid: "3143",
          user_quantity: "1",
          converted_unit: "small bunch",
          metric_translation: "1",
        },
      ],
    },
  ];
};

const getRawMacros = () => {
  return [
    {
      auth: {
        user: {
          id: "wex3R4vy",
          first_name: "Rob",
          last_name: "Owen",
          provider_id: false,
          permissions: ["admin", "members"],
          options: {
            startDay: 1,
            always_reduce: null,
            tdee: {
              age: 32,
              sex: 0,
              goal: 0,
              meal: 698,
              ratio: 91.5,
              snack: 194,
              height: 180,
              weight: 80,
              exercise: 2,
              lifestyle: 0,
              dailyTarget: 2287,
            },
          },
          onboarding: [
            {
              boarding_variable: "BODYCOACH_CATEGORIES",
              boardingid: "1",
              status: "1",
            },
            {
              boarding_variable: "INTRODUCTION",
              boardingid: "2",
              status: "1",
            },
            {
              boarding_variable: "HOME_ONBOARDING",
              boardingid: "3",
              status: "1",
            },
            {
              boarding_variable: "RECIPES_ONBOARDING",
              boardingid: "5",
              status: "0",
            },
            {
              boarding_variable: "PLANNER_ONBOARDING",
              boardingid: "4",
              status: "0",
            },
          ],
        },
        loggingIn: false,
        loggedIn: true,
        error: null,
        loading: false,
        registering: false,
        signUp: false,
        facebook: null,
        defaultCalorieTarget: 2000,
      },
      recipes: {
        recipes: null,
        tags: null,
        raw_macros: [
          {
            id: "1328",
            name: "coconut oil",
            api_id: null,
            unit: "tsp",
            converted_unit: "ml/g",
            metric_translation: "5",
            macro_id: "569",
            macro_unit: "tsp",
            weight: "4.5",
            protein: "0.00",
            carbs: "0.00",
            fat: "4.49",
            calories: "40.4",
            verified: "0",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "9205",
            name: "small onion",
            api_id: null,
            unit: "whole",
            converted_unit: "whole",
            metric_translation: "1",
            macro_id: "377",
            macro_unit: "whole",
            weight: "60.0",
            protein: "0.80",
            carbs: "6.10",
            fat: "0.10",
            calories: "26.0",
            verified: "1",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "22",
            name: "carrots",
            api_id: null,
            unit: "whole",
            converted_unit: "whole",
            metric_translation: "1",
            macro_id: "315",
            macro_unit: "whole",
            weight: "65.0",
            protein: "0.50",
            carbs: "5.40",
            fat: "0.10",
            calories: "22.6",
            verified: "1",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "13",
            name: "garlic cloves",
            api_id: null,
            unit: "whole",
            converted_unit: "whole",
            metric_translation: "1",
            macro_id: "328",
            macro_unit: "whole",
            weight: "6.0",
            protein: "0.40",
            carbs: "2.00",
            fat: "0.00",
            calories: "9.0",
            verified: "1",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "729",
            name: "kale",
            api_id: null,
            unit: "g",
            converted_unit: "g",
            metric_translation: "1",
            macro_id: "97",
            macro_unit: "g",
            weight: "100.0",
            protein: "4.00",
            carbs: "9.00",
            fat: "1.00",
            calories: "49.0",
            verified: "1",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "9241",
            name: "uncooked brown rice",
            api_id: null,
            unit: "g",
            converted_unit: "g",
            metric_translation: "1",
            macro_id: "199",
            macro_unit: "g",
            weight: "100.0",
            protein: "7.20",
            carbs: "76.70",
            fat: "2.40",
            calories: "359.4",
            verified: "1",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "9523",
            name: "cooked chicken breast",
            api_id: null,
            unit: "g",
            converted_unit: "g",
            metric_translation: "1",
            macro_id: "50",
            macro_unit: "g",
            weight: "100.0",
            protein: "30.60",
            carbs: "1.00",
            fat: "1.20",
            calories: "141.0",
            verified: "1",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "411",
            name: "fish sauce",
            api_id: null,
            unit: "tsp",
            converted_unit: "ml/g",
            metric_translation: "5",
            macro_id: "713",
            macro_unit: "tsp",
            weight: "6.0",
            protein: "0.30",
            carbs: "0.22",
            fat: "0.00",
            calories: "2.1",
            verified: "1",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "9253",
            name: "sriracha chilli sauce",
            api_id: "17300712",
            converted_unit: "ml/g",
            unit: "tbsp",
            macro_unit: "ml/g",
            volume_conversion: "5.000",
            real_unit: "g",
            weight: "5.000",
            protein: "0.05",
            fat: "0.01",
            carbs: "1.14",
            calories: "5",
          },
          {
            id: "7",
            name: "eggs",
            api_id: null,
            unit: "whole",
            converted_unit: "whole",
            metric_translation: "1",
            macro_id: "325",
            macro_unit: "whole",
            weight: "50.0",
            protein: "6.30",
            carbs: "0.40",
            fat: "4.80",
            calories: "72.0",
            verified: "1",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "519",
            name: "sirloin steak",
            api_id: null,
            unit: "g",
            converted_unit: "g",
            metric_translation: "1",
            macro_id: "167",
            macro_unit: "g",
            weight: "100.0",
            protein: "30.00",
            carbs: "0.00",
            fat: "7.00",
            calories: "194.0",
            verified: "1",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "10",
            name: "olive oil",
            api_id: null,
            unit: "tsp",
            converted_unit: "ml/g",
            metric_translation: "5",
            macro_id: "584",
            macro_unit: "tsp",
            weight: "4.5",
            protein: "0.00",
            carbs: "0.00",
            fat: "4.50",
            calories: "39.8",
            verified: "0",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "156",
            name: "sweet potatoes",
            api_id: null,
            unit: "g",
            converted_unit: "g",
            metric_translation: "1",
            macro_id: "1099",
            macro_unit: "g",
            weight: "280.0",
            protein: "5.60",
            carbs: "58.80",
            fat: "0.40",
            calories: "252.0",
            verified: "0",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "749",
            name: "portobello mushrooms",
            api_id: "6548",
            converted_unit: "whole",
            unit: "whole",
            macro_unit: "whole",
            volume_conversion: null,
            real_unit: null,
            weight: "28.350",
            protein: "0.60",
            fat: "0.78",
            carbs: "1.46",
            calories: "14",
          },
          {
            id: "78",
            name: "spinach",
            api_id: "36577",
            converted_unit: "handfuls",
            unit: "handful",
            macro_unit: "g",
            volume_conversion: null,
            real_unit: null,
            weight: "100.000",
            protein: "2.86",
            fat: "0.39",
            carbs: "3.63",
            calories: "23",
          },
          {
            id: "9204",
            name: "greek yoghurt",
            api_id: "32696412",
            converted_unit: "ml/g",
            unit: "tbsp",
            macro_unit: "ml/g",
            volume_conversion: 6.0047587713262764,
            real_unit: "g",
            weight: 6.0047587713262764,
            protein: 0.24344292852085278,
            fat: 0.5519374103977401,
            carbs: 0.30273992138769973,
            calories: 7.0305717280945155,
          },
          {
            id: "61",
            name: "dijon mustard",
            api_id: "294912",
            converted_unit: "ml/g",
            unit: "tsp",
            macro_unit: "ml/g",
            volume_conversion: 7.505948464157846,
            real_unit: "g",
            weight: 7.505948464157846,
            protein: 0.2964849643342349,
            fat: 0.233434997235309,
            carbs: 0.5839627905114804,
            calories: 4.953925986344178,
          },
          {
            id: "1203",
            name: "dark soy sauce",
            api_id: "6874",
            converted_unit: "ml",
            unit: "ml",
            macro_unit: "ml",
            volume_conversion: null,
            real_unit: null,
            weight: "93.000",
            protein: "2.04",
            fat: "0.25",
            carbs: "8.98",
            calories: "42",
          },
          {
            id: "17",
            name: "brown sugar",
            api_id: null,
            unit: "tsp",
            converted_unit: "ml/g",
            metric_translation: "5",
            macro_id: "715",
            macro_unit: "tsp",
            weight: "3.0",
            protein: "0.00",
            carbs: "2.94",
            fat: "0.00",
            calories: "11.4",
            verified: "1",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "242",
            name: "rice vinegar",
            api_id: null,
            unit: "tsp",
            converted_unit: "ml/g",
            metric_translation: "5",
            macro_id: "902",
            macro_unit: "tsp",
            weight: "1.0",
            protein: "0.00",
            carbs: "0.00",
            fat: "0.00",
            calories: "0.0",
            verified: "0",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "154",
            name: "sesame oil",
            api_id: null,
            unit: "tsp",
            converted_unit: "ml/g",
            metric_translation: "5",
            macro_id: "611",
            macro_unit: "tsp",
            weight: "4.5",
            protein: "0.00",
            carbs: "0.00",
            fat: "4.53",
            calories: "40.1",
            verified: "0",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "10843",
            name: "chicken thigh fillets",
            api_id: null,
            unit: "g",
            converted_unit: "g",
            metric_translation: "1",
            macro_id: "836",
            macro_unit: "g",
            weight: "200.0",
            protein: "36.60",
            carbs: "0.00",
            fat: "19.60",
            calories: "322.0",
            verified: "0",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "755",
            name: "toasted sesame seeds",
            api_id: null,
            unit: "tsp",
            converted_unit: "ml/g",
            metric_translation: "5",
            macro_id: "903",
            macro_unit: "tsp",
            weight: "1.5",
            protein: "0.90",
            carbs: "1.90",
            fat: "3.80",
            calories: "42.2",
            verified: "0",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "9270",
            name: "tenderstem broccoli",
            api_id: null,
            unit: "g",
            converted_unit: "g",
            metric_translation: "1",
            macro_id: "184",
            macro_unit: "g",
            weight: "100.0",
            protein: "4.30",
            carbs: "3.10",
            fat: "0.60",
            calories: "40.0",
            verified: "1",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "4582",
            name: "edamame beans",
            api_id: null,
            unit: "g",
            converted_unit: "g",
            metric_translation: "1",
            macro_id: "70",
            macro_unit: "g",
            weight: "100.0",
            protein: "4.00",
            carbs: "29.00",
            fat: "0.00",
            calories: "137.0",
            verified: "1",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "9180",
            name: "green salad leaves",
            api_id: null,
            unit: "handful",
            converted_unit: "handfuls",
            metric_translation: "1",
            macro_id: "2057",
            macro_unit: "handful",
            weight: "62.0",
            protein: "2.45",
            carbs: "4.89",
            fat: "0.22",
            calories: "25.0",
            verified: "0",
            real_unit: "g",
            volume_conversion: null,
            db_existed: true,
          },
          {
            id: "9836",
            name: "pickled ginger",
            api_id: null,
            unit: "g",
            converted_unit: "g",
            metric_translation: "1",
            macro_id: "507",
            macro_unit: "g",
            weight: "30.0",
            protein: "0.20",
            carbs: "5.90",
            fat: "0.10",
            calories: "27.0",
            verified: "0",
            real_unit: null,
            volume_conversion: null,
            db_existed: true,
          },
        ],
        user_categories: null,
        default_categories: null,
        recipe_filter_category: null,
        recipesLoaded: false,
        recipe_details: null,
        recipeDetailsLoaded: false,
        edit_ingredients: false,
        edit_recipe: false,
        importedRecipe: null,
      },
      planner: {
        planner: [],
        plannerLoaded: false,
        dragging: false,
        workouts: [],
        selectedRecipes: [],
        selectedWorkouts: [],
        selectedCards: [],
        customCards: [
          {
            id: 1,
            type: "custom",
            name: "Treat Meal",
            icon: "treat",
          },
          {
            id: 2,
            type: "custom",
            name: "Eating Out",
            icon: "eatout",
          },
        ],
      },
      shopping: {
        shopping: [],
        initShopping: false,
        purchased: [],
        additional: [],
        stockCupboard: [],
        extra: [],
        filter: "aisle",
      },
      ui: {
        ui: {
          bottomNavActive: "home",
        },
        recipeScreen: null,
        overlay: null,
      },
      xp: {
        xp: [],
        existingTotal: 0,
        total: 0,
      },
      diary: {
        diary: [],
        streak: 0,
      },
    },
  ];
};

export default getRecipes;
