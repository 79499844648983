import * as actionTypes from "../actions/diaryActions";
import _ from "lodash";
const initialState = {
  diary: [],
  streak: 0,
};

const reducer = (state = initialState, action) => {
  let diaryCopy;
  switch (action.type) {
    case actionTypes.INSERT_DIARY:
      return { ...state, diary: [...state.diary, action.payload] };

    case actionTypes.UPDATE_DIARY_SEGMENT:
      console.log(action.payload);
      diaryCopy = [...state.diary];
      _.remove(diaryCopy, { planner_uuid: action.uuid });
      return { ...state, diary: [...diaryCopy, action.payload] };

    case actionTypes.REPLACE_PLAN_RECIPE:
      diaryCopy = [...state.diary];
      _.remove(diaryCopy, { planner_uuid: action.payload.planner_uuid });

      return { ...state, diary: [...diaryCopy, action.payload] };
    case actionTypes.HYDRATE_DIARY:
      return { ...state, diary: [...action.payload] };
    case actionTypes.UPDATE_STREAK:
      return { ...state, streak: action.payload.amount };
    default:
      return state;
  }
};

export default reducer;
