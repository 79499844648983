import React, { useState, useEffect } from "react";
import {
  Header,
  Grid,
  Button,
  Segment,
  Form,
  Dimmer,
  Loader,
  Message,
  Modal,
  Divider,
  Image,
  Container,
} from "semantic-ui-react";
import { connect, useDispatch } from "react-redux";
import {
  login,
  facebookLogin,
  startSignup,
  clearErrors,
} from "../../store/actions/authActions";
import { Navigate, useNavigate } from "react-router-dom";
import FBLoginButton from "../../components/login/fbLoginButton";
import axios from "../../api/axios-auth";
import logo from "../../logo.png";
import classes from "../Landing/Landing.module.scss";
import PASButton from "./PlanandShopLogin";

const SignIn = (props) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [forgotPassword, setforgotPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState();
  const history = useNavigate();
  const facebookResponse = (response) => {
    props.facebook_login(response);
  };
  const dispatch = useDispatch();
  //      icon={<i className="fa fa-facebook" style={{ marginLeft: "5px" }}></i>}

  const passwordReset = () => {
    if (!resetEmail) {
      alert("You didn't enter an email!");
      return;
    }
    axios
      .post("/forgot_password", {
        email: resetEmail,
      })
      .then(function (response) {
        setforgotPassword(false);
      })
      .catch(function (error) {
        alert(
          "Something went wrong, if this problem continues please contact us."
        );
        setforgotPassword(false);
      });
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    const payload = { props, email, password };
    props.login(payload);
  };

  if (props.auth.loggedIn) {
    return <Navigate to="/home" />;
  } else {
    if (props.auth.facebook && props.auth.signUp) {
      return <Navigate to="/sign-up" />;
    }
    return (
      <Container
        className={classes.Container}
        fluid
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/img/landing/spheres.png)`,
        }}
      >
        {forgotPassword && (
          <Modal
            onClose={() => setforgotPassword(false)}
            onOpen={() => setforgotPassword(true)}
            open={forgotPassword}
            trigger={<Button>Show Modal</Button>}
          >
            <Modal.Header>Forgot Password</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Header>Enter your email address:</Header>
                <Form.Input
                  fluid
                  icon="mail"
                  iconPosition="left"
                  placeholder="Enter email"
                  type="email"
                  onChange={(e) => {
                    setResetEmail(e.target.value);
                  }}
                />
                <Divider hidden />
                <p>
                  If your email address exists we'll send you a reset email.
                </p>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={() => setforgotPassword(false)}>
                Cancel
              </Button>
              <Button
                content="Send password reset"
                labelPosition="right"
                icon="checkmark"
                onClick={() => passwordReset()}
                positive
              />
            </Modal.Actions>
          </Modal>
        )}
        <Grid
          textAlign="center"
          style={{ minHeight: "100vh", padding: "1em" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" textAlign="center">
              <Image src={logo} size="small" />
              Log-in to your account
            </Header>
            <Form size="large" onSubmit={onFormSubmit}>
              <Grid.Column>
                <Segment>
                  {props.auth.loggingIn ? (
                    <Dimmer inverted active>
                      <Loader content="" />
                    </Dimmer>
                  ) : null}
                  <Form.Input
                    fluid
                    icon="mail"
                    iconPosition="left"
                    placeholder="Enter email"
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <Form.Input
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder="Password"
                    type="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  {props.auth.error && props.auth.error.details && (
                    <Message color="red">{props.auth.error.details[0]}</Message>
                  )}
                  <Button fluid primary type="submit">
                    Login
                  </Button>
                  <div style={{ marginTop: "0.5em" }}>
                    <PASButton />
                  </div>

                  <FBLoginButton callback={facebookResponse} />
                  <div style={{ marginTop: "0.5em" }}>
                    <a onClick={() => setforgotPassword(true)}>
                      Forgot password
                    </a>
                  </div>
                </Segment>
              </Grid.Column>
            </Form>
            <Grid.Row style={{ marginTop: "2em" }}>
              <Grid.Column>
                <Button
                  style={{ marginBottom: "0.5em" }}
                  className="rounded"
                  secondary
                  onClick={() => history("/")}
                >
                  Back to Homepage
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Button
                  className="rounded"
                  color="teal"
                  onClick={() => history("/sign-up")}
                >
                  Create an account
                </Button>
              </Grid.Column>
            </Grid.Row>

            {/* <a onClick={() => setforgotPassword(true)}>Forgot Password</a> */}
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload) => dispatch(login(payload)),
    facebook_login: (payload) => dispatch(facebookLogin(payload)),
    startSignup: () => dispatch(startSignup()),
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
