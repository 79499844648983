import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import classes from "../Home.module.scss";
import { Icon, Placeholder } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDrumstickBite, faUtensils } from "@fortawesome/free-solid-svg-icons";
import CustomIcon from "../../../components/CustomIcon";
import RecipeImage from "../../../components/recipes/recipeImage";

function iconSwitch(props) {
  switch (props.type) {
    case "meal":
      return <CustomIcon className={classes.placeholderIcon} icon="meal" />;
    case "custom":
      return (
        <CustomIcon className={classes.placeholderIcon} icon={props.icon} />
      );
    default:
      return (
        <FontAwesomeIcon
          className={classes.placeholderIcon}
          icon={faUtensils}
        />
      );
  }
}

const PlaceHolderCard = (props) => {
  const checked = props.completed;

  let checkingClass = null;

  if (!checked) {
    checkingClass = classes.unchecking;
  }
  if (checked) {
    checkingClass = classes.checked;
  }

  let disabledClass = null;
  if (props.disabled) {
    disabledClass = "disabled";
  }

  let placeholder = null;
  if (!props.image) {
    placeholder = classes.placeholder;
  }
  let subtype = null;
  let type = classes.recipe;
  if (props.type == "meal") {
    type = classes.meal;
  }

  if (props.type == "snack") {
    type = classes.snack;
  }
  if (props.type == "custom") {
    subtype = props.icon;
    type = classes.custom;
  }

  return (
    <>
      <div
        className={`${classes.DaySegment} ${disabledClass} ${placeholder} ${classes.placeholderCard}`}
      >
        <div className={classes.Image}>
          {props.image ? (
            <RecipeImage
              className="diaryImage"
              image={props.image}
              source={props.source}
            />
          ) : (
            iconSwitch(props)
          )}
        </div>
        <div className={classes.Content}>
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>
        </div>
        <div className={`${classes.Options} ${checkingClass}`}>
          <div className={classes.check}>
            <p>
              <Icon name="check" />
            </p>
          </div>
          <div className={classes.swap}>
            <p>Swap</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlaceHolderCard;
