import React, { useState } from "react";
import { useParams } from "react-router-dom";
import classes from "../Screens.module.scss";
import { connect } from "react-redux";
import axios from "../../api/axios-token";
import { updateRecipes, insertRecipe } from "../../store/actions/recipeActions";
import _ from "lodash";
import { Container } from "semantic-ui-react";
import RecipeForm from "./RecipeForm";
import RecipeAmend from "./RecipeAmend";

const RecipeCreate = (props) => {
  return (
    <>
      <div>
        <Container className={classes.pageContainer}>
          <RecipeAmend action="create" />
        </Container>
      </div>
    </>
  );
};

export default RecipeCreate;
