import React, { useState, useEffect } from "react";
import recipes from "../Recipes.module.scss";
import {
  Container,
  Header,
  Grid,
  Dropdown,
  Button,
  Checkbox,
  TextArea,
  Form,
  Modal,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import RecipeMeta from "../../Recipes/components/RecipeMeta";
import RecipeImage from "../../../components/recipes/recipeImage";
import Ingredients from "../components/IngredientsBasic";
import Instructions from "../components/Instructions";
import Macros from "../../Recipes/components/Macros";
import MacroBox from "../../Recipes/components/MacroBox";
import axios from "../../../api/axios-token";
import SimpleCalorieBar from "./SimpleCalorieBar";
import ImportRecipe from "./ImportRecipe";
import discover from "./Discover.module.scss";
import { connect, useDispatch } from "react-redux";
import { addRecipe } from "../../../store/actions/recipeActions";
import { useQuery, useQueryClient } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import _ from "lodash";
import amplitude from "amplitude-js";

const View = (props) => {
  const params = useParams();
  const history = useNavigate();

  const [currentView, setCurrentView] = useState("ingredients");
  const [macros, setMacros] = useState(null);
  const [ingredients, setIngredients] = useState(null);
  const [ingredientsAdjust, setIngredientsAdjust] = useState(null);
  const [editing, setEditing] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryDropdown, setCategoryDropdown] = useState([]);
  const [editDescription, setEditDescription] = useState("");
  const [serves, setServes] = useState("");
  const [prepTime, setPrepTime] = useState("");
  const [cookTime, setCookTime] = useState("");
  const [macroTotals, setMacroTotals] = useState();
  const [verified, setVerified] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [ratio, setRatio] = useState(1);
  const [isSaving, setIsSaving] = useState(false);
  const [saveComplete, setSaveComplete] = useState(false);
  const [savedRecipe, setSavedRecipe] = useState(null);
  const queryClient = new useQueryClient();

  const dispatch = useDispatch();

  const saveRecipe = (recipeID, hash) => {
    let formData = new FormData();
    formData.append("id", hash);
    formData.append("serves", serves);

    if (verified) {
      formData.append("verified", true);
    } else {
      formData.append("verified", false);
    }

    if (macroTotals) {
      const macros = {
        calories: Math.round(macroTotals.calories),
        protein: Math.round(macroTotals.protein),
        fat: Math.round(macroTotals.fat),
        carbs: Math.round(macroTotals.carbs),
      };
      formData.append("macros", JSON.stringify(macros));
    }

    cookTime && formData.append("cookTime", cookTime);
    prepTime && formData.append("prepTime", prepTime);
    editDescription && formData.append("description", editDescription);
    formData.append("ingredients", JSON.stringify(ingredients));
    formData.append("categories", JSON.stringify(categories));

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    // if (pictures.length > 0) {
    //   if (pictures[0][0] instanceof File || pictures[0][0] instanceof Blob) {
    //     formData.append("file", pictures[0][0], pictures[0][0].name);
    //   }
    // }

    axios
      .post("/admin/save_recipe", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      .then(function (response) {
        if (response.data.success) {
          setEditing(false);
          history("/recipes/discover/view/" + hash);
        }
      });
  };

  const importRecipe = (hash) => {
    setIsSaving(true);
    axios
      .post("/discover/import_recipe", {
        recipe: hash,
        ingredients: ingredientsAdjust,
        macros: macroTotals,
        ratio: ratio,
      })
      .then(function (response) {
        amplitude.getInstance().logEvent("Imported Recipe from Discover");
        dispatch(addRecipe(response.data));
        setSavedRecipe(response.data.id);
        setSaveComplete(true);
      });
  };

  const addCategory = (categoryName) => {
    let newCategory = null;
    axios
      .post("/admin/add_category", {
        category_name: categoryName,
      })
      .then(function (response) {
        // update tag redux

        const category = response.data.category;
        newCategory = {
          keys: category.id,
          text: categoryName,
          value: category.id,
        };
        return category;
      })
      .then(function (response) {
        if (newCategory) {
          setCategoryDropdown([...categoryDropdown, { ...newCategory }]);
          setCategories([...categories, newCategory.value]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const deleteRecipe = (id) =>
    axios
      .post("/admin/delete_recipe", {
        id: id,
      })
      .then((res) => {
        history("/recipes/discover");
      });

  const fetchRecipe = () =>
    axios
      .post("/discover/get_recipe", {
        id: params.id,
      })
      .then((res) => {
        return res.data.recipe;
      });

  const getCategories = () =>
    axios.get("/discover/get_categories").then((res) => {
      return res.data.categories;
    });

  const { isLoading, isError, data, error } = useQuery(
    `recipe-${params.id}`,
    fetchRecipe,
    {
      refetchOnWindowFocus: false,
    }
  );

  const categoryOptions = useQuery("globalCategories", getCategories, {
    refetchOnWindowFocus: false,
  });

  const recipe = data;
  let { edit } = useParams();

  const updateIngs = (array) => {
    setIngredientsAdjust([...array]);
  };

  useEffect(() => {
    if (data) {
      setIngredients(data.ingredients);
      setIngredientsAdjust(data.ingredients);
      setEditDescription(data.description);
      setServes(data.serves);
      setPrepTime(data.prepTime);
      setCookTime(data.cookTime);
      setVerified(data.verified === "0" ? false : true);
      const categoryValues = _.reduce(
        data.categories,
        function (acc, curr) {
          return [...acc, curr.category_id];
        },
        []
      );
      setCategories(categoryValues);
    }
  }, [data]);

  useEffect(() => {
    if (categoryOptions) {
      setCategoryDropdown(categoryOptions.data);
    }
  }, [categoryOptions]);

  useEffect(() => {
    if (props.auth.user.permissions.includes("admin")) {
      setAdmin(true);
    }

    if (edit) {
      setEditing(true);
    }
  }, []);

  const converted_units = {
    cup: "ml/g",
    ml: "ml",
    kg: "g",
    pt: "ml",
    tsp: "ml/g",
    tbsp: "ml/g",
    pinch: "ml/g",
    dash: "ml/g",
    squeeze: "ml",
    whole: "whole",
    handful: "handfuls",
    bunch: "bunch",
    "small bunch": "small bunch",
    slices: "slices",
    g: "g",
    l: "l",
    oz: "g",
    lb: "g",
  };
  const metric_translation = {
    cup: 240,
    ml: 1,
    kg: 1000,
    pt: 480,
    tsp: 5,
    tbsp: 15,
    pinch: 0.5,
    dash: 0.5,
    squeeze: 15,
    whole: 1,
    handful: 1,
    bunch: 1,
    "small bunch": 1,
    slices: 1,
    g: 1,
    l: 1000,
    oz: 28,
    lb: 450,
  };
  const removeIngredient = (id) => {
    console.log(id);
    let newArray = [...ingredients];
    _.remove(newArray, (i) => i.id === id);
    setIngredients(newArray);
  };
  const updateIngredient = (id, data) => {
    let ingCopy, updatedIngredient;
    if (id.includes("new-")) {
      updatedIngredient = {
        ..._.find(ingredients, {
          id: id.replace("new-", ""),
        }),
        ...data,
      };
      delete updatedIngredient.macros;

      updatedIngredient.converted_unit =
        converted_units[updatedIngredient.unit];
      updatedIngredient.metric_translation =
        metric_translation[updatedIngredient.unit];
      updatedIngredient.loadingStatus = "refresh";
      ingCopy = ingredients.map((ingredient) =>
        ingredient.riid === id ? { ...updatedIngredient } : ingredient
      );
    } else {
      updatedIngredient = {
        ..._.find(ingredients, {
          riid: id,
        }),
        ...data,
      };

      delete updatedIngredient.macros;
      updatedIngredient.loadingStatus = 0;
      updatedIngredient.converted_unit =
        converted_units[updatedIngredient.unit];
      updatedIngredient.metric_translation =
        metric_translation[updatedIngredient.unit];

      ingCopy = ingredients.map((ingredient) =>
        ingredient.riid === id ? { ...updatedIngredient } : ingredient
      );
    }
    setIngredients([...ingCopy]);
    return true;
  };

  return (
    <Container className={discover.viewContainer}>
      <Dimmer
        onClickOutside={() => {
          if (saveComplete) {
            setIsSaving(false);
            setSaveComplete(false);
          }
        }}
        page
        active={isSaving}
      >
        {!saveComplete ? (
          <Header as="h2" inverted>
            <Loader indeterminate>Importing Recipe</Loader>
          </Header>
        ) : (
          <>
            <Header as="h2" inverted>
              Recipe Saved
            </Header>

            <Button
              positive
              onClick={() => history("/recipes/view/" + savedRecipe)}
            >
              View recipe in my collection
            </Button>
            <Button color="teal" onClick={() => history("/recipes/discover/")}>
              Find more recipes
            </Button>
            <Header.Subheader style={{ marginTop: "1em" }}>
              Or click anywhere to go back to the recipe page
            </Header.Subheader>
          </>
        )}
      </Dimmer>
      {isLoading || !ingredients ? (
        <span>Loading</span>
      ) : (
        <Grid stackable columns="two">
          <Grid.Column>
            <div className={recipes.header}>
              <h1 className={recipes.title} as="h1">
                {recipe.name}
              </h1>
            </div>

            <RecipeImage
              source_method={recipe.source_method}
              image={recipe.image}
            />

            <div className={recipes.Meta}>
              <RecipeMeta
                editing={editing}
                serves={serves}
                cookTime={cookTime}
                prepTime={prepTime}
                updateServes={(v) => setServes(v)}
                updateCook={(v) => setCookTime(v)}
                updatePrep={(v) => setPrepTime(v)}
              />
            </div>

            <Macros
              returnTotals={(a) => setMacroTotals(a)}
              ingredients={ingredients}
              serves={serves || recipe.serves}
              updateIngredients={(arr) => updateIngs(arr)}
              returnRatio={(r) => setRatio(r)}
            >
              <SimpleCalorieBar />
              {!editing ? (
                <ImportRecipe
                  import={() => importRecipe(recipe.hash)}
                  className={discover.ImportButton}
                />
              ) : null}
            </Macros>

            {!editing && admin && (
              <>
                <Button
                  negative
                  onClick={() => {
                    history("/recipes/discover/view/" + recipe.hash + "/edit");
                    setEditing(true);
                  }}
                >
                  Admin Edit Recipe
                </Button>
                <Modal
                  trigger={<Button>Delete Discover Recipe</Button>}
                  header="Delete recipe?"
                  content="Are you sure you want to permanently delete this recipe?"
                  actions={[
                    "Cancel",
                    {
                      onClick: () => deleteRecipe(recipe.id),
                      key: "done",
                      content: "Yes",
                      positive: true,
                    },
                  ]}
                />
              </>
            )}

            {editing && (
              <>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <h3>Categories</h3>
                      {categoryDropdown && (
                        <Dropdown
                          loading={categoryOptions.isLoading}
                          allowAdditions
                          search
                          placeholder="Categories"
                          fluid
                          multiple
                          value={categories}
                          onChange={(e, v) => setCategories(v.value)}
                          onAddItem={(e, t) => {
                            addCategory(t.value);
                          }}
                          selection
                          options={categoryDropdown}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        onChange={(e, d) => {
                          setVerified(d.checked);
                        }}
                        toggle
                        checked={verified}
                        label="Recipe Verified"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Button
                        onClick={() => saveRecipe(recipe.id, recipe.hash)}
                      >
                        Admin Save Recipe
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </>
            )}
          </Grid.Column>
          {/* <Tab
                    className={recipes.TabsOverride}
                    menu={{ secondary: true }}
                    panes={panes}
                  /> */}
          <Grid.Column>
            <div className={recipes.Content}>
              {!editing ? (
                <Header as="h3" style={{ padding: "0.5em" }}>
                  {recipe.description}
                </Header>
              ) : (
                <Form>
                  <Header as="h4" style={{ marginTop: "1em" }}>
                    Edit Description:
                  </Header>
                  <TextArea
                    style={{ marginBottom: "1em" }}
                    placeholder="Description"
                    value={editDescription}
                    rows={10}
                    onChange={(d, v) => setEditDescription(v.value)}
                  />
                </Form>
              )}
              <ul className={discover.viewTab}>
                <li
                  onClick={() => {
                    setCurrentView("ingredients");
                  }}
                  className={
                    currentView == "ingredients" ? discover.active : null
                  }
                >
                  Ingredients
                </li>
                <li
                  onClick={() => {
                    setCurrentView("method");
                  }}
                  className={currentView == "method" ? discover.active : null}
                >
                  Method
                </li>
              </ul>

              {currentView == "ingredients" ? (
                <Macros
                  ingredients={editing ? ingredients : ingredientsAdjust}
                  serves={serves || recipe.serves}
                >
                  <Ingredients
                    editing={editing}
                    updateIngredient={(id, data) => updateIngredient(id, data)}
                    removeIng={(id) => removeIngredient(id)}
                  />
                </Macros>
              ) : (
                <div style={{ marginTop: "1em" }}>
                  <Instructions
                    editing={false}
                    instructions={recipe.method}
                    source={recipe.source}
                    source_method={recipe.source_method}
                    description={null}
                  />
                </div>
              )}
            </div>
          </Grid.Column>
        </Grid>
      )}
    </Container>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    tdee: state.auth.user.options.tdee,
  };
};

export default connect(mapStateToProps)(View);
