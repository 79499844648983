import React, { useState, useEffect, useCallback } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import classes from "./Ingredients.module.scss";
import {
  Image,
  Form,
  Dropdown,
  Icon,
  Grid,
  Button,
  Input,
  Loader,
} from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import UnitSelector from "./UnitSelector";
import faker from "faker";
import axios from "../../../api/axios-token";
import { round } from "../../../utility/helper";
import uuid from "react-uuid";
import _, { set } from "lodash";
import MacroSearch from "./MacroSearch";
import LogRocket from "logrocket";

const Ingredient = (props) => {
  const [editing, setEditing] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const [searchQuery, setsearchQuery] = useState(null);
  const [search, setSearch] = useState(true);
  const [unit, setUnit] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [macroSearch, showMacroSearch] = useState(false);
  const [searchValue, setsearchValue] = useState([]);
  const [multiple, setMultiple] = useState(false);
  const [value, setValue] = useState("");
  const [ingName, setingName] = useState(null);
  const [instructions, setInstructions] = useState(null);
  const [editBox, setEditBox] = useState(false);
  const [options, setOptions] = useState([]);
  const [ingImage, setIngImage] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([
    {
      key: props.ingredientID,
      text: props.name,
      value: props.ingredientID,
      image_src: null,
    },
  ]);

  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [animation, setAnimation] = useState(null);

  function getOptions(ingredients) {
    if (ingredients) {
      return Object.keys(ingredients).map((i, index) => {
        return {
          key: ingredients[i].id,
          text: ingredients[i].name,
          value: ingredients[i].id,
          image_src: ingredients[i].image,
          image: !ingredients[i].image
            ? undefined
            : {
                avatar: true,
                src: `${process.env.PUBLIC_URL}/ing_images/${ingredients[i].image}`,
              },
        };
      });
    }
  }

  const imageError = (image) => {
    if (process.env.NODE_ENV === "production") {
      LogRocket.captureMessage("Ingredient Error", {
        tags: {
          // additional data to be grouped as "tags"
          subscription: "IngredientImage",
        },
        extra: {
          // additional arbitrary data associated with the event
          ingredient: image,
        },
      });
    }
  };

  useEffect(() => {
    if (props.name && editing) {
      Promise.resolve()

        .then(() => ingredientSearch(props.name))
        .then(() => {
          setValue(props.ingredientID);
          setingName(props.name);
          setInstructions(props.instructions);
          setUnit(props.unit);
          setQuantity(props.quantity);
          setIngImage(props.image);
        })
        .then(() => {
          setEditBox(true);
        });
    }
  }, [editing]);

  const addIng = (ingName) => {
    let newIng = null;
    const optionsCopy = options;
    axios
      .post("/recipes/add_ingredient", {
        ingredient: ingName,
      })
      .then(function (response) {
        // update option

        const ingredient = response.data.ingredient;
        newIng = {
          keys: ingredient.id,
          text: ingredient.name,
          value: ingredient.id,
          image: ingredient.image,
        };
        return ingredient;
      })
      .then(function (response) {
        if (newIng) {
          //ingredientSearch(newIng.text);
          // setOptions([...optionsCopy, newIng]);

          Promise.resolve()
            .then(() => {
              if (options) {
                setOptions([...options, newIng]);
              } else {
                setOptions([newIng]);
              }
            })
            .then(() => {
              console.log(newIng.image);
              setValue(newIng.value);
              setingName(newIng.text);
              setIngImage(newIng.image);
            });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChange = (e, v) => {
    if (v.value) {
      const newName = _.find(options, {
        value: v.value,
      });
      if (newName) {
        setValue(v.value);
        setingName(newName.text);
        setIngImage(newName.image_src);
      }
    }
  };
  const ingredientSearch = (ing) => {
    let foundIngredients;
    axios
      .post("/recipes/ingredient_search", {
        ingredient: ing,
      })
      .then(function (response) {
        foundIngredients = response.data.ingredients;
      })
      .then(function () {
        if (foundIngredients && foundIngredients.length > 0) {
          setOptions(getOptions(foundIngredients));
        } else {
          setOptions(defaultOptions);
        }
      })
      .then(function () {
        setOptionsLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const debouncedSearch = useCallback(
    _.debounce((e) => ingredientSearch(e), 300),
    []
  );

  const handleSearchChange = (e) => {
    setsearchQuery(searchQuery);
    debouncedSearch(e.searchQuery);
  };

  const saveIngredient = () => {
    const data = {
      user_quantity: quantity,
      unit: unit,
      name: ingName,
      instructions: instructions,
      ingredientID: value.toString(),
      riid: props.riid || null,
      phrase: props.phrase || null,
      image: ingImage,
    };

    if (
      props.updateIngredient(props.riid, {
        ...data,
      })
    ) {
      setEditing(false);
    }
  };

  //   const fetchOptions = () => {
  //     setFetching({ isFetching: true });

  //     setTimeout(() => {
  //       setFetching(false);
  //       setOptions(getOptions());
  //       selectRandom();
  //     }, 500);
  //   };

  //   const selectRandom = () => {
  //     const value = _.sample(options).value;
  //     setValue(multiple ? [value] : value);
  //   };

  let spacer = " ";
  let unit_spacer = null;
  let unit_class = null;
  const regex = /\bkg\b|\bl\b|\blb\b|\boz\b|\bpt\b|\btsp\b|\btbsp\b|\bml\b|\bg\b/g;
  if (!props.unit) {
    return null;
  }
  if (!props.unit.match(regex)) {
    unit_spacer = spacer;
  } else {
    unit_spacer = null;
  }
  if (props.unit == "whole") {
    unit_class = "hidden";
  }

  const cancelEditing = () => {
    setEditing(false);
    setEditBox(false);
  };

  return (
    <div className={classes.IngRow}>
      {props.phrase && (
        <div className={classes.IngPhrase}>
          <label>Search term: {props.phrase}</label>
        </div>
      )}
      <div className={classes.IngDetails}>
        {props.editing && (
          <div
            className={classes.removeButton}
            onClick={() => props.removeIng(props.riid)}
          >
            <FontAwesomeIcon icon={faMinusCircle} />
          </div>
        )}
        <div className={classes.IngImage}>
          {ingImage || props.image ? (
            <Image
              onError={() => imageError(ingImage || props.image)}
              style={{ maxHeight: "30px" }}
              src={
                process.env.PUBLIC_URL +
                "/ing_images/" +
                `${ingImage ? ingImage : props.image}`
              }
            />
          ) : (
            <Icon color="grey" name="tag" />
          )}
        </div>
        {editing && editBox && props.editing && optionsLoaded ? (
          <div>
            <Grid widths="equal">
              {props.phrase && (
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <label>Search term: {props.phrase}</label>
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Dropdown
                    className={classes.ingDropdown}
                    closeOnBlur
                    style={{ textTransform: "capitalize" }}
                    fluid
                    selection
                    multiple={multiple}
                    search={search}
                    options={options}
                    value={value}
                    placeholder="Search for ingredient"
                    allowAdditions={true}
                    onAddItem={(e, t) => {
                      debouncedSearch.cancel();
                      addIng(t.value);
                    }}
                    onChange={(e, v) => {
                      handleChange(e, v);
                    }}
                    onSearchChange={(e, v) => {
                      handleSearchChange(v);
                    }}
                    onBlur={(e, o) => {
                      setValue(o.value);
                    }}
                    disabled={isFetching}
                    loading={isFetching}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form.Input
                    value={instructions || ""}
                    fluid
                    placeholder="Instructions"
                    onChange={(e) => {
                      setInstructions(e.target.value);
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Input
                    value={quantity}
                    fluid
                    type="number"
                    placeholder="Quantity"
                    onChange={(e) => {
                      setQuantity(e.target.value);
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <UnitSelector
                    value={unit}
                    change={(v) => {
                      setUnit(v);
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{ marginTop: ".7em" }}>
                  <Button
                    onClick={() => {
                      saveIngredient();
                    }}
                    color="green"
                  >
                    Save Ingredient
                  </Button>
                  <Button
                    onClick={() => {
                      cancelEditing();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        ) : (
          <div
            className={classes.IngName}
            onClick={(e) => {
              if (props.editing) {
                setEditing(true);
              }
            }}
          >
            <div>
              <span className={classes.Quantity}>
                {props.noRatio ? props.quantity : props.ratio || props.quantity}
              </span>
              {unit_spacer}
              <span className={`${unit_class} ${classes.Unit}`}>
                {props.unit}
              </span>
              {spacer}
              <span className={classes.Name}>{props.name}</span>
            </div>

            {props.macros && props.loadingStatus && (
              <div className={classes.Calories}>
                {props.loadingStatus == 1 && (
                  <>
                    <Loader active inline size="tiny" /> Loading Macros
                  </>
                )}
                {props.loadingStatus == 2 && props.macros.calories == null ? (
                  <>
                    <Loader active inline size="tiny" /> Searching the galaxy
                    for macros
                  </>
                ) : props.loadingStatus == 2 &&
                  props.macros.calories != null ? (
                  `${round(props.macros.calories, 0)} kcal per serving`
                ) : (
                  props.loadingStatus == 3 &&
                  props.macros.error == 1 &&
                  `Unable to find calories for this ingredient.`
                )}
                <MacroSearch
                  open={macroSearch}
                  currentMacros={props.macros}
                  close={() => showMacroSearch(false)}
                  unit={props.unit}
                  name={props.name}
                  ingredientID={props.ingredientID}
                  quantity={
                    props.noRatio
                      ? props.quantity
                      : props.ratio || props.quantity
                  }
                />
                <Icon
                  onClick={(e) => {
                    e.stopPropagation();
                    showMacroSearch(true);
                  }}
                  name="question circle outline"
                />
              </div>
            )}

            {props.instructions && (
              <div className={classes.Instruction}>
                <span>{props.instructions}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default Ingredient;
