import React from "react";

import { Modal, Header, Button } from "semantic-ui-react";

const ShoppingComplete = (props) => {
  return (
    <Modal onClose={() => props.close()} open={props.show}>
      <Modal.Header>Shopping Complete</Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          {/* <Header>Default Profile Image</Header> */}
          <p>
            Looks like you've completed your shopping! Click complete your
            shopping to earn energy points and clear your list.
          </p>
          <p> Your shopping will be available in your Shopping History.</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => props.close()}>
          Not yet
        </Button>
        <Button
          content="Complete"
          labelPosition="right"
          icon="checkmark"
          onClick={() => props.complete()}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ShoppingComplete;
