import classes from "./Custom.module.scss";
import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { connect } from "react-redux";
import CustomCard from "./CustomCard";

import EditCustomCard from "./EditCustomCard";
import {
  Grid,
  Modal,
  Input,
  Button,
  Segment,
  Icon,
  Checkbox,
} from "semantic-ui-react";
import {
  updateSelectedCards,
  clearSelected,
  insertCard,
  archiveCard,
} from "../../store/actions/plannerActions";
import _ from "lodash";

const CardBrowser = (props) => {
  const [addCustomModal, setAddCustomModal] = useState(false);
  const [editID, setEditID] = useState(null);
  const createNewCard = () => {
    setAddCustomModal(true);
  };

  useEffect(() => {
    if (editID) {
      setAddCustomModal(true);
    }
  }, [editID]);

  const toggleCustomCard = (id) => {
    if (props.toggle) {
      props.clearSelected();
      props.updateSelectedCards([id]);
      return;
    }

    const currentCards = [...props.selectedCards];

    if (
      _.find(currentCards, function (o) {
        return o == id;
      })
    ) {
      _.remove(currentCards, function (el) {
        return el == id;
      });
    } else {
      currentCards.push(id);
    }

    props.updateSelectedCards(currentCards);
  };

  const archiveCard = (id) => {
    props.archiveCard(id);
  };

  return (
    <Grid columns={2} doubling className={classes.customContainer}>
      {props.customCards.length &&
        props.customCards.map(function (w, i) {
          if (w.archived) {
            return null;
          }
          return (
            <CustomCard
              toggleCard={() => {
                toggleCustomCard(w.id);
              }}
              edit={(id) => setEditID(w.id)}
              archive={(id) => archiveCard(id)}
              id={w.id}
              key={i}
              icon={w.icon}
              name={w.name}
              selected={props.selectedCards.indexOf(w.id) > -1 ? true : false}
            />
          );
        })}
      <CustomCard
        name={"Add Custom"}
        icon={"custom"}
        selected={false}
        toggleCard={() => {
          createNewCard();
        }}
      />
      <EditCustomCard
        editID={editID}
        toggleCard={(id) => toggleCustomCard(id)}
        open={addCustomModal}
        close={() => {
          setAddCustomModal(false);
          setEditID(null);
        }}
      />{" "}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedCards: state.planner.selectedCards,
    customCards: state.planner.customCards,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateSelectedCards: (array) => dispatch(updateSelectedCards(array)),
    clearSelected: () => dispatch(clearSelected()),
    insertCard: (card) => dispatch(insertCard(card)),
    archiveCard: (id) => dispatch(archiveCard(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardBrowser);
