import React, { useState, useEffect } from "react";
import Avatar from "react-avatar";
import { connect, useDispatch } from "react-redux";
import "../Registration/PasswordStrength.css";
import FBLoginButton from "../../components/login/fbLoginButton";
import {
  Container,
  Header,
  Grid,
  Input,
  Segment,
  Form,
  Button,
  Divider,
  Icon,
  Checkbox,
  Label,
  Modal,
  Image,
  List,
  Dimmer,
  Loader,
  Message,
} from "semantic-ui-react";
import ProfileImage from "./ProfileImage";
import axios from "../../api/axios-token";
import {
  authCheckLogin,
  loginSuccess,
  updateUserImage,
} from "../../store/actions/authActions";
import { useToasts } from "react-toast-notifications";
import * as EmailValidator from "email-validator";
import zxcvbn from "zxcvbn";
import { fromUnixTime } from "date-fns";
import classes from "./profileSettings.module.scss";
import SimpleImageUpload from "../../components/SimpleImageUpload";

const EditProfile = (props) => {
  const [firstName, setFirstName] = useState(props.user.first_name);
  const [lastName, setLastName] = useState(props.user.last_name);
  const [nameErrors, setNameErrors] = useState(null);
  const { addToast } = useToasts();
  const [current, setCurrent] = useState();
  const [password, setPassword] = useState({
    text: "",
    errors: null,
    strength: zxcvbn(""),
    strengthClass: "Weak",
  });
  const [confirm, setConfirm] = useState("");
  const [email, setEmail] = useState(props.user.email);
  const [confirmEmail, setEmailConfirm] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [mailing, setMailing] = useState(parseInt(props.user.mailing));
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteEmail, setDeleteEmail] = useState("");
  const [imageUpload, imageHandler] = useState();
  const [previewImage, setPreviewImage] = useState(null);
  const [selected, setSelected] = useState("tomato");
  const [deleteImage, setDeleteImage] = useState(false);
  const [savingPic, setSavingPic] = useState(false);
  const [changeProfilePic, setChangeProfilePic] = useState(false);
  const [errors, setErrors] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.user.options.profileImage) {
      setSelected(props.user.options.profileImage);
    }
    if (props.user.options.userImage) {
      setPreviewImage(
        `${process.env.REACT_APP_API}/user_images/${props.user.options.userImage}`
      );
    }
  }, []);

  const passwordChangeListener = (e) => {
    const testedResult = zxcvbn(e.target.value);
    const passwordLabel = createPasswordLabel(testedResult);
    setPassword({
      text: e.target.value,
      error: null,
      strength: testedResult,
      strengthClass: passwordLabel,
    });
  };

  const selectHandler = (type) => {
    switch (type) {
      case "facebook":
        setSelected("facebook");
        break;
      case "user":
        setSelected("user");
        break;
      default:
        setSelected(type);
    }
  };

  const savePassword = () => {
    if (password.strength.score < 3) {
      addToast("Password not saved, the new password is not strong enough.", {
        appearance: "error",
        autoDismiss: true,
      });
      setPassword({
        ...password,
        error: 'The password strength must be at least "good"',
        autoDismiss: true,
      });
      return;
    }
    if (password.text != confirm) {
      addToast("Password not saved, the passwords do not match.", {
        appearance: "error",
        autoDismiss: true,
      });
      setPassword({ ...password, error: "The passwords do not match." });
      return;
    }
    axios
      .post("/profile/change_password", {
        old: current,
        new: confirm,
      })
      .then(function (response) {
        if (response.data.status == "SUCCESS") {
          addToast("Password saved successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          setPassword({
            text: "",
            errors: null,
            strength: zxcvbn(""),
            strengthClass: "Weak",
          });
          setConfirm("");
          setCurrent("");
        } else {
          if (response.data.error) {
            addToast(response.data.error, {
              appearance: "error",
            });
          } else {
            addToast("An error occured, change not saved.", {
              appearance: "error",
            });
          }
        }
      })
      .catch(function (error) {
        addToast("An error occured, change not saved.", {
          appearance: "error",
        });
      });
  };
  const createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return "Weak";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "Weak";
    }
  };

  const updateName = () => {
    if (!firstName || !lastName) {
      setNameErrors("You must enter a first and last name");
      return;
    }

    axios
      .post("/profile/update_name", {
        first_name: firstName,
        last_name: lastName,
      })
      .then(function (response) {
        if (response.data.STATUS == "SUCCESS") {
          dispatch(authCheckLogin());
          addToast("Name saved successfully", {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          addToast("An error occured, change not saved.", {
            appearance: "error",
          });
        }
      })
      .catch(function (error) {
        addToast("An error occured, change not saved.", {
          appearance: "error",
        });
      });
  };

  const facebookResponse = (res) => {
    const id = res.id;
    if (!id) {
      return false;
    }
    axios
      .post("/profile/facebook_connect", {
        id: id,
      })
      .then(function (response) {
        if (!response.data.status) {
          addToast(response.data.message, {
            appearance: "error",
          });
        } else {
          dispatch(loginSuccess(response.data));
        }
      });
  };

  const updateEmail = () => {
    if (!EmailValidator.validate(email)) {
      setEmailError("The email address is not valid.");
      addToast("The email address is not valid.", {
        appearance: "error",
      });
      return;
    }
    if (email != confirmEmail) {
      setEmailError("The emails do not match.");
      addToast("The emails do not match.", {
        appearance: "error",
      });
      return;
    }
    axios
      .post("/profile/update_email", {
        email: email,
      })
      .then(function (response) {
        if (response.data.STATUS == "SUCCESS") {
          dispatch(authCheckLogin());
          addToast("Email saved successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          setEmailConfirm("");
        } else {
          addToast("An error occured, email not saved.", {
            appearance: "error",
          });
        }
      })
      .catch(function (error) {
        addToast("An error occured, email not saved.", {
          appearance: "error",
        });
      });
  };

  const updateMailing = (v) => {
    const prev = mailing;
    setMailing(v);
    axios
      .post("/profile/update_mailing", {
        mailing: v,
      })
      .then(function (response) {
        if (response.data.STATUS == "SUCCESS") {
          dispatch(authCheckLogin());
          addToast("Mailing preferences saved successfully", {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          addToast("An error occured, mailing preference not saved.", {
            appearance: "error",
          });
          setMailing(prev);
        }
      })
      .catch(function (error) {
        addToast("An error occured, mailing preference not saved.", {
          appearance: "error",
        });
        setMailing(prev);
      });
  };

  const deleteAccount = () => {
    alert(deleteEmail);
  };

  const cancelProfilePic = () => {
    setSavingPic(false);
    setChangeProfilePic(false);
    setDeleteImage(false);
  };

  const saveProfilePic = () => {
    setSavingPic(true);

    let formData = new FormData();
    if (imageUpload instanceof File || imageUpload instanceof Blob) {
      formData.append("newImage", imageUpload);
    }
    formData.append("deleteImage", deleteImage);
    formData.append("selectedImage", selected);

    axios
      .post("/profile/save_profile_pic", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        if (response.data.success) {
          setSavingPic(false);
          setChangeProfilePic(false);
          setDeleteImage(false);
          console.log(response.data);
          dispatch(
            updateUserImage(response.data.userImage, response.data.profileImage)
          );
        } else {
          if (response.data.errors) {
            setErrors([response.data.errors]);
            setSavingPic(false);
            return false;
          } else {
            setErrors([
              "An unknown error occured, try refreshing the page or checking your connection.",
            ]);
            setSavingPic(false);
            return false;
          }
        }
      });
  };

  return (
    <Container style={{ marginTop: "1em" }}>
      <Modal open={changeProfilePic}>
        <Dimmer active={savingPic}>
          <Loader />
        </Dimmer>
        <Modal.Header>Change Avatar</Modal.Header>
        <Modal.Content>
          {errors ? (
            <Message negative>
              <Message.Header>Unable to change image</Message.Header>
              {errors}
            </Message>
          ) : null}

          <ul className={classes.Avatars}>
            <li
              onClick={() => selectHandler("tomato")}
              className={`${selected == "tomato" ? "active" : null}`}
            >
              <Image
                fluid
                src={`${process.env.PUBLIC_URL}/avatars/tomato.png`}
              />
            </li>
            <li
              onClick={() => selectHandler("pepper")}
              className={`${selected == "pepper" ? "active" : null}`}
            >
              <Image
                fluid
                src={`${process.env.PUBLIC_URL}/avatars/pepper.png`}
              />
            </li>
            <li
              onClick={() => selectHandler("eggplant")}
              className={`${selected == "eggplant" ? "active" : null}`}
            >
              <Image
                fluid
                src={`${process.env.PUBLIC_URL}/avatars/eggplant.png`}
              />
            </li>
            <li
              onClick={() => selectHandler("carrot")}
              className={`${selected == "carrot" ? "active" : null}`}
            >
              <Image
                fluid
                src={`${process.env.PUBLIC_URL}/avatars/carrot.png`}
              />
            </li>
            {props.user.provider_id ? (
              <li
                onClick={() => selectHandler("facebook")}
                className={`${selected == "facebook" ? "active" : null}`}
              >
                <Avatar
                  size={"100%"}
                  round
                  facebookId={props.user.provider_id}
                />
              </li>
            ) : null}
            <li
              onClick={() => {
                if (previewImage) {
                  selectHandler("user");
                }
              }}
              className={`${selected == "user" ? "active" : null}`}
            >
              <div
                className={classes.userUpload}
                style={{ backgroundImage: `url(${previewImage})` }}
              >
                <SimpleImageUpload
                  imageHandler={imageHandler}
                  disablePreview
                  defaultImage={previewImage}
                  deleteImage={() => {
                    selectHandler("tomato");
                    setDeleteImage(true);
                  }}
                  imagePreview={setPreviewImage}
                />
              </div>
            </li>
          </ul>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => cancelProfilePic()} color="black">
            Cancel
          </Button>
          <Button
            content="Change Avatar"
            labelPosition="right"
            icon="checkmark"
            positive
            onClick={() => saveProfilePic()}
          />
        </Modal.Actions>
      </Modal>
      <Grid style={{ maxWidth: "600px", margin: "0 auto" }}>
        <Grid.Row>
          <Grid.Column>
            <Header>Edit Profile</Header>
            <Segment>
              <Form>
                <div style={{ textAlign: "center" }}>
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <ProfileImage />
                    <Icon
                      onClick={() => setChangeProfilePic(true)}
                      color="light grey"
                      inverted
                      name="camera"
                      circular
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                      }}
                    />
                  </div>
                </div>
                <Divider horizontal>
                  <Header as="h4">
                    <Icon name="user" />
                    User Details
                  </Header>
                </Divider>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>First Name</label>
                    <Form.Input
                      onChange={(e, v) => {
                        setNameErrors(null);
                        setFirstName(v.value);
                      }}
                      value={firstName}
                      placeholder="First Name"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Last Name</label>
                    <Form.Input
                      onChange={(e, v) => {
                        setNameErrors(null);
                        setLastName(v.value);
                      }}
                      value={lastName}
                      placeholder="Last Name"
                    />
                  </Form.Field>

                  <Form.Field style={{ display: "flex" }}>
                    <Button
                      onClick={() => updateName()}
                      style={{ marginTop: "auto" }}
                      fluid
                      color="teal"
                      type="button"
                    >
                      Update Name
                    </Button>
                  </Form.Field>
                </Form.Group>
                {nameErrors && (
                  <Form.Group>
                    <Label basic color="red">
                      {nameErrors}
                    </Label>
                  </Form.Group>
                )}
                {!props.user.provider_id && (
                  <>
                    <Divider horizontal>
                      <Header as="h4">
                        <Icon name="lock" />
                        Password
                      </Header>
                    </Divider>
                    <Form.Group widths="equal">
                      <Form.Field>
                        <label>Current password:</label>

                        <Form.Input
                          value={current}
                          onChange={(e, v) => {
                            setCurrent(v.value);
                          }}
                          type="password"
                        />
                      </Form.Field>
                    </Form.Group>

                    <Form.Group widths="equal">
                      <Form.Field>
                        <label>New Password</label>
                        <Form.Input
                          value={password.text}
                          onChange={(e) => {
                            passwordChangeListener(e);
                          }}
                          type="password"
                          placeholder="New password"
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>Confirm Password</label>
                        <Form.Input
                          type="password"
                          value={confirm}
                          placeholder="Confirm password"
                          onChange={(e, v) => setConfirm(v.value)}
                        />
                      </Form.Field>
                      <Form.Field style={{ display: "flex" }}>
                        <Button
                          style={{ marginTop: "auto" }}
                          fluid
                          color="teal"
                          type="button"
                          onClick={() => {
                            savePassword();
                          }}
                        >
                          Update Password
                        </Button>
                      </Form.Field>
                    </Form.Group>
                  </>
                )}
                {password.text && (
                  <div>
                    <progress
                      className={`password-strength-meter-progress strength-${password.strengthClass}`}
                      value={password.strength.score}
                      max="4"
                    />

                    <label className="password-strength-meter-label">
                      {password && (
                        <>
                          <strong>Password strength:</strong>{" "}
                          {password.strengthClass}
                        </>
                      )}
                    </label>
                  </div>
                )}{" "}
                {password.error && (
                  <Label basic color="red">
                    {password.error}
                  </Label>
                )}
                {!props.user.provider_id && (
                  <>
                    <Divider horizontal>
                      <Header as="h4">
                        <Icon name="facebook" />
                        Social
                      </Header>
                    </Divider>
                    <Form.Field>
                      <FBLoginButton callback={facebookResponse} />
                    </Form.Field>{" "}
                  </>
                )}
                <Divider horizontal>
                  <Header as="h4">
                    <Icon name="address card outline" />
                    Account Settings
                  </Header>
                </Divider>
                <Grid columns="equal">
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <label>Update Email</label>
                          <Form.Input
                            onChange={(e, v) => {
                              setEmailError(null);
                              setEmail(v.value);
                            }}
                            value={email}
                            type="email"
                            placeholder="john@smith.com"
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>Confirm Email</label>
                          <Form.Input
                            onChange={(e, v) => {
                              setEmailError(null);
                              setEmailConfirm(v.value);
                            }}
                            value={confirmEmail}
                            type="email"
                            placeholder="john@smith.com"
                          />
                        </Form.Field>
                        <Form.Field style={{ display: "flex" }}>
                          <Button
                            onClick={() => updateEmail()}
                            style={{ marginTop: "auto" }}
                            fluid
                            color="teal"
                            type="button"
                          >
                            Update Email
                          </Button>
                        </Form.Field>
                      </Form.Group>
                      {emailError && (
                        <Form.Group>
                          <Label basic color="red">
                            {emailError}
                          </Label>
                        </Form.Group>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        checked={mailing}
                        onChange={(e, d) => updateMailing(d.checked ? 1 : 0)}
                        toggle
                        label="Receive email marketing updates."
                      />
                    </Grid.Column>
                    <Grid.Column>
                      {/* <Modal
                        onOpen={() => setDeleteModal(true)}
                        onClose={() => setDeleteModal(false)}
                        open={deleteModal}
                        trigger={<Button fluid>Delete Account</Button>}
                      >
                        <Modal.Header>Are you sure?</Modal.Header>
                        <Modal.Content>
                          <Grid padded>
                            <Grid.Row>
                              This action is permanent and you will lose all of
                              your data. Are you sure you want to continue?
                            </Grid.Row>
                            <Grid.Row>
                              In order to delete your account you must enter
                              your account email:
                            </Grid.Row>
                            <Grid.Row>
                              <Input
                                onChange={(e, v) => setDeleteEmail(v.value)}
                                value={deleteEmail}
                                placeholder="email"
                              />
                            </Grid.Row>
                          </Grid>
                        </Modal.Content>
                        <Modal.Actions>
                          <Button onClick={() => setDeleteModal(false)}>
                            Cancel
                          </Button>{" "}
                          <Button onClick={() => deleteAccount()} negative>
                            Confirm
                          </Button>
                        </Modal.Actions>
                      </Modal>
                       */}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(EditProfile);
