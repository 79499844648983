import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import discover from "../Discover/Discover.module.scss";
import { useNavigate } from "react-router-dom";
import UserImage from "../components/UserImage";
import { Header, Icon } from "semantic-ui-react";
import { ErrorBoundary } from "react-error-boundary";

const VerticalCard = (props) => {
  let history = useNavigate();
  let background = null;
  if (props.recipeImage) {
    let image = null;
    if (props.recipeSourceMethod == "web") {
      image = props.recipeImage;
    } else {
      image = `${process.env.REACT_APP_API}/recipe_images/${props.recipeImage}`;
    }
    background = image;
    background = `${process.env.REACT_APP_API}/app/image/discover/${props.id}`;
  }
  let url;
  if (props.recipeSourceMethod == "web") {
    url = new URL(props.recipeSource);
  }

  let host, urlIcon;
  if (url) {
    host = url.host.replace("www.", "");
  }
  let author = props.recipeAuthor || null;

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div className={discover.VerticalCard} onClick={props.onClick}>
        <div className={discover.content}>
          <p>Something went wrong with this recipe</p>
          <button onClick={resetErrorBoundary}>Try again</button>
        </div>
      </div>
    );
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className={discover.VerticalCard} onClick={props.onClick}>
        <div className={discover.content}>
          <div
            className={discover.HeaderImage}
            style={{
              background: `url("${background}"),  url(${process.env.PUBLIC_URL}/img/placeholder.png)`,
            }}
          />
          <div className={discover.Caption}>
            <h2>{props.recipeName}</h2>
            <div className={discover.webCaption}>
              {host && (
                <Header as="h5">
                  <Icon name="linkify" />
                  <Header.Content>{host}</Header.Content>
                </Header>
              )}
              {author && (
                <Header as="h5">
                  <Icon name="user circle" />
                  <Header.Content>{author}</Header.Content>
                </Header>
              )}
            </div>
          </div>
        </div>

        <div className={discover.footer}>
          <UserImage user={props.user} />
          <div className={discover.sharedText}>
            {" "}
            shared by{" "}
            <span className={discover.username}>
              {props.user && props.user.first_name
                ? props.user.first_name
                : "[Deleted User]"}
            </span>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default VerticalCard;
