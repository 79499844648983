import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Grid,
  Segment,
  Input,
  Checkbox,
  Icon,
  Button,
  Dropdown,
  Label,
} from "semantic-ui-react";
import {
  updateSelectedCards,
  clearSelected,
  insertCard,
  updateCard,
} from "../../store/actions/plannerActions";
import _ from "lodash";
import axios from "../../api/axios-token";
import uuid from "react-uuid";
import CustomIcon from "../../components/CustomIcon";
import classes from "./editCustom.module.scss";

const EditCustomCard = (props) => {
  const [customIconIndex, setIconIndex] = useState(0);
  const [customText, setCustomText] = useState("");
  const [customCalories, setCustomCalories] = useState("");
  const [customProtein, setCustomProtein] = useState("");
  const [customCarbs, setCustomCarbs] = useState("");
  const [customFat, setCustomFat] = useState("");
  const [customType, setCustomType] = useState("recipe");
  const [saveCard, setSaveCard] = useState(false);
  const [predictedCalories, setPredictedCalories] = useState(null);
  const [disableSave, setDisableSave] = useState(true);

  const icons = [
    "custom",
    "hamburger",
    "cookie",
    "bread",
    "hotdog",
    "drumstick",
    "fish",
    "hottub",
    "run",
    "treat",
    "walk",
    "swim",
    "cycle",
    "hiit",
  ];

  useEffect(() => {
    if (props.editID) {
      const data = _.find(props.customCards, { id: parseInt(props.editID) });
      setCustomText(data.name);
      setCustomCalories(data.assumedCalories);
      setCustomCarbs(data.assumedCarbs || "");
      setCustomFat(data.assumedFat || "");
      setCustomProtein(data.assumedProtein || "");
      setCustomType(data.assumedType || "recipe");
      setIconIndex(icons.indexOf(data.icon));
      setDisableSave(true);
    } else {
      setDisableSave(false);
      setCustomText("");
      setCustomCalories("");
      setCustomCarbs("");
      setCustomProtein("");
      setCustomFat("");
      setCustomType("recipe");
      setIconIndex(0);
      setSaveCard(false);
    }
  }, [props.editID]);

  useEffect(() => {
    if (customCarbs != "" && customFat != "" && customProtein != "") {
      const calories =
        (parseFloat(customCarbs) + parseFloat(customProtein)) * 4 +
        parseFloat(customFat) * 9;
      setPredictedCalories(calories);
    } else {
      setPredictedCalories(null);
    }
  }, [customCarbs, customFat, customProtein]);

  const changeIcon = (n) => {
    let iconNumber = customIconIndex;
    iconNumber = iconNumber + n;

    if (iconNumber > icons.length - 1) {
      iconNumber = 0;
    } else {
      if (iconNumber < 0) {
        iconNumber = icons.length - 1;
      }
    }
    setIconIndex(iconNumber);
  };

  const changeCalories = (v, type) => {
    const regex = /^\d+\.?\d*$/;

    if (!regex.test(v) && v != "" && type != "type") {
      return false;
    }
    switch (type) {
      case "calories":
        setCustomCalories(v);
        break;
      case "protein":
        setCustomProtein(v);
        break;
      case "carbs":
        setCustomCarbs(v);
        break;
      case "fat":
        setCustomFat(v);
        break;
      case "type":
        setCustomType(v);

        break;
      default:
        setCustomCalories(v);
    }
  };

  const addCustomCard = () => {
    // create temporary card..
    // if 'not save' add to temporary cards
    // if 'save' add to custom cards redux
    // then select the card...
    let id;
    const assumedCalories =
      customCalories != "" ? parseFloat(customCalories) : 0;
    const assumedProtein = customProtein != "" ? parseFloat(customProtein) : 0;
    const assumedCarbs = customCarbs != "" ? parseFloat(customCarbs) : 0;
    const assumedFat = customFat != "" ? parseFloat(customFat) : 0;
    const assumedType = customType;
    if (!saveCard) {
      id = "custom-" + uuid();
      const cardPromise = new Promise((resolve, reject) => {
        const card = {
          id: id,
          type: "custom",
          name: customText,
          icon: icons[customIconIndex],
          assumedCalories: assumedCalories,
          assumedProtein: assumedProtein,
          assumedCarbs: assumedCarbs,
          assumedFat: assumedFat,
          assumedType: assumedType,
        };
        const updateItem = props.insertCard(card);
        resolve(updateItem); // insert card
      })
        .then(() => {
          props.toggleCard(id);
        })
        .then(() => {
          props.close();
        });
    } else {
      const data = {
        type: "custom",
        name: customText,
        icon: icons[customIconIndex],
        assumedCalories: assumedCalories,
        assumedProtein: assumedProtein,
        assumedCarbs: assumedCarbs,
        assumedFat: assumedFat,
        assumedType: assumedType,
      };
      axios
        .post("/planner/add_card", {
          data: data,
        })
        .then(function (response) {
          if (response.data.status) {
            id = parseInt(response.data.id);
            const card = {
              id: id,
              type: "custom",
              name: customText,
              icon: icons[customIconIndex],
              assumedCalories: assumedCalories,
              assumedProtein: assumedProtein,
              assumedCarbs: assumedCarbs,
              assumedFat: assumedFat,
              assumedType: assumedType,
            };
            const cardPromise = new Promise((resolve, reject) => {
              const updateItem = props.insertCard(card);
              resolve(updateItem); // insert card
            }).then(() => {
              props.toggleCard(id);
              props.close();
            });
          } else {
            alert("Saving failed, check your connection");
            return false;
          }
        });
    }
  };

  const updateCard = (o) => {
    const assumedCalories =
      customCalories != "" ? parseFloat(customCalories) : 0;
    const assumedProtein = customProtein != "" ? parseFloat(customProtein) : 0;
    const assumedCarbs = customCarbs != "" ? parseFloat(customCarbs) : 0;
    const assumedFat = customFat != "" ? parseFloat(customFat) : 0;
    const assumedType = customType;
    const card = {
      name: customText,
      icon: icons[customIconIndex],
      assumedCalories: assumedCalories,
      assumedProtein: assumedProtein,
      assumedCarbs: assumedCarbs,
      assumedFat: assumedFat,
      assumedType: assumedType,
      type: "custom",
      archived: 0,
    };
    props.updateCard(props.editID, card);
  };

  const typeOptions = [
    {
      key: "recipe",
      text: "Recipe",
      value: "recipe",
    },
    {
      key: "snack",
      text: "Snack",
      value: "snack",
    },
  ];

  return (
    <Modal dimmer="inverted" open={props.open}>
      <Modal.Header>Add custom card to planner</Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Column width={4}>
            <label>Icon</label>
            <Segment
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Icon name="chevron left" onClick={() => changeIcon(-1)} />
              <div style={{ fontSize: "4em", display: "flex" }}>
                <CustomIcon
                  iconClass="customCard"
                  icon={icons[customIconIndex]}
                />
              </div>
              <Icon name="chevron right" onClick={() => changeIcon(1)} />
            </Segment>
          </Grid.Column>
          <Grid.Column width={12}>
            <label>Text to appear on card:</label>
            <Input
              value={customText}
              style={{ marginTop: "1em" }}
              fluid
              placeholder="Custom Card Text"
              onChange={(e, v) => setCustomText(v.value)}
            />
            <div className={classes.advanced}>
              <ul>
                <li>
                  {predictedCalories ? (
                    <Label basic color="red" pointing="below">
                      {`Estimated ${Math.round(predictedCalories)} calories`}
                    </Label>
                  ) : null}
                  <label>Calories:</label>
                  <Input
                    value={customCalories}
                    onChange={(e, v) => changeCalories(v.value, "calories")}
                    style={{ marginTop: "1em" }}
                    placeholder="1000"
                  />
                </li>
                <li>
                  <label>Protein:</label>
                  <Input
                    value={customProtein}
                    onChange={(e, v) => changeCalories(v.value, "protein")}
                    style={{ marginTop: "1em" }}
                    placeholder="1000"
                  />
                </li>
                <li>
                  <label>Carbs:</label>
                  <Input
                    value={customCarbs}
                    onChange={(e, v) => changeCalories(v.value, "carbs")}
                    style={{ marginTop: "1em" }}
                    placeholder="1000"
                  />
                </li>
                <li>
                  <label>Fat:</label>
                  <Input
                    value={customFat}
                    onChange={(e, v) => changeCalories(v.value, "fat")}
                    style={{ marginTop: "1em" }}
                    placeholder="1000"
                  />
                </li>
                <li>
                  <label>Type:</label>
                  <Dropdown
                    placeholder="Select"
                    fluid
                    selection
                    value={customType}
                    options={typeOptions}
                    onChange={(e, v) => changeCalories(v.value, "type")}
                  />
                </li>
              </ul>
            </div>
            <div style={{ marginTop: "1em", display: "flex" }}>
              <Checkbox
                disabled={disableSave}
                onChange={(e, d) => setSaveCard(d.checked)}
                checked={props.editID ? true : saveCard}
                toggle
                style={{ marginRight: "1em" }}
              />{" "}
              <label>Save this card</label>
            </div>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={() => props.close()}>
          Cancel
        </Button>
        <Button
          positive
          onClick={() => {
            props.editID ? updateCard() : addCustomCard();
          }}
        >
          {props.editID ? "Save" : "Add"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
const mapStateToProps = (state) => {
  return {
    selectedCards: state.planner.selectedCards,
    customCards: state.planner.customCards,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateSelectedCards: (array) => dispatch(updateSelectedCards(array)),
    clearSelected: () => dispatch(clearSelected()),
    insertCard: (card) => dispatch(insertCard(card)),
    updateCard: (id, data) => dispatch(updateCard(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomCard);
