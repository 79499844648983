import uuid from "react-uuid";
import _ from "lodash";
import groupArray from "group-array";
import {
  formatDateDay,
  parseDate,
  getFullDay,
} from "../screens/Planner/dateHelper";

export const parseCustomCard = (obj) => {
  let data;
  try {
    data = JSON.parse(obj);
  } catch (error) {
    return null;
  }

  try {
    const card = {
      id: uuid(),
      type: "custom",
      name: data.name,
      icon: data.icon,
      assumedCalories: data.assumedCalories,
      assumedProtein: data.assumedProtein || null,
      assumedCarbs: data.assumedCarbs || null,
      assumedFat: data.assumedFat || null,
      assumedType: data.assumedType || "recipe",
    };
    return card;
  } catch (error) {
    const card = {
      id: uuid(),
      type: "custom",
      name: "Custom Card [Corrupt Card]",
      icon: "custom",
      assumedCalories: 0,
      assumedProtein: 0,
      assumedCarbs: 0,
      assumedFat: 0,
      assumedType: "recipe",
    };
    return card;
  }
};

export const getWorkoutInfo = (props) => {
  const regex = /.*v=(.*?)&/g;
  let found = null;
  if (props.video_url) {
    found = regex.exec(props.video_url);
  }

  let cardImage = "https://via.placeholder.com/150";

  if (found && found.length > 1) {
    cardImage = `https://img.youtube.com/vi/${found[1]}/hqdefault.jpg`;
  }
  let cycle, cycle_number, cycle_name;
  if (props.source == "bodycoach") {
    cycle = props.cycle.substr(0, 2);

    cycle_number = props.cycle.substr(1, 1);

    const regexName = new RegExp(`Cycle ${cycle_number}\s*`, "i");

    cycle_name = props.name.replace(regexName, "");
    cycle_name = cycle_name.replace(/graduate\s*/i, "");

    return {
      name: cycle_name,
      cycle: cycle,
      image: cardImage,
      type: "bodycoach",
    };
  }
  if (props.source == "planeatmeal") {
    let thumbnail = null;
    let image = null;
    let icon = null;
    let type = null;
    if (props.thumbnail) {
      try {
        thumbnail = JSON.parse(props.thumbnail);
        if (thumbnail.type == "icon") {
          type = "icon";
          icon = thumbnail.image;
        }
        if (thumbnail.type == "image") {
          type = "image";
          icon = thumbnail.image;
        }
      } catch (error) {
        thumbnail = null;
      }
    }
    return {
      name: props.name,
      cycle: props.type,
      image: image,
      type: type,
      icon: icon,
      thumnail: thumbnail,
    };
  } else {
    return {
      name: props.name,
      cycle: "custom",
      image: null,
      type: "icon",
      icon: "run",
    };
  }
};

export const getRawWorkoutInfo = (props) => {
  const regex = /.*v=(.*?)&/g;

  let found = null;
  if (props.video1_url) {
    found = regex.exec(props.video1_url);
  }
  let cardImage = "https://via.placeholder.com/150";

  if (found && found.length > 1) {
    cardImage = `https://img.youtube.com/vi/${found[1]}/hqdefault.jpg`;
  }
  let cycle, cycle_number, cycle_name;
  if (props.source == "bodycoach") {
    cycle = props.master_cycle.substr(0, 2);

    cycle_number = props.master_cycle.substr(1, 1);

    const regexName = new RegExp(`Cycle ${cycle_number}\s*`, "i");

    cycle_name = props.name.replace(regexName, "");
    cycle_name = cycle_name.replace(/graduate\s*/i, "");
    return { name: cycle_name, cycle: cycle, image: cardImage };
  }
  if (props.source == "planeatmeal") {
    let thumbnail = null;
    let image = null;
    let icon = null;
    let type = null;
    if (props.thumbnail) {
      try {
        thumbnail = JSON.parse(props.thumbnail);
        if (thumbnail.type == "icon") {
          type = "icon";
          icon = thumbnail.image;
        }
        if (thumbnail.type == "image") {
          type = "image";
          icon = thumbnail.image;
        }
      } catch (error) {
        if (typeof props.thumbnail === "object") {
          thumbnail = props.thumbnail;
        } else {
          thumbnail = null;
        }
      }
    }
    return {
      name: props.name,
      cycle: props.type,
      image: image,
      type: type,
      icon: icon,
    };
  } else {
    return {
      name: props.name,
      cycle: "custom",
      image: null,
      type: "icon",
      icon: "run",
    };
  }
};

export const round = (num, decimalPlaces) => {
  if (isNaN(num)) {
    return "--";
  } else {
    var p = Math.pow(10, decimalPlaces);
    var m = num * p * (1 + Number.EPSILON);
    return Math.round(m) / p;
  }
};

export const convertMinutes = (mins) => {
  let hours;
  if (mins >= 60) {
    hours = Math.floor(mins / 60);
    mins = mins - hours * 60;
  }
  if (mins < 0) {
    mins = 0;
  }
  let string = "";
  if (hours && mins) {
    string += hours + ":" + mins;
    return string;
  }
  if (hours && !mins) {
    string += hours + "hrs";
    return string;
  }
  string = mins + "m";

  return string;
};

export const getWaterTarget = (calories, exercise, lifestyle) => {
  let active = 1;
  if (!calories) {
    return 2500;
  }
  if (!exercise || !lifestyle) {
    active = 1.5;
  } else {
    if (exercise + lifestyle > 2) {
      active = 1.5;
    }
  }

  let water = calories * active;
  if (water < 2500) {
    water = 2500;
  }
  //console.log(weight);
  // weight = weight * 1.1;
  // weight = weight + active * 12;
  // weight = (weight / 33.81) * 1000;

  return Math.round(water);
};

export const parseShopping = (
  shoppingData,
  data,
  sort,
  purchased,
  additional,
  extra
) => {
  const shopping = {
    sort: sort,
    columns: [],
    purchased: purchased,
    extras_column: extra,
  };
  {
    _.map(data, function (d, i) {
      const grouped = groupArray(d, "ingredient_id", "converted_unit");
      let header = "";
      switch (sort) {
        case "aisle":
          header = data[i][0].cupboard_name;
          break;
        case "recipe":
          header = data[i][0].recipe_name;
          break;
        case "day":
          const date = parseDate(data[i][0].iso_date);
          header = getFullDay(date) + " " + formatDateDay(date);
          break;
      }

      let totalCount = 0;
      let checkCount = 0;
      _.map(grouped, function (unit) {
        _.map(unit, function (ing) {
          totalCount++;
          let checkPurchase = 0;
          _.map(ing, function (item) {
            if (purchased.indexOf(item.shopping_id) > -1) {
              checkPurchase++;
            }
          });

          if (checkPurchase == ing.length) {
            checkCount++;
          }
        });
      });
      let displayTitle = "auto";
      if (totalCount == checkCount) {
        displayTitle = 0;
      }
      let collapseId = sort + "-" + i;

      const shoppingGroup = {
        title: displayTitle,
        header: header,
        groupId: collapseId,
        totalCount: totalCount,
        checkCount: checkCount,
        shopping: [],
        cupboard_name: data[i][0].cupboard_name,
        cupboard_id: data[i][0].cupboard_id,
      };

      _.map(grouped, function (ingredient, ingIndex) {
        return _.map(ingredient, function (unit, unitIndex) {
          const name = unit[0].name;

          // find in original where the Array is the converted_unit / ingredient id
          //  const shopping_ids = _.filter(props.shopping, {
          //    converted_unit: q.converted_quantity,
          //  });
          // console.log(shopping_ids);

          let quantity = _.sumBy(unit, function (q) {
            let quantity = parseFloat(q.converted_quantity);

            if (parseInt(q.reduce) && parseInt(q.servings) > 1) {
              const percent = Math.round((1 / parseInt(q.servings)) * 100);
              quantity = ratioIngredient(q, percent).user_quantity;
            }
            if (parseInt(q.servings) == 1 || parseInt(q.reduce)) {
              return parseFloat(quantity) * parseFloat(q.portions);
            } else {
              const totalServings = Math.ceil(
                parseInt(q.portions) / parseInt(q.servings)
              );

              return parseFloat(quantity) * totalServings;
            }
          });

          /// add additional ingredients...

          const additionalAmount = _.find(additional, {
            ingredient_id: String(ingIndex),
            unit: String(unitIndex),
          });

          /// SUBTRACK IN STOCK

          let checked_count = 0;
          const ingRecipes = [];
          const shopping_ids = _.reduce(
            unit,
            function (acc, curr) {
              return [...acc, curr.shopping_id];
            },
            []
          );

          _.map(unit, function (u) {
            if (
              _.find(purchased, function (p) {
                return p == u.shopping_id;
              })
            ) {
              checked_count++;
            }
          });

          let totalLength = unit.length;
          if (sort == "aisle") {
            if (additionalAmount) {
              totalLength++;
              quantity = quantity + parseFloat(additionalAmount.quantity);
              shopping_ids.push("add-" + additionalAmount.id);
              if (purchased.indexOf("add-" + additionalAmount.id) > -1) {
                checked_count++;
              }
            }
          }

          let checked, indeterminate;
          if (checked_count == totalLength) {
            checked = true;
            indeterminate = null;
          } else {
            checked = false;
            if (checked_count > 0) {
              indeterminate = "indeterminate";
            } else {
              indeterminate = false;
            }
          }

          let recipes = shopping_ids.reduce(function (acc, si) {
            const rec = _.find(shoppingData, {
              shopping_id: si,
            });
            if (rec) {
              acc.push(rec);
            }
            return acc;
          }, []);

          recipes = _.groupBy(recipes, "recipe_id");
          const keyId = shopping_ids.join("-");
          const iUnit = unitIndex;
          let height = 0;

          let checkedClass = "auto";
          if (checked) {
            checkedClass = 0;
          }

          let textSpacer = iUnit == "whole" ? " " : "";
          let ingQuantity = round(quantity, 1) + textSpacer + iUnit;
          const ingredientParse = {
            key: keyId,
            name: name,
            quantity: ingQuantity,
          };

          const shoppingItems = {
            key: keyId,
            shopping_ids: shopping_ids,
            checked: checked,
            indeterminate: indeterminate,
            quantity: quantity,
            name: name,
            unit: iUnit,
            ingredient_id: unit[0].ingredient_id,
            converted_unit: unit[0].converted_unit,

            recipes: recipes,
          };
          shoppingGroup.shopping.push(shoppingItems);
        }); // end ingredient unit map
      });
      shopping.columns.push(shoppingGroup);
    });
  }

  return shopping;
};

export const ratioIngredient = (ingredient, percent) => {
  if (isNaN(parseInt(percent))) {
    return;
  }
  const ratio = parseInt(percent) / 100;

  let rounding = 1;

  let unit = ingredient.converted_unit;

  if (!ingredient.converted_quantity) {
    unit = ingredient.unit;
  }

  let quantity =
    ingredient.converted_quantity ||
    ingredient.user_quantity ||
    ingredient.quantity;

  quantity = parseFloat(quantity);

  switch (unit) {
    case "g":
      rounding = 1;
      break;
    case "ml":
      rounding = 1;
      break;
    case "whole":
      rounding = 0.5;
      break;
    case "pinch":
      rounding = 1;
      break;
    case "handful":
      rounding = 1;
      break;
    case "tsp":
      rounding = 0.5;
      break;
    case "tbsp":
      rounding = 0.5;
      break;
    case "bunch":
      rounding = 0.5;
      break;
    case "squeeze":
      rounding = 1;
      break;
    case "slices":
      rounding = 1;
      break;
    case "cup":
      rounding = 0.25;
      break;
    case "kg":
      rounding = 0.25;
      break;
    case "l":
      rounding = 0.25;
      break;
    case "lb":
      rounding = 0.25;
      break;
    case "pt":
      rounding = 0.25;
      break;
    default:
      rounding = 1;
  }

  var eggsRegex = /eggs?\b( whites?)?/;

  if (eggsRegex.test(ingredient.name)) {
    rounding = 1;
  }

  let newUserQuantity = quantity;

  if (ratio != 1) {
    newUserQuantity = roundNearest(ratio * quantity, rounding);
  }
  if (newUserQuantity <= 0) {
    newUserQuantity = rounding;
  }

  const newIngredient = {
    ...ingredient,
    user_quantity: newUserQuantity,
  };

  return newIngredient;
};

function roundNearest(v, n) {
  return Math.round(v / n) * n;
}
