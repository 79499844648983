import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { addTag } from "../../../store/actions/recipeActions";
import axios from "../../../api/axios-token";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
const TagSearch = (props) => {
  const [tagOptions, setTagOptions] = useState([]);
  const [loadingIngredients, setloadingIngredients] = useState(false);
  const [textIngredients, settextIngredients] = useState(null);
  const [tags, setTags] = useState([]);

  const setOptions = (tags) => {
    if (tags) {
      return Object.keys(tags).map((i) => {
        return {
          key: tags[i].id,
          text: tags[i].tag_name,
          value: tags[i].id,
        };
      });
    }
    return true;
  };

  useEffect(() => {
    setTagOptions(setOptions(props.tags));
  }, [props.tags, props.tagValue]);

  const addTag = (tagName) => {
    let newTag = null;
    axios
      .post("/recipes/add_tag", {
        tag_name: tagName,
      })
      .then(function (response) {
        // update tag redux

        const tag = response.data.tag;
        newTag = {
          keys: tag.id,
          text: tagName,
          value: tag.id,
        };
        return tag;
      })
      .then(function (response) {
        if (newTag) {
          setTagOptions([...tagOptions, { ...newTag }]);
          props.updateTags([...props.tagValue, newTag.value]);
          props.addTag(response);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Dropdown
      closeOnChange
      upward
      style={{ marginTop: "1em" }}
      fluid
      selection
      multiple={true}
      search={true}
      options={tagOptions}
      value={props.tagValue}
      allowAdditions={true}
      onAddItem={(e, t) => {
        addTag(t.value);
      }}
      placeholder="Add Tags"
      onChange={(e, t) => {
        props.updateTags(t.value);
      }}
      onSearchChange={() => {
        // searchChange();
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    tags: state.recipes.tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTag: (payload) => dispatch(addTag(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TagSearch);
