import uuid from "react-uuid";
import axios from "../../api/axios-token";

export const UPDATE_XP = "UPDATE_XP";
export const UPDATE_XP_PRE = "UPDATE_XP_PRE";

export const updateXp = (payload) => {
  axios
    .post("/diary/updatexp", {
      data: payload,
    })
    .catch(function (error) {
      console.log("Update Error");
    });
  return {
    type: UPDATE_XP,
    payload: { uuid: uuid(), time: Date.now(), ...payload },
  };
};

export const updateXpPreTotal = (payload) => {
  return { type: UPDATE_XP_PRE, payload: payload };
};

export const getLatestXp = () => {
  return (dispatch) => {
    axios
      .get("/diary/get_xp")
      .then(function (res) {
        dispatch(updateXpPreTotal(parseInt(res.data.total)));
      })
      .catch(function (error) {
        console.log("Update Error");
      });
  };
};
