import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { round } from "../../../utility/helper";
import {
  Modal,
  Button,
  Input,
  Table,
  Dimmer,
  Loader,
  List,
  Image,
  Form,
  Radio,
  Header,
  Icon,
  Select,
  Message,
} from "semantic-ui-react";
import axios from "../../../api/axios-token";
import MacroBox from "../../Recipes/components/MacroBox";
import _ from "lodash";
import classes from "./MacroSearch.module.scss";
import { useDispatch } from "react-redux";
import { updateIngredientMacros } from "../../../store/actions/recipeActions";
import amplitude from "amplitude-js";
const AddCustom = (props) => {
  const [units, setUnits] = useState([]);
  const [customMacro, setCustomMacro] = useState({
    unit: props.unit == "whole" ? "whole" : "g",
    per: 100,
    carbs: 0,
    fat: 0,
    protein: 0,
    kcal: 0,
  });

  const updateCustom = (state, value) => {
    const macroCopy = { ...customMacro };

    const regex = /^\d+\.?\d*$/;
    if (state != "unit") {
      if (!regex.test(value) && value != "") {
        return false;
      }
    }
    switch (state) {
      case "unit":
        macroCopy.unit = value;
        break;
      case "per":
        macroCopy.per = value;
        break;
      case "carbs":
        macroCopy.carbs = value;
        break;
      case "fat":
        macroCopy.fat = value;
        break;
      case "protein":
        macroCopy.protein = value;
        break;
      case "kcal":
        macroCopy.kcal = value;
        break;
    }
    setCustomMacro({ ...macroCopy });
  };

  const getUnits = () =>
    axios
      .get("/recipes/get_units")
      .then(function (response) {
        if (response.data.units) {
          setUnits(response.data.units);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  const options = _.map(units, function (i) {
    return {
      key: i.id,
      value: i.unit,
      text: i.unit,
    };
  });

  const saveCustom = () => {
    const data = {
      calories: customMacro.kcal,
      carbs: customMacro.carbs,
      weight: customMacro.per,
      fat: customMacro.fat,
      unit: customMacro.unit,
      protein: customMacro.protein,
      IngredientID: props.ingredientID,
    };
    axios
      .post("/recipes/insert_macro", { data: data })
      .then(function (response) {
        const macros = response.data.macros;

        if (macros) {
          amplitude.getInstance().logEvent("Custom Macro Added");

          props.dispatch(props.ingredientID, macros);
          props.reset();
        } else {
          alert(
            "Something went wrong connecting to the server. Try reloading the page and trying again."
          );
        }
      })
      .catch(function (error) {
        alert(
          "Something went wrong connecting to the server. Try reloading the page and trying again."
        );

        console.log(error);
      });
  };

  return (
    <Modal
      closeIcon
      open={props.open}
      trigger={<a>Add Custom Macro</a>}
      onClose={() => props.setOpen(false)}
      onOpen={() => {
        getUnits();
        amplitude.getInstance().logEvent("Macro Tools Opened");
        props.setOpen(true);
      }}
    >
      <Header icon="grid layout" content="Add Custom Macro" />
      <Modal.Content>
        <Table
          className={classes.newMacro}
          unstackable
          size="small"
          compact
          basic="very"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Macro Unit</Table.HeaderCell>
              <Table.HeaderCell>
                {customMacro.unit == "whole" ? "Unit Weight" : "Per Unit"}
              </Table.HeaderCell>
              <Table.HeaderCell>Protein</Table.HeaderCell>
              <Table.HeaderCell>Fats</Table.HeaderCell>
              <Table.HeaderCell>Carbs</Table.HeaderCell>
              <Table.HeaderCell>Kcal</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Select
                  value={customMacro.unit}
                  loading={!units.length}
                  basic
                  fluid
                  onChange={(e, v) => updateCustom("unit", v.value)}
                  options={options}
                />
              </Table.Cell>
              <Table.Cell>
                <Input
                  placeholder="0.00"
                  value={customMacro.per}
                  onChange={(e, v) => updateCustom("per", v.value)}
                />
              </Table.Cell>
              <Table.Cell>
                <Input
                  placeholder="0.00"
                  value={customMacro.protein}
                  onChange={(e, v) => updateCustom("protein", v.value)}
                />
              </Table.Cell>
              <Table.Cell>
                <Input
                  placeholder="0.00"
                  value={customMacro.fat}
                  onChange={(e, v) => updateCustom("fat", v.value)}
                />
              </Table.Cell>
              <Table.Cell>
                <Input
                  placeholder="0.00"
                  value={customMacro.carbs}
                  onChange={(e, v) => updateCustom("carbs", v.value)}
                />
              </Table.Cell>
              <Table.Cell>
                <Input
                  placeholder="0.00"
                  value={customMacro.kcal}
                  onChange={(e, v) => updateCustom("kcal", v.value)}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => props.setOpen(false)}>
          Cancel
        </Button>
        <Button color="green" onClick={() => saveCustom()}>
          <Icon name="checkmark" /> Add and Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const MacroSearch = (props) => {
  const [database, setDatabase] = useState("planeatmeal");
  const [searching, setSearching] = useState(false);
  const [addMacro, setAddMacro] = useState(false);
  const [newSelect, setNewSelect] = useState(null);
  const [saving, setSaving] = useState(false);
  const [ingredient, setIngredient] = useState(
    props.name ||
      (props.currentMacros.details && props.currentMacros.details.name) ||
      ""
  );
  const [ingredientResults, setIngredientResults] = useState(null);
  const dispatch = useDispatch();

  const resetModal = () => {
    setSaving(false);
    props.close();
    setSearching(false);
    setNewSelect(null);
  };

  const updateIngredientRedux = (id, macros) => {
    amplitude.getInstance().logEvent("Macro Ingredient Updated");

    dispatch(updateIngredientMacros(id, macros));
  };
  const updateMacro = () => {
    if (!newSelect) {
      return;
    }
    setSaving(true);

    axios
      .post("/recipes/get_macro_ingredient", {
        id: newSelect,
        name: props.name,
        unit: props.unit,
      })
      .then(function (response) {
        if (response.data.macros) {
          const macros = response.data.macros;
          macros.id = props.ingredientID;
          macros.ingredientID = props.ingredientID;
          macros.macro_id = "new-" + props.ingredientID;
          // replace macro ing ID with current ID;

          dispatch(updateIngredientMacros(props.ingredientID, macros));
          resetModal();
        } else {
          setSaving(false);
          alert(
            "Error communicating with the server. Try refreshing the page to try again."
          );
        }
      })
      .catch(function (error) {
        setSaving(false);
        alert(
          "Error communicating with the server. Try refreshing the page to try again."
        );
      });
  };

  const searchHandler = () => {
    axios
      .post("/recipes/macro_ingredients", {
        database: database,
        ingredient: ingredient,
      })
      .then(function (response) {
        console.log(response);
        if (response.data && response.data.ingredients) {
          setIngredientResults(response.data.ingredients);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const selectNewMacro = (e, v) => {
    setNewSelect(v);
  };

  return (
    <Modal open={props.open} onClick={(e) => e.stopPropagation()}>
      <Modal.Header>Macro Tools</Modal.Header>
      <Modal.Content scrolling>
        {props.unit == "whole" ? (
          <Message positive>
            <Message.Header>Macro Tip</Message.Header>
            <p>
              Macros require the serving weight in order to provide the correct
              amount. Whilst we do our best to calculate this for you, try
              changing your unit to grams and providing an exact serving
              quantity for better results.
            </p>
          </Message>
        ) : null}
        <Dimmer active={saving}>
          <Loader />
        </Dimmer>
        <Modal.Description>
          <h4>Current Ingredient Macros</h4>
          <Table size="small" compact>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Ingredient Name</Table.Cell>
                <Table.Cell className={classes.title}>{props.name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Amount</Table.Cell>
                <Table.Cell>{props.quantity}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Unit</Table.Cell>
                <Table.Cell>{props.unit}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Calories</Table.Cell>
                <Table.Cell>
                  {round(props.currentMacros.calories, 2)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <h4>Macro Record</h4>
          {props.currentMacros.details ? (
            <List className={classes.MacroList}>
              <List.Item>
                <Image
                  avatar
                  src={`${process.env.PUBLIC_URL}/img/icons8-search.svg`}
                />
                <List.Content>
                  <List.Header>Source</List.Header>
                  {props.currentMacros.details.api_id ||
                  props.currentMacros.details.fat_secret_name
                    ? "Fat Secret"
                    : "PlanEatMeal"}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header className={classes.title}>
                    Database Name
                  </List.Header>
                  {props.currentMacros.details.fat_secret_name ||
                    props.currentMacros.details.name}
                </List.Content>
              </List.Item>
              <List.Item>
                <Image avatar />
                <List.Content>
                  <List.Header>Macro Weight</List.Header>
                  {`Per ${round(
                    parseFloat(props.currentMacros.details.weight),
                    2
                  )}g`}
                </List.Content>
              </List.Item>
              <List.Item>
                <Image avatar />
                <List.Content>
                  <List.Header>Macro Unit</List.Header>
                  {props.currentMacros.details.macro_unit}
                </List.Content>
              </List.Item>
              <List.Item>
                <Image avatar />
                <List.Content>
                  <List.Header>Protein</List.Header>
                  {round(props.currentMacros.details.protein, 2)}
                </List.Content>
              </List.Item>
              <List.Item>
                <Image avatar />
                <List.Content>
                  <List.Header>Carbs</List.Header>
                  {round(props.currentMacros.details.carbs, 2)}
                </List.Content>
              </List.Item>
              <List.Item>
                <Image avatar />
                <List.Content>
                  <List.Header>Fats</List.Header>
                  {round(props.currentMacros.details.fat, 2)}
                </List.Content>
              </List.Item>
              <List.Item>
                <Image
                  avatar
                  src={`${process.env.PUBLIC_URL}/img/icons8-calculator.svg`}
                />
                <List.Content>
                  <List.Header>Total</List.Header>
                  {round(props.currentMacros.details.calories, 2)}
                </List.Content>
              </List.Item>
            </List>
          ) : (
            <p>No macro found</p>
          )}
          <div style={{ marginTop: "1em" }}>
            <h4>Search for an alternative macro: </h4>
            <Form>
              <Form.Group inline>
                <Form.Field>
                  <Radio
                    label="PlanEatMeal Database"
                    name="radioGroup"
                    value="planeatmeal"
                    checked={database == "planeatmeal"}
                    onChange={(e, v) => {
                      setDatabase(v.value);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="FatSecret Database"
                    name="radioGroup"
                    value="fatsecret"
                    checked={database == "fatsecret"}
                    onChange={(e, v) => {
                      setDatabase(v.value);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="FatSecret Database incl. brands"
                    name="radioGroup"
                    value="brands"
                    checked={database == "brands"}
                    onChange={(e, v) => {
                      setDatabase(v.value);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <AddCustom
                    dispatch={(id, macros) => {
                      updateIngredientRedux(id, macros);
                    }}
                    unit={props.unit}
                    ingredientID={props.ingredientID}
                    reset={() => resetModal()}
                    open={addMacro}
                    setOpen={(e) => setAddMacro(e)}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            <Input
              onChange={(e, v) => setIngredient(v.value)}
              value={ingredient}
              action={{
                content: "Search",
                onClick: (e) => {
                  searchHandler();
                },
              }}
              placeholder="Search..."
            />
          </div>
          <div
            className="results"
            style={{ marginTop: "1em", overflowX: "scroll" }}
          >
            {ingredientResults && (
              <Table selectable small compact unstackable definition>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>Kcal</Table.HeaderCell>

                    <Table.HeaderCell>Protein</Table.HeaderCell>
                    <Table.HeaderCell>Carbs</Table.HeaderCell>
                    <Table.HeaderCell>Fats</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {_.map(ingredientResults, function (i, index) {
                    let selectID = i.api_id ? i.id : "MACRO-" + i.macros.id;

                    return (
                      <Table.Row
                        key={`result-${index}`}
                        onClick={(e) => selectNewMacro(e, selectID)}
                      >
                        <Table.Cell className={classes.title}>
                          <>
                            <Radio
                              style={{ marginRight: "0.5em" }}
                              checked={newSelect == selectID}
                              onClick={(e) => selectNewMacro(e, selectID)}
                            />
                            {i.macros.fat_secret_name || i.name}
                            {i.description && (
                              <span>{` (${i.description})`}</span>
                            )}
                          </>
                        </Table.Cell>
                        <Table.Cell>{i.macros.calories}</Table.Cell>

                        <Table.Cell>{i.macros.protein}</Table.Cell>
                        <Table.Cell>{i.macros.carbs}</Table.Cell>
                        <Table.Cell>{i.macros.fat}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            )}
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => props.close()}>
          Cancel
        </Button>
        <Button
          content="Save"
          labelPosition="right"
          icon="checkmark"
          onClick={() => updateMacro()}
          positive
          disabled={!newSelect}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default MacroSearch;
