import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import classes from "./Planner.module.scss";
import {
  getWeekLetters,
  getRelativeDayInWeek,
  getISODay,
  addDays,
} from "./dateHelper";
import _ from "lodash";
const MacroTotals = (props) => {
  const Bar = () => {
    const Chart = [];
    for (let i = 0; i < 7; i++) {
      const date = props.date;
      const currDate = addDays(new Date(date), i);

      const isoDay = getISODay(currDate);
      const getDayCalorie = _.find(props.data, { date: isoDay });
      let percent = 0;
      const calorieBuffer = props.target * 1.25;
      if (getDayCalorie) {
        percent = Math.round((getDayCalorie.calories / calorieBuffer) * 100);
      }
      let chartBarStyle = { height: `${percent}%` };
      if (!props.default) {
        if (percent > 120) {
          chartBarStyle = { ...chartBarStyle, backgroundColor: "#ffb100" };
        }
      }
      Chart.push(
        <div key={i} className={classes.day}>
          <div style={chartBarStyle} className={classes.chart}>
            <span>{getWeekLetters(currDate)}</span>
          </div>
        </div>
      );
    }
    return Chart;
  };

  const target = (props.target / (props.target * 1.25)) * 100;

  const styleTarget = { height: `${Math.round(target)}%` };

  return (
    <>
      <div className={classes.daily}>
        {!props.default && (
          <div style={styleTarget} className={classes.target} />
        )}
        <Bar />
      </div>
    </>
  );
};
export default MacroTotals;
