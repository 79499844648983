import React, { useEffect, useState, useRef } from "react";
import { TagButton } from "../../../components/recipes/tag";
import { Label } from "semantic-ui-react";
import { useWindowWidth } from "@react-hook/window-size";

const Filters = (props) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [endofRow, setEndofRow] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const tagRef = useRef([]);
  const onlyWidth = useWindowWidth();

  const tags = props.categories;

  useEffect(() => {
    if (endofRow) {
      setEndofRow(null);
    }
  }, [onlyWidth]);

  useEffect(() => {
    const offsetTopFirst = tagRef.current[0]
      ? tagRef.current[0].offsetTop
      : null;
    const tagLength = tags.length;
    const offsetTopLast = tagRef.current[tagLength - 1]
      ? tagRef.current[tagLength - 1].offsetTop
      : null;
    if (offsetTopLast > offsetTopFirst) {
      if (endofRow === null) {
        let firstRow, firstRowTop, endRow;
        if (tagRef.current.length) {
          tagRef.current.map((curr, index) => {
            if (curr) {
              if (curr.offsetTop == offsetTopFirst) {
                firstRow = index;
              }
              if (firstRow && tagRef.current[firstRow + 1]) {
                if (curr.offsetTop == tagRef.current[firstRow + 1].offsetTop) {
                  endRow = index;
                }
              }
            }
          });
          let checkTotal = tags.length - 1;
          if (endRow) {
            if (
              tagRef.current[endRow].clientWidth < 77 &&
              endRow < tags.length
            ) {
              endRow -= 1;
              checkTotal -= 1;
            }
          }
          if (endRow < checkTotal && endRow) {
            setEndofRow(endRow);
          }
        }
      }
    }
  }, [tagRef.current, endofRow]);

  if (tags) {
    return (
      <div className="categories">
        {tags.map((category, index) => {
          const toggled = props.toggled.indexOf(category.id) > -1;
          let showMoreTest = false;
          if (index == endofRow) {
            showMoreTest = true;
          }
          if (!showMore && showMoreTest) {
            return (
              <Label
                key="showmore"
                style={{ cursor: "pointer" }}
                onClick={() => setShowMore(true)}
                color="green"
              >
                Show More...
              </Label>
            );
          }
          if (endofRow === null) {
            return (
              <TagButton
                ref={(el) => {
                  tagRef.current[index] = el;
                }}
                toggle={toggled}
                toggleCategory={() => {
                  props.toggleCategory(category.id, !toggled);
                }}
                key={category.id}
                label={`${category.category_name}`}
              />
            );
          } else {
            if ((!showMore && index < endofRow) || showMore) {
              return (
                <TagButton
                  ref={(el) => {
                    tagRef.current[index] = el;
                  }}
                  toggle={toggled}
                  toggleCategory={() => {
                    props.toggleCategory(category.id, !toggled);
                  }}
                  key={category.id}
                  label={`${category.category_name}`}
                />
              );
            }
          }
        })}
        {showMore && endofRow !== null && (
          <Label
            key="showless"
            style={{ cursor: "pointer" }}
            onClick={() => setShowMore(false)}
            color="green"
          >
            Show Less...
          </Label>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default Filters;
