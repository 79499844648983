import React, { useRef } from "react";

import { Modal, Container, Image, Button, Progress } from "semantic-ui-react";
import _ from "lodash";
import Slider from "react-slick";
import {
  Charts,
  ChartContainer,
  ChartRow,
  YAxis,
  LineChart,
} from "react-timeseries-charts";

const ProgressImages = (props) => {
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const sliderRef = useRef();
  return (
    <Modal open={props.open}>
      <Modal.Header></Modal.Header>
      <Modal.Content>
        <Container style={{ marginBottom: "2em" }}>
          <Slider ref={sliderRef} {...sliderSettings}>
            {_.map(props.images, function (img, index) {
              return (
                <div key={"progress-view-" + index}>
                  <Image
                    fluid
                    src={`${process.env.REACT_APP_API}/user_images/${img}`}
                  />
                </div>
              );
            })}
          </Slider>
        </Container>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            props.close();
          }}
          positive
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ProgressImages;
