import { useCallback, useEffect, useRef } from "react";

export const useIfMounted = () => {
  const isMounted = useRef(true);
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const ifMounted = useCallback((func) => {
    if (isMounted.current && func) {
      func();
    } else {
      // console.log('not mounted, not doing anything')
    }
  }, []);

  return ifMounted;
};

export default useIfMounted;
