import React from "react";
import FacebookLoginWithButton from "react-facebook-login";
import classes from "./FBLogin.module.scss";
const LoginButton = (props) => {
  const isFacebookApp = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (
      ua.indexOf("FBAN") > -1 ||
      ua.indexOf("FBAV") > -1 ||
      ua.indexOf("Instagram") > -1
    );
  };
  return (
    <FacebookLoginWithButton
      appId="327384285260284"
      autoLoad={isFacebookApp()} //auto load had to be true for facebook browser
      fields="name,email,picture.width(200)"
      // onClick={}
      callback={props.callback}
      icon="fa fa-facebook fbIcon"
      cssClass={`ui button fluid facebook ${classes.fbLogin}`}
      disableMobileRedirect={!isFacebookApp()}
      redirectUri={"https://www.planeatmeal.com/sign-up"} //had to give this
      version="9.0"
    />
  );
};

export default LoginButton;
