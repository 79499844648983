import axios from "../../api/axios-token";
export const INSERT_SHOPPING = "INSERT_SHOPPING";
export const INIT_SHOPPING = "INIT_SHOPPING";
export const INIT_ADDITIONAL = "INIT_ADDITIONAL";
export const UPDATE_SHOPPING_PURCHASED = "UPDATE_SHOPPING_PURCHASED";
export const UPDATE_SHOPPING_FILTER = "UPDATE_SHOPPING_FILTER";
export const REMOVE_SHOPPING_IDS = "REMOVE_SHOPPING_IDS";
export const UPDATE_SHOPPING_ADJUSTMENT = "UPDATE_SHOPPING_ADJUSTMENT";
export const UPDATE_INGREDIENT_ADJUSTMENT = "UPDATE_INGREDIENT_ADJUSTMENT";
export const INIT_PURCHASES = "INIT_PURCHASES";
export const INSERT_ITEM = "INSERT_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const CLEAR_SHOPPING = "CLEAR_SHOPPING";

export const insertShopping = (payload) => {
  return {
    type: INSERT_SHOPPING,
    payload: payload,
  };
};

export const clearShoppingState = () => {
  return {
    type: CLEAR_SHOPPING,
  };
};

export const initShoppingSuccess = (payload) => {
  return {
    type: INIT_SHOPPING,
    payload: payload,
  };
};

export const initPurchases = (payload) => {
  return {
    type: INIT_PURCHASES,
    payload: payload,
  };
};

export const initAdditionalSuccess = (payload) => {
  return {
    type: INIT_ADDITIONAL,
    payload: payload,
  };
};

export const shoppingPurchasedUpdated = (payload) => {
  return {
    type: UPDATE_SHOPPING_PURCHASED,
    payload: payload,
  };
};

export const updateShoppingPurchased = (payload) => {
  return (dispatch) => {
    dispatch(shoppingPurchasedUpdated(payload));
    axios
      .post("/shopping/update_shopping_purchased", {
        shopping: payload,
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const removeShoppingIds = (payload) => {
  const normalize = [];
  payload.map(function (i) {
    let type = "shopping";
    let id = i;
    if (i.includes("add-")) {
      type = "addition";
      id = i.replace("add-", "");
    }
    normalize.push({ id: id, type: type });
  });
  axios
    .post("/shopping/remove_shopping", {
      data: normalize,
    })
    .catch(function (error) {
      console.log(error);
    });

  return {
    type: REMOVE_SHOPPING_IDS,
    payload: normalize,
  };
};

export const updateShoppingFilter = (payload) => {
  return {
    type: UPDATE_SHOPPING_FILTER,
    payload: payload,
  };
};

export const updateShoppingAdjustment = (payload) => {
  return {
    type: UPDATE_SHOPPING_ADJUSTMENT,
    payload: payload,
  };
};

export const updateItemSuccess = (payload) => {
  return {
    type: UPDATE_ITEM,
    payload: payload,
  };
};
export const removeItemSuccess = (payload) => {
  return {
    type: REMOVE_ITEM,
    payload: payload,
  };
};

export const updateItem = (id, value) => {
  return (dispatch) => {
    dispatch(updateItemSuccess({ id: id, name: value }));
    axios
      .post("/shopping/update_item", {
        id: id,
        value: value,
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const removeItem = (id) => {
  return (dispatch) => {
    dispatch(removeItemSuccess(id));
    axios
      .post("/shopping/remove_item", {
        id: id,
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const ingredientAdjustmentSuccess = (payload) => {
  return {
    type: UPDATE_INGREDIENT_ADJUSTMENT,
    payload: payload,
  };
};

export const insertShoppingItemSuccess = (payload) => {
  return {
    type: INSERT_ITEM,
    payload: payload,
  };
};

export const insertShoppingItem = (payload) => {
  console.log(payload);
  return (dispatch) => {
    dispatch(insertShoppingItemSuccess(payload));
    axios
      .post("/shopping/insert_item", {
        item: payload.name,
        uuid: payload.id,
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const updateIngredientAdjustment = (payload) => {
  return (dispatch) => {
    dispatch(ingredientAdjustmentSuccess(payload));
    axios
      .post("/shopping/update_additional_shopping", {
        shopping: {
          uuid: payload.uuid,
          ingredient_id: payload.ingredient_id,
          unit: payload.unit,
          quantity: payload.quantity,
        },
      })
      .then(function (response) {
        dispatch(ingredientAdjustmentSuccess(response.data.additional_item));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const initShoppingPurchases = () => {
  return (dispatch) => {
    axios
      .get("/shopping/get_shopping_purchased")
      .then(function (response) {
        dispatch(initPurchases(response.data.purchased));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const initShopping = () => {
  return (dispatch) => {
    axios
      .get("/shopping/get_shopping")
      .then(function (response) {
        dispatch(initShoppingSuccess(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const archiveShopping = () => {
  return (dispatch) => {
    dispatch(clearShoppingState());
    axios
      .get("/shopping/archive_shopping")
      .then(function (response) {
        if (response.data.success == "SHOPPING_ARCHIVED") {
          // we're being optimistic...
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const clearShopping = () => {
  return (dispatch) => {
    dispatch(clearShoppingState());

    axios.get("/shopping/clear_shopping").catch(function (error) {
      console.log(error);
    });
  };
};
