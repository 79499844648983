import React from "react";
import { Container, Header } from "semantic-ui-react";
import Browse from "./Browse";

const DiscoverHome = () => {
  return (
    <Container>
      <Browse />
    </Container>
  );
};

export default DiscoverHome;
