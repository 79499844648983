import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  Segment,
  Statistic,
  Input,
  Header,
  Button,
  Loader,
} from "semantic-ui-react";
import IngredientsBasic from "../../Recipes/components/IngredientsBasic";
import classes from "./Macros.module.scss";
import { round } from "../../../utility/helper";
import Slider from "@material-ui/core/Slider";
import sliderStyles from "./sliderStyles.module.scss";
import _ from "lodash";

const MacroBox = (props) => {
  const marksArray = [];
  let i;
  for (i = 70; i <= 130; i += 10) {
    marksArray.push({ value: i });
  }
  const marks = [...marksArray];

  let sliderMarks;
  if (props.targetRatio && props.targetRatio.amount) {
    _.remove(marks, { value: props.targetRatio.amount });
    marks.push({
      value: props.targetRatio.amount,
      label: round(props.targetRatio.calories, 0) + " calories",
    });

    if (marks) {
      sliderMarks = marks;
    }
  } else {
    sliderMarks = marks;
  }

  let mini = null;
  if (props.mini) {
    mini = "mini";
  }
  const sliderText = (value) => {
    return `${props.macroPercent}%`;
  };

  let mealType = props.type == "snack" ? "snack" : "meal";

  return (props.ingredients && props.ingredients.length > 0) ||
    props.showAlways ? (
    <>
      {props.sliderOnly ? null : (
        <>
          <Segment
            inverted
            color="green"
            className={`${classes.Stats} ${mini}`}
          >
            <Statistic.Group widths="four">
              <Statistic className="calories">
                <Statistic.Label>Calories</Statistic.Label>
                <Statistic.Value style={{ textTransform: "initial" }}>
                  {props.ajaxAttempts == 1 ? (
                    <Loader active inline />
                  ) : (
                    <>
                      {isNaN(props.macroTotals.calories) ||
                      props.macroTotals.calories === null
                        ? "--"
                        : round(props.macroTotals.calories, 0)}
                    </>
                  )}
                </Statistic.Value>
              </Statistic>

              <Statistic>
                <Statistic.Label>Protein</Statistic.Label>
                <Statistic.Value>
                  {isNaN(props.macroTotals.protein) ||
                  props.macroTotals.protein === null
                    ? "--"
                    : `${round(props.macroTotals.protein, 0)}g`}
                </Statistic.Value>
              </Statistic>
              <Statistic>
                <Statistic.Label>Carbs</Statistic.Label>
                <Statistic.Value>
                  {isNaN(props.macroTotals.carbs) ||
                  props.macroTotals.carbs === null
                    ? "--"
                    : `${round(props.macroTotals.carbs, 0)}g`}
                </Statistic.Value>
              </Statistic>
              <Statistic>
                <Statistic.Label>Fats</Statistic.Label>
                <Statistic.Value>
                  {isNaN(props.macroTotals.fat) ||
                  props.macroTotals.fat === null
                    ? "--"
                    : `${round(props.macroTotals.fat, 0)}g`}
                </Statistic.Value>
              </Statistic>
            </Statistic.Group>
          </Segment>
        </>
      )}
      {props.hideadjustment ? null : (
        <>
          {props.sliderOnly ? null : (
            <>
              <Header style={{ margin: 0 }} as="h4">
                Adjust the recipe to suit your needs
              </Header>
              {props.calorieTarget == "--" ? null : (
                <Header as="h5">
                  Your {mealType} goal is {props.calorieTarget} calories.
                  {`${
                    props.targetRatio &&
                    " Adjust the slider to " + props.targetRatio.amount + "%"
                  }`}
                </Header>
              )}
            </>
          )}
          <div className={sliderStyles.macroSlider}>
            {props.macroPercent && (
              <Slider
                style={{ marginTop: "2em" }}
                value={parseInt(props.macroPercent)}
                getAriaValueText={sliderText}
                aria-labelledby="slider-macros"
                step={1}
                min={60}
                max={140}
                onChange={(e, v) => {
                  props.changeRatio(v);
                }}
                marks={sliderMarks}
                valueLabelDisplay="on"
              />
            )}
          </div>

          {!props.hideupdatebutton && (
            <Button
              style={{ marginBottom: "2em" }}
              fluid
              color="green"
              className="rounded"
              onClick={() => props.saveIngredients()}
            >
              Update Ingredients
            </Button>
          )}
          {!props.hideingredients && (
            <IngredientsBasic
              noRatio
              keyPrefix={"macrobox-"}
              ingredients={props.ingredients}
            />
          )}
        </>
      )}
    </>
  ) : null;
};

export default MacroBox;
