import React, { useState, useEffect } from "react";
import {
  getISODay,
  getFullDay,
  getRelativeDayInWeek,
  formatDateDay,
  addDays,
  dateDifference,
} from "./dateHelper";
import Day from "./Day";
import _ from "lodash";
import Drawer from "./Drawer";
import classes from "./Planner.module.scss";

const Week = (props) => {
  const [drawer, setDrawer] = useState(null);
  var i;
  let d = props.startDay;
  let date = props.date;

  const days = [];

  const startDay = getRelativeDayInWeek(new Date(date), d);

  const editEntry = (e) => {
    setDrawer(e);
  };

  for (i = 0; i < 7; i++) {
    const dayDate = addDays(new Date(startDay), i);

    const fullDay = getFullDay(dayDate);

    let dayEntries = _.find(props.entries, function (p) {
      return p.date == getISODay(dayDate);
    });

    if (dayEntries) {
      dayEntries = [...dayEntries.data];
    } else {
      dayEntries = [];
    }

    days.push(
      <Day
        clickHandler={() => props.modal(dayDate)}
        key={getISODay(dayDate)}
        day={fullDay}
        date={dayDate}
        editEntry={(e) => editEntry(e)}
        dayIndex={d}
        entries={dayEntries}
        clipboard={props.clipboard}
        dateDay={formatDateDay(dayDate)}
        paste={() => props.pasteClipboard(getISODay(dayDate))}
        copy={() => props.copyPaste(getISODay(dayDate))}
        trash={() => props.trash(getISODay(dayDate))}
        recycle={() => props.recycle(getISODay(dayDate))}
      />
    );
    d++;
  }

  return (
    <div className={classes.weekContent}>
      {days}
      {drawer && (
        <Drawer
          toggle={() => setDrawer(!drawer)}
          display={drawer ? true : false}
          recipe={drawer}
          data={props.entries}
        />
      )}
    </div>
  );
};

export default Week;
