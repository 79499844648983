import axios from "axios";
import authReducer from "../store/reducers/auth";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { refreshToken } from "../store/actions/authActions";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  authReducer,
  composeEnhancers(applyMiddleware(thunk))
);

var axiosToken = axios.create({
  baseURL: process.env.REACT_APP_API + "/app",
  timeout: 20000,
  headers: {
    Authorization: "Bearer " + localStorage.getItem("REACT_TOKEN_AUTH"),
  },
});

axiosToken.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "REACT_TOKEN_AUTH"
  )}`;
  return config;
});

axiosToken.interceptors.response.use(
  function (response) {
    // If the request succeeds, we don't have to do anything and just return the response
    return response;
  },
  function (error) {
    const errorResponse = error.response;
    if (isTokenExpiredError(errorResponse)) {
      return resetTokenAndReattemptRequest(error);
    }
    // If the error is due to other reasons, we just throw it back to axios
    return Promise.reject(error);
  }
);
function isTokenExpiredError(errorResponse) {
  if (!errorResponse) {
    return false;
  }
  console.log("axios debug error", errorResponse);
  if (errorResponse.status) {
    if (
      errorResponse.status == 401 &&
      errorResponse.data.message == "TOKEN_EXPIRED"
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];

async function resetTokenAndReattemptRequest(error) {
  try {
    const { response: errorResponse } = error;
    const resetToken = localStorage.getItem("REACT_TOKEN_REFRESH"); // Your own mechanism to get the refresh token to refresh the JWT token
    if (!resetToken) {
      // We can't refresh, throw the error anyway
      return Promise.reject(error);
    }
    /* Proceed to the token refresh procedure
    We create a new Promise that will retry the request,
    clone all the request configuration from the failed
    request in the error object. */
    const retryOriginalRequest = new Promise((resolve) => {
      /* We need to add the request retry to the queue
    since there another request that already attempt to
    refresh the token */
      addSubscriber((access_token) => {
        errorResponse.config.headers.Authorization = "Bearer " + access_token;
        resolve(axios(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const response = await axios({
        method: "post",
        url: process.env.REACT_APP_API + "/app/auth/refresh_token",
        data: {
          token: localStorage.getItem("REACT_TOKEN_AUTH"),
          refresh: resetToken, // Just an example, your case may vary
        },
      });

      if (!response.data) {
        return Promise.reject(error);
      }
      const newToken = response.data.token;
      // save the newly refreshed token for other requests to use
      store.dispatch(refreshToken(response));
      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched(newToken);
    }
    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
}

function onAccessTokenFetched(access_token) {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach((callback) => callback(access_token));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

export default axiosToken;
