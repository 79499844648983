import { ReactReduxContext } from "react-redux";
import * as actionTypes from "../actions/recipeActions";
import _ from "lodash";
import update from "immutability-helper";

const initialState = {
  recipes: null,
  tags: null,
  // macros: null,
  raw_macros: [],
  user_categories: null,
  default_categories: null,
  recipe_filter_category: null,
  recipesLoaded: false,
  recipe_details: null,
  recipeDetailsLoaded: false,
  edit_ingredients: false,
  edit_recipe: false,
  importedRecipe: null,
};

const reducer = (state = initialState, action) => {
  let newRecipes,
    recipeCopy,
    newTags,
    newMacros,
    newRawMacros,
    newRecipeDetails,
    recipeArray,
    tag;
  switch (action.type) {
    case actionTypes.UPDATE_RECIPES:
      const categoryArray = [];
      const defaultCategories = [];
      action.payload.default_categories.map((category) => {
        categoryArray.push({ ...category, enabled: false });
        defaultCategories.push({ ...category, enabled: false });
      });
      action.payload.user_categories.map((category) => {
        categoryArray.push({
          ...category,
          enabled: false,
        });
      });

      return {
        ...state,
        recipes: [...action.payload.recipes],
        user_categories: [...action.payload.user_categories],
        default_categories: [...defaultCategories],
        recipe_filter_category: [...categoryArray],
        tags: [...action.payload.tags],

        raw_macros: [...action.payload.raw_macros],
        recipesLoaded: true,
      };

    case actionTypes.UPDATE_USER_RECIPE_DETAILS:
      return {
        ...state,
        recipe_details: [...action.payload.details],
        recipeDetailsLoaded: true,
      };
    case actionTypes.UPDATE_RECIPE_MACROS:
      return {
        ...state,
        macros: [...action.payload.macros],
      };
    case actionTypes.UPDATE_USER_TAGS:
      newTags = [...state.tags, action.payload];
      return {
        ...state,
        tags: [...newTags],
      };
    case actionTypes.IMPORT_WEB_RECIPE:
      return {
        ...state,
        importedRecipe: action.payload,
      };
    case actionTypes.RESET_RECIPE_FILTERS:
      let filtersCopy = _.reduce(
        state.recipe_filter_category,
        function (a, c) {
          if (action.payload) {
            if (c.category_name === action.payload) {
              c.enabled = true;
              return [...a, c];
            }
          }
          c.enabled = false;
          return [...a, c];
        },
        []
      );

      return { ...state, recipe_filter_category: [...filtersCopy] };
    case actionTypes.ADD_RECIPE:
      newRecipes = _.sortBy(
        [...state.recipes, ...action.payload.recipe],
        [
          function (r) {
            return r.name.toLowerCase();
          },
        ]
      );
      return {
        ...state,
        recipes: [...newRecipes],
        recipe_details: [...state.recipe_details, ...action.payload.details],
      };
    case actionTypes.SAVE_RECIPE:
      newRecipes = [...state.recipes];
      _.remove(newRecipes, function (r) {
        return r.hash == action.payload.id;
      });
      newRecipes = _.sortBy(
        [...newRecipes, ...action.payload.recipe],
        [
          function (r) {
            return r.name.toLowerCase();
          },
        ]
      );
      newRecipeDetails = [...state.recipe_details];
      _.remove(newRecipeDetails, function (r) {
        return r.id == action.payload.id;
      });
      newRecipeDetails = [...newRecipeDetails, ...action.payload.details];

      return {
        ...state,
        recipes: [...newRecipes],
        recipe_details: [...newRecipeDetails],
      };
    case actionTypes.UPDATE_USER_TAGS:
      return {
        ...state,
        recipe_filter_category: [...action.payload],
      };
    case actionTypes.UPDATE_INGREDIENTS_RATIO: {
      newRecipeDetails = [...state.recipe_details];
      recipeCopy = _.remove(newRecipeDetails, {
        id: action.payload.recipeID,
      })[0];

      recipeCopy.user_ratio = action.payload.userRatio;

      const newData = update(recipeCopy, {
        ingredients: { $set: [...action.payload.ingredients] },
      });

      return {
        ...state,
        recipe_details: [...newRecipeDetails, { ...newData }],
      };
    }
    case actionTypes.INJECT_MACROS:
      newMacros = [...state.raw_macros];
      if (action.payload) {
        const removed = _.remove(newMacros, {
          id: action.payload.id,
          unit: action.payload.unit,
        });

        newMacros = [...newMacros, action.payload];
      }
      return { ...state, raw_macros: newMacros };
    case actionTypes.UPDATE_ING_MACROS:
      newMacros = [...state.raw_macros];
      if (action.payload) {
        const removed = _.remove(newMacros, {
          id: action.payload.id,
        });
        newMacros = [...newMacros, action.payload];
      }
      return { ...state, raw_macros: newMacros };
    case actionTypes.INJECT_MACROS_ARRAY:
      newMacros = [...state.raw_macros];

      _.map(action.payload, function (i) {
        const remove = _.remove(newMacros, {
          id: i.id,
          unit: i.unit,
        });
      });
      newMacros = [...newMacros, ...action.payload];
      return { ...state, raw_macros: newMacros };
    case actionTypes.REMOVE_RECIPE:
      recipeCopy = [...state.recipes];
      const removeRecipe = _.find(recipeCopy, { hash: action.payload });
      removeRecipe.archive = "1";
      return {
        ...state,
        recipes: recipeCopy,
      };
    case actionTypes.REMOVE_RECIPES:
      recipeCopy = [...state.recipes];
      _.map(action.payload, function (r) {
        const removeRecipe = _.find(recipeCopy, { id: r });
        removeRecipe.archive = "1";
      });
      return {
        ...state,
        recipes: recipeCopy,
      };
    case actionTypes.APPLY_TAG:
      recipeCopy = [...state.recipes];

      recipeArray = action.payload.recipes;
      tag = _.find(state.tags, { id: action.payload.tag });

      _.map(recipeArray, function (r) {
        const recipe = _.find(recipeCopy, { id: r });

        recipe.tags = _.union(recipe.tags, [tag.tag_name]);
        recipe.tagIDs = _.union(recipe.tagIDs, [tag.id]);
        recipe.filterIDs = _.union(recipe.filterIDs, ["tag-" + tag.id]);
      });
      return {
        ...state,
        recipes: recipeCopy,
      };
    case actionTypes.APPLY_CATEGORY:
      recipeCopy = [...state.recipes];

      recipeArray = action.payload.recipes;
      tag = _.find(state.recipe_filter_category, { id: action.payload.tag });
      _.map(recipeArray, function (r) {
        const recipe = _.find(recipeCopy, { id: r });

        if (tag.category_group == "type") {
          // if a type remove all before enabling to switch it
          _.map(recipe.categories, function (c, i) {
            if (c.category_group == "type") {
              recipe.categories[i].enabled = false;
              _.pull(recipe.filterIDs, tag.id);
            }
          });
        }
        const category = _.findIndex(recipe.categories, {
          category_id: tag.id,
        });
        recipe.categories[category].enabled = true;
        recipe.filterIDs = _.union(recipe.filterIDs, [tag.id]);
      });
      return {
        ...state,
        recipes: recipeCopy,
      };
    case actionTypes.REMOVE_TAG:
      recipeCopy = [...state.recipes];
      recipeArray = action.payload.recipes;
      tag = _.find(state.tags, { id: action.payload.tag });

      _.map(recipeArray, function (r) {
        const recipe = _.find(recipeCopy, { id: r });
        _.pull(recipe.tags, tag.tag_name);
        _.pull(recipe.tagIDs, tag.id);
        _.pull(recipe.filterIDs, "tag-" + tag.id);
      });
      return {
        ...state,
        recipes: recipeCopy,
      };
    case actionTypes.REMOVE_CATEGORY:
      recipeCopy = [...state.recipes];
      recipeArray = action.payload.recipes;
      tag = _.find(state.recipe_filter_category, { id: action.payload.tag });

      _.map(recipeArray, function (r) {
        const recipe = _.find(recipeCopy, { id: r });
        _.pull(recipe.filterIDs, "tag-" + tag.id);
        const category = _.findIndex(recipe.categories, {
          category_id: tag.id,
        });
        recipe.categories[category].enabled = false;
      });
      return {
        ...state,
        recipes: recipeCopy,
      };
    default:
      return state;
  }
};

export default reducer;
