import { initPlanner } from "./plannerActions";
import { getRecipes } from "./recipeActions";
import { initShopping } from "./shoppingActions";
import { rehydrateDiary } from "./diaryActions";
import axios from "../../api/axios-token";
export const SWITCH_BOTTOM_NAV = "SWITCH_BOTTOM_NAV";
export const RESET_OVERLAY = "RESET_OVERLAY";
export const LOAD_OVERLAY = "LOAD_OVERLAY";
export const SAVE_CURRENT_DAY = "SAVE_CURRENT_DAY";

export const switchBottomNav = (active) => {
  return {
    type: SWITCH_BOTTOM_NAV,
    active: active,
  };
};

export const resetOverlay = () => {
  return {
    type: RESET_OVERLAY,
  };
};

export const saveVisibleDay = (date) => {
  return {
    type: SAVE_CURRENT_DAY,
    payload: date,
  };
};

export const loadTdee = (value = null) => {
  return {
    type: LOAD_OVERLAY,
    payload: "TDEE",
    state: value,
  };
};

export const loadOverlay = (payload) => {
  return {
    type: LOAD_OVERLAY,
    payload: payload,
  };
};

export const preloadStates = () => {
  return (dispatch) => {
    dispatch(getRecipes());
    dispatch(initPlanner());
    dispatch(initShopping());
    dispatch(rehydrateDiary());
  };
};

export const updateOnboardingState = (payload) => {
  return {
    type: "UPDATE_ONBOARDING",
    payload: payload,
  };
};

export const updateOnboarding = (payload) => {
  return (dispatch) => {
    axios
      .post("/profile/update_onboarding", {
        onboarding: payload.variable,
        status: payload.status,
      })
      .then((res) => {
        dispatch(updateOnboardingState(res.data.onboarding));
      });
  };
};
