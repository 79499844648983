import React from "react";
import Content from "./StatsEvent";
import { Feed, Divider } from "semantic-ui-react";

const Stats = () => {
  return (
    <Feed>
      <Divider horizontal>Water Stats</Divider>
      <Content type="water" />
      <Divider horizontal>Current Streaks</Divider>

      <Content type="log_streak" />
      <Divider horizontal>Achievements</Divider>
      <Content type="highest_day_streak" />
      <Content type="highest_log_streak" />
      {/* <Feed.Event>
        <Feed.Label image="/images/avatar/small/jenny.jpg" />
        <Feed.Content>
          <Feed.Summary>Highest Diary Streak</Feed.Summary>
          <Feed.Content>Your longest diary streak is xx</Feed.Content>
        </Feed.Content>
      </Feed.Event>
      <Feed.Event>
        <Feed.Label image="/images/avatar/small/jenny.jpg" />
        <Feed.Content>
          <Feed.Summary>Planning</Feed.Summary>
          <Feed.Content>You have planned xx meals to this date!</Feed.Content>
        </Feed.Content>
      </Feed.Event>
      <Feed.Event>
        <Feed.Label image="/images/avatar/small/jenny.jpg" />
        <Feed.Content>
          <Feed.Summary>Shopping</Feed.Summary>
          <Feed.Content>You have shopped x ingredients so far!</Feed.Content>
        </Feed.Content>
      </Feed.Event> */}
    </Feed>
  );
};

export default Stats;
