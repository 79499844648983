import React, { useRef } from "react";
import Landing from "./Landing";
import Guide from "../Pages/Guide";
import classes from "./Landing.module.scss";
import Legal from "../Pages/Legal";
import SignIn from "../Registration/SignIn";
import SignUp from "../Registration/SignUp";

import ResetPassword from "../Registration/ResetPassword";
import {
  Grid,
  Container,
  Menu,
  Header,
  Image,
  Button,
  Segment,
  List,
  Ref,
  Dropdown,
  Icon,
} from "semantic-ui-react";
import { Routes, Route, NavLink, Outlet } from "react-router-dom";
import HomeScreen from "../Home/HomeScreen";

const LandingHome = (props) => {
  const footerRef = useRef();
  const menuRef = useRef();
  let offset = 0;

  if (footerRef.current) {
    offset = footerRef.current.offsetHeight;
    offset += menuRef.current.offsetHeight;
    offset += "px";
  }

  return (
    <Container
      className={classes.Container}
      fluid
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/landing/spheres.png)`,
      }}
    >
      <Ref innerRef={menuRef}>
        <Grid style={{ padding: "1em", margin: "0" }} className={classes.Menu}>
          <Grid.Row>
            <Grid.Column
              width={4}
              only="computer"
              style={{ padding: "0em 2em" }}
            >
              <Menu secondary size="large" fluid borderless>
                {/* <a className="item" href="https://www.planeatmeal.com/blog">
                  Blog
                </a>
                <Menu.Item as={NavLink} to="/guide">
                  How It Works
                </Menu.Item> */}
                <Menu.Item as={NavLink} to="/sign-in">
                  Sign In
                </Menu.Item>
              </Menu>
            </Grid.Column>
            <Grid.Column width={4} only="mobile tablet">
              <Dropdown
                icon="bars"
                labeled={false}
                button
                className="icon teal"
                direction="right"
              >
                <Dropdown.Menu>
                  {/* <a className="item" href="https://www.planeatmeal.com/blog">
                    Blog
                  </a>

                  <Dropdown.Item as={NavLink} to="/guide">
                    How it Works
                  </Dropdown.Item> */}
                  <Dropdown.Item as={NavLink} to="/sign-in">
                    Sign In
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
            <Grid.Column
              computer={8}
              tablet={8}
              mobile={8}
              verticalAlign="middle"
            >
              <Image
                style={{ maxWidth: "200px", margin: "0 auto" }}
                fluid
                src={`${process.env.PUBLIC_URL}/img/PlanEatMealLogoText.png`}
              />
            </Grid.Column>
            <Grid.Column
              computer={4}
              tablet={4}
              mobile={4}
              className={classes.Social}
              verticalAlign="middle"
            >
              <div style={{ textAlign: "right" }}>
                <Icon circular inverted color="teal" name="facebook official" />
                <Icon circular inverted color="teal" name="instagram" />
                <Icon circular inverted color="teal" name="youtube" />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Ref>
      {props.children}

      <div ref={footerRef} className={classes.footer}>
        <Container style={{ padding: "5em 0" }}>
          <Grid
            className={classes.footer}
            divided
            columns="equal"
            style={{ margin: 0 }}
          >
            <Grid.Column>
              <List>
                <List.Item>
                  <a href="/policies/terms-and-conditions">
                    Terms and Conditions
                  </a>
                </List.Item>
                <List.Item>
                  <a href="/policies/privacy">Privacy Policy</a>
                </List.Item>
                <List.Item>
                  <a href="/policies/cookies">Cookies Policy</a>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" style={{ textAlign: "right" }}>
              Copyright Plan Eat Meal 2021
            </Grid.Column>
          </Grid>
        </Container>
      </div>

      <Outlet />
    </Container>
  );
};

export default LandingHome;
