import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import axios from "../../api/axios-token";
import { Feed, Image, Placeholder, Message } from "semantic-ui-react";
import isUrl from "is-url";
import _ from "lodash";
import RecipeImage from "./components/RecipeImage";
import home from "./Home.module.scss";
import { formatDistanceToNow, parseISO } from "date-fns";
import { connect } from "react-redux";
const Timeline = (props) => {
  const queryClient = new useQueryClient();

  const FeedEvent = (props) => {
    const EventText = (verb, date, data, quantity) => {
      let object, image, meals;

      if (data) {
        data = data.split("§");
        data = _.reduce(
          data,
          function (acc, curr) {
            let current;
            try {
              current = JSON.parse(curr);
            } catch (e) {
              return acc;
            }
            return [...acc, current];
          },
          []
        );
      }
      if (quantity) {
        quantity = quantity.split(",");
        quantity = quantity.reduce((a, b) => a + parseFloat(b), 0);
      }
      let additional = null;
      // combine meals / recipes
      if (verb == "meal") {
        verb = "recipe";
      }

      switch (verb) {
        case "imported_recipe":
          // data = JSON.parse(data);
          image = (
            <Feed.Label>
              <img src={`${process.env.PUBLIC_URL}/img/feed/save_recipe.png`} />
            </Feed.Label>
          );
          let count = data.length > 1 ? "a" : data.length;
          let recipe = data.length > 1 ? "recipes" : "recipe";
          additional = <RecipeImage data={data} />;
          object = {
            text: `Shared ${count} ${recipe}`,
            image: image,
            additional: additional,
          };
          break;
        case "day_bonus":
          image = (
            <Feed.Label>
              <img src={`${process.env.PUBLIC_URL}/img/star.svg`} />
            </Feed.Label>
          );
          object = {
            text: "Achieved your day bonus",
            image: image,
          };
          break;
        case "streak":
          let streak = quantity > 1 ? "days" : "day";
          image = (
            <Feed.Label>
              <img src={`${process.env.PUBLIC_URL}/img/feed/rocket.png`} />
            </Feed.Label>
          );

          object = {
            text: `Logged in ${quantity} ${streak} in a row.`,
            image: image,
          };
          break;
        case "recipe":
          meals = "a meal";
          if (data) {
            const recipeIDs = _.reduce(
              data,
              function (acc, curr) {
                return [...acc, { id: curr.recipe_id }];
              },
              []
            );

            additional = <RecipeImage data={recipeIDs} recipes />;
            meals = data.length > 1 ? data.length + " meals" : "a meal";
          }
          image = (
            <Feed.Label>
              <img src={`${process.env.PUBLIC_URL}/img/feed/cooked.png`} />
            </Feed.Label>
          );

          object = {
            text: `Cooked ${meals}`,
            additional: additional,
            image: image,
          };

          break;

        case "snack":
          meals = "a snack";
          if (data) {
            const recipeIDs = _.reduce(
              data,
              function (acc, curr) {
                return [...acc, { id: curr.recipe_id }];
              },
              []
            );

            additional = <RecipeImage snack data={recipeIDs} recipes />;
            meals = data.length > 1 ? data.length + " snack" : "a snack";
          }
          image = (
            <Feed.Label>
              <img src={`${process.env.PUBLIC_URL}/img/feed/cooked.png`} />
            </Feed.Label>
          );

          object = {
            text: `Ate ${meals}`,
            additional: additional,
            image: image,
          };

          break;

        case "custom":
          let custom_type = data[0] ? data[0].custom || null : null;
          let custom_text = null;
          let custom_image = null;
          if (custom_type == "ate_out") {
            custom_text = "Ate out";
            custom_image = "ate_out.png";
          }
          if (custom_type == "treat_meal") {
            custom_text = "Had a treat meal";
            custom_image = "treat_night.png";
          }
          if (!custom_text) {
            custom_text = "Logged a custom card";
            custom_image = "treat_night.png";
          }
          image = (
            <Feed.Label>
              <img src={`${process.env.PUBLIC_URL}/img/feed/${custom_image}`} />
            </Feed.Label>
          );
          object = { text: custom_text, image: image };

          break;
        case "workout":
          image = (
            <Feed.Label>
              <img src={`${process.env.PUBLIC_URL}/img/feed/workout.png`} />
            </Feed.Label>
          );
          object = { text: "Completed a workout", image: image };
          break;
        case "water":
          let unit = quantity >= 1000 ? " litre" : "ml";
          if (quantity > 2000) {
            unit = " litres";
          }
          if (quantity >= 1000) {
            quantity = quantity / 1000;
          }
          image = (
            <Feed.Label>
              <img src={`${process.env.PUBLIC_URL}/img/feed/water.png`} />
            </Feed.Label>
          );

          object = { text: `Drank ${quantity}${unit} of water`, image: image };
          break;
        default:
          object = { text: "" };
      }

      return (
        <>
          {object.image && object.image}
          <Feed.Content>
            <Feed.Date>{`${formatDistanceToNow(new Date(date), {
              addSuffix: true,
            })}`}</Feed.Date>
            <Feed.Summary>{object.text}</Feed.Summary>
            {additional && additional}
          </Feed.Content>
        </>
      );
    };

    return (
      <Feed.Event>
        {EventText(props.verb, props.date, props.data, props.quantity)}
      </Feed.Event>
    );
  };

  const getStream = () =>
    axios
      .get("/profile/activity_feed")
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  const feed = useQuery(["feed"], getStream);

  useEffect(() => {
    feed.refetch();
  }, [props.diary]);

  return (
    <Feed className={home.ActivityFeed}>
      {feed.isFetching && !feed.data ? (
        <>
          <Feed.Content>
            <Feed.Date>
              <Placeholder></Placeholder>
            </Feed.Date>
            <Feed.Summary>
              <Placeholder>
                <Placeholder.Line /> <Placeholder.Line />
              </Placeholder>
            </Feed.Summary>
          </Feed.Content>
          <Feed.Content>
            <Feed.Date>
              <Placeholder></Placeholder>
            </Feed.Date>
            <Feed.Summary>
              <Placeholder>
                <Placeholder.Line /> <Placeholder.Line />
              </Placeholder>
            </Feed.Summary>
          </Feed.Content>
          <Feed.Content>
            <Feed.Date>
              <Placeholder></Placeholder>
            </Feed.Date>
            <Feed.Summary>
              <Placeholder>
                <Placeholder.Line /> <Placeholder.Line />
              </Placeholder>
            </Feed.Summary>
          </Feed.Content>
          <Feed.Content>
            <Feed.Date>
              <Placeholder></Placeholder>
            </Feed.Date>
            <Feed.Summary>
              <Placeholder>
                <Placeholder.Line /> <Placeholder.Line />
              </Placeholder>
            </Feed.Summary>
          </Feed.Content>
          <Feed.Content>
            <Feed.Date>
              <Placeholder></Placeholder>
            </Feed.Date>
            <Feed.Summary>
              <Placeholder>
                <Placeholder.Line /> <Placeholder.Line />
              </Placeholder>
            </Feed.Summary>
          </Feed.Content>
          <Feed.Content>
            <Feed.Date>
              <Placeholder></Placeholder>
            </Feed.Date>
            <Feed.Summary>
              <Placeholder>
                <Placeholder.Line /> <Placeholder.Line />
              </Placeholder>
            </Feed.Summary>
          </Feed.Content>
          <Feed.Content>
            <Feed.Date>
              <Placeholder></Placeholder>
            </Feed.Date>
            <Feed.Summary>
              <Placeholder>
                <Placeholder.Line /> <Placeholder.Line />
              </Placeholder>
            </Feed.Summary>
          </Feed.Content>
        </>
      ) : feed.data ? (
        feed.data.feed &&
        _.map(feed.data.feed, function (f, i) {
          return (
            <FeedEvent
              key={`activity-feed-${i}`}
              verb={f.type}
              date={parseISO(f.date_updated)}
              calories={f.calorie_array}
              data={f.data_array}
              quantity={f.quantity_array}
            />
          );
        })
      ) : (
        <Message>
          <Message.Header>Activity Stream</Message.Header>
          <p>Your latest activity will appear here once you start.</p>
        </Message>
      )}
    </Feed>
  );
};

const mapStateToProps = (state) => {
  return {
    diary: state.diary.diary,
  };
};

export default connect(mapStateToProps)(Timeline);
