import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Menu,
  Segment,
  Accordion,
  List,
  Divider,
} from "semantic-ui-react";
import { useQuery, useQueryClient } from "react-query";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import classes from "../Landing/Landing.module.scss";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import useWindowSize from "../../utility/useWindowSize";
import _ from "lodash";
const Guide = () => {
  const [menu, setMenu] = useState([]);
  const [slug, setSlug] = useState(null);
  // https://cdn.contentful.com/spaces/tq9y35xdll24/entries
  const params = useParams();
  //https://cdn.contentful.com/spaces/tq9y35xdll24/entries?content_type=guide&fields.category.sys.id=1SD4d6PdQbh72vPM6irVNe

  const queryClient = new useQueryClient();
  const window = useWindowSize();
  let config = {
    headers: {
      Authorization: "Bearer " + "rG32T6ZnypFo4YhCut1tcYzZQlMg-sf-B4fiS-0Qa38",
    },
  };

  const getPages = () =>
    axios
      .get(
        "https://cdn.contentful.com/spaces/tq9y35xdll24/entries?content_type=guide",
        config
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

  const pages = useQuery("pages", getPages, {
    refetchOnWindowFocus: false,
  });

  const getPage = (slug) =>
    axios
      .get(
        "https://cdn.contentful.com/spaces/tq9y35xdll24/entries?content_type=guide&limit=1&fields.slug=" +
          slug,
        config
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

  let pageSlug = "introduction";
  if (params.slug) {
    pageSlug = params.slug;
  }

  const page = useQuery(pageSlug, () => getPage(pageSlug), {
    refetchOnWindowFocus: false,
  });

  console.log(page);

  useEffect(() => {
    if (!menu.length) {
      if (pages.isFetched && pages.data) {
        axios
          .get(
            "https://cdn.contentful.com/spaces/tq9y35xdll24/entries?content_type=guideCategory&order=fields.menuOrder",
            config
          )
          .then(function (response) {
            const guideReduction = _.reduce(
              pages.data.items,
              function (acc, curr) {
                const item = {
                  id: curr.sys.id,
                  slug: curr.fields.slug,
                  categoryID: curr.fields.category[0].sys.id,
                  heading: curr.fields.heading,
                  menu_order: curr.fields.menuOrder || 0,
                };

                return [...acc, item];
              },
              []
            );

            let menuReducer = _.reduce(
              response.data.items,
              function (acc, curr) {
                let items = _.filter(guideReduction, {
                  categoryID: curr.sys.id,
                });

                items = _.orderBy(items, ["menu_order"], ["asc"]);

                const menuItem = {
                  id: curr.sys.id,
                  menuSlug: curr.fields.categorySlug,
                  menuItem: curr.fields.categoryTitle,
                  menuOrder: curr.fields.menuOrder || 0,
                  items: items,
                };

                return [...acc, menuItem];
              },
              []
            );
            menuReducer = _.orderBy(menuReducer, ["menuOrder"], ["asc"]);
            setMenu(menuReducer);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }, [pages]);

  const panels = _.map(menu, function (m) {
    const list = _.map(m.items, function (i) {
      return (
        <NavLink
          key={i.id}
          role="listitem"
          to={`/guide/${m.menuSlug}/${i.slug}`}
          activeClassName="active"
          className="item"
        >
          {i.heading}
        </NavLink>
      );
    });
    const Content = (
      <Accordion.Content>
        <List link>{list}</List>
      </Accordion.Content>
    );
    return {
      key: m.id,
      title: m.menuItem,
      content: Content,
    };
  });

  return (
    <Container style={{ flex: 1 }}>
      <Grid>
        {window.width < 992 && (
          <Grid.Row>
            <Grid.Column>
              <Accordion styled panels={panels} />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          {window.width > 991 && (
            <Grid.Column width={4}>
              <Menu pointing secondary vertical>
                {_.map(menu, function (m) {
                  return (
                    <Menu.Item key={m.id}>
                      <Menu.Header>{m.menuItem}</Menu.Header>
                      {_.map(m.items, function (i) {
                        return (
                          <NavLink
                            key={i.id}
                            to={`/guide/${m.menuSlug}/${i.slug}`}
                            activeClassName="active"
                            className="item"
                          >
                            {i.heading}
                          </NavLink>
                        );
                      })}
                    </Menu.Item>
                  );
                })}
              </Menu>
            </Grid.Column>
          )}

          <Grid.Column computer={12} mobile={16} tablet={16}>
            <Segment piled padded="very" style={{ borderRadius: "5px" }}>
              {page.isSuccess && (
                <>
                  <h1>{page.data.items[0].fields.heading}</h1>
                  <div>
                    {documentToReactComponents(page.data.items[0].fields.text)}
                  </div>
                </>
              )}
            </Segment>
            <Divider hidden />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default Guide;
