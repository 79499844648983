import * as actionTypes from "../actions/plannerActions";
import _ from "lodash";

import { updateDayStreak } from "../actions/diaryActions";

const initialState = {
  planner: [],
  plannerLoaded: false,
  dragging: false,
  workouts: [],
  selectedRecipes: [],
  selectedWorkouts: [],
  selectedCards: [],
  customCards: [
    {
      id: 1,
      type: "custom",
      name: "Treat Meal",
      icon: "treat",
      assumedCalories: 1000,
    },
    {
      id: 2,
      type: "custom",
      name: "Eating Out",
      icon: "eatout",
      assumedCalories: 1000,
    },
  ],
};

const reducer = (state = initialState, action) => {
  let plannerCopy, newDate;
  switch (action.type) {
    case actionTypes.REMOVE_SEGMENT:
      plannerCopy = [...state.planner];
      const updateItem = _.remove(plannerCopy, function (el) {
        return el.date == action.date;
      })[0];

      const removed = _.remove(updateItem.data, function (el) {
        return el.uuid == action.segment;
      });
      // console.log("removed:", removed);
      // console.log("new item:", updateItem);

      return { ...state, planner: [...plannerCopy, updateItem] };

    case actionTypes.STOP_DRAG:
      return { ...state, dragging: false };
    case actionTypes.INIT_DRAG:
      return { ...state, dragging: action.day };
    case actionTypes.UPDATE_SELECTED_RECIPES:
      return { ...state, selectedRecipes: [...action.payload] };
    case actionTypes.UPDATE_SELECTED_WORKOUTS:
      return { ...state, selectedWorkouts: [...action.payload] };
    case actionTypes.UPDATE_SELECTED_CARDS:
      return { ...state, selectedCards: [...action.payload] };
    case actionTypes.CLEAR_SELECTED:
      return {
        ...state,
        selectedWorkouts: [],
        selectedRecipes: [],
        selectedCards: [],
      };
    case actionTypes.ClEAR_DAY:
      plannerCopy = [...state.planner];
      const removedDay = _.remove(plannerCopy, function (p) {
        return p.date == action.payload;
      });

      const lockedItems = [];
      _.map(removedDay, function (p) {
        _.find(p.data, function (d) {
          if (parseInt(d.locked) == 1) {
            lockedItems.push(d);
          }
        });
      });

      const newPlanItem = {
        data: lockedItems,
        date: removedDay[0].date,
      };

      return { ...state, planner: [...plannerCopy, newPlanItem] };
    case actionTypes.UPDATE_DAY:
      plannerCopy = [...state.planner];
      newDate = _.find(plannerCopy, function (el) {
        return el.date == action.payload.date;
      });
      if (!newDate) {
        newDate = {
          date: parseInt(action.payload.date),
          data: [...action.payload.data],
        };
      } else {
        newDate.data = [...action.payload.data];
      }
      _.remove(plannerCopy, function (p) {
        return p.date == action.payload.date;
      });

      plannerCopy = [...plannerCopy, newDate];
      return { ...state, planner: plannerCopy };

    case actionTypes.INSERT_DAY:
      plannerCopy = [...state.planner];
      newDate = _.find(plannerCopy, function (el) {
        return el.date == action.payload.date;
      });

      if (!newDate) {
        newDate = {
          date: parseInt(action.payload.date),
          data: [...action.payload.data],
        };
      } else {
        newDate.data = [...newDate.data, ...action.payload.data];
      }

      _.remove(plannerCopy, function (p) {
        return p.date == action.payload.date;
      });
      plannerCopy = [...plannerCopy, newDate];
      return { ...state, planner: plannerCopy };
    case actionTypes.INIT_PLANNER || "LOGIN_SUCCESS":
      return {
        ...state,
        planner: [...action.payload.planner],
        workouts: [...action.payload.workouts],
        customCards: [...action.payload.customCards],
        plannerLoaded: true,
      };
    case actionTypes.PLANNER_CHANGE_WEEKS:
      plannerCopy = [...state.planner];
      let updatecopy = [...plannerCopy, ...action.payload.planner];
      return {
        ...state,
        planner: [...updatecopy],
      };
    case actionTypes.UPDATE_SEGMENT:
      plannerCopy = [...state.planner];
      let updateDay = _.remove(plannerCopy, {
        data: [{ uuid: action.payload.uuid }],
      })[0];

      let updateSegment = {
        ..._.remove(updateDay.data, { uuid: action.payload.uuid })[0],
        ...action.payload,
      };

      updateDay.data = _.sortBy(
        [...updateDay.data, updateSegment],
        "segment_order"
      );

      plannerCopy = [...plannerCopy, updateDay];

      return { ...state, planner: plannerCopy };
    case actionTypes.INSERT_CARD:
      return { ...state, customCards: [...state.customCards, action.payload] };
    case actionTypes.UPDATE_CARD:
      plannerCopy = state.customCards;
      const newCard = { ...action.payload, id: action.id };

      const updateCard = _.find(plannerCopy, { id: parseInt(action.id) });

      updateCard.name = newCard.name;
      updateCard.icon = newCard.icon;
      updateCard.assumedCalories = newCard.assumedCalories;

      return { ...state, customCards: [...plannerCopy] };

    case actionTypes.ARCHIVE_CARD:
      plannerCopy = state.customCards;
      const updatedCard = _.find(plannerCopy, {
        id: parseInt(action.payload),
      });
      updatedCard.archived = 1;
      plannerCopy = [...plannerCopy, updatedCard];
      return { ...state, customCards: [...plannerCopy] };
    default:
      return state;
  }
};

export default reducer;
