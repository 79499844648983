import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { Card, Grid, Label, Icon, Confirm } from "semantic-ui-react";
import classes from "./Custom.module.scss";
import CustomIcon from "../../components/CustomIcon";
const CustomCard = (props) => {
  const [archiveConfirm, setArchiveConfirm] = useState(false);
  return (
    <Grid.Column className={classes.CustomCard}>
      <div>
        <Card onClick={() => props.toggleCard()}>
          <div className={`${classes.CardIcon} ${props.icon}`}>
            <div className={classes.Icon}>
              <CustomIcon icon={props.icon} />
            </div>
            <div className={classes.Caption}>
              <h3>{props.name}</h3>
            </div>

            {props.selected && (
              <div className={classes.SelectedImage}>
                <Icon circular inverted color="green" name="check" />
              </div>
            )}
          </div>
          {parseInt(props.id) > 2 ? (
            <div className={classes.editCard}>
              <Icon
                name="edit"
                onClick={(e) => {
                  e.stopPropagation();
                  props.edit(props.id);
                }}
              />
              <Confirm
                open={archiveConfirm}
                header="Archive Custom Card"
                content="Are you sure you want to archive this card?"
                cancelButton="Cancel"
                confirmButton="Archive"
                onCancel={(e) => {
                  e.stopPropagation();
                  setArchiveConfirm(false);
                }}
                onConfirm={(e) => {
                  e.stopPropagation();
                  props.archive(props.id);
                }}
              />
              <Icon
                onClick={(e) => {
                  e.stopPropagation();
                  setArchiveConfirm(true);
                }}
                name="trash"
              />
            </div>
          ) : null}
        </Card>
      </div>
    </Grid.Column>
  );
};

export default CustomCard;
