import React, { useState, useEffect } from "react";
import * as EmailValidator from "email-validator";
import zxcvbn from "zxcvbn";
import "./PasswordStrength.css";
import PASButton from "./PlanandShopLogin";
import {
  Header,
  Grid,
  Button,
  Segment,
  Form,
  Dimmer,
  Loader,
  Checkbox,
  Divider,
  Image,
  Label,
  Container,
  Message,
} from "semantic-ui-react";
import { connect, useDispatch } from "react-redux";
import {
  cancelSignup,
  facebookLogin,
  signUp,
  clearErrors,
} from "../../store/actions/authActions";
import { useNavigate, Navigate } from "react-router-dom";
import FBLoginButton from "../../components/login/fbLoginButton";
import classes from "../Landing/Landing.module.scss";

const createPasswordLabel = (result) => {
  switch (result.score) {
    case 0:
      return "Weak";
    case 1:
      return "Weak";
    case 2:
      return "Fair";
    case 3:
      return "Good";
    case 4:
      return "Strong";
    default:
      return "Weak";
  }
};

const SignUp = (props) => {
  const [firstname, setFirstName] = useState({ text: "", errors: null });
  const [lastname, setLastName] = useState({ text: "", errors: null });
  const [terms, setTerms] = useState({ status: false, error: null });
  const [mailing, setMailing] = useState(false);
  const [email, setEmail] = useState({ text: "", errors: null });
  const [password, setPassword] = useState({
    text: "",
    errors: null,
    strength: zxcvbn(""),
    strengthClass: "Weak",
  });
  let facebookPreload = null;
  const history = useNavigate();
  //graph.facebook.com/v2.12/me?fields=name,picture.width(800).height(800),first_name,last_name,email&access_token=${props.auth.facebook.accessToken}'
  const facebookResponse = (response) => {
    props.facebook_login(response);
  };

  const passwordChangeListener = (e) => {
    const testedResult = zxcvbn(e.target.value);
    const passwordLabel = createPasswordLabel(testedResult);
    setPassword({
      text: e.target.value,
      error: null,
      strength: testedResult,
      strengthClass: passwordLabel,
    });
  };

  const launchSignIn = () => {
    props.cancelSignup();
    history("/sign-in");
  };

  useEffect(() => {
    const populateFacebook = () => {
      if (props.auth.facebook) {
        const fullname = props.auth.facebook.name;
        setFirstName({
          ...firstname,
          text: fullname.split(" ").slice(0, -1).join(" "),
        });
        setLastName({
          ...lastname,
          text: fullname.split(" ").slice(-1).join(" "),
        });
        setEmail({
          ...email,
          text: props.auth.facebook.email,
        });
      }
    };
    populateFacebook();
  }, [props.auth.facebook]);

  const validateSignup = () => {
    let errors = 0;
    if (!firstname.text) {
      setFirstName({ ...firstname, error: true });
      errors++;
    }
    if (!firstname.text) {
      setLastName({ ...lastname, error: true });
      errors++;
    }
    if (!EmailValidator.validate(email.text)) {
      setEmail({ ...email, error: true });
      errors++;
    }

    if (!terms.status) {
      setTerms({ ...terms, error: true });
      errors++;
    }
    let facebook = false;

    if (props.auth.facebook) {
      facebook = props.auth.facebook.id;
    }
    if (!facebook) {
      if (password.strength.score < 3) {
        setPassword({ ...password, error: true });
        errors++;
      }
    }

    if (errors < 1) {
      const data = {
        firstname: firstname.text,
        lastname: lastname.text,
        email: email.text,
        password: password.text,
        facebook,
        mailing,
      };
      props.signUp(data);
    }
  };

  if (props.auth.facebook) {
    facebookPreload = (
      <>
        <Image
          src={props.auth.facebook.picture.data.url}
          size="small"
          circular
          style={{ margin: "0 auto" }}
        />
        <Header as="h4">Hi {props.auth.facebook.name}</Header>
        <p>We just need a bit more information to get you started!</p>
      </>
    );
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    // props.login(payload);
    validateSignup();
  };
  if (props.auth.loggedIn) {
    history("/home");
  }

  return (
    <Container
      className={classes.Container}
      fluid
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/landing/spheres.png)`,
      }}
    >
      <Grid
        textAlign="center"
        style={{ minHeight: "100vh", padding: "1em" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" textAlign="center">
            Sign up for an account
          </Header>

          <Form size="large" onSubmit={onFormSubmit}>
            <Segment textAlign="left">
              {props.auth.registering ||
                (props.auth.loggingIn && (
                  <Dimmer active>
                    <Loader content="Registering" />
                  </Dimmer>
                ))}
              {facebookPreload}
              <Grid.Row>
                <Grid columns={2}>
                  <Grid.Column>
                    {firstname.error ? (
                      <Label basic color="red" pointing="below">
                        Please enter your first name
                      </Label>
                    ) : null}
                    <Form.Input
                      fluid
                      icon="user"
                      iconPosition="left"
                      placeholder="First name"
                      type="text"
                      value={firstname.text}
                      onChange={(e) => {
                        setFirstName({ text: e.target.value, error: null });
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    {lastname.error ? (
                      <Label basic color="red" pointing="below">
                        Please enter your last name
                      </Label>
                    ) : null}
                    <Form.Input
                      fluid
                      icon="user"
                      iconPosition="left"
                      placeholder="Last name"
                      type="text"
                      value={lastname.text}
                      onChange={(e) => {
                        setLastName({ text: e.target.value, error: null });
                      }}
                    />
                  </Grid.Column>
                </Grid>

                <Grid columns={1} stackable>
                  <Grid.Column>
                    {email.error ? (
                      <Label basic color="red" pointing="below">
                        Please enter a valid email address
                      </Label>
                    ) : null}
                    <Form.Input
                      fluid
                      icon="mail"
                      iconPosition="left"
                      placeholder="Email Address"
                      value={email.text}
                      type="text"
                      onChange={(e) => {
                        setEmail({ text: e.target.value, error: null });
                      }}
                    />
                  </Grid.Column>
                  {!props.auth.facebook && (
                    <>
                      <Grid.Column>
                        {password.error && (
                          <Label basic color="red" pointing="below">
                            You must enter a password strength of at least
                            'good'.
                          </Label>
                        )}
                        <Form.Input
                          fluid
                          icon="lock"
                          iconPosition="left"
                          placeholder="Password"
                          type="password"
                          value={password.text}
                          onChange={(e) => {
                            passwordChangeListener(e);
                          }}
                        />
                        {password.text && (
                          <>
                            <progress
                              className={`password-strength-meter-progress strength-${password.strengthClass}`}
                              value={password.strength.score}
                              max="4"
                            />
                            <label className="password-strength-meter-label">
                              {password && (
                                <>
                                  <strong>Password strength:</strong>{" "}
                                  {password.strengthClass}
                                </>
                              )}
                            </label>
                          </>
                        )}
                      </Grid.Column>
                    </>
                  )}
                  <Grid.Column>
                    {terms.error && (
                      <Label basic color="red" pointing="below">
                        Please accept the terms and counditions
                      </Label>
                    )}
                    <Checkbox
                      label="I agree to the terms and conditions"
                      toggle
                      checked={terms.status}
                      onChange={() => {
                        setTerms({
                          ...terms,
                          error: null,
                          status: !terms.status,
                        });
                      }}
                    />

                    <p style={{ marginTop: "0.5em", marginBottom: "0.2em" }}>
                      From time to time we send out tips and ideas for healthy
                      eating. You'll be able to unsubscribe at anytime.
                    </p>
                    <Checkbox
                      label="I agree to be contacted"
                      toggle
                      checked={mailing}
                      onChange={() => setMailing(!mailing)}
                    />
                    <Divider hidden />
                    {props.auth.error && props.auth.error.errors && (
                      <Message color="red">
                        {props.auth.error.errors[0]}
                      </Message>
                    )}
                    <Button primary fluid size="large" type="submit">
                      Sign Up
                    </Button>

                    {!props.auth.facebook && (
                      <>
                        <Divider horizontal>Or</Divider>
                        <PASButton />
                        <Divider basic />
                        <FBLoginButton callback={facebookResponse} />
                      </>
                    )}
                  </Grid.Column>
                </Grid>
              </Grid.Row>
            </Segment>
          </Form>

          <Button
            style={{ marginTop: "2em" }}
            className="rounded"
            secondary
            onClick={() => history("/")}
          >
            Back to Homepage
          </Button>
          <Button
            style={{ marginTop: "2em" }}
            className="rounded"
            color="teal"
            onClick={() => launchSignIn()}
          >
            I'm already registered
          </Button>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancelSignup: () => dispatch(cancelSignup()),
    facebook_login: (payload) => dispatch(facebookLogin(payload)),
    signUp: (payload) => dispatch(signUp(payload)),
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
