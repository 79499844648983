import * as actionTypes from "../actions/shoppingActions";
import _ from "lodash";

const initialState = {
  shopping: [],
  initShopping: false,
  purchased: [],
  additional: [],
  stockCupboard: [],
  extra: [],
  filter: "aisle",
};

const reducer = (state = initialState, action) => {
  let purchasedCopy, shoppingCopy, additionalCopy, extraCopy;
  switch (action.type) {
    case actionTypes.INSERT_SHOPPING:
      return { ...state, shopping: [...action.payload] };

    case actionTypes.INIT_SHOPPING:
      return {
        ...state,
        shopping: [...action.payload.shopping],
        purchased: [...action.payload.purchased],
        additional: [...action.payload.additional],
        extra: [...action.payload.extras],
        initShopping: true,
      };
    case actionTypes.INIT_ADDITIONAL:
      return { ...state, additional: [...action.payload] };
    case actionTypes.INIT_PURCHASES:
      return { ...state, purchased: [...action.payload] };
    case actionTypes.CLEAR_SHOPPING:
      return {
        ...state,
        shopping: [],
        extra: [],
        additional: [],
        purchased: [],
      };
    case actionTypes.REMOVE_SHOPPING_IDS:
      shoppingCopy = [...state.shopping];
      purchasedCopy = [...state.purchased];
      additionalCopy = [...state.additional];
      action.payload.map(function (el) {
        if (el.type === "shopping") {
          _.remove(shoppingCopy, { shopping_id: el.id });
        }
        if (el.type === "addition") {
          _.remove(additionalCopy, { id: el.id });
        }
        _.remove(purchasedCopy, { shopping_id: el.id, type: el.type });
      });
      return {
        ...state,
        purchased: [...purchasedCopy],
        shopping: [...shoppingCopy],
        additional: [...additionalCopy],
      };
    case actionTypes.UPDATE_INGREDIENT_ADJUSTMENT:
      additionalCopy = [...state.additional];

      // _.remove(additionalCopy, {ingredienet_id});
      _.remove(additionalCopy, {
        ingredient_id: action.payload.ingredient_id,
        unit: action.payload.unit,
      });
      additionalCopy = [...additionalCopy, action.payload];
      return { ...state, additional: additionalCopy };
    case actionTypes.UPDATE_SHOPPING_FILTER:
      return { ...state, filter: action.payload };
    case actionTypes.UPDATE_SHOPPING_PURCHASED:
      purchasedCopy = [...state.purchased];

      action.payload.map(function (el) {
        _.remove(purchasedCopy, { shopping_id: el.shopping_id, type: el.type });
        purchasedCopy = [...purchasedCopy, el];
      });

      return { ...state, purchased: purchasedCopy };
    case actionTypes.INSERT_ITEM:
      extraCopy = [...state.extra, action.payload];
      return { ...state, extra: extraCopy };
    case actionTypes.UPDATE_ITEM:
      extraCopy = [...state.extra].map(function (i) {
        if (i.id == action.payload.id) {
          return action.payload;
        } else {
          return i;
        }
      });
      return { ...state, extra: extraCopy };
    case actionTypes.REMOVE_ITEM:
      extraCopy = _.reduce(
        state.extra,
        function (acc, curr) {
          if (curr.id !== action.payload) {
            return [...acc, curr];
          } else {
            return acc;
          }
        },
        []
      );
      return { ...state, extra: extraCopy };
    default:
      return state;
  }
};
export default reducer;
