import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";

import { Modal, Button, Input, Icon } from "semantic-ui-react";
import classes from "./Shopping.module.scss";

const StockAdjustModal = (props) => {
  const [value, setValue] = useState(0);
  const [total, setTotal] = useState(0);
  const [adjustMode, setAdjustMode] = useState("add");

  useEffect(() => {
    if (props.data) {
      if (props.data.amount > 0) {
        setTotal(parseFloat(props.data.amount));
      }
      if (props.data.initialQuantity) {
        if (props.data.initialQuantity < 0) {
          setValue(parseFloat(-1 * props.data.initialQuantity));
          setAdjustMode("minus");
        } else {
          setValue(parseFloat(props.data.initialQuantity));
          setAdjustMode("add");
        }
      }
    }
  }, [props.data]);

  useEffect(() => {
    const valCopy = parseFloat(value);
    if (isNaN(valCopy)) {
      setTotal(props.data.amount);
    } else {
      if (adjustMode === "add") {
        setTotal(valCopy + props.data.amount);
      } else {
        if (props.data.amount - valCopy < 0) {
          setTotal(0);
        } else {
          setTotal(props.data.amount - valCopy);
        }
      }
    }
  }, [value, adjustMode]);

  return (
    <Modal size="mini" open={props.show} className={classes.adjustModal}>
      <Modal.Header>Adjust Ingredient Quantity</Modal.Header>
      <Modal.Content>
        <p>
          Update quantities for{" "}
          <span className={classes.RecipeTitle}>{props.data.name}</span>
        </p>
        <div className={classes.adjustGroupInput}>
          <Button.Group>
            <Button
              icon
              onClick={() => {
                setAdjustMode("minus");
              }}
              color={adjustMode == "minus" ? "red" : "grey"}
            >
              <Icon name="minus" />
            </Button>
            <Button
              onClick={() => {
                setAdjustMode("add");
              }}
              color={adjustMode == "add" ? "green" : "grey"}
              className={`${classes.Increase}`}
              icon
            >
              <Icon name="plus" />
            </Button>
          </Button.Group>
          <Input
            className={classes.QuantityInput}
            label={{ basic: true, content: props.data.converted_unit }}
            type="number"
            labelPosition="right"
            placeholder=""
            onChange={(e, d) => {
              setValue(d.value);
            }}
            value={value || ""}
          />
        </div>
        <p style={{ marginTop: "1em" }}>
          Result: {total} {props.data.converted_unit} {props.data.name}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={() => {
            setValue(0);
            props.cancel();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            props.update(
              props.data.ing_id,
              props.data.converted_unit,
              value,
              props.data.name,
              props.data.cupboard,
              props.data.cupboard_id,
              adjustMode
            );
            setValue(0);
          }}
          positive
        >
          Update Ingredient
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default StockAdjustModal;
