import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import _ from "lodash";
import classes from "./Planner.module.scss";
import MacroTotals from "./MacroTotals";
import { connect, useDispatch } from "react-redux";
import { getRecipeMacros } from "../../utility/macros";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBurn } from "@fortawesome/free-solid-svg-icons";
import NutritionAdvanced from "./NutritionAdvanced";
import { Loader, Grid, Icon } from "semantic-ui-react";

const NutritionOverview = (props) => {
  const [calories, setCalories] = useState(null);
  const [macroTotals, setMacroTotals] = useState([]);
  const [recipeMacros, setRecipeMacros] = useState(null);
  const [advanced, showAdvanced] = useState(false);
  useEffect(() => {
    if (props.recipes.recipesLoaded && props.recipes.recipe_details) {
      const macros = getRecipeMacros(props.weekEntries, props.recipes).filter(
        function (day) {
          return !Number.isNaN(day.calories);
        }
      );
      setRecipeMacros(macros);

      const macroTotals = _(macros)
        .groupBy("date")
        .map((date, id) => ({
          date: id,
          calories: _.sumBy(date, "calories"),
        }))
        .value();
      // console.log(macroTotals);
      if (macroTotals.length > 0) {
        const totalRounded = _.sumBy(macroTotals, "calories");

        setCalories(Math.round(totalRounded / macroTotals.length));
      } else {
        const emptyCal = <span>--&nbsp;</span>;
        setCalories(emptyCal);
      }

      setMacroTotals(macroTotals);
    }
  }, [props.recipes.raw_macros, props.weekEntries]);
  const macroTarget =
    props.user.options.tdee && props.user.options.tdee.dailyTarget
      ? props.user.options.tdee.dailyTarget
      : props.defaultTarget;
  const mealTarget =
    props.user.options.tdee && props.user.options.tdee.meal
      ? props.user.options.tdee.meal
      : null;
  const snackTarget =
    props.user.options.tdee && props.user.options.tdee.snack
      ? props.user.options.tdee.snack
      : null;

  return (
    <div data-tour="nutrition" className={classes.nutritionOverview}>
      <NutritionAdvanced
        open={props.showAdvanced}
        close={() => props.closeAdvanced()}
        macros={recipeMacros}
        dailyTarget={macroTarget}
        mealTarget={mealTarget}
        snackTarget={snackTarget}
      />

      <Grid style={{ flex: 1 }}>
        <Grid.Row>
          <Grid.Column computer={16} tablet={8} mobile={8}>
            <div className={classes.calories}>
              <FontAwesomeIcon icon={faBurn} />

              <div className={classes.calorieFigure}>
                {calories != null ? (
                  <>
                    <div className={classes.calorieBox}>
                      <span className={classes.calorieNumber}>
                        {calories == 0 ? "--" : calories}
                      </span>
                      <span className={classes.calorieUnit}>Kcal</span>
                    </div>
                    <div className={classes.calorieCaption}>Daily Avg</div>
                  </>
                ) : (
                  <Loader active inline="centered" />
                )}
              </div>
            </div>
          </Grid.Column>
          <Grid.Column computer={16} tablet={8} mobile={8}>
            <MacroTotals
              target={macroTarget}
              date={props.date}
              data={macroTotals}
              default={
                !(
                  props.user.options.tdee && props.user.options.tdee.dailyTarget
                )
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    recipes: state.recipes,
    user: state.auth.user,
    defaultTarget: state.auth.defaultCalorieTarget,
  };
};

export default connect(mapStateToProps)(NutritionOverview);
