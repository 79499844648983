import classes from "./Workouts.module.scss";
import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { connect } from "react-redux";
import WorkoutCard from "./WorkoutCard";
import { Grid } from "semantic-ui-react";
import { updateSelectedWorkouts } from "../../store/actions/plannerActions";
import _ from "lodash";

const WorkoutsBrowser = (props) => {
  const toggleWorkout = (id) => {
    const currentWorkouts = [...props.selectedWorkouts];

    if (
      _.find(currentWorkouts, function (o) {
        return o == id;
      })
    ) {
      _.remove(currentWorkouts, function (el) {
        return el == id;
      });
    } else {
      currentWorkouts.push(id);
    }
    props.updateSelectedWorkouts(currentWorkouts);
  };

  return (
    <Grid columns={4} doubling className={classes.workoutContainer}>
      {props.workouts.length &&
        props.workouts.map(function (w, i) {
          return (
            <WorkoutCard
              key={`workout-${i}`}
              name={w.name}
              source={w.source}
              video_url={w.video1_url}
              cycle={w.master_cycle}
              type={w.type}
              thumbnail={w.thumbnail}
              toggleWorkout={() => toggleWorkout(w.id)}
              selected={
                props.selectedWorkouts.indexOf(w.id) > -1 ? true : false
              }
            />
          );
        })}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    workouts: state.planner.workouts,
    selectedWorkouts: state.planner.selectedWorkouts,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateSelectedWorkouts: (array) => dispatch(updateSelectedWorkouts(array)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutsBrowser);
