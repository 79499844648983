import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { connect } from "react-redux";
import { Container, Grid, Header, Segment } from "semantic-ui-react";
import { Route, Routes } from "react-router-dom";

import IngredientQueue from "./IngredientQueue";

const AdminHome = (props) => {
  return (
    <Container style={{ padding: "2em 5em" }}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h1">Mission Control</Header>
            <Routes>
              <Route path="/" component={IngredientQueue} />
            </Routes>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    //   getRecipes: () => dispatch(getRecipes()),
    //   resetRecipeFilters: (enable) => dispatch(resetRecipeFilters(enable)),
    //   clearSelected: () => dispatch(clearSelected()),
    //   updateXp: (payload) => dispatch(updateXp(payload)),
    //   updateDiary: (payload) => dispatch(updateDiary(payload)),
    //   updateDiarySegment: (id, payload) =>
    //     dispatch(updateDiarySegment(id, payload)),
    //   replaceRecipe: (uuid, replaceID, type, day) =>
    //     dispatch(replaceRecipe(uuid, replaceID, type, day)),
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AdminHome);
