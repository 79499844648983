import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";

import { Modal, Button, Input, Grid, Icon, Label } from "semantic-ui-react";
import classes from "./Shopping.module.scss";

const ShoppingAdjustModal = (props) => {
  const [value, setValue] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (props.data) {
      setTotal(props.data.amount);
    }
  }, [props.data]);

  useEffect(() => {
    if (!isNaN(parseFloat(value))) {
      const newTotal = parseFloat(props.data.amount) - parseFloat(value);

      if (newTotal < 0) {
        setTotal(0);
      } else {
        setTotal(newTotal);
      }
    } else {
      setTotal(props.data.amount);
    }
  }, [value]);

  return (
    <Modal size="mini" open={props.show} className={classes.adjustModal}>
      <Modal.Header>Adjust Stock Quantity</Modal.Header>
      <Modal.Content>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column style={{ display: "flex", flex: 0 }}>
              <Icon name="clipboard check" size="huge" />
            </Grid.Column>
            <Grid.Column style={{ flex: 1 }}>
              <p>
                Check your cupboards for{" "}
                <span className={classes.RecipeTitle}>{props.data.name}</span>{" "}
                and we'll subtract this from the quantity.
              </p>
              <p> If you have enough in stock, we'll hide it from your list.</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div style={{ marginTop: "2em" }} className={classes.QuantityInput}>
          <p style={{ textAlign: "center" }}>Amount in Stock:</p>
          <Input
            type="number"
            className={classes.QuantityInput}
            label={{ basic: true, content: props.data.converted_unit }}
            labelPosition="right"
            placeholder="Enter quantity..."
            onChange={(e, d) => {
              setValue(d.value);
            }}
            value={value || ""}
          />
        </div>
        <div className={classes.stockResult}>
          <p style={{ textAlign: "center" }}>Updated Shopping:</p>
          <Input
            className={classes.stockTotal}
            labelPosition="right"
            placeholder="Amount"
            value={total || "0"}
          >
            <Label basic>
              <Icon name="calculator" />
            </Label>
            <input />
            <Label basic>{props.data.converted_unit}</Label>
          </Input>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={() => {
            setValue(0);
            props.cancel();
          }}
        >
          Cancel
        </Button>
        <Button positive>Update Ingredient</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ShoppingAdjustModal;
