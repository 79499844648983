import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import localforage from "localforage";
import reduxReset from "redux-reset";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import authReducer from "./reducers/auth";
import recipeReducer from "./reducers/recipes";
import plannerReducer from "./reducers/planner";
import uiReducer from "./reducers/ui";
import shoppingReducer from "./reducers/shopping";
import xpReducer from "./reducers/xp";
import diaryReducer from "./reducers/diary";
import LogRocket from "logrocket";
import amplitude from "amplitude-js";

import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage: localforage,
  whitelist: ["recipes"],
  stateReconciler: autoMergeLevel2,
};

const appReducer = combineReducers({
  auth: authReducer,
  recipes: recipeReducer,
  planner: plannerReducer,
  shopping: shoppingReducer,
  ui: uiReducer,
  xp: xpReducer,
  diary: diaryReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "RESET") {
    localforage.setItem("persist:root", {});
    amplitude.getInstance().setUserId(null); // not string 'null'
    amplitude.getInstance().regenerateDeviceId();
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(thunk, LogRocket.reduxMiddleware()),
    reduxReset()
  )
);

// Tell react-snap how to save Redux state

export const persistedStore = persistStore(store);
