import React, { useState } from "react";

import { Grid, Button, Icon } from "semantic-ui-react";

import classes from "./Number.module.scss";

const NumberPicker = ({ children, options, onChange, value }) => {
  return (
    <div className={classes.Container}>
      <select
        className={classes.selectOverlay}
        onChange={onChange}
        id="filter-select"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
      <label className={classes.custom} htmlFor="filter-select">
        {children}
      </label>
    </div>
  );
};

export default NumberPicker;
