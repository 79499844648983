import React, { useState, useEffect } from "react";
import { Label, Ref } from "semantic-ui-react";
import tags from "./tags.scss";

function text_color(color) {
  if (!color) {
    return "black";
  }
  const hex = color.replace("#", "");
  const c_r = parseInt(hex.substr(0, 2), 16);
  const c_g = parseInt(hex.substr(2, 2), 16);
  const c_b = parseInt(hex.substr(4, 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  if (brightness > 155) {
    return "black";
  } else {
    return "white";
  }
}

const tag = (props) => {
  return (
    <Label className="tagLabel">
      <Label.Detail className="tagDetail">{props.label}</Label.Detail>
    </Label>
  );
};

export const TagButton = React.forwardRef((props, ref) => {
  const [toggled, setToggled] = useState(props.toggle || false);

  useEffect(() => {
    props.toggle ? setToggled(true) : setToggled(false);
  }, [props.toggle]);

  return (
    <Ref innerRef={ref}>
      <Label
        onClick={() => {
          setToggled(!toggled);
          props.toggleCategory();
        }}
        className={`tagLabel button ${toggled && "active"}`}
      >
        <Label.Detail className="tagDetail">{props.label}</Label.Detail>
      </Label>
    </Ref>
  );
});

export default tag;
