import React from "react";
import classes from "./Instructions.module.scss";
import { Message } from "semantic-ui-react";
import { Form, TextArea, Header, Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

const Tip = (props) => {
  if (!props.text) {
    return null;
  } else {
    return (
      <Message positive>
        <Message.Header>Tip</Message.Header>
        <p>{props.text}</p>
      </Message>
    );
  }
};
const openTab = (url) => {
  window.open(url);
};

const Alternative = (props) => {
  if (!props.text) {
    return null;
  } else {
    return (
      <Message info>
        <Message.Header>Alternative Ingredients</Message.Header>
        <p>{props.text}</p>
      </Message>
    );
  }
};

const Instructions = (props) => {
  let method,
    instructions,
    alternative_text,
    tip_text = null;

  instructions = props.instructions;

  if (instructions) {
    let regex = /(?:Tips?\s*$\s*)((.*\s?)*)/gim;
    const tip = instructions.match(/(?:Tips?\s*$\s*)((.*\s?)*)/gim);
    let t;

    while ((t = regex.exec(tip)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (t.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      instructions = instructions.replace(t[0], "");

      tip_text = t[1];
    }
    regex = /(?:Alternative ingredients?$\s)((.*\s?)*)/;
    let a;

    while ((a = regex.exec(instructions)) !== null) {
      // This is necessary to avoid infinite loops with zero-width aatches
      if (a.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      instructions = instructions.replace(a[0], "");
      alternative_text = a[1];
    }
    method = instructions.split("\n").map((item, idx) => {
      let text = item;
      if (text.replace(/\s/g, "").length) {
        return <li key={idx}>{text}</li>;
      }
    });
  }

  return !props.editing ? (
    !props.instructions && props.source_method == "web" ? (
      <>
        {props.description && <Header as="h3">{props.description}</Header>}
        <Button
          onClick={(e) => {
            openTab(props.source);
          }}
          fluid
          className="rounded"
          color="teal"
        >
          <Icon name="external" />
          View instructions on the web
        </Button>
      </>
    ) : (
      <div className={classes.Container}>
        <Header as="h3">{props.description}</Header>
        <ol>{method}</ol>
        <Alternative text={alternative_text} />
        <Tip text={tip_text} />
      </div>
    )
  ) : (
    <div>
      <Form>
        <TextArea
          rows={10}
          value={props.instructions}
          onChange={function (e, d) {
            props.updateMethod(d.value);
          }}
          placeholder="Enter recipe method"
        />
      </Form>
    </div>
  );
};

export default Instructions;
