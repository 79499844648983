import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import recipes from "./Recipes.module.scss";
import classes from "../Screens.module.scss";

import { connect } from "react-redux";
import axios from "../../api/axios-token";
import IngredientsTab from "./components/Ingredients";
import Instructions from "./components/Instructions";
import Macros from "./components/Macros";
import { updateIngredient } from "../../utility/data";
import {
  getUserRecipeDetails,
  updateRecipes,
} from "../../store/actions/recipeActions";

import _ from "lodash";

import { Container, Grid, Header, Dimmer, Loader } from "semantic-ui-react";

import RecipeAmend from "./RecipeAmend";

const RecipeEdit = (props) => {
  let { id } = useParams();
  const [loaded, setLoaded] = useState(false);
  const recipeLoaded = () => {
    setLoaded(true);
  };
  return (
    <>
      <div>
        <Container className={classes.pageContainer}>
          <Dimmer active={!loaded} inverted>
            <Loader>Loading Recipe</Loader>
          </Dimmer>
          <RecipeAmend
            id={id}
            action="edit"
            initialize={() => recipeLoaded()}
          />
        </Container>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRecipes: (payload) => dispatch(updateRecipes(payload)),
    getUserRecipeDetails: () => dispatch(getUserRecipeDetails()),
  };
};
const mapStateToProps = (state) => {
  return {
    recipes: state.recipes,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipeEdit);
